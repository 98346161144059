import { InversionContainer } from '@/controller/container'
import { InjectionTokens } from '@/controller/tokens'

import { UseCase } from '@/domain/core/UseCase'
import { Route } from '@/domain/models/Route/Route'
import { Result } from '@/domain/protocols/Result'
import { RouteState } from '@/domain/states/RouteState'

import { IUserRoutesRepository } from '@/data/UserRoutesRepository/IUserRoutesRepository'

import { IsActiveRouteInRoutesSavedUseCase } from './IsActiveRouteInRoutesSaved'

class DeleteUserRouteUseCase implements UseCase<string, Result<Route[]>> {
  private routeState: RouteState
  private userRoutesRepository: IUserRoutesRepository

  constructor() {
    const injectionContainer = InversionContainer.getInstance().getContainer()
    this.userRoutesRepository = injectionContainer.get<IUserRoutesRepository>(InjectionTokens.UserRoutesRepository)
    this.routeState = injectionContainer.get<RouteState>(InjectionTokens.RouteState)
  }

  async execute(routeId: string): Promise<Result<Route[]>> {
    try {
      if (new IsActiveRouteInRoutesSavedUseCase().execute()) {
        this.routeState.setIsSaved(false)
      }
      const deleteResult = await this.userRoutesRepository.deleteRoute(routeId)

      if (deleteResult.isFailure) return Result.fail(`serverDeleteError: ${deleteResult.error}`)

      return await this.userRoutesRepository.getRoutes()
    } catch (error) {
      return Result.fail(error)
    }
  }
}

export { DeleteUserRouteUseCase }
