import * as Turf from '@turf/turf'
import GeoJSON from 'geojson'

import { ADHP, Waypoint } from '@/domain/models'
import { Coordinates } from '@/domain/protocols/Coordinates'

import { calculateMagneticHeading, nmDistance } from '@/utils/coordinates'
import { limitString } from '@/utils/string'

export type WaypointStateType = 'default' | 'current' | 'past'

export type MapRouteWaypoint = {
  item: Waypoint
  state: WaypointStateType
}

export const MAP_SYMBOL_PRIORITIES = {
  CLOSE_CHART: 0,
  USER_LOCATION: 1,
  ADHP_HEADBOARD: 3,
  TARGET: 4,
  ROUTE_ADHP: 5,
  ROUTE_WAYPOINT: 10,
  ROUTE_LENGTH_HEADING: 15,
  MAP_ADHP: 20
}

const getWaypointState = (index: number, nextWaypointSequence: number): WaypointStateType => {
  if (!nextWaypointSequence) return 'default'

  const isPastItem = index + 1 < nextWaypointSequence
  const isCurrent = index + 1 === nextWaypointSequence

  if (isPastItem) {
    return 'past'
  } else if (isCurrent) {
    return 'current'
  } else {
    return 'default'
  }
}

type RubberbandMovingInfo = {
  index: number
  coordinates: Coordinates
}

export const makeGeoJsonPointFeatureCollection =
  (
    waypoints: Waypoint[],
    nextWaypointSequence: number,
    highlightedWaypoint: Waypoint,
    rubberbandInfo: RubberbandMovingInfo | null
  ) =>
  (): GeoJSON.FeatureCollection => {
    const waypointToFeature = (waypoint: Waypoint, index: number): GeoJSON.Feature => {
      return {
        type: 'Feature',
        id: waypoint.id.toString(),
        properties: {
          code: limitString(waypoint.getDisplayName(), 16),
          state: getWaypointState(index + 1, nextWaypointSequence),
          isAdhp: waypoint instanceof ADHP,
          priority: waypoint instanceof ADHP ? MAP_SYMBOL_PRIORITIES.ROUTE_ADHP : MAP_SYMBOL_PRIORITIES.ROUTE_WAYPOINT,
          labelOpacity: highlightedWaypoint
            ? highlightedWaypoint.id.toString() === waypoint.id.toString()
              ? 0.8
              : 0
            : 0.8,
          textOpacity: highlightedWaypoint ? (highlightedWaypoint.id.toString() === waypoint.id.toString() ? 1 : 0) : 1,
          waypointId: waypoint.id.toString()
        },
        geometry: {
          type: 'Point',
          coordinates: waypoint.coordinates.toArray()
        }
      }
    }

    const pointFeatureArray: GeoJSON.Feature[] = waypoints.map(waypointToFeature)

    if (rubberbandInfo) {
      pointFeatureArray[rubberbandInfo.index] = {
        ...pointFeatureArray[rubberbandInfo.index],
        properties: {
          ...pointFeatureArray[rubberbandInfo.index].properties,
          labelOpacity: 0,
          textOpacity: 0
        },
        geometry: {
          type: 'Point',
          coordinates: rubberbandInfo.coordinates.toArray()
        }
      }
    }

    return {
      type: 'FeatureCollection',
      features: pointFeatureArray
    }
  }

export const makeGeoJsonHighlightCircleFeatureCollection = (waypoint: Waypoint) => (): GeoJSON.FeatureCollection => {
  if (!waypoint)
    return {
      type: 'FeatureCollection',
      features: []
    }

  const highlightCircle: GeoJSON.Feature = {
    type: 'Feature',
    id: waypoint.id.toString(),
    properties: {
      code: limitString(waypoint.getDisplayName(), 16),
      isAdhp: waypoint instanceof ADHP,
      priority: waypoint instanceof ADHP ? MAP_SYMBOL_PRIORITIES.ROUTE_ADHP : MAP_SYMBOL_PRIORITIES.ROUTE_WAYPOINT
    },
    geometry: {
      type: 'Point',
      coordinates: waypoint.coordinates.toArray()
    }
  }

  return {
    type: 'FeatureCollection',
    features: [highlightCircle]
  }
}

export const makeGeoJsonLineFeatureCollection =
  (waypoints: Waypoint[], rubberbandInfo: RubberbandMovingInfo | null) => (): GeoJSON.FeatureCollection => {
    const coordinates: number[][] = []

    for (let i = 1; i < waypoints.length; i++) {
      const currentWaypoint =
        rubberbandInfo && rubberbandInfo.index === i ? rubberbandInfo.coordinates : waypoints[i].coordinates
      const pastWaypoint =
        rubberbandInfo && rubberbandInfo.index === i - 1 ? rubberbandInfo.coordinates : waypoints[i - 1].coordinates

      const npoints = Math.ceil(nmDistance(pastWaypoint, currentWaypoint) / 30)

      const segmentPoints = Turf.greatCircle(pastWaypoint.toArray(), currentWaypoint.toArray(), {
        npoints: npoints
      }).geometry.coordinates as number[][]

      if (i > 1) segmentPoints.shift()

      coordinates.push(...segmentPoints)
    }

    return {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates
          }
        }
      ]
    }
  }

export const makeGeoJsonLineLabelFeatureCollection =
  (waypoints: Waypoint[], nextWaypointSequence: number, rubberbandInfo: RubberbandMovingInfo) =>
  (): GeoJSON.FeatureCollection => {
    const lineFeatureArray: GeoJSON.Feature[] = []
    for (let i = 1; i < waypoints.length; i++) {
      if (rubberbandInfo && (rubberbandInfo.index === i || rubberbandInfo.index === i - 1)) continue

      const currentWaypoint = waypoints[i]
      const pastWaypoint = waypoints[i - 1]

      let heading = Turf.rhumbBearing(pastWaypoint.coordinates.toArray(), currentWaypoint.coordinates.toArray()) - 90
      if (heading < 0) heading = Turf.bearingToAzimuth(heading)

      // Keep text upright on map
      let headingText = heading
      if (headingText > 90 && headingText < 270) headingText += 180

      const lineDistance = nmDistance(pastWaypoint.coordinates, currentWaypoint.coordinates)

      const lineHeading = calculateMagneticHeading(pastWaypoint.coordinates, currentWaypoint.coordinates)
      const headingLabel = Math.floor(lineHeading).toFixed(0).padStart(3, '0')
      const distanceLabel = lineDistance > 10 ? lineDistance.toFixed(0) : lineDistance.toFixed(1)

      lineFeatureArray.push({
        type: 'Feature',
        properties: {
          label: `${headingLabel}° ${distanceLabel}nm`,
          heading: heading,
          headingText: headingText,
          state: getWaypointState(i, nextWaypointSequence)
        },
        geometry: Turf.midpoint(pastWaypoint.coordinates.toArray(), currentWaypoint.coordinates.toArray()).geometry
      })
    }

    return {
      type: 'FeatureCollection',
      features: lineFeatureArray
    }
  }
