import { injectable } from 'inversify'

import { User } from '../protocols/User'
import { SerializableState } from './SerializableState'

export interface AuthStateProps {
  token: string
  user: User
}

const INITIAL_STATE: AuthStateProps = {
  token: null,
  user: null
}

@injectable()
export class AuthState extends SerializableState<AuthStateProps> {
  constructor() {
    super(INITIAL_STATE)
  }

  setToken(token: string) {
    this._updateStateProp('token', token)
  }

  setUser(user: User) {
    this._updateStateProp('user', user)
  }

  getKey(): string {
    return 'auth'
  }
}
