import { useEffect } from 'react'

import appStoreLogo from '@assets/mobile/app-store-dark-button.svg'
import playStoreLogo from '@assets/mobile/play-store-dark-button.svg'
import { Assets } from '@nexds/web'
import styled, { useTheme } from 'styled-components'

import { BackdropImperativeInterface } from '@/presentation/interfaces/BackdropImperativeInterface'
import { sendMetrics } from '@/presentation/utils/sendMetrics'
import { appZIndex } from '@/presentation/utils/zIndexMapper'

interface TouchDeviceAlertProps {
  onClose: () => void
  onConsent: () => void
}

function TouchDeviceAlert(props: TouchDeviceAlertProps) {
  const { onClose, onConsent } = props
  const theme = useTheme()

  useEffect(() => {
    BackdropImperativeInterface.show(appZIndex.BANNER_DESKTOP, 0.7, onClose)

    return () => {
      BackdropImperativeInterface.hide()
    }
  }, [])

  return (
    <Container>
      <Header>
        <Box />
        <CloseButton onClick={onClose}>
          <Assets.Close2 size={'sm'} color={theme.colors.secondaryL3} />
        </CloseButton>
      </Header>
      <Body>
        <TitleContainer>
          <Title>Navegue melhor no aplicativo NexAtlas!</Title>
        </TitleContainer>
        <Content>
          <Message>
            Essa versão funciona melhor em um <strong>dispositivo com mouse e teclado</strong>. Para evitar problemas de
            uso, baixe o nosso aplicativo disponível para Android e IOS. Baixe agora:
          </Message>
          <StoresButtonsWrapper>
            <StoreLinkButton href="https://play.google.com/store/apps/details?id=com.nexatlas.pilotapp" target="_blank">
              <img src={playStoreLogo} alt="play-store" />
            </StoreLinkButton>
            <StoreLinkButton href="https://apps.apple.com/us/app/nexatlas/id1562704198" target="_blank">
              <img src={appStoreLogo} alt="app-store" />
            </StoreLinkButton>
          </StoresButtonsWrapper>
          <DoNotShow
            onClick={() => {
              onClose()
              onConsent()
            }}
          >
            Não mostrar novamente
          </DoNotShow>
        </Content>
      </Body>
    </Container>
  )
}

export { TouchDeviceAlert }

const Container = styled.div`
  z-index: ${appZIndex.BANNER_DESKTOP};
  width: 90%;
  max-width: 654px;
  height: 90%;
  max-height: 391px;
  border-radius: 10px;
  padding-bottom: 20px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.neutralL5};
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const Box = styled.div`
  width: 48px;
  height: 32px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 10px 0px 0px 0px;

  @media (max-height: 400px) {
    width: 42px;
    height: 23px;
  }
`

const CloseButton = styled.button`
  margin: 16px 16px 0 0;
  width: 24px;
  height: 24px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutralL4};
  }

  @media (max-height: 400px) {
    margin: 8px 16px 0 0;
  }
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 30px;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
`

const Title = styled.h2`
  ${({ theme }) => theme.fonts.h3}
  color: ${({ theme }) => theme.colors.secondaryD1};
  padding: 14px 0;
  text-align: center;

  @media (max-height: 400px) {
    ${({ theme }) => theme.fonts.h4}
  }

  @media (max-width: 500px) {
    ${({ theme }) => theme.fonts.h4}
  }
`

const Content = styled.div`
  padding: 0 20px;
  height: 100%;
  overflow: auto;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
`
const Message = styled.p`
  ${({ theme }) => theme.fonts.infoLG}
  color: ${({ theme }) => theme.colors.secondaryD1};
  white-space: normal;

  & strong {
    color: ${({ theme }) => theme.colors.secondaryD1};
  }

  @media (max-height: 400px) {
    ${({ theme }) => theme.fonts.infoMD}
    margin-top: 10px;
  }

  @media (max-width: 500px) {
    ${({ theme }) => theme.fonts.infoMD}
    margin-top: 10px;
  }
`

const DoNotShow = styled.button`
  border: none;
  width: fit-content;
  background-color: transparent;
  ${({ theme }) => theme.fonts.infoSM}
  color: ${({ theme }) => theme.colors.secondaryD1};
  text-decoration: underline;
  text-align: center;
  align-self: center;
  margin-top: 20px;
  cursor: pointer;
`

const StoresButtonsWrapper = styled.div`
  margin-top: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 70px;

  @media (max-width: 500px) {
    flex-direction: column;
    margin-top: 16px;
    gap: 5px;
  }
`

const StoreLinkButton = styled.a``
