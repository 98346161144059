import { ADHP, Waypoint } from '@/domain/models'

import ModalBalloonPointer from '@/presentation/assets/adhp/modal/balloon-pointer.svg?react'

import { Overlay } from '../../../containers/Map.styles'
import { WaypointInfo } from './WaypointInfo'
import { WaypointInfoContainer as Container } from './WaypointInfo.styles'

interface WaypointInfoContainerProps {
  menuScreenPos: {
    top: number
    left: number
    translateX: string
    translateY: string
  }

  pointerPosition:
    | 'top-left'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-right'
    | 'center-left'
    | 'center-right'
    | 'center-top'
    | 'center-bottom'
    | 'default'
  onOverlayClick: () => void
  waypoint: ADHP | Waypoint
}

export function WaypointInfoContainer(props: WaypointInfoContainerProps) {
  const { menuScreenPos, onOverlayClick, waypoint, pointerPosition } = props

  const pointerPos = {
    'top-left': { transform: 'translate(-30%, -30%) rotate(-45deg)', top: '0', left: '0', opacity: 0 },
    'top-right': { transform: 'translate(-70%, -30%) rotate(45deg)', top: '0', left: '100%', opacity: 0 },

    'bottom-left': { transform: 'translate(-30%, -90%) rotate(-10deg)', top: '100%', left: '0', opacity: 0 },
    'bottom-right': { transform: 'translate(-70%, -90%) rotate(10deg)', top: '100%', left: '100%', opacity: 0 },

    'center-left': { transform: 'translate(-60%, -60%) rotate(30deg)', top: '50%', left: '0', opacity: 1 },
    'center-right': { transform: 'translate(-40%, -60%) rotate(-30deg)', top: '50%', left: '100%', opacity: 1 },

    'center-top': { transform: 'translate(-50%, -70%)', top: '0', left: '50%', opacity: 1 },
    'center-bottom': { transform: 'translate(-50%, -30%) rotate(180deg)', top: '100%', left: '50%', opacity: 1 },

    default: { transform: 'translate(-60%, -80%) rotate(30deg)', top: '50%', left: '0', opacity: 1 }
  }

  return (
    <Overlay onClick={onOverlayClick}>
      <Container screenPosition={menuScreenPos}>
        <ModalBalloonPointer
          height={24}
          width={24}
          style={{
            position: 'absolute',
            top: pointerPos[pointerPosition].top,
            left: pointerPos[pointerPosition].left,
            transform: pointerPos[pointerPosition].transform,
            opacity: pointerPos[pointerPosition].opacity,
            zIndex: 1000
          }}
        />
        {waypoint instanceof ADHP ? (
          <WaypointInfo
            name={waypoint.name}
            city={waypoint.city}
            uf={waypoint.uf}
            icao={waypoint.code}
            waypoint={waypoint}
          />
        ) : (
          <WaypointInfo name={waypoint.getDisplayName()} waypoint={waypoint} />
        )}
      </Container>
    </Overlay>
  )
}
