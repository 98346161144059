import { Route } from '../models/Route'
import { Result } from '../protocols/Result'

export class RoutePolicy {
  static hasWaypointSequence(route: Route, sequence: number): Result<void> {
    if (!route) {
      return Result.fail('Route is null')
    }

    if (sequence <= 0) {
      return Result.fail('Sequence is a invalid number, must be equal or greather than 1')
    }

    if (route.waypoints && sequence > route.waypoints.length) {
      return Result.fail("Route doesn't have such sequence")
    }

    return Result.ok()
  }
}
