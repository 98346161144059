import { useMemo } from 'react'

import mapTarget from '@presentation/assets/map/map-target.png'
import GeoJSON from 'geojson'
import { SymbolLayout } from 'mapbox-gl'

import { useBehaviorSubject } from '@/presentation/hooks/useBehaviorSubject'

import { MAP_SYMBOL_PRIORITIES } from '../components/MapRoute/GeojsonUtils'
import { MapSymbolLayer } from '../components/MapView/MapSymbolLayer'
import { MapSymbolSource } from '../components/MapView/MapSymbolSource'
import { MapTargetState } from '../states/MapTargetState'

const mapTargetLayout = (): SymbolLayout => ({
  'icon-size': 0.7,
  'icon-image': mapTarget,
  'icon-ignore-placement': true,
  'icon-allow-overlap': true,
  'icon-keep-upright': true,
  'symbol-sort-key': MAP_SYMBOL_PRIORITIES.TARGET,
  'symbol-z-order': 'source'
})

function MapTargetPresenter() {
  const { coordinates } = useBehaviorSubject(MapTargetState)

  const geoJsonFeature: GeoJSON.Feature<GeoJSON.Point, GeoJSON.GeoJsonProperties> = useMemo(() => {
    if (!coordinates) return null
    return {
      type: 'Feature',
      properties: {},
      geometry: {
        coordinates: [coordinates.longitude, coordinates.latitude],
        type: 'Point'
      }
    }
  }, [coordinates])

  if (!geoJsonFeature) return null

  return (
    <MapSymbolSource id="map-target-source" data={geoJsonFeature}>
      <MapSymbolLayer
        id="map-target-layer"
        beforeLayerId="map-target-stack"
        sourceId="map-target-source"
        layout={mapTargetLayout()}
        imageSrc={mapTarget}
      />
    </MapSymbolSource>
  )
}

export { MapTargetPresenter }
