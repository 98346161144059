import { InjectionTokens } from '@/controller/tokens'

import { MapLayerState, MapLayerStateProps } from '@/domain/states/MapLayerState'

import { useGlobalState } from '@/presentation/hooks/useGlobalState'

import { MapRasterLayer } from '../components/MapView/MapRasterLayer'
import { MapRasterSource } from '../components/MapView/MapRasterSource'
import { stackIds } from './MapStackPresenter'

function MapChartsPresenter() {
  const [mapLayerState] = useGlobalState<MapLayerState, MapLayerStateProps>(InjectionTokens.MapLayerState)

  const ids = {
    source: {
      ENRCL: 'ENRCL-source',
      ENRCH: 'ENRCH-source',
      WAC: 'WAC-source',
      ARC: 'ARC-source',
      REA: 'REA-source',
      REH: 'REH-source'
    },
    layer: {
      ENRCL: 'ENRCL-layer',
      ENRCH: 'ENRCH-layer',
      WAC: 'WAC-layer',
      ARC: 'ARC-layer',
      REA: 'REA-layer',
      REH: 'REH-layer'
    }
  }

  return (
    <>
      {/* Base Charts */}
      {mapLayerState.baseChart === 'ENRCL' && (
        <MapRasterSource
          id={ids.source.ENRCL}
          tiles={[
            'https://a.tiles.nexatlas.com/enrcl/latest/{z}/{x}/{y}.png',
            'https://b.tiles.nexatlas.com/enrcl/latest/{z}/{x}/{y}.png',
            'https://c.tiles.nexatlas.com/enrcl/latest/{z}/{x}/{y}.png'
          ]}
        >
          <MapRasterLayer
            id={ids.layer.ENRCL}
            sourceId={ids.source.ENRCL}
            minZoom={0}
            maxZoom={12}
            beforeLayerId={stackIds.ENRCL}
          />
        </MapRasterSource>
      )}

      {mapLayerState.baseChart === 'ENRCH' && (
        <MapRasterSource
          id={ids.source.ENRCH}
          tiles={[
            'https://a.tiles.nexatlas.com/enrch/latest/{z}/{x}/{y}.png',
            'https://b.tiles.nexatlas.com/enrch/latest/{z}/{x}/{y}.png',
            'https://c.tiles.nexatlas.com/enrch/latest/{z}/{x}/{y}.png'
          ]}
        >
          <MapRasterLayer
            id={ids.layer.ENRCH}
            sourceId={ids.source.ENRCH}
            minZoom={0}
            maxZoom={12}
            beforeLayerId={stackIds.ENRCH}
          />
        </MapRasterSource>
      )}

      {mapLayerState.baseChart === 'WAC' && (
        <MapRasterSource
          id={ids.source.WAC}
          tiles={[
            'https://a.tiles.nexatlas.com/wac/latest/{z}/{x}/{y}.png',
            'https://b.tiles.nexatlas.com/wac/latest/{z}/{x}/{y}.png',
            'https://c.tiles.nexatlas.com/wac/latest/{z}/{x}/{y}.png'
          ]}
        >
          <MapRasterLayer
            id={ids.layer.WAC}
            sourceId={ids.source.WAC}
            minZoom={0}
            maxZoom={13}
            beforeLayerId={stackIds.WAC}
          />
        </MapRasterSource>
      )}

      {/* Overlay charts */}
      {mapLayerState.overlayChart === 'ARC' && (
        <MapRasterSource
          id={ids.source.ARC}
          tiles={[
            'https://a.tiles.nexatlas.com/arc/latest/{z}/{x}/{y}.png',
            'https://b.tiles.nexatlas.com/arc/latest/{z}/{x}/{y}.png',
            'https://c.tiles.nexatlas.com/arc/latest/{z}/{x}/{y}.png'
          ]}
        >
          <MapRasterLayer
            id={ids.layer.ARC}
            sourceId={ids.source.ARC}
            minZoom={0}
            maxZoom={13}
            beforeLayerId={stackIds.ARC}
          />
        </MapRasterSource>
      )}

      {mapLayerState.overlayChart === 'REA' && (
        <MapRasterSource
          id={ids.source.REA}
          tiles={[
            'https://a.tiles.nexatlas.com/rea/latest/{z}/{x}/{y}.png',
            'https://b.tiles.nexatlas.com/rea/latest/{z}/{x}/{y}.png',
            'https://c.tiles.nexatlas.com/rea/latest/{z}/{x}/{y}.png'
          ]}
        >
          <MapRasterLayer
            id={ids.layer.REA}
            sourceId={ids.source.REA}
            minZoom={0}
            maxZoom={13}
            beforeLayerId={stackIds.REA}
          />
        </MapRasterSource>
      )}

      {mapLayerState.overlay2Chart === 'REH' && (
        <MapRasterSource
          id={ids.source.REH}
          tiles={[
            'https://a.tiles.nexatlas.com/reh/latest/{z}/{x}/{y}.png',
            'https://b.tiles.nexatlas.com/reh/latest/{z}/{x}/{y}.png',
            'https://c.tiles.nexatlas.com/reh/latest/{z}/{x}/{y}.png'
          ]}
        >
          <MapRasterLayer
            id={ids.layer.REH}
            sourceId={ids.source.REH}
            minZoom={0}
            maxZoom={14}
            beforeLayerId={stackIds.REH}
          />
        </MapRasterSource>
      )}
    </>
  )
}

export { MapChartsPresenter }
