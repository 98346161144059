import { Result } from '@domain/protocols/Result'
import { SerializableState } from '@domain/states/SerializableState'
import type { IKeyValuePersister } from '@infra/keyValuePersister/IKeyValuePersister'
import type { ILogger } from '@infra/logger/ILogger'
import { IService } from '@services/IService'
import { Container, inject, injectable, interfaces } from 'inversify'
import { Subscription, skip } from 'rxjs'

import { InversionContainer } from '@/controller/container'
import { InjectionTokens } from '@/controller/tokens'

@injectable()
export class StatePersister implements IService {
  private _subscriptions: Subscription[] = []
  private InjectionContainer: Container

  constructor(
    @inject(InjectionTokens.StatePersisterStates)
    private statesToPersist: SerializableState<any>[],
    @inject(InjectionTokens.KeyValuePersister) private readonly _persister: IKeyValuePersister,
    @inject(InjectionTokens.Logger) private readonly _logger: ILogger
  ) {
    this.InjectionContainer = InversionContainer.getInstance().getContainer()
  }

  async init(): Promise<Result<void>> {
    await this._rehydrateStates()
    return Result.ok()
  }

  start(): Result<void> {
    this._subscribeToStateChanges()
    return Result.ok()
  }

  stop(): Result<void> {
    this._unsubscribeFromStateChanges()
    return Result.ok()
  }

  private async _rehydrateStates(): Promise<void> {
    await Promise.all(
      this.statesToPersist.map(async (state) => {
        const value = await this._persister.get(state.getKey())

        if (value.isSuccess) {
          state.fromString(value.getValue())
        }
      })
    )
  }

  private _subscribeToStateChanges(): void {
    this._subscriptions = this.statesToPersist.map((state) =>
      // Skip first value to avoid persisting the initial state
      state
        .getState()
        .pipe(skip(1))
        .subscribe(() => {
          try {
            this._logger.debug('StatePersister', 'Persisting state changes')
            this._persister.set(state.getKey(), state.toString())
          } catch (error) {
            this._logger.error('StatePersister', 'Could not persist state changes')
          }
        })
    )
  }

  private _unsubscribeFromStateChanges(): void {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe())
  }
}
