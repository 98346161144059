import { BehaviorSubject } from 'rxjs'

export interface MapADHPStateProps {
  aerodromes: boolean
  helipads: boolean
  pavedRunway: boolean
  nightOperation: boolean
  ats: boolean
  ifr: boolean
  minRunwayLength: number
  userWaypoints: boolean
}

export const INITIAL_STATE = {
  aerodromes: true,
  helipads: false,
  pavedRunway: false,
  nightOperation: false,
  ats: false,
  ifr: false,
  minRunwayLength: 0,
  userWaypoints: false
}

export const MapADHPState = new BehaviorSubject<MapADHPStateProps>(INITIAL_STATE)

export const MapADHPStateChecker = {
  isADHPActive(): boolean {
    const currentState = MapADHPState.getValue()
    return currentState.aerodromes || currentState.helipads || currentState.userWaypoints
  },

  isADHPFilterActive(): boolean {
    const currentState = MapADHPState.getValue()
    return (
      currentState.pavedRunway ||
      currentState.nightOperation ||
      currentState.ats ||
      currentState.ifr ||
      currentState.minRunwayLength === 0
    )
  }
}

export const MapADHPStateMutator = {
  mutate: (newState: MapADHPStateProps) => {
    MapADHPState.next(newState)
  },

  setAerodromes(aerodromes: boolean) {
    MapADHPState.next({
      ...MapADHPState.getValue(),
      aerodromes
    })
  },

  setHelipads(helipads: boolean) {
    MapADHPState.next({
      ...MapADHPState.getValue(),
      helipads
    })
  },

  setPavedRunway(pavedRunway: boolean) {
    MapADHPState.next({
      ...MapADHPState.getValue(),
      pavedRunway
    })
  },

  setNightOperation(nightOperation: boolean) {
    MapADHPState.next({
      ...MapADHPState.getValue(),
      nightOperation
    })
  },

  setAts(ats: boolean) {
    MapADHPState.next({
      ...MapADHPState.getValue(),
      ats
    })
  },

  setIfr(ifr: boolean) {
    MapADHPState.next({
      ...MapADHPState.getValue(),
      ifr
    })
  },

  setMinRunwayLength(minRunwayLength: number) {
    MapADHPState.next({
      ...MapADHPState.getValue(),
      minRunwayLength
    })
  },

  setUserWaypoints(userWaypoints: boolean) {
    MapADHPState.next({
      ...MapADHPState.getValue(),
      userWaypoints
    })
  }
}
