import HeadboardIcon from '@presentation/assets/map/headboard-icon.png'
import { SymbolLayout, SymbolPaint } from 'mapbox-gl'
import { DefaultTheme } from 'styled-components'

import { MAP_SYMBOL_PRIORITIES } from '../../components/MapRoute/GeojsonUtils'

export const trackHeadboardLayout = (): SymbolLayout => ({
  'text-field': ['get', 'label'],
  'text-rotate': ['get', 'heading'],
  'text-rotation-alignment': 'map',
  'text-allow-overlap': true,
  'text-size': 16,
  'text-font': ['Open Sans Bold'],
  'text-keep-upright': true,
  'icon-text-fit': 'both',
  'icon-text-fit-padding': [10, 6, 4, 6],
  'icon-image': HeadboardIcon,
  'icon-rotate': ['get', 'heading'],
  'icon-rotation-alignment': 'map',
  'icon-allow-overlap': true,
  'symbol-z-order': 'source',
  'symbol-sort-key': MAP_SYMBOL_PRIORITIES.ADHP_HEADBOARD
})

export const trackHeadboardPaint = (theme: DefaultTheme): SymbolPaint => ({
  'text-color': theme.colors.neutralL5
})

export { HeadboardIcon }
