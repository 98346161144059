import { useCallback, useEffect, useMemo } from 'react'

import { ConfirmModal } from '@nexds/web'

import { useBehaviorSubject } from '@/presentation/hooks/useBehaviorSubject'
import { useI18n } from '@/presentation/hooks/useI18n'
import { drawerModalSubject, globalModalSubject, ModalLocale, ModalUtil } from '@/utils/modalUtil'

import { Backdrop, ModalContainer } from './ModalPresenter.styles'

interface ModalPresenterProps {
  locale: ModalLocale
}

function ModalPresenter(props: ModalPresenterProps) {
  const { locale } = props

  const { t } = useI18n()

  const modalList = useBehaviorSubject(locale === 'drawer' ? drawerModalSubject : globalModalSubject)
  const currentModal = modalList[0]

  const hideModal = ModalUtil[locale].hide

  const defaultPositiveLabel = useMemo(() => t('MODAL_BUTTON_DEFAULT_CONFIRM'), [])

  const handleClose = useCallback(() => {
    hideModal(currentModal.id)
    if (currentModal.onClose) currentModal.onClose()
  }, [currentModal])

  if (modalList.length === 0) return null
  return (
    <ModalContainer>
      <Backdrop onClick={handleClose} />

      <ConfirmModal
        isOpen={modalList.length > 0}
        title={currentModal.title}
        description={currentModal.headerDescription}
        hasDescription={!!currentModal.headerDescription}
        positiveLabel={currentModal.positiveLabel ?? defaultPositiveLabel}
        negativeLabel={currentModal.negativeLabel}
        positiveColor={currentModal.positiveColor}
        buttonsSize="xs"
        maxWidth={currentModal.maxWidth}
        hasCloseButton={false}
        onPositive={() => {
          hideModal(currentModal.id)
          if (currentModal.onPositive) {
            currentModal.onPositive()
          }
        }}
        onNegative={() => {
          hideModal(currentModal.id)
          currentModal.onNegative?.()
        }}
        onClose={handleClose}
      >
        {currentModal.content}
      </ConfirmModal>
    </ModalContainer>
  )
}

export { ModalPresenter }
