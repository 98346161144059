import styled from 'styled-components'

import { appZIndex } from '@/presentation/utils/zIndexMapper'

export const BannerDesktopContainer = styled.div`
  z-index: ${appZIndex.BANNER_DESKTOP};
  width: 100%;
  max-width: 654px;
  height: 391px;
  border-radius: 10px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background-color: ${({ theme }) => theme.colors.neutralL5};
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Box = styled.div`
  width: 48px;
  height: 32px;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 10px 0px 0px 0px;
`

export const CloseButton = styled.button`
  margin: 16px 16px 0 0;
  width: 24px;
  height: 24px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutralL4};
  }
`

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 48px;
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
`

export const Title = styled.h2`
  ${({ theme }) => theme.fonts.h2}
  color: black;
  padding: 14px 0;
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const StepsContainer = styled.ol`
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const StepItem = styled.li`
  ${({ theme }) => theme.fonts.infoLG}
  color: black;
  white-space: normal;
`
