import { UniqueEntityID } from '@domain/core'

import { Coordinates } from '@/domain/protocols/Coordinates'

import { WaypointType } from '../WaypointType'
import { Waypoint, WaypointPojo, WaypointProps } from './Waypoint'

export interface CityExtra {}

export class City extends Waypoint {
  public declare extra: CityExtra

  private constructor(props: WaypointProps, extra: CityExtra, id?: UniqueEntityID) {
    super(props, id)
    this.extra = extra
  }

  public static create(props: WaypointProps, extra: CityExtra, id?: UniqueEntityID): City {
    props.waypointType = WaypointType.create(
      {
        name: 'Centro da cidade', // t('WAYPOINT_TYPE_CITY'), // TODO: fix this
        codeName: 'City'
      },
      new UniqueEntityID('city')
    )

    return new City(props, extra, id)
  }

  public clone(): City {
    return City.create(this.props, this.extra)
  }

  public cloneWithID(): City {
    return City.create(this.props, this.extra, this.id)
  }

  public getDisplayName(): string {
    return this.props.customName ? this.props.customName : this.props.name ? this.props.name : ''
  }

  public static fromPojo(pojo: WaypointPojo): City {
    return City.create(
      {
        waypointType: pojo.waypointType && WaypointType.fromPojo(pojo.waypointType),
        coordinates: Coordinates.fromPojo(pojo.coordinates),
        code: pojo.code,
        name: pojo.name,
        customName: pojo.customName,
        createdAt: pojo.createdAt,
        updatedAt: pojo.updatedAt
      },
      {
        ...(pojo.extra as any)
      },
      new UniqueEntityID(pojo.id)
    )
  }
}
