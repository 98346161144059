import { getStateColor } from '@presentation/utils'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  overflow: hidden;
`

/* ------------------------------------------ */

interface DistanceFieldProps {
  show?: boolean
  isAccumulated?: boolean
  align?: 'left' | 'center' | 'right'
  isTime?: boolean
}

export const DistanceFieldInfo = styled.span<DistanceFieldProps>`
  ${({ theme }) => theme.fonts.infoMD}
  color: ${({ theme, isAccumulated }) => (isAccumulated ? theme.colors.neutralL5 : theme.colors.primaryL2)};
  text-align: ${({ align }) => align};
  width: ${({ isTime }) => (isTime ? '15%' : '22%')};

  /* border: 1px solid red; */
`

export const DistanceField = styled.div<DistanceFieldProps>`
  /* border: 1px solid red; */

  display: ${({ show }) => (show ? 'flex' : 'none')};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  height: 48px;
`

export const Divider = styled.div`
  width: 1px;
  height: 14px;
  background-color: #fff;
`

/* ------------------------------------------ */

export const ActionButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  max-height: 55px;
  top: 0;
  right: 0;
  margin-left: 8px;
  visibility: hidden;
`

export const ActionButton = styled.button`
  border: none;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
  line-height: 0;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondaryL1};
  }
`

export const Content = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary};
  width: 100%;
  height: 76px;
  border-radius: 8px;
  padding: 0 16px;
  cursor: grab;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => getStateColor(theme.colors.secondary, 'hover')};
  }

  &:hover ${ActionButtonsWrapper} {
    visibility: visible;
  }
`

export const ContentInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const WaypointInfo = styled.span`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
`

interface ClickableProps {
  hideIcon?: boolean
}

export const Clickable = styled.button<ClickableProps>`
  border: none;
  background-color: transparent;
  line-height: 0;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;

  & svg {
    opacity: ${({ hideIcon }) => (hideIcon ? 0 : 1)};
    transition: opacity 0.1s;
  }

  &:hover svg {
    opacity: 1;
  }
`

export const NameInput = styled.input`
  ${({ theme }) => theme.fonts.titleXS}
  border: none;
  width: 90%;
  border-radius: 4px;
  padding: 3px 6px 3px 0;
  background-color: ${({ theme }) => theme.colors.secondaryL2};
`

export const Name = styled.p`
  padding: 3px 0;
  ${({ theme }) => theme.fonts.titleXS};
  width: 90%;
  max-height: 37px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: normal;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

export const Coord = styled.p`
  ${({ theme }) => theme.fonts.infoMD}
  color: ${({ theme }) => theme.colors.secondaryL3};
`
