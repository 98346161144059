import GeoJSON from 'geojson'
import { BehaviorSubject } from 'rxjs'

import { MeteorologyManifest } from '@/data/MeteorologyRepository/IMeteorologyRepository'

interface AirspaceFeature {
  type: 'airspace'
  title: string
  feature: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>
}

interface MeteorologyLegend {
  type: 'meteorology-legend'
  title: string
  legends: MeteorologyManifest['legends']
}

interface MeteorologyTooltip {
  type: 'meteorology-tooltip'
  title: string
  content: string
}

type FeatureType = AirspaceFeature | MeteorologyLegend | MeteorologyTooltip

export interface FeatureInfoStateProps {
  active: FeatureType
}

const INITIAL_STATE: FeatureInfoStateProps = {
  active: null
}

export const FeatureInfoState = new BehaviorSubject<FeatureInfoStateProps>(INITIAL_STATE)

export const FeatureInfoStateMutator = {
  mutate: (newState: FeatureInfoStateProps) => {
    FeatureInfoState.next(newState)
  },

  showAirspace: (title: string, feature: GeoJSON.Feature<GeoJSON.Geometry, GeoJSON.GeoJsonProperties>) => {
    FeatureInfoState.next({
      active: {
        type: 'airspace',
        title,
        feature
      }
    })
  },

  showMeteorologyLegend: (title: string, legends: MeteorologyManifest['legends']) => {
    FeatureInfoState.next({
      active: {
        type: 'meteorology-legend',
        title,
        legends
      }
    })
  },

  setInfo: (title: string, content: string) => {
    FeatureInfoState.next({
      active: {
        type: 'meteorology-tooltip',
        title,
        content
      }
    })
  },

  hide: () => {
    FeatureInfoState.next({ active: null })
  }
}
