import { Icon } from '@nexds/web'
import { BehaviorSubject } from 'rxjs'

export interface ToastItem {
  id: string
  leftIcon?: Icon
  rightIcon?: Icon
  label: string
  actionLabel?: string
  variant?: 'dark' | ' light' | 'primary' | 'secondary' | 'error'
  size?: 'sm' | 'md' | 'lg'
  timeDelay?: number
  dismissible?: boolean
  zIndex?: number
  onAction?: () => boolean
  onFinish?: () => void
}

const DEFAULT_PROPS: ToastItem = {
  id: '',
  leftIcon: null,
  rightIcon: null,
  label: '',
  actionLabel: null,
  variant: 'secondary',
  size: 'sm',
  timeDelay: 3000,
  dismissible: false,
  onAction: () => {
    return true
  },
  onFinish: () => {}
}

const toastSubject = new BehaviorSubject<ToastItem[]>([])

const ToastUtil = {
  show: (item: ToastItem) => {
    toastSubject.next([...toastSubject.getValue(), item])
  },

  hide: (id: string) => {
    const toastItems = toastSubject.getValue()
    const index = toastItems.findIndex((item) => item.id === id)
    if (index === -1) return
    toastItems.splice(index, 1)
    toastSubject.next([...toastItems])
  },

  send: (toastProps: Partial<ToastItem>) => {
    ToastUtil.show({
      ...DEFAULT_PROPS,
      id: `sent-${new Date().getTime()}`,
      ...toastProps
    })
  },

  subscribe: (callback: (items: ToastItem[]) => void) => {
    return toastSubject.subscribe(callback)
  }
}

export { toastSubject, ToastUtil }
