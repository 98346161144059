import { BaseState } from '@domain/states/BaseState'
import { injectable } from 'inversify'
import { jsonDateParser } from 'json-date-parser'

@injectable()
export abstract class SerializableState<T> extends BaseState<T> {
  abstract getKey(): string

  toString(): string {
    return JSON.stringify(this.getStateSnapshot())
  }

  fromString(state: string): void {
    this._updateState(JSON.parse(state, jsonDateParser))
  }
}
