import { ConfirmModalProps } from '@nexds/web'
import { BehaviorSubject } from 'rxjs'

export type ModalLocale = 'drawer' | 'global'

export interface ModalItemProps {
  id: string
  locale: ModalLocale
  title: string
  headerDescription?: string
  content: any
  maxWidth?: number
  negativeLabel?: string
  positiveLabel?: string
  positiveColor: ConfirmModalProps['positiveColor']
  onClose: () => void
  onPositive: () => void
  onNegative?: () => void
}

const drawerModalSubject = new BehaviorSubject<ModalItem[]>([])
const globalModalSubject = new BehaviorSubject<ModalItem[]>([])

const drawerDefaultProps: ModalItemProps = {
  id: `modal-${new Date().getTime()}`,
  locale: 'drawer',
  title: '',
  headerDescription: null,
  content: '',
  maxWidth: 500,
  positiveColor: 'secondary',
  onPositive: null,
  onNegative: null,
  onClose: null
}

const globalDefaultProps: ModalItemProps = {
  id: `modal-${new Date().getTime()}`,
  locale: 'global',
  title: '',
  headerDescription: null,
  content: '',
  maxWidth: 600,
  positiveColor: 'secondary',
  onPositive: null,
  onNegative: null,
  onClose: null
}

type ModalItem = Omit<ModalItemProps, 'locale'>

const ModalUtil = {
  drawer: {
    show: (item: Partial<ModalItem>) => {
      const pushItem = { ...drawerDefaultProps, ...item }

      drawerModalSubject.next([...drawerModalSubject.getValue(), pushItem])
    },

    hide: (id: string) => {
      drawerModalSubject.next(drawerModalSubject.getValue().filter((item) => item.id !== id))
    },

    subscribe: (callback: (items: ModalItem[]) => void) => {
      return drawerModalSubject.subscribe(callback)
    }
  },
  global: {
    show: (item: Partial<ModalItem>) => {
      const pushItem = { ...globalDefaultProps, ...item }

      globalModalSubject.next([...globalModalSubject.getValue(), pushItem])
    },

    hide: (id: string) => {
      globalModalSubject.next(globalModalSubject.getValue().filter((item) => item.id !== id))
    },

    subscribe: (callback: (items: ModalItem[]) => void) => {
      return globalModalSubject.subscribe(callback)
    }
  }
}

export { drawerModalSubject, ModalUtil, globalModalSubject }
