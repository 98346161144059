import { InversionContainer } from '@/controller/container'
import { InjectionTokens } from '@/controller/tokens'

import { UseCase } from '@/domain/core/UseCase'
import { Route } from '@/domain/models/Route/Route'
import { Result } from '@/domain/protocols/Result'

import { IUserRoutesRepository } from '@/data/UserRoutesRepository/IUserRoutesRepository'

class GetUserRoutesUseCase implements UseCase<void, Result<Route[]>> {
  private userRoutesRepository: IUserRoutesRepository

  constructor() {
    const injectionContainer = InversionContainer.getInstance().getContainer()
    this.userRoutesRepository = injectionContainer.get<IUserRoutesRepository>(InjectionTokens.UserRoutesRepository)
  }

  async execute(): Promise<Result<Route[]>> {
    try {
      return this.userRoutesRepository.getRoutes(false)
    } catch (error) {
      return Result.fail(error)
    }
  }
}

export { GetUserRoutesUseCase }
