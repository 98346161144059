import { inject, injectable } from 'inversify'

import { InjectionTokens } from '@/controller/tokens'

import { Waypoint, ADHP, AwWaypoint, City, Navaid, VisualPosition, CoordWaypoint } from '@/domain/models/Waypoint'
import { WaypointTypeCode } from '@/domain/models/WaypointType/WaypointType'
import { Coordinates } from '@/domain/protocols/Coordinates'
import { Result } from '@/domain/protocols/Result'
import { LoadUserWaypointsUseCase } from '@/domain/useCases/UserWaypoints/loadWaypoints'

import type { IGeocodeApi } from '@/infra/Geocode/IGeocodeApi'
import type { IHttpClient } from '@/infra/http/IHttpClient'
import type { ILogger } from '@/infra/logger/ILogger'
import { DMSToDecimal, nmDistance, nmPythagoreanDistance } from '@/utils/coordinates'
import { removeAccents } from '@/utils/string'

import { AeroInfoMapper } from './AeroInfoMapper'
import { IAeroInfoRepository, WaypointsFullResponseItem } from './IAeroInfoRepository'

@injectable()
export class AeroInfoRepository implements IAeroInfoRepository {
  private ADHPs: ADHP[] = []
  private ADHPsFull: ADHP[] = []
  private AwWaypoints: AwWaypoint[] = []
  private Cities: City[] = []
  private Navaids: Navaid[] = []
  private VisualPositions: VisualPosition[] = []
  private UserWaypoints: CoordWaypoint[] = []

  public constructor(
    @inject(InjectionTokens.HttpClient) private remoteClient: IHttpClient,
    @inject(InjectionTokens.GeocodeApi) private geocodeApi: IGeocodeApi,
    @inject(InjectionTokens.Logger) private logger: ILogger
  ) {}

  private get waypoints(): Waypoint[] {
    return [
      ...this.UserWaypoints,
      ...this.ADHPs,
      ...this.AwWaypoints,
      ...this.Cities,
      ...this.Navaids,
      ...this.VisualPositions
    ]
  }

  protected getRemoteUrl(): string {
    return import.meta.env.VITE_AERODATA_API_BASEURL
  }

  public getAllData() {
    return this.waypoints
  }

  public async init(): Promise<Result<void>> {
    try {
      await this._loadWaypoints()
      await this._loadCustomWaypoints()
    } catch (error) {
      this.logger.error('AeroInfoRepository', `Error loading waypoints: ${error}`)
    }

    return Result.ok()
  }

  private async _loadWaypoints() {
    const remoteUrl = this.getRemoteUrl()

    const response = await this.remoteClient.get<WaypointsFullResponseItem[]>(`${remoteUrl}/waypoints_full`)

    if (response.success === true) {
      this._mapRemoteToLocal(response.response)
    } else {
      return Result.fail(response.error)
    }

    return Result.ok()
  }

  protected _mapRemoteToLocal(remoteData: WaypointsFullResponseItem[]): void {
    const { ADHPs, ADHPsFull, AwWaypoints, Cities, Navaids, VisualPositions } = AeroInfoMapper(remoteData)

    this.ADHPs = ADHPs
    this.ADHPsFull = ADHPsFull
    this.AwWaypoints = AwWaypoints
    this.Cities = Cities
    this.Navaids = Navaids
    this.VisualPositions = VisualPositions
  }

  private async _loadCustomWaypoints() {
    const userWaypoints = await new LoadUserWaypointsUseCase().execute()

    if (userWaypoints.isSuccess) {
      this.UserWaypoints = userWaypoints.getValue()
    }
  }

  searchByProximity(center: Coordinates, radius: number, max?: number, typeCode?: WaypointTypeCode): Waypoint[] {
    interface FindItem {
      item: Waypoint
      distance: number
    }

    const priorityMap: any = {
      Navaid: 2,
      VisualPosition: 2,
      AwWaypoint: 1,
      City: 1
    }

    const getPriorityMap = (wpt: Waypoint) => {
      if (wpt.waypointType?.codeName === 'ADHP') {
        if ((wpt as ADHP).type === 'AD') return 4
        else return 2
      }

      return priorityMap[wpt.waypointType?.codeName!]
    }

    let mapItems = this.waypoints
      .map((wpt) => {
        return {
          item: wpt,
          distance: nmDistance(center, wpt.coordinates)
        } as FindItem
      })
      .filter((item) => item.distance <= radius)
      .sort((a, b) => a.distance / getPriorityMap(a.item) - b.distance / getPriorityMap(b.item))

    if (typeCode) {
      mapItems = mapItems.filter((item) => item.item.constructor.name === typeCode)
    }

    if (max) {
      mapItems = mapItems.slice(0, max)
    }

    return mapItems.map((search) => search.item)
  }
  searchByProximityWithLowPrecision(
    center: Coordinates,
    radius: number,
    max?: number,
    typeCode?: WaypointTypeCode
  ): Waypoint[] {
    interface FindItem {
      item: Waypoint
      distance: number
    }

    const priorityMap: any = {
      Navaid: 2,
      VisualPosition: 2,
      AwWaypoint: 1,
      City: 1
    }

    const getPriorityMap = (wpt: Waypoint) => {
      if (wpt.waypointType?.codeName === 'ADHP') {
        if ((wpt as ADHP).type === 'AD') return 4
        else return 2
      }

      return priorityMap[wpt.waypointType?.codeName!]
    }

    let mapItems = this.waypoints
      .map((wpt) => {
        return {
          item: wpt,
          distance: nmPythagoreanDistance(center, wpt.coordinates)
        } as FindItem
      })
      .filter((item) => item.distance <= radius)
      .sort((a, b) => a.distance / getPriorityMap(a.item) - b.distance / getPriorityMap(b.item))

    if (typeCode) {
      mapItems = mapItems.filter((item) => item.item.constructor.name === typeCode)
    }

    if (max) {
      mapItems = mapItems.slice(0, max)
    }

    return mapItems.map((search) => search.item)
  }

  findInPlace(coord: Coordinates, typeCode: WaypointTypeCode = 'ADHP', radius: number = 0.3): Waypoint {
    const search = this.searchByProximity(coord, radius, 1, typeCode)

    if (search.length === 0) {
      return null
    }

    return search[0]
  }

  async searchByInfo(info: string, max?: number, adhpOnly?: boolean): Promise<Waypoint[]> {
    if (!info) return []

    const normalizedInfo = removeAccents(info).toLowerCase().trim().replace(/\s+/g, ' ')

    const latRE =
      /^([0-9]{1,2})\s*[º°]?\s*([0-5]{0,1}[0-9]{0,1})?\s*["']?\s*([0-5]{0,1}[0-9]{0,1})?\s*["']?\s*([NnSs])?\s*\/?,?-?\s*$/gi
    const latSearch = latRE.exec(info)
    if (latSearch && !adhpOnly) {
      const coordinates = DMSToDecimal(latSearch)
      return [CoordWaypoint.create({ coordinates }, {})]
    }

    const latlngRE =
      /^([0-9]{1,2})\s*[º°]?\s*([0-5]{0,1}[0-9]{0,1})?\s*["']?\s*([0-5]{0,1}[0-9]{0,1})?\s*["']?\s*([NnSs])?\s*\/?,?-?\s*([0-9]{1,3})\s*[º°]?\s*([0-5]{0,1}[0-9]{0,1})?\s*["']?\s*([0-5]{0,1}[0-9]{0,1})?\s*["']?\s*([LlOoEeWw])?\s*$/gi
    const latlngSearch = latlngRE.exec(info)
    if (latlngSearch && !adhpOnly) {
      const coordinates = DMSToDecimal(latlngSearch)
      return [CoordWaypoint.create({ coordinates }, {})]
    }

    let scoredFound = 0
    const resultWaypoints = (adhpOnly ? this.ADHPs : this.waypoints)
      .map((waypoint: Waypoint) => {
        const normalizedCode = waypoint.code?.toLowerCase() ?? null
        const normalizedName = waypoint.name ? removeAccents(waypoint.name.toLowerCase()).split(',')[0].trim() : null
        const normalizedCustomName = waypoint.customName ? removeAccents(waypoint.customName.toLowerCase()) : null
        const normalizedCity = waypoint.extra?.city ? removeAccents(waypoint.extra?.city.toLowerCase()).trim() : null

        const isInfoInName =
          normalizedInfo &&
          normalizedInfo.split(' ').every((infoWord) => {
            return normalizedName?.split(' ').some((nameWord) => {
              return infoWord === nameWord
            })
          })

        const isInfoInCustomName =
          normalizedInfo &&
          normalizedInfo.split(' ').every((infoWord) => {
            return normalizedCustomName?.split(' ').some((nameWord) => {
              return infoWord === nameWord
            })
          })

        const isInfoInCity =
          normalizedInfo &&
          normalizedInfo.split(' ').every((infoWord) => {
            return normalizedCity?.split(' ').some((nameWord) => {
              return infoWord === nameWord
            })
          })

        // If is a awWaypoint and the info is exactly the code
        if (waypoint instanceof AwWaypoint && normalizedCode === normalizedInfo) {
          scoredFound++
          return { waypoint, score: 45 }
        }

        // if is a navaid and the info is exactly the code
        if (waypoint instanceof Navaid && normalizedCode === normalizedInfo) {
          scoredFound++
          return { waypoint, score: 44 }
        }

        // if is a navaid and the words of the name are exactly the info or the name is exactly the info
        if (waypoint instanceof Navaid && (isInfoInName || normalizedName === normalizedInfo)) {
          scoredFound++
          return { waypoint, score: 43 }
        }

        // if is a user waypoint and the words of the name are exactly the info or the name starts with the info
        if (
          waypoint instanceof CoordWaypoint &&
          (isInfoInCustomName || normalizedCustomName?.startsWith(normalizedInfo))
        ) {
          scoredFound++
          return { waypoint, score: 42 }
        }

        // if is a VisualPosition and the words of the name are exactly the info or the name starts with the info
        if (waypoint instanceof VisualPosition && (isInfoInName || normalizedName?.startsWith(normalizedInfo))) {
          scoredFound++
          return { waypoint, score: 40 }
        }

        // Aerodromes that are in the city:
        // - if is a AD and the code starts with SB
        // - if is a AD and the code doesn't start with SB
        // - if is a HP
        if (normalizedCity === normalizedInfo) {
          if (waypoint instanceof ADHP && waypoint.extra.type === 'AD' && waypoint.code?.startsWith('sb')) {
            scoredFound++
            return { waypoint, score: 35 }
          } else if (waypoint instanceof ADHP && waypoint.extra.type === 'AD' && !waypoint.code?.startsWith('sb')) {
            scoredFound++
            return { waypoint, score: 25 }
          } else if (waypoint instanceof ADHP && waypoint.extra.type === 'HP') {
            scoredFound++
            return { waypoint, score: 15 }
          }
        }

        // Info is one of the words of the city:
        // - if is a AD and the code starts with SB
        // - if is a AD and the code doesn't start with SB
        // - if is a HP
        if (isInfoInCity) {
          if (waypoint instanceof ADHP && waypoint.extra.type === 'AD' && waypoint.code?.startsWith('sb')) {
            scoredFound++
            return { waypoint, score: 34 }
          } else if (waypoint instanceof ADHP && waypoint.extra.type === 'AD' && !waypoint.code?.startsWith('sb')) {
            scoredFound++
            return { waypoint, score: 24 }
          } else if (waypoint instanceof ADHP && waypoint.extra.type === 'HP') {
            scoredFound++
            return { waypoint, score: 14 }
          }
        }

        // if is a ADHP and the info is exactly the name:
        // - if is a AD and the code starts with SB
        // - if is a AD and the code doesn't start with SB
        // - if is a HP
        if (normalizedName === normalizedInfo) {
          if (waypoint instanceof ADHP && waypoint.extra.type === 'AD' && waypoint.code?.startsWith('sb')) {
            scoredFound++
            return { waypoint, score: 33 }
          } else if (waypoint instanceof ADHP && waypoint.extra.type === 'AD' && !waypoint.code?.startsWith('sb')) {
            scoredFound++
            return { waypoint, score: 23 }
          } else if (waypoint instanceof ADHP && waypoint.extra.type === 'HP') {
            scoredFound++
            return { waypoint, score: 13 }
          }
        }

        // if is a ADHP and one of the words of the name is exactly the info:
        // - if is a AD and the code starts with SB
        // - if is a AD and the code doesn't start with SB
        // - if is a HP
        if (isInfoInName) {
          if (waypoint instanceof ADHP && waypoint.extra.type === 'AD' && waypoint.code?.startsWith('sb')) {
            scoredFound++
            return { waypoint, score: 32 }
          } else if (waypoint instanceof ADHP && waypoint.extra.type === 'AD' && !waypoint.code?.startsWith('sb')) {
            scoredFound++
            return { waypoint, score: 22 }
          } else if (waypoint instanceof ADHP && waypoint.extra.type === 'HP') {
            scoredFound++
            return { waypoint, score: 12 }
          }
        }

        if (waypoint instanceof City && (normalizedName === normalizedInfo || isInfoInName)) {
          scoredFound++
          return { waypoint, score: 11, shouldBeOrderedAlphabetically: true }
        }

        if (waypoint instanceof City && normalizedName?.startsWith(normalizedInfo)) {
          scoredFound++
          return { waypoint, score: 10, shouldBeOrderedAlphabetically: true }
        }

        // If is a AwWaypoint start with match
        if (waypoint instanceof AwWaypoint && normalizedName?.startsWith(normalizedInfo)) {
          scoredFound++
          return { waypoint, score: 9 }
        }

        // If is a Navaid start with match
        if (waypoint instanceof Navaid && normalizedName?.startsWith(normalizedInfo)) {
          scoredFound++
          return { waypoint, score: 8 }
        }

        // If is a custom start with match
        if (normalizedCustomName && normalizedCustomName.startsWith(normalizedInfo)) {
          scoredFound++
          return { waypoint, score: 7 }
        }

        // If is a code start with match
        if (normalizedCode && normalizedCode.startsWith(normalizedInfo)) {
          scoredFound++
          return { waypoint, score: 6 }
        }

        // If is a name start with match
        if (normalizedName && normalizedName.startsWith(normalizedInfo)) {
          scoredFound++
          // se for um ADHP que começa com SB, coloca no topo
          if (waypoint instanceof ADHP && waypoint.extra.type === 'AD' && normalizedCode?.startsWith('sb')) {
            return { waypoint, score: 71 }
          } else {
            return { waypoint, score: 5 }
          }
        }

        if (scoredFound >= (max || 10) * 2) {
          return null
        }

        return null
      })
      .filter((item) => item !== null)
      .sort((a, b) => {
        if (b.score !== a.score) {
          return b.score - a.score
        } else {
          if (a.waypoint instanceof City && b.waypoint instanceof City) {
            return a.waypoint.getDisplayName()!.split(',')[0].localeCompare(b.waypoint.getDisplayName()!.split(',')[0])
          }
          return a.waypoint.getDisplayName()!.localeCompare(b.waypoint.getDisplayName()!)
        }
      })
      .map((item) => {
        return item.waypoint
      })
      .slice(0, max || 10)

    if (!adhpOnly && normalizedInfo.length >= 8) {
      const searchGeocode = await this.geocodeApi.getGeocode(normalizedInfo)

      if (searchGeocode.isSuccess) {
        const geocode = searchGeocode.getValue()
        geocode.forEach((geo) => {
          resultWaypoints.push(
            CoordWaypoint.create({ coordinates: geo.coordinates, customName: geo.name }, { isAddress: true })
          )
        })
      }
    }

    return resultWaypoints
  }

  getAllADHPs(): Result<ADHP[]> {
    const ADHPs = this.ADHPsFull.map((adhp) => adhp.clone())

    if (ADHPs.length > 0) {
      return Result.ok(ADHPs)
    }

    return Result.fail('No ADHPs found')
  }

  getADHPData(code: string): ADHP {
    const fullAdhp = this.ADHPsFull.find((wpt) => {
      return wpt.code === code
    })

    if (!fullAdhp) {
      return null
    }

    return fullAdhp.clone()
  }
}
