import { useMemo } from 'react'

import styled from 'styled-components'

import { InjectionTokens } from '@/controller/tokens'

import { RouteState, RouteStateProps } from '@/domain/states/RouteState'

import { useGlobalState } from '@/presentation/hooks/useGlobalState'
import { appZIndex } from '@/presentation/utils/zIndexMapper'

function RouteGuidePresenter() {
  const [routeState] = useGlobalState<RouteState, RouteStateProps>(InjectionTokens.RouteState)

  const guideLabel = useMemo(
    () =>
      !routeState.activeRoute
        ? 'Defina o aeródromo de partida'
        : routeState.activeRoute.waypoints.length === 1
          ? 'Defina o aeródromo de destino'
          : 'Escolha pontos intermediários',
    [routeState.activeRoute?.waypoints]
  )

  if (routeState.activeRoute?.waypoints.length >= 3) return null

  return (
    <Guide>
      <p>{guideLabel}</p>
    </Guide>
  )
}

export { RouteGuidePresenter }

const Guide = styled.div`
  position: absolute;
  bottom: 23px;
  left: 50%;
  transform: translateX(-50%);

  width: fit-content;
  height: 40px;
  padding: 0 15px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.neutralL3};
  z-index: ${appZIndex.ROUTER_GUIDE};

  & p {
    color: ${({ theme }) => theme.colors.secondaryD1};
    ${({ theme }) => theme.fonts.infoMD};
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    @media (max-width: 350px) {
      ${({ theme }) => theme.fonts.infoSM};
    }
  }
`
