import styled from 'styled-components'

export const ChartsAndDataScreenContainer = styled.div`
  height: calc(100% - 64px);
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const ChartsAndDataScreenBody = styled.div`
  width: calc(100% + 9px);
  display: flex;
  flex-direction: column;
  gap: 15px;

  padding: 0 9px;

  overflow-y: auto;

  &::-webkit-scrollbar {
    border-radius: 20px;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.secondaryL2};
    border-radius: 20px;
  }
`

export const Table = styled.table`
  width: 100%;
`

export const Thead = styled.thead`
  width: 100%;
  ${({ theme }) => theme.fonts.textMD};
  font-weight: 600;
`

export const Tbody = styled.tbody`
  ${({ theme }) => theme.fonts.textMD};
  width: 100%;
`

export const Tr = styled.tr`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const Td = styled.td``
