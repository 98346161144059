import { Result } from '@domain/protocols/Result.js'
import { TranslationTokens, VarsByToken } from '@i18n/ITranslations'
import type { ICachedHttpClient } from '@infra/cachedHttp/ICachedHttpClient.js'
import type { ILogger } from '@infra/logger/ILogger.js'
import { inject, injectable } from 'inversify'

import { InjectionTokens } from '@/controller/tokens'

export type Language = 'en' | 'es' | 'pt'
type LanguageTokens = Record<TranslationTokens, string>

@injectable()
export class I18n {
  private _language: Language = 'pt'
  private _languageTokens: LanguageTokens = null

  constructor(
    @inject(InjectionTokens.CachedHttpClient) private readonly _cachedHttpClient: ICachedHttpClient,
    @inject(InjectionTokens.Logger) private readonly _logger: ILogger
  ) {
    this._language = this._getUserLanguage()
  }

  public async init(): Promise<Result<void>> {
    for (;;) {
      await this._downloadLanguageTokens(this._language)

      if (!this._languageTokens) {
        this._logger.error('i18n', `Language tokens not loaded, trying to fetch again in 2 seconds`)
        await new Promise((resolve) => setTimeout(resolve, 2000))
        continue
      }

      break
    }

    return Result.ok()
  }

  public resetLanguageTokens(): void {
    this._languageTokens = null
  }

  public resolveToken<T extends TranslationTokens>(
    ...args: VarsByToken[T] extends never ? [T] : [T, VarsByToken[T]]
  ): string {
    const token = args[0]
    const vars = args[1]

    if (!this._languageTokens?.[token]) {
      this._logger.warn('i18n', `Token not found: ${token}`)
      return `*** NEED TOKEN (${Math.random()}) ***`
    }

    const resolvedToken = this._languageTokens[token]

    const resolveVars = vars ?? ({} as VarsByToken[T])

    return Object.entries(resolveVars).reduce((acc, [key, value]) => {
      return acc.replace(`{${key}}`, value)
    }, resolvedToken)
  }

  public getDateFormat(): string {
    switch (this._language) {
      case 'pt':
        return 'dd/MM/yyyy'
      case 'en':
        return 'MM/dd/yyyy'
      case 'es':
        return 'dd/MM/yyyy'
      default:
        return 'dd/MM/yyyy'
    }
  }

  public getTimeFormat(): string {
    return 'HH:mm'
  }

  public getDateTimeFormat(): string {
    return `${this.getDateFormat()} ${this.getTimeFormat()}`
  }

  public getLanguageTokens(): any {
    return this._languageTokens
  }

  public getLanguage(): Language {
    return this._language
  }

  /*
   * PRIVATE METHODS
   */

  private _getUserLanguage(): Language {
    if (navigator.languages) {
      for (const language of navigator.languages) {
        if (['pt', 'en', 'es'].includes(language.split('-')[0])) {
          return language.split('-')[0] as Language
        }
      }
    }

    return 'en'
  }

  private async _downloadLanguageTokens(language: Language) {
    return new Promise<void>((resolve) => {
      const response = this._cachedHttpClient.get<LanguageTokens>(`${import.meta.env.VITE_BFF_URL}/i18n/${language}`, {
        key: `i18n$tokens$${language}`
      })

      response.subscribe({
        next: (data) => {
          if (data.status !== 200) return
          this._languageTokens = data.response
          resolve()
        },

        complete: () => {
          this._logger.info('i18n', `Language tokens loaded for ${language}`)
        },

        error: (error) => {
          this._logger.error('i18n', `Error fetching language tokens: ${error.message}`)
          resolve()
        }
      })
    })
  }
}
