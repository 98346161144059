import { Assets } from '@nexds/web'
import styled from 'styled-components'

import { useScreenDimensions } from '@/presentation/hooks/useScreenDimensions'

function BackToOldButton() {
  const { screenWidth } = useScreenDimensions()

  if (screenWidth <= 650) return <SmallButton href="https://app.nexatlas.com">Voltar à versão anterior</SmallButton>
  return (
    <LargeButton href="https://app.nexatlas.com">
      <Assets.ChevronLeft size={16} color="#FCFCFC" />
      Voltar à versão anterior
    </LargeButton>
  )
}

export { BackToOldButton }

const LargeButton = styled.a`
  position: absolute;
  right: 9px;
  top: 10px;
  z-index: 10;
  border: none;
  cursor: pointer;
  text-decoration: none;
  height: 43px;
  padding: 14px 19px;
  border-radius: 25px;
  background-color: ${({ theme }) => theme.colors.secondaryD1};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  box-shadow: -2px 4px 6px 0px ${({ theme }) => `${theme.colors.secondary}80`};
  ${({ theme }) => theme.fonts.titleXS}

  @media (max-height: 400px) {
    right: 60px;
  }
`

const SmallButton = styled.a`
  position: absolute;
  right: 9px;
  z-index: 11;
  border: none;
  cursor: pointer;
  text-decoration: none;
  height: 25px;
  border-radius: 10px;
  padding: 3px 10px;
  background-color: ${({ theme }) => theme.colors.secondaryD1};
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: -2px 4px 6px 0px ${({ theme }) => `${theme.colors.secondary}80`};
  ${({ theme }) => theme.fonts.infoMD}
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
