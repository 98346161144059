import * as Turf from '@turf/turf'
import GeoJSON from 'geojson'

import { Coordinates } from '@/domain/protocols/Coordinates'

import { nmDistance } from '@/utils/coordinates'

export const makeGeoJsonMeasuringSegmentLineFeatureCollection =
  (coordinates: [Coordinates, Coordinates]) => (): GeoJSON.FeatureCollection => {
    const lineFeatureArray: GeoJSON.Feature[] = []

    const npoints = Math.ceil(nmDistance(coordinates[0], coordinates[1]) / 30)
    const greatCircle = Turf.greatCircle(coordinates[0].toArray(), coordinates[1].toArray(), {
      npoints
    })

    lineFeatureArray.push({
      type: 'Feature',
      properties: {},
      geometry: greatCircle.geometry
    })

    return {
      type: 'FeatureCollection',
      features: lineFeatureArray
    }
  }

export const makeGeoJsonMeasuringSegmentLabelFeatureCollection =
  (coordinates: Coordinates, oppositeCoordinates: Coordinates, end: Coordinates, radial: number) =>
  (): GeoJSON.Feature => {
    const distance = Turf.distance(coordinates.toArray(), oppositeCoordinates.toArray(), {
      units: 'nauticalmiles'
    })

    const radialText = radial.toFixed(0).padStart(3, '0').concat('°')

    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: end.toArray()
      },
      properties: {
        labelText: `${distance.toFixed(1)} nm ${radialText} mag`,
        radial: Turf.rhumbBearing(coordinates.toArray(), oppositeCoordinates.toArray()),
        radialText,
        distance
      }
    }
  }

export const makeGeoJsonMeasuringSegmentPointFeatureCollection =
  (coordinates: [Coordinates, Coordinates]) => (): GeoJSON.FeatureCollection => {
    const pointFeatureArray: GeoJSON.Feature[] = []
    const [start, end] = coordinates

    pointFeatureArray.push({
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Point',
        coordinates: start.toArray()
      }
    })

    pointFeatureArray.push({
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Point',
        coordinates: end.toArray()
      }
    })

    return {
      type: 'FeatureCollection',
      features: pointFeatureArray
    }
  }
