import { useState } from 'react'

import { CircularProgress } from '@mui/material'
import { Assets, TextField } from '@nexds/web'
import { useTheme } from 'styled-components'

import { UniqueEntityID } from '@/domain/core'
import { Route } from '@/domain/models'

import { useI18n } from '@/presentation/hooks/useI18n'

import { SavedRoute } from '../SavedRoute/SavedRoute'
import {
  Container,
  EmptyList,
  EmptyMessage,
  SavedRouteList,
  ScrollableContainer,
  Title
} from './SavedRouteScreen.styles'

interface RouteItem {
  id: UniqueEntityID
  name: string
  distance: string
  updatedAt: string
  duration: string
  groundSpeed: string
}
interface SavedRoutesScreenProps {
  routes: RouteItem[]
  shouldShowLoading: boolean
  filterValue: string
  onFilterChange: (value: string) => void
  onPress: (routeId: UniqueEntityID) => void
  onDelete: (routeId: string) => Promise<void>
}

function SavedRoutesScreen(props: SavedRoutesScreenProps) {
  const { routes, shouldShowLoading, filterValue, onFilterChange, onPress, onDelete } = props

  const theme = useTheme()
  const { t } = useI18n()
  return (
    <Container>
      <Title>{t('DRAWER_SAVED-ROUTES_MAIN_TITLE')}</Title>
      <TextField
        size="sm"
        labelGutter={false}
        helpGutter={false}
        leftIcon="Funnel"
        value={filterValue}
        onChange={(e) => onFilterChange(e.target.value)}
        placeholder={t('DRAWER_SAVED-ROUTES_MAIN_SEARCH_PLACEHOLDER')}
      />
      {shouldShowLoading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%'
          }}
        >
          <CircularProgress />
        </div>
      ) : routes.length === 0 ? (
        <EmptyList>
          {filterValue.length === 0 && <Assets.Save color={theme.colors.secondaryL3} size={32} />}
          <EmptyMessage>
            {filterValue.length > 0
              ? t('DRAWER_SAVED-ROUTES_MAIN_LIST_NO-RESULTS')
              : t('DRAWER_SAVED-ROUTES_MAIN_LIST_NO-ROUTES')}
          </EmptyMessage>
        </EmptyList>
      ) : (
        <ScrollableContainer>
          <SavedRouteList>
            {routes.map((route) => (
              <SavedRoute
                key={route.id.toString()}
                distance={route.distance}
                time={route.duration}
                groundSpeed={route.groundSpeed}
                routeName={route.name}
                routeUpdatedAt={route.updatedAt}
                onDelete={async () => {
                  await onDelete(route.id.toString())
                }}
                onPress={() => {
                  onPress(route.id)
                }}
              />
            ))}
          </SavedRouteList>
        </ScrollableContainer>
      )}
    </Container>
  )
}

export { SavedRoutesScreen }
