import mapboxgl from 'mapbox-gl'

const iconStyle: {
  layout: mapboxgl.SymbolLayout
  paint: mapboxgl.SymbolPaint
} = {
  layout: {
    'text-field': ['get', 'code'],
    'text-size': 12,
    'text-font': ['Open Sans Bold'],
    'text-offset': [0, 1.9],
    'text-ignore-placement': true,
    'text-allow-overlap': true,
    'text-anchor': 'center',
    'text-transform': 'uppercase',
    'text-justify': 'center',
    'text-optional': false,
    'icon-optional': false,
    'icon-offset': [0, 16],
    'icon-size': 0.25,
    'icon-allow-overlap': true,
    'symbol-z-order': 'source',
    'symbol-sort-key': ['to-number', ['get', 'priority']]
  },
  paint: {
    'text-halo-color': '#000',
    'text-color': '#fff',
    'text-halo-width': 1
  }
}

const iconStyleAd = {
  layout: iconStyle.layout,
  paint: {
    ...iconStyle.paint,
    'text-opacity': ['step', ['zoom'], 0, 8, 1]
  }
}

const iconStyleHp = {
  layout: iconStyle.layout,
  paint: {
    ...iconStyle.paint,
    'text-opacity': ['step', ['zoom'], 0, 11, 1]
  }
}

const iconStyleUserWaypoint = {
  layout: iconStyle.layout,
  paint: {
    ...iconStyle.paint,
    'text-opacity': ['step', ['zoom'], 0, 9.5, 1]
  }
}

const iconStyleFocused = {
  paint: {
    'text-opacity': 1
  }
}

export { iconStyle, iconStyleAd, iconStyleHp, iconStyleUserWaypoint, iconStyleFocused }
