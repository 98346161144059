import { useCallback, useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'

import { AppContainer } from '@presentation/App.styles'

import { InjectionTokens } from '@/controller/tokens'

import { RouteState, RouteStateProps } from '@/domain/states/RouteState'

import { LocalStorageKeyValuePersister } from '@/infra/keyValuePersister/LocalStorageKeyValuePersister'
import { MapPresenter } from '@/presentation/modules/MapScreen/containers'
import { isTesting } from '@/utils/testing'

import { useInjection } from './contexts/InjectionContext'
import { useGlobalState } from './hooks/useGlobalState'
import { BackdropGlobal } from './modules/Base/components/BackdropGlobal/BackdropGlobal'
import { BackToOldButton } from './modules/Base/components/general/BackToOldButton'
import { BetaNotice } from './modules/Base/components/general/BetaNotice'
import { TouchDeviceAlert } from './modules/Base/components/TouchDeviceAlert/TouchDeviceAlert'
import {
  BannerPresenter,
  DrawerPresenter,
  FeedbackCollectorPresenter,
  InfoPanelPresenter,
  MeteorologyControlPresenter,
  ModalPresenter,
  ToolbarPresenter
} from './modules/Base/containers'
import { ChatPresenter } from './modules/Base/containers/ChatPresenter'
import { RouteGuidePresenter } from './modules/Base/containers/RouteGuidePresenter'

function App() {
  const persister = useInjection<LocalStorageKeyValuePersister>(InjectionTokens.KeyValuePersister)
  const [deviceAlert, setDeviceAlert] = useState(false)

  useEffect(() => {
    const run = async () => {
      const consent = await persister.get('touch-alert-consent')
      if (consent.isFailure || (consent.isSuccess && consent.getValue() !== 'true')) {
        setDeviceAlert('ontouchstart' in window || navigator.maxTouchPoints > 0)
      }
    }

    run()
  }, [])

  const handlePersistConsent = useCallback(async () => {
    const result = await persister.set('touch-alert-consent', 'true')
    if (result.isFailure) console.log(result.error)
  }, [])

  return (
    <AppContainer>
      {deviceAlert && <TouchDeviceAlert onClose={() => setDeviceAlert(false)} onConsent={handlePersistConsent} />}
      <BrowserRouter>
        <DrawerPresenter />
      </BrowserRouter>
      <MapPresenter />
      <ToolbarPresenter />
      <InfoPanelPresenter />
      <MeteorologyControlPresenter />
      <RouteGuidePresenter />
      <ModalPresenter locale="global" />
      <BackdropGlobal />
      {/* <BannerPresenter /> TODO: habilitar o banner */}
      {isTesting() && <FeedbackCollectorPresenter />}
      <BackToOldButton />
      {isTesting() && <BetaNotice />}
      {!isTesting() && <ChatPresenter />}
    </AppContainer>
  )
}

export { App }
