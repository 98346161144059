import { InjectionTokens } from '@/controller/tokens'

import { I18n } from '@/i18n/I18n'

import { useInjection } from '../contexts/InjectionContext'

export function useI18n() {
  const i18n = useInjection<I18n>(InjectionTokens.I18n)

  return {
    t: i18n.resolveToken.bind(i18n),
    lang: i18n.getLanguage.bind(i18n),
    getTimeFormat: i18n.getTimeFormat.bind(i18n),
    getDateFormat: i18n.getDateFormat.bind(i18n)
  }
}
