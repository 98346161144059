export type MeteorologyLayer =
  | 'sigmet'
  | 'stsc'
  | 'radar-maxcappi'
  | 'satellite-infrared'
  | 'satellite-visible'
  | 'satellite-highlight'
  | 'sigwx'

export const meteorologyLayersList: readonly MeteorologyLayer[] = [
  'sigmet',
  'stsc',
  'radar-maxcappi',
  'satellite-infrared',
  'satellite-visible',
  'satellite-highlight',
  'sigwx'
]

type MeteorologyWarningTimes = Record<MeteorologyLayer, number>

export const meteorologyWarningTimes: MeteorologyWarningTimes = {
  sigmet: 1000 * 60 * 60 * 6,
  stsc: 1000 * 60 * 30,
  'radar-maxcappi': 1000 * 60 * 30,
  'satellite-infrared': 1000 * 60 * 60 * 1.5,
  'satellite-visible': 1000 * 60 * 60 * 1.5,
  'satellite-highlight': 1000 * 60 * 60 * 1.5,
  sigwx: 1000 * 60 * 60 * 6 // TODO: Verify this value
}
