import { Result } from '@domain/protocols/Result'
import { IKeyValuePersister } from '@infra/keyValuePersister/IKeyValuePersister'
import { injectable } from 'inversify'

@injectable()
export class LocalStorageKeyValuePersister implements IKeyValuePersister {
  async get(key: string): Promise<Result<string>> {
    if (localStorage.getItem(key) === null) return Promise.resolve(Result.fail('Key not found'))
    return Promise.resolve(Result.ok(localStorage.getItem(key) as string))
  }

  async set(key: string, value: string): Promise<Result<void>> {
    localStorage.setItem(key, value)
    return Promise.resolve(Result.ok())
  }

  async delete(key: string): Promise<Result<void>> {
    localStorage.removeItem(key)
    return Promise.resolve(Result.ok())
  }
}
