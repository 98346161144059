import { useEffect, useMemo } from 'react'

import { useI18n } from '@/presentation/hooks/useI18n'

import { ChartsAndData, ChartsAndDataResponse as ChartsAndDataProps } from '../types'
import {
  ChartsAndDataScreenContainer,
  ChartsAndDataScreenBody,
  Table,
  Thead,
  Tbody,
  Td,
  Tr
} from './ChartsAndDataScreen.styles'

function ChartsAndDataScreen(props: ChartsAndDataProps) {
  const { dataUpdateItems } = props

  const { t } = useI18n()

  const segregateSectionTypes = useMemo(() => {
    const segregatedSections: {
      [key in 'data' | 'rea' | 'reh' | 'wac' | 'enrch' | 'enrcl' | 'arc']: ChartsAndData[]
    } = {
      data: [],
      rea: [],
      reh: [],
      wac: [],
      enrch: [],
      enrcl: [],
      arc: []
    }

    dataUpdateItems.forEach((d) => {
      switch (d.type) {
        case 'dados':
          segregatedSections.data.push(d)
          break
        case 'rea':
          segregatedSections.rea.push(d)
          break
        case 'reh':
          segregatedSections.reh.push(d)
          break
        case 'wac':
          segregatedSections.wac.push(d)
          break
        case 'enrch':
          segregatedSections.enrch.push(d)
          break
        case 'enrcl':
          segregatedSections.enrcl.push(d)
          break
        case 'arc':
          segregatedSections.arc.push(d)
          break
        default:
          break
      }
    })

    return segregatedSections
  }, dataUpdateItems)

  return (
    <ChartsAndDataScreenContainer>
      <ChartsAndDataScreenBody>
        <DataSection name={'DADOS'} sectionData={segregateSectionTypes.data} />
        <DataSection name={'CARTAS REA'} sectionData={segregateSectionTypes.rea} />
        <DataSection name={'CARTAS REH'} sectionData={segregateSectionTypes.reh} />
        <DataSection name={'CARTAS WAC'} sectionData={segregateSectionTypes.wac} />
        <DataSection name={'CARTAS ENRC HIGH'} sectionData={segregateSectionTypes.enrch} />
        <DataSection name={'CARTAS ENRC LOW'} sectionData={segregateSectionTypes.enrcl} />
        <DataSection name={'CARTAS ARC'} sectionData={segregateSectionTypes.arc} />
      </ChartsAndDataScreenBody>
    </ChartsAndDataScreenContainer>
  )
}

const DataSection = (props: { name: string; sectionData: ChartsAndData[] }) => {
  const { name, sectionData } = props

  const formatDate = (date: string) => {
    return new Date(date)
      .toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: '2-digit' })
      .replace(/\//g, '-')
  }

  return (
    <Table>
      <Thead>
        <tr>{name}</tr>
      </Thead>
      <Tbody>
        {sectionData.map((item) => (
          <Tr key={item.id}>
            <Td>{item.name}</Td>
            <Td>{formatDate(item.updated_date)}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}

export { ChartsAndDataScreen }
