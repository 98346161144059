import styled from 'styled-components'

export const SplashContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  margin: 0 32px;
  width: 100%;
  max-width: 500px;
  height: 100%;
  max-width: 100vw;
  margin: 0 auto;

  & figure {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`

export const Logo = styled.img`
  width: 100%;
  height: 100%;
  max-width: 474px;
  max-height: 96px;
  padding: 0 64px;
`

export const ProgressContainer = styled.div`
  width: 100%;
  max-width: 474px;
  padding: 64px 12px;
`

export const Messages = styled.div`
  width: 100%;
  padding: 8px 0;
  text-align: center;
  color: ${({ theme }) => theme.colors.secondaryD1};
  ${({ theme }) => theme.fonts.h5}
`
