import styled from 'styled-components'

interface UserWaypointProps {
  place: number
  waypointsLength: number
}

export const UserWaypoint = styled.div<UserWaypointProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 48px;
  width: 100%;

  padding-left: 8px;

  background: ${({ theme }) => theme.colors.secondary};

  ${({ place, waypointsLength, theme }) => {
    return place + 1 === waypointsLength ? 'border: none;' : `border-bottom: 1px solid ${theme.colors.secondaryL1};`
  }}

  transition: background 200ms;

  &:hover {
    background: ${({ theme }) => theme.colors.secondaryL1};
  }

  & div:first-child {
    position: relative;
  }

  & label {
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    width: max-content;
  }
`

export const ClickableArea = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding-right: 12px;

  border: none;
  background: transparent;
  outline: none;

  cursor: pointer;

  flex-grow: 1;

  height: 100%;
  z-index: 1;
`
