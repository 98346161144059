import { ADHP } from '../models'
import { Procedure, ProcedureType } from '../protocols/Procedures'
import { BaseState } from './BaseState'

export interface ProceduresStateProps {
  adhp: ADHP

  adhpProcedures: Procedure[]

  procedureTypeSelected: ProcedureType

  procedureSelected: Procedure

  presentedProcedure: Procedure
}

const INITIAL_STATE: ProceduresStateProps = {
  adhp: null,

  adhpProcedures: [],

  procedureTypeSelected: null,

  procedureSelected: null,

  presentedProcedure: null
}

export class ProceduresState extends BaseState<ProceduresStateProps> {
  constructor() {
    super(INITIAL_STATE)
  }

  setPresentedProcedure(procedure: Procedure) {
    this._updateStateProp('presentedProcedure', procedure)
  }

  setAdhp(adhp: ADHP) {
    this._updateStateProp('adhp', adhp)
  }

  setAdhpProcedures(procedures: Procedure[]) {
    this._updateStateProp('adhpProcedures', procedures)
  }

  setProcedureTypeSelected(procedureType: ProcedureType) {
    this._updateStateProp('procedureTypeSelected', procedureType)
  }

  setProcedureSelected(procedure: Procedure) {
    this._updateStateProp('procedureSelected', procedure)
  }
}
