import GeoJSON from 'geojson'
import { inject, injectable } from 'inversify'

import { InjectionTokens } from '@/controller/tokens'

import { MeteorologyLayer } from '@/domain/protocols/Meteorology'
import { Result } from '@/domain/protocols/Result'

import type { IHttpClient } from '@/infra/http/IHttpClient'

import { IMeteorologyRepository, MeteorologyManifest } from './IMeteorologyRepository'
@injectable()
export class RemoteMeteorologyRepository implements IMeteorologyRepository {
  public constructor(@inject(InjectionTokens.HttpClient) private remoteClient: IHttpClient) {}

  protected getRemoteUrl(): Record<string, string> {
    return {
      sigmet: `${import.meta.env.VITE_METEOROLOGY_PACKAGES_API_BASEURL}/sigmet/manifest.json`,
      stsc: `${import.meta.env.VITE_METEOROLOGY_PACKAGES_API_BASEURL}/stsc/manifest.json`,
      'radar-maxcappi': `${import.meta.env.VITE_METEOROLOGY_PACKAGES_API_BASEURL}/radar-maxcappi/manifest.json`,
      'satellite-infrared': `${import.meta.env.VITE_METEOROLOGY_PACKAGES_API_BASEURL}/satellite-infrared/manifest.json`,
      'satellite-visible': `${import.meta.env.VITE_METEOROLOGY_PACKAGES_API_BASEURL}/satellite-visible/manifest.json`,
      'satellite-highlight': `${import.meta.env.VITE_METEOROLOGY_PACKAGES_API_BASEURL}/satellite-highlight/manifest.json`,
      sigwx: `${import.meta.env.VITE_METEOROLOGY_PACKAGES_API_BASEURL}/sigwx/manifest.json`
    }
  }

  public async getManifestByLayerName(layerName: MeteorologyLayer): Promise<Result<MeteorologyManifest>> {
    const url = this.getRemoteUrl()[layerName]
    const localData = await this.remoteClient.get<MeteorologyManifest>(url)

    if (localData.success === true) {
      return Result.ok(localData.response)
    }

    return Result.fail(localData.error)
  }

  public async getGeoJson(
    layerName: MeteorologyLayer,
    frameTimestamp: string
  ): Promise<Result<GeoJSON.FeatureCollection>> {
    const manifest = await this.getManifestByLayerName(layerName)

    if (manifest.isFailure) {
      return Result.fail('Cannot get manifest')
    }

    const frame = manifest.getValue().frameTimestamps.find((timestamp) => timestamp === frameTimestamp)

    if (!frame) {
      return Result.fail('Frame not found')
    }

    const url = `${import.meta.env.VITE_METEOROLOGY_PACKAGES_API_BASEURL}/${layerName}/${frameTimestamp}.geojson`

    const response = await this.remoteClient.get<GeoJSON.FeatureCollection>(url)

    if (!response.success) {
      return Result.fail('Cannot get GeoJSON')
    }

    return Result.ok(response.response)
  }
}
