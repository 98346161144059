import { LegendContainer } from './ADHPTool.styles'

interface LegendProps {
  meaning: string
  icon: string
  place: number
  legendsLength: number
}

export function Legend(props: LegendProps) {
  const { meaning, icon, place, legendsLength } = props

  return (
    <LegendContainer legendsLength={legendsLength} place={place}>
      <img src={icon} alt={meaning} />
      <p>{meaning}</p>
    </LegendContainer>
  )
}
