import type { ReactNode } from 'react'

import { ChevronDown, ChevronUp } from '@nexds/web'
import { useTheme } from 'styled-components'

import { Drawer as DrawerContainer, DrawerHandle, DrawerContent } from './ADHPTool.styles'

interface DrawerProps {
  title: string
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  children: ReactNode
  handlePosition?: 'top' | 'middle' | 'bottom' | 'single'
  lastChild?: boolean
}

export function Drawer(props: DrawerProps) {
  const theme = useTheme()

  return (
    <>
      <DrawerHandle
        positionInGroup={props.handlePosition || 'single'}
        onClick={() => {
          props.setIsOpen(!props.isOpen)
        }}
      >
        <span>{props.title}</span>
        {props.isOpen ? (
          <ChevronUp color={theme.colors.neutralL5} size="sm" />
        ) : (
          <ChevronDown color={theme.colors.neutralL5} size="sm" />
        )}
      </DrawerHandle>
      <DrawerContainer isOpen={props.isOpen} lastChild={props.lastChild}>
        <DrawerContent isOpen={props.isOpen}>{props.children}</DrawerContent>
      </DrawerContainer>
    </>
  )
}
