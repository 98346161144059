import styled from 'styled-components'

import { appZIndex } from '@/presentation/utils/zIndexMapper'

interface MapSearchContainerProps {
  screenPosition: {
    top: number
    left: number
    translateX: string
    translateY: string
  }
  width: number
}

export const MapSearchContainer = styled.div<MapSearchContainerProps>`
  position: absolute;
  z-index: ${appZIndex.MAP_SEARCH};
  height: fit-content;
  width: ${({ width }) => width}px;

  top: ${({ screenPosition }) => screenPosition.top}px;
  left: ${({ screenPosition }) => screenPosition.left}px;
  transform: ${({ screenPosition }) => `translate(${screenPosition.translateX},${screenPosition.translateY})`};
`

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  z-index: ${appZIndex.MAP_SEARCH - 1};
`
