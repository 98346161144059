import { BehaviorSubject } from 'rxjs'

export const taskMessages = {
  testingInternetConnection: 'Verificando conexão com a NexAtlas',
  checkingLoginStatus: 'Obtendo dados da sua conta',
  getWaypointData: 'Obtendo dados aeronáuticos'
} as const

export const taskSize = Object.keys(taskMessages).length

type TaskId = keyof typeof taskMessages

interface Task {
  taskId: TaskId
  done: boolean
}

export interface SplashScreenInfoStateProps {
  tasks: Task[]
}

const INITIAL_STATE: SplashScreenInfoStateProps = {
  tasks: []
}

export const SplashScreenInfoState = new BehaviorSubject<SplashScreenInfoStateProps>(INITIAL_STATE)

export const SplashScreenInfoStateMutator = {
  mutate: (newState: SplashScreenInfoStateProps) => {
    SplashScreenInfoState.next(newState)
  },

  initTask: (id: TaskId) => {
    SplashScreenInfoState.next({
      tasks: [
        ...SplashScreenInfoState.getValue().tasks,
        {
          taskId: id,
          done: false
        }
      ]
    })
  },

  finishTask: (id: TaskId) => {
    const currentState = SplashScreenInfoState.getValue()
    const updatedTasks: Task[] = currentState.tasks.map((t) => (t.taskId === id ? { ...t, done: true } : t))

    SplashScreenInfoStateMutator.mutate({
      tasks: updatedTasks
    })
  }
}
