import { injectable } from 'inversify'
import { Observable } from 'rxjs'

import FeatureFlags from '@/domain/protocols/FeatureFlags'

import { IFeatureFlagsProvider } from './IFeatureFlagsProvider'

const LocalFeatureFlags: FeatureFlags = {
  airspaces: true,
  meteorology: true
}

@injectable()
class LocalFeatureFlagsProvider implements IFeatureFlagsProvider {
  getFeatureFlags(): Observable<FeatureFlags> {
    return new Observable<FeatureFlags>((observer) => {
      observer.next(LocalFeatureFlags)
      observer.complete()
    })
  }
}

export { LocalFeatureFlagsProvider }
