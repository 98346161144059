import styled from 'styled-components'

function BetaNotice() {
  return (
    <Container>
      <Label>Versão de testes</Label>
    </Container>
  )
}

export { BetaNotice }

const Container = styled.span`
  position: absolute;
  top: 0;
  z-index: 10;
  width: 100dvw;
  height: 8px;
  background-color: ${({ theme }) => theme.colors.yellowL0};
`

const Label = styled.span`
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.colors.yellowL0};
  height: 15px;
  width: fit-content;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: black;
  ${({ theme }) => theme.fonts.labelMD}
`
