import LegendAerodromePaved from '@presentation/assets/adhp/aerodrome-paved.png'
import LegendAerodromeUnpaved from '@presentation/assets/adhp/aerodrome-unpaved.png'
import LegendHelipad from '@presentation/assets/adhp/helipad.png'
import AerodromePaved from '@presentation/assets/adhp/map/aerodrome-paved.png'
import AerodromeUnpaved from '@presentation/assets/adhp/map/aerodrome-unpaved.png'
import FocusedAerodromePaved from '@presentation/assets/adhp/map/focused-aerodrome-paved.png'
import FocusedAerodromeUnpaved from '@presentation/assets/adhp/map/focused-aerodrome-unpaved.png'
import FocusedHelipad from '@presentation/assets/adhp/map/focused-helipad.png'
import FocusedMilitaryAerodromePaved from '@presentation/assets/adhp/map/focused-military-aerodrome-paved.png'
import FocusedMilitaryAerodromeUnpaved from '@presentation/assets/adhp/map/focused-military-aerodrome-unpaved.png'
import FocusedMilitaryHelipad from '@presentation/assets/adhp/map/focused-military-helipad.png'
import FocusedUserWaypoint from '@presentation/assets/adhp/map/focused-user-waypoint.png'
import Helipad from '@presentation/assets/adhp/map/helipad.png'
import MilitaryAerodromePaved from '@presentation/assets/adhp/map/military-aerodrome-paved.png'
import MilitaryAerodromeUnpaved from '@presentation/assets/adhp/map/military-aerodrome-unpaved.png'
import MilitaryHelipad from '@presentation/assets/adhp/map/military-helipad.png'
import UserWaypoint from '@presentation/assets/adhp/map/user-waypoint.png'
// Legends
import LegendMilitaryAerodromePaved from '@presentation/assets/adhp/military-aerodrome-paved.png'
import LegendMilitaryAerodromeUnpaved from '@presentation/assets/adhp/military-aerodrome-unpaved.png'
import LegendMilitaryHelipad from '@presentation/assets/adhp/military-helipad.png'
import LegendUserWaypoint from '@presentation/assets/adhp/user-waypoint.png'

export default {
  'aerodrome-paved': AerodromePaved,
  'aerodrome-unpaved': AerodromeUnpaved,
  helipad: Helipad,
  'focused-aerodrome-paved': FocusedAerodromePaved,
  'focused-aerodrome-unpaved': FocusedAerodromeUnpaved,
  'focused-helipad': FocusedHelipad,
  'military-aerodrome-paved': MilitaryAerodromePaved,
  'military-aerodrome-unpaved': MilitaryAerodromeUnpaved,
  'military-helipad': MilitaryHelipad,
  'focused-military-aerodrome-paved': FocusedMilitaryAerodromePaved,
  'focused-military-aerodrome-unpaved': FocusedMilitaryAerodromeUnpaved,
  'focused-military-helipad': FocusedMilitaryHelipad,
  'user-waypoint': UserWaypoint,
  'focused-user-waypoint': FocusedUserWaypoint,
  // Legends
  'legend-aerodrome-paved': LegendAerodromePaved,
  'legend-military-aerodrome-paved': LegendMilitaryAerodromePaved,
  'legend-aerodrome-unpaved': LegendAerodromeUnpaved,
  'legend-military-aerodrome-unpaved': LegendMilitaryAerodromeUnpaved,
  'legend-helipad': LegendHelipad,
  'legend-military-helipad': LegendMilitaryHelipad,
  'legend-user-waypoint': LegendUserWaypoint
} as { [key: string]: string }
