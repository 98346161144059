import { useEffect, useMemo, useState } from 'react'

import { Point } from 'mapbox-gl'

import { InjectionTokens } from '@/controller/tokens'

import { ADHP, Waypoint } from '@/domain/models'
import { MapPosition } from '@/domain/protocols/MapPosition'

import { IAeroInfoRepository } from '@/data/AeroInfoRepository/IAeroInfoRepository'
import { IUserWaypointsRepository } from '@/data/UserWaypointsRepository/IUserWaypointsRepository'

import { useInjection } from '@/presentation/contexts/InjectionContext'
import { useBehaviorSubject } from '@/presentation/hooks/useBehaviorSubject'

import { MapADHP } from '../components/MapADHP/ADHPs/MapADHP'
import { WaypointInfoContainer } from '../components/MapADHP/WaypointInfo/WaypointInfoContainer'
import { ignoreMapClickLayers } from '../components/MapView/constants'
import { useMapContext } from '../components/MapView/MapContext'
import { MapADHPState, MapADHPStateProps } from '../states/MapADHPState'
import { MeasuringSegmentState } from '../states/MapMeasuringSegmentState'

function MapADHPPresenter() {
  const aeroinfoRepository = useInjection<IAeroInfoRepository>(InjectionTokens.AeroInfoRepository)
  const userWaypointsRepository = useInjection<IUserWaypointsRepository>(InjectionTokens.UserWaypointsRepository)
  const mapADHPState = useBehaviorSubject<MapADHPStateProps>(MapADHPState)
  const measuringSegmentState = useBehaviorSubject(MeasuringSegmentState)

  const mapContext = useMapContext()

  const [_adhps, setAdhps] = useState<ADHP[]>([])
  const [_userWaypoints, setUserWaypoints] = useState<Waypoint[]>([])
  const [focusedWaypoint, setFocusedWaypoint] = useState<string | null>(null)
  const [waypoint, setWaypoint] = useState<Waypoint | null>(null)
  const [waypointInfoOnMapPos, setWaypointInfoOnMapPos] = useState<MapPosition['position']>({ x: 0, y: 0 })
  const [menuPosition, setMenuPosition] = useState<
    | 'top-left'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-right'
    | 'center-left'
    | 'center-right'
    | 'center-bottom'
    | 'center-top'
    | 'default'
  >('top-left')

  useEffect(() => {
    const fetchADHPs = () => {
      const adhpsRequest = aeroinfoRepository.getAllADHPs()

      if (adhpsRequest.isSuccess) setAdhps(adhpsRequest.getValue())
    }

    const fetchUserWaypoints = () => {
      const userWaypointsRequest = userWaypointsRepository.getWaypoints()

      if (userWaypointsRequest.isSuccess) setUserWaypoints(userWaypointsRequest.getValue())
    }

    fetchADHPs()
    fetchUserWaypoints()
  }, [aeroinfoRepository, userWaypointsRepository])

  const adhps = useMemo(() => [..._adhps, ..._userWaypoints], [_adhps, _userWaypoints])

  const waypointInfoMenuPosition = useMemo(() => {
    const { innerWidth: width, innerHeight: height } = window

    const margin = 200
    const menuWidth = 300

    const screenRightLimit = width - margin - menuWidth
    const screenLeftLimit = margin
    const screenBottomLimit = height - margin
    const screenTopLimit = margin

    if (waypointInfoOnMapPos.y > screenBottomLimit) {
      if (waypointInfoOnMapPos.x < screenLeftLimit) {
        setMenuPosition('bottom-left')

        return {
          top: waypointInfoOnMapPos.y,
          left: waypointInfoOnMapPos.x,
          translateX: '10%',
          translateY: '-110%'
        }
      }

      if (waypointInfoOnMapPos.x > screenRightLimit) {
        setMenuPosition('bottom-right')

        return {
          top: waypointInfoOnMapPos.y,
          left: waypointInfoOnMapPos.x,
          translateX: '-110%',
          translateY: '-110%'
        }
      }

      setMenuPosition('center-bottom')

      return {
        top: waypointInfoOnMapPos.y,
        left: waypointInfoOnMapPos.x,
        translateX: '-50%',
        translateY: '-110%'
      }
    }

    if (waypointInfoOnMapPos.y < screenTopLimit) {
      if (waypointInfoOnMapPos.x < screenLeftLimit) {
        setMenuPosition('top-left')

        return {
          top: waypointInfoOnMapPos.y,
          left: waypointInfoOnMapPos.x,
          translateX: '10%',
          translateY: '10%'
        }
      }

      if (waypointInfoOnMapPos.x > screenRightLimit) {
        setMenuPosition('top-right')

        return {
          top: waypointInfoOnMapPos.y,
          left: waypointInfoOnMapPos.x,
          translateX: '-110%',
          translateY: '10%'
        }
      }

      setMenuPosition('center-top')

      return {
        top: waypointInfoOnMapPos.y,
        left: waypointInfoOnMapPos.x,
        translateX: '-50%',
        translateY: '10%'
      }
    }

    if (waypointInfoOnMapPos.x > screenRightLimit) {
      setMenuPosition('center-right')

      return {
        top: waypointInfoOnMapPos.y,
        left: waypointInfoOnMapPos.x,
        translateX: '-110%',
        translateY: '-50%'
      }
    }

    if (waypointInfoOnMapPos.x < screenLeftLimit) {
      setMenuPosition('center-left')

      return {
        top: waypointInfoOnMapPos.y,
        left: waypointInfoOnMapPos.x,
        translateX: '10%',
        translateY: '-50%'
      }
    }

    setMenuPosition('default')

    return {
      top: waypointInfoOnMapPos.y,
      left: waypointInfoOnMapPos.x,
      translateX: '10%',
      translateY: '-50%'
    }
  }, [waypointInfoOnMapPos])

  const handleCloseWaypointInfo = () => {
    setFocusedWaypoint(null)
    setWaypoint(null)
    setWaypointInfoOnMapPos({ x: 0, y: 0 })
  }

  const handleSymbolClick = (waypointId: string, point: Point) => {
    if (measuringSegmentState.active) return
    // TODO: Refactor race condition on feature click
    const ignoredLayers: string[] = []

    mapContext.map.getStyle().layers.forEach((layer) => {
      if (ignoreMapClickLayers.filter((layer) => !layer.includes('adhp')).includes(layer.id)) {
        ignoredLayers.push(layer.id)
      }
    })

    const features = mapContext.map.queryRenderedFeatures(point, { layers: ignoredLayers })
    if (features.length > 0) return

    setFocusedWaypoint(waypointId)

    const waypoint = adhps.find((w) => w.id.toValue().toString() === waypointId)

    if (waypoint instanceof ADHP) {
      setWaypoint(waypoint)
    } else {
      const userWaypoint = userWaypointsRepository.getWaypoint(waypoint.id)
      setWaypoint(userWaypoint.getValue())
    }

    setWaypointInfoOnMapPos(point)
  }

  return (
    <>
      {adhps && (
        <MapADHP
          adhps={adhps}
          minRunwayLength={mapADHPState.minRunwayLength ?? 0}
          showAerodromes={mapADHPState.aerodromes ?? false}
          showHelipads={mapADHPState.helipads ?? false}
          showUserWaypoints={mapADHPState.userWaypoints ?? false}
          onlyPaved={mapADHPState.pavedRunway ?? false}
          onlyNightOperation={mapADHPState.nightOperation ?? false}
          onlyATS={mapADHPState.ats ?? false}
          onlyIFR={mapADHPState.ifr ?? false}
          onSymbolClick={handleSymbolClick}
          focusedWaypoint={focusedWaypoint}
        />
      )}
      {focusedWaypoint && waypointInfoOnMapPos.x !== 0 && waypointInfoOnMapPos.y !== 0 && (
        <WaypointInfoContainer
          pointerPosition={menuPosition}
          menuScreenPos={waypointInfoMenuPosition}
          waypoint={waypoint}
          onOverlayClick={handleCloseWaypointInfo}
        />
      )}
    </>
  )
}

export { MapADHPPresenter }
