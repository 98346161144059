import { ChangeEvent, KeyboardEventHandler, useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { TextField } from '@nexds/web'

import { InjectionTokens } from '@/controller/tokens'

import { Waypoint } from '@/domain/models'
import { Coordinates } from '@/domain/protocols/Coordinates'
import { AddWaypointToRouteUseCase, OverrideWaypointOnRoute } from '@/domain/useCases/Route'

import { IAeroInfoRepository } from '@/data/AeroInfoRepository/IAeroInfoRepository'

import { useInjection } from '@/presentation/contexts/InjectionContext'
import { useBehaviorSubject } from '@/presentation/hooks/useBehaviorSubject'
import { useI18n } from '@/presentation/hooks/useI18n'
import { BackdropImperativeInterface } from '@/presentation/interfaces/BackdropImperativeInterface'
import { RouterPaths } from '@/presentation/router/RouterPathsMapper'
import { sendMetrics } from '@/presentation/utils/sendMetrics'
import { appZIndex } from '@/presentation/utils/zIndexMapper'

import { MapImperativeInterface } from '../../MapScreen/interfaces/MapImperativeInterface'
import { MapSearchState, MapSearchStateProps } from '../../MapScreen/states/MapSearchState'
import { MapTargetStateActions } from '../../MapScreen/states/MapTargetState'
import { ScreenElementsState, ScreenElementsStateProps } from '../../MapScreen/states/ScreenElementsState'
import { getFitBoundaries } from '../../MapScreen/utils/map'
import { SearchList } from '../components/SearchList'
import { SearchBox, SearchContainer } from './WrittenSearchPresenter.styles'

interface WrittenSearchPresenterProps {
  shouldOverlap: boolean
}

function WrittenSearchPresenter(props: WrittenSearchPresenterProps) {
  const { shouldOverlap } = props
  const { t } = useI18n()
  const navigate = useNavigate()

  const mapSearchState = useBehaviorSubject<MapSearchStateProps>(MapSearchState)
  const screenElementsState = useBehaviorSubject<ScreenElementsStateProps>(ScreenElementsState)

  const aeroInfoRepository = useInjection<IAeroInfoRepository>(InjectionTokens.AeroInfoRepository)
  const [searchValue, setSearchValue] = useState('')
  const [searchResults, setSearchResults] = useState<Waypoint[]>([] as Waypoint[])
  const [isFocused, setIsFocused] = useState(false)

  const [currentSearchResult, setCurrentSearchResult] = useState(0)
  const [selectedSearchResult, setSelectedSearchResult] = useState<Waypoint | null>(null)
  const resultsRef = useRef<HTMLDivElement>(null)

  const searchWaypoints = useCallback(
    async (value: string) => {
      const resultSet = await aeroInfoRepository.searchByInfo(value, 100)
      setSearchResults(resultSet)
    },
    [aeroInfoRepository]
  )

  const handleSearchInputValueChanged = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchValue(e.target.value)

    setCurrentSearchResult(0)
    setSelectedSearchResult(null)

    searchWaypoints(e.target.value)
  }, [])

  const handleFocus = useCallback(() => {
    setIsFocused(true)
    searchWaypoints(searchValue)

    sendMetrics('PLANNINGAPP_SEARCH_INPUT_CLICKED')
  }, [searchValue])

  const handleBlur = useCallback(() => {
    setIsFocused(false)
    setSearchResults([])
  }, [])

  const handleAddWaypointToRoute = useCallback(
    (item: Waypoint): void => {
      if (mapSearchState.searchOverride !== null) {
        new OverrideWaypointOnRoute().execute({ waypoint: item, index: mapSearchState.searchOverride })
      } else {
        const updatedRoute = new AddWaypointToRouteUseCase().execute(item)
        if (updatedRoute.isFailure) return

        const padding: [number, number, number, number] = [
          40 + screenElementsState.boundsDefaultPadding.top,
          75 + screenElementsState.boundsDefaultPadding.right,
          40 + screenElementsState.boundsDefaultPadding.bottom,
          75 + screenElementsState.boundsDefaultPadding.left
        ]

        if (updatedRoute.getValue().waypoints.length === 1) {
          navigate(RouterPaths.ROUTES_PANEL)
          MapImperativeInterface.flyToCoordinates(item.coordinates, 11, 2000, padding)
        } else if (updatedRoute.getValue().waypoints.length === 2) {
          const boundaries = getFitBoundaries(updatedRoute.getValue())
          MapImperativeInterface.flyToBounds(boundaries, padding, 500)
        }
      }

      setIsFocused(false)
      setSearchValue('')
      setSearchResults([])
    },
    [mapSearchState.searchOverride, screenElementsState.boundsDefaultPadding]
  )

  const handleSearchKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    const resultItemHeight = 55

    if (e.key === 'Escape') {
      e.preventDefault()
      e.currentTarget.blur()
      setCurrentSearchResult(0)
      handleResultClick(selectedSearchResult)
    }

    if (e.key === 'ArrowDown') {
      e.preventDefault()
      setCurrentSearchResult((prev) => (prev < searchResults.length - 1 ? prev + 1 : searchResults.length - 1))

      if (currentSearchResult > 2) {
        resultsRef.current.scrollBy({ top: resultItemHeight, behavior: 'smooth' })
      }
    }

    if (e.key === 'ArrowUp') {
      e.preventDefault()
      setCurrentSearchResult((prev) => (prev > 0 ? prev - 1 : 0))

      resultsRef.current.scrollBy({ top: -resultItemHeight, behavior: 'smooth' })
    }

    if (e.key === 'Enter' && searchResults[currentSearchResult]) {
      e.preventDefault()
      e.currentTarget.blur()
      handleResultClick(searchResults[currentSearchResult])
    }
  }

  const handleResultClick = useCallback(
    (waypoint: Waypoint | null) => {
      if (waypoint) {
        setSelectedSearchResult(waypoint)
        setSearchValue(waypoint.name)
        handleAddWaypointToRoute(waypoint)
      } else {
        handleBlur()
      }
    },
    [handleAddWaypointToRoute]
  )

  const handleLocateWaypoint = useCallback((coordinates: Coordinates) => {
    setIsFocused(false)
    setSearchResults([])
    MapTargetStateActions.setTarget(coordinates)
    const padding: [number, number, number, number] = [
      40 + screenElementsState.boundsDefaultPadding.top,
      75 + screenElementsState.boundsDefaultPadding.right,
      40 + screenElementsState.boundsDefaultPadding.bottom,
      75 + screenElementsState.boundsDefaultPadding.left
    ]
    MapImperativeInterface.flyToCoordinates(coordinates, 8, 2000, padding)
  }, [])

  useEffect(() => {
    if (isFocused)
      BackdropImperativeInterface.show(shouldOverlap ? appZIndex.SEARCH_BOX : appZIndex.DRAWER + 1, 0.3, handleBlur)
    else BackdropImperativeInterface.hide()
  }, [isFocused])
  return (
    <SearchContainer>
      <SearchBox shouldOverlap={shouldOverlap || isFocused}>
        <TextField
          labelGutter={false}
          helpGutter={false}
          placeholder={t('GLOBAL_SEARCH-BOX_PLACEHOLDER')}
          size="sm"
          isLightStyle
          value={searchValue}
          clearable={searchValue.length > 0}
          onClearClick={handleBlur}
          onFocus={handleFocus}
          onChange={handleSearchInputValueChanged}
          onKeyDown={handleSearchKeyDown}
        />
        {searchResults.length > 0 && (
          <SearchList
            resultsRef={resultsRef}
            waypoints={searchResults}
            maxHeight={400}
            onWaypointClick={handleAddWaypointToRoute}
            onLocateWaypointClick={handleLocateWaypoint}
            currentSearchResult={currentSearchResult}
            searchResults={searchResults}
          />
        )}
      </SearchBox>
    </SearchContainer>
  )
}

export { WrittenSearchPresenter }
