import { useCallback, useEffect, useRef, useState } from 'react'

import { FeatureInfoState, FeatureInfoStateMutator } from '@modules/MapScreen/states/FeatureInfoState'

import { useBehaviorSubject } from '@/presentation/hooks/useBehaviorSubject'

import { MapClickEvent } from '../../MapScreen/interfaces/MapEvents'
import { ScreenElementsStateMutator } from '../../MapScreen/states/ScreenElementsState'
import { AirspacesFeatureInfo } from '../components/InfoPanel/AirspacesFeatureInfo'
import { FeatureInfoContainer } from '../components/InfoPanel/InfoPanel.styles'
import { MeteorologyLegends } from '../components/InfoPanel/MeteorologyLegends'
import { MeteorologyTooltip } from '../components/InfoPanel/MeteorologyTooltip'

function InfoPanelPresenter() {
  const infoPanelRef = useRef<HTMLDivElement>(null)
  const featureInfoState = useBehaviorSubject(FeatureInfoState)

  const [showInfoPanel, setShowInfoPanel] = useState(false)

  // Listen to ESC key press to close the dialog
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleCloseInfoPanel()
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  useEffect(() => {
    const mapClickSubscription = MapClickEvent.subscribe(() => {
      handleCloseInfoPanel()
    })

    return () => {
      mapClickSubscription.unsubscribe()
    }
  }, [featureInfoState, MapClickEvent])

  const handleCloseInfoPanel = useCallback(() => {
    setShowInfoPanel(false)
    setTimeout(() => {
      FeatureInfoStateMutator.hide()
      ScreenElementsStateMutator.setFeatureInfoPanel(false)
    }, 300)
  }, [FeatureInfoStateMutator, ScreenElementsStateMutator])

  useEffect(() => {
    if (featureInfoState.active) {
      setShowInfoPanel(true)
      ScreenElementsStateMutator.setFeatureInfoPanel(true)
    }
  }, [featureInfoState])

  return (
    <FeatureInfoContainer ref={infoPanelRef} show={showInfoPanel}>
      {featureInfoState.active?.type === 'airspace' ? (
        <AirspacesFeatureInfo
          title={featureInfoState.active?.title}
          airspaceInfo={featureInfoState.active?.feature.properties}
          onClose={handleCloseInfoPanel}
        />
      ) : featureInfoState.active?.type === 'meteorology-legend' ? (
        <MeteorologyLegends
          title={featureInfoState.active.title}
          legends={featureInfoState.active.legends}
          onClose={handleCloseInfoPanel}
        />
      ) : featureInfoState.active?.type === 'meteorology-tooltip' ? (
        <MeteorologyTooltip
          title={featureInfoState.active.title}
          content={featureInfoState.active.content}
          onClose={handleCloseInfoPanel}
        />
      ) : null}
    </FeatureInfoContainer>
  )
}

export { InfoPanelPresenter }
