import { useState } from 'react'

import { CircularProgress } from '@mui/material'
import { Assets } from '@nexds/web'
import { useTheme } from 'styled-components'

import {
  Container,
  Row,
  Column,
  Route,
  SavedAt,
  Label,
  Value,
  Delete,
  ColumnRow,
  DeleteButton,
  HideOnHover,
  MainColumnWrapper
} from './SavedRoute.styles'

interface SavedRouteProps {
  routeName: string
  routeUpdatedAt: string
  distance: string
  time: string
  groundSpeed: string
  onDelete: () => Promise<void>
  onPress: () => void
}

function SavedRoute(props: SavedRouteProps) {
  const { routeName, routeUpdatedAt, distance, time, groundSpeed, onDelete, onPress } = props

  const theme = useTheme()
  const [isDeleting, setIsDeleting] = useState(false)

  return (
    <Container onClick={onPress}>
      <Row>
        <MainColumnWrapper>
          <Column>
            <Route>{routeName}</Route>
            <SavedAt>{routeUpdatedAt}</SavedAt>
          </Column>
        </MainColumnWrapper>

        <HideOnHover isDeleting={isDeleting}>
          <Column>
            <ColumnRow>
              <Label>Distância:</Label>
              <Value>{distance}</Value>
            </ColumnRow>
            <ColumnRow>
              <Label>Tempo:</Label>
              <Value>
                {time} &#40;{groundSpeed}kt&#41;
              </Value>
            </ColumnRow>
          </Column>
        </HideOnHover>
      </Row>

      <DeleteButton
        isDeleting={isDeleting}
        onClick={async (e) => {
          e.stopPropagation()
          setIsDeleting(true)
          await onDelete()
          setIsDeleting(false)
        }}
      >
        {isDeleting ? (
          <CircularProgress size={20} />
        ) : (
          <>
            <Assets.Trash size="sm" color={theme.colors.neutralL5} />
            <Delete>Apagar</Delete>
          </>
        )}
      </DeleteButton>
    </Container>
  )
}

export { SavedRoute }
