import { createContext, useContext } from 'react'

import mapboxgl from 'mapbox-gl'

interface MapContextProps {
  map: mapboxgl.Map
}

const MapContext = createContext<MapContextProps>({ map: null })

function useMapContext(): MapContextProps {
  return useContext(MapContext)
}

export { MapContext, useMapContext }
