import { useCallback, useEffect, useMemo } from 'react'
import { DropResult } from 'react-beautiful-dnd'

import { InjectionTokens } from '@/controller/tokens'

import { UniqueEntityID } from '@/domain/core'
import { Waypoint } from '@/domain/models'
import { MapLayerState, MapLayerStateProps } from '@/domain/states/MapLayerState'
import { RouteState, RouteStateProps } from '@/domain/states/RouteState'
import {
  ClearRouteUseCase,
  DeleteWaypointFromRouteUseCase,
  RenameWaypointFromRouteUseCase,
  ReorderWaypointsOnRouteUseCase,
  ReverseActiveRouteUseCase,
  SaveActiveRouteUseCase
} from '@/domain/useCases/Route'
import { CloneRouteUseCase } from '@/domain/useCases/Route/cloneRoute'
import { CopyRouteToFPLBR } from '@/domain/useCases/Route/copyRouteToFPLBR'
import { IsActiveRouteInRoutesSavedUseCase } from '@/domain/useCases/Route/IsActiveRouteInRoutesSaved'
import { RenameRouteUseCase } from '@/domain/useCases/Route/renameRoute'
import { SplitActiveRoute } from '@/domain/useCases/Route/splitActiveRoute'
import { UndoRouteUseCase } from '@/domain/useCases/Route/undoRoute'

import { useInjection } from '@/presentation/contexts/InjectionContext'
import { useGlobalState } from '@/presentation/hooks/useGlobalState'
import { useI18n } from '@/presentation/hooks/useI18n'
import { download } from '@/presentation/utils/download'
import { sendMetrics } from '@/presentation/utils/sendMetrics'
import { ModalUtil } from '@/utils/modalUtil'
import { ToastUtil } from '@/utils/toastUtil'

import { LoadingStateMutator } from '../../Base/states/LoadingState'
import { RoutesPanelScreen } from '../components/RoutesPanelScreen'
import { generateFMS, generateGPX, generatePLN, handleGenerateNavLog } from './ExportActions'

export type GpsExportOption = 'garmin'
export type SimulatorExportOption = 'MS FS2020' | 'XPlane 11' | 'XPlane 10'

function RoutesPanelScreenPresenter() {
  const { t } = useI18n()

  const undoRouteUseCase = useInjection<UndoRouteUseCase>(InjectionTokens.UndoRouteUseCase)

  const [routeState, routeStateMutator] = useGlobalState<RouteState, RouteStateProps>(InjectionTokens.RouteState)
  const [mapLayerState] = useGlobalState<MapLayerState, MapLayerStateProps>(InjectionTokens.MapLayerState)

  const route = useMemo(() => routeState.activeRoute ?? null, [routeState.activeRoute])
  const isRouteSaved = useMemo(() => routeState.isSaved, [routeState.isSaved, routeState.activeRoute])

  const handleEditRouteName = useCallback(
    (newName: string) => {
      if (!route) return

      const result = new RenameRouteUseCase().execute({
        route,
        name: newName
      })

      if (result.isSuccess)
        ToastUtil.send({
          label: t('TOAST_ROUTE_RENAME_SUCCESS'),
          dismissible: true
        })
      else
        ToastUtil.send({
          label: t('TOAST_ROUTE_RENAME_ERROR'),
          variant: 'error',
          dismissible: true
        })
    },
    [route]
  )

  const handleClearRoute = useCallback(() => {
    const clearRoute = () => {
      sendMetrics('PLANNINGAPP_DRAWER_ROUTE-CLEAR_CLICKED')
      const result = new ClearRouteUseCase().execute()
      if (result.isSuccess)
        ToastUtil.send({
          label: t('TOAST_ROUTE_DELETE_SUCCESS'),
          actionLabel: t('TOAST_ROUTE_DELETE_UNDO'),
          onAction: () => {
            undoRouteUseCase.execute()
            return true
          }
        })
      else
        ToastUtil.send({
          label: t('TOAST_ROUTE_DELETE_ERROR'),
          variant: 'error',
          dismissible: true
        })
    }

    ModalUtil.drawer.show({
      title: t('MODAL_CLEAR-ROUTE_TITLE'),
      positiveColor: 'primary',
      positiveLabel: t('MODAL_CLEAR-ROUTE_BUTTON_CONFIRM'),
      negativeLabel: t('MODAL_CLEAR-ROUTE_BUTTON_CANCEL'),
      onPositive: clearRoute,
      onNegative: () => {}
    })
  }, [ClearRouteUseCase])

  const handleSaveRoute = useCallback(async () => {
    if (isRouteSaved) return

    const saveRoute = async () => {
      LoadingStateMutator.setIsLoading(true)
      const result = await new SaveActiveRouteUseCase().execute()
      if (result.isFailure)
        return ToastUtil.send({
          label: t('TOAST_ROUTE_SAVE_ERROR'),
          variant: 'error',
          dismissible: true
        })
      else {
        sendMetrics('PLANNINGAPP_DRAWER_ROUTE_SAVED', { extra: 'save-route(yes)' })
        ToastUtil.send({
          label: t('TOAST_ROUTE_SAVE_SUCCESS'),
          variant: 'primary',
          leftIcon: 'Check',
          dismissible: true
        })
      }
      LoadingStateMutator.setIsLoading(false)
    }

    const saveAsNewRoute = async () => {
      LoadingStateMutator.setIsLoading(true)
      const routeCloned = new CloneRouteUseCase().execute(route)
      if (routeCloned.isFailure)
        return ToastUtil.send({
          label: t('TOAST_ROUTE_SAVE_ERROR'),
          variant: 'error',
          dismissible: true
        })
      const result = await new SaveActiveRouteUseCase().execute()
      if (result.isFailure)
        return ToastUtil.send({
          label: t('TOAST_ROUTE_SAVE_ERROR'),
          variant: 'error',
          dismissible: true
        })
      else {
        sendMetrics('PLANNINGAPP_DRAWER_ROUTE_SAVED', { extra: 'new-route' })
        ToastUtil.send({
          label: t('TOAST_NEW-ROUTE_SAVE_SUCCESS'),
          variant: 'primary',
          leftIcon: 'Check',
          dismissible: true
        })
      }

      LoadingStateMutator.setIsLoading(false)
    }

    const isInRoutesSaved = new IsActiveRouteInRoutesSavedUseCase().execute()
    if (isInRoutesSaved.isFailure) {
      ToastUtil.send({
        label: t('TOAST_ROUTE_SAVE_ERROR'),
        variant: 'error'
      })
      return
    }
    if (!isInRoutesSaved.getValue()) {
      saveRoute()
      return
    }

    ModalUtil.drawer.show({
      title: t('MODAL_SAVE-ROUTE_TITLE'),
      positiveColor: 'primary',
      positiveLabel: t('MODAL_SAVE-ROUTE_BUTTON_FIRST-OPTION'),
      negativeLabel: t('MODAL_SAVE-ROUTE_BUTTON_SECOND-OPTION'),
      onPositive: saveRoute,
      onNegative: saveAsNewRoute
    })
  }, [isRouteSaved, route])

  const handleInvertRoute = useCallback(() => {
    // TODO: O WebApp verifica se há outra alteração antes dessa e diz que ela será perdida. Verificar esse comportamento

    const invertRoute = () => {
      const result = new ReverseActiveRouteUseCase().execute()
      if (result.isSuccess) {
        sendMetrics('PLANNINGAPP_DRAWER_ROUTE_INVERTED')
        ToastUtil.send({
          label: t('TOAST_ROUTE_INVERT_SUCCESS'),
          actionLabel: t('TOAST_ROUTE_INVERT_UNDO'),
          onAction: () => {
            undoRouteUseCase.execute()
            return true
          }
        })
      } else
        ToastUtil.send({
          label: t('TOAST_ROUTE_INVERT_ERROR'),
          variant: 'error',
          dismissible: true
        })
    }

    ModalUtil.drawer.show({
      title: t('MODAL_INVERT-ROUTE_TITLE'),
      positiveColor: 'primary',
      positiveLabel: t('MODAL_INVERT-ROUTE_BUTTON_CONFIRM'),
      negativeLabel: t('MODAL_INVERT-ROUTE_BUTTON_CANCEL'),
      onPositive: invertRoute
    })
  }, [route, ReverseActiveRouteUseCase])

  const handleSplitRoute = useCallback(() => {
    const splitRoute = () => {
      const routeLength = route.waypoints.length ?? 0
      const splitRoute = new SplitActiveRoute().execute()

      if (splitRoute.isSuccess) {
        sendMetrics('PLANNINGAPP_DRAWER_ROUTE_SEGMENTED')
        const addedPoints = splitRoute.getValue().waypoints.length - routeLength
        const label =
          addedPoints > 1
            ? t('TOAST_ROUTE_SPLIT_SEVERAL_POINTS_SUCCESS', { addedPoints: addedPoints.toString() })
            : t('TOAST_ROUTE_SPLIT_ONE_POINT_SUCCESS')

        if (addedPoints < 1) {
          ModalUtil.drawer.show({
            title: t('MODAL_SPLIT-ROUTE_SHOULD-NOT-SPILT_TITLE'),
            headerDescription: t('MODAL_SPLIT-ROUTE_SHOULD-NOT-SPILT_MESSAGE'),
            positiveColor: 'secondary',
            positiveLabel: t('MODAL_SPLIT-ROUTE_SHOULD-NOT-SPILT_BUTTON')
          })
        } else {
          ToastUtil.send({
            label,
            actionLabel: t('TOAST_ROUTE_SPLIT_UNDO'),
            onAction: () => {
              undoRouteUseCase.execute()
              return true
            }
          })
        }
      } else
        ToastUtil.send({
          label: t('TOAST_ROUTE_SPLIT_ERROR'),
          variant: 'error',
          dismissible: true
        })
    }

    ModalUtil.drawer.show({
      title: t('MODAL_SPLIT-ROUTE_TITLE'),
      headerDescription: t('MODAL_SPLIT-ROUTE_DESCRIPTION'),
      positiveColor: 'primary',
      positiveLabel: t('MODAL_SPLIT-ROUTE_BUTTON_CONFIRM'),
      negativeLabel: t('MODAL_SPLIT-ROUTE_BUTTON_CANCEL'),
      onPositive: splitRoute
    })
  }, [route])

  const handleSaveAsPdf = useCallback(() => {
    handleGenerateNavLog(route, mapLayerState.overlayChart || mapLayerState.overlay2Chart, mapLayerState.baseChart)

    sendMetrics('PLANNINGAPP_DRAWER_ROUTE_EXPORTED', { type: 'pdf' })
  }, [route])

  const handlePrintRoute = useCallback(() => {
    handleGenerateNavLog(route, mapLayerState.overlayChart || mapLayerState.overlay2Chart, mapLayerState.baseChart)
    sendMetrics('PLANNINGAPP_DRAWER_ROUTE_EXPORTED', { type: 'print' })
  }, [route])

  const handleExportToGps = useCallback(
    (option: GpsExportOption) => {
      if (!route?.name || !route?.waypoints) return

      if (option === 'garmin') {
        const GPX = generateGPX(route.name, route.waypoints)
        download(`${route?.name}.gpx`, GPX)
        sendMetrics('PLANNINGAPP_DRAWER_ROUTE_EXPORTED', { type: 'garmin' })
      }
    },
    [route]
  )

  const handleExportToSimulator = useCallback(
    (option: SimulatorExportOption) => {
      if (!route?.name || !route?.waypoints) return

      if (option === 'MS FS2020') {
        const PLN = generatePLN(route.name, route.waypoints, false)
        download(`${route?.name}.pln`, PLN)
        sendMetrics('PLANNINGAPP_DRAWER_ROUTE_EXPORTED', { type: 'MF FS2020' })
      } else if (option === 'XPlane 10') {
        const FMS = generateFMS(route.waypoints, 3)
        download(`${route?.name}.fms`, FMS)
        sendMetrics('PLANNINGAPP_DRAWER_ROUTE_EXPORTED', { type: 'XPlane 10' })
      } else if (option === 'XPlane 11') {
        const FMS = generateFMS(route.waypoints, 11)
        download(`${route?.name}.fms`, FMS)
        sendMetrics('PLANNINGAPP_DRAWER_ROUTE_EXPORTED', { type: 'XPlane 11' })
      } else return
    },
    [route]
  )

  const handleCopyToFPLBR = useCallback(async () => {
    LoadingStateMutator.setIsLoading(true)
    const result = await new CopyRouteToFPLBR().execute()

    if (result.isSuccess)
      ToastUtil.send({
        label: t('TOAST_ROUTE_COPY_TO_FPL_SUCCESS'),
        variant: 'primary',
        dismissible: true
      })
    else
      ToastUtil.send({
        label: t('TOAST_ROUTE_COPY_TO_FPL_ERROR'),
        variant: 'error',
        dismissible: true
      })
    LoadingStateMutator.setIsLoading(false)
  }, [route])

  const handleDeleteWaypointFromRoute = useCallback(
    (waypoint: Waypoint) => {
      const result = new DeleteWaypointFromRouteUseCase().execute(waypoint)

      if (result.isSuccess)
        ToastUtil.send({
          label: t('TOAST_WAYPOINT_DELETE_SUCCESS'),
          variant: 'error',
          actionLabel: t('TOAST_WAYPOINT_DELETE_UNDO'),
          onAction: () => {
            undoRouteUseCase.execute()
            return true
          }
        })
      else
        ToastUtil.send({
          label: t('TOAST_WAYPOINT_DELETE_ERROR'),
          variant: 'error',
          dismissible: true
        })

      LoadingStateMutator.setIsLoading(false)
    },
    [route]
  )

  const handleEditWaypointName = useCallback(
    (waypoint: Waypoint, name: string) => {
      const result = new RenameWaypointFromRouteUseCase().execute({ waypoint, name })
      if (result.isSuccess)
        ToastUtil.send({
          label: t('TOAST_WAYPOINT_RENAME_SUCCESS'),
          variant: 'secondary',
          dismissible: true
        })
      else
        ToastUtil.send({
          label: t('TOAST_WAYPOINT_RENAME_ERROR'),
          variant: 'error',
          dismissible: true
        })
    },
    [route]
  )

  const handleReorderWaypoints = useCallback(
    (dragResult: DropResult) => {
      if (!dragResult.destination) return
      if (dragResult.destination.index === dragResult.source.index) return

      const result = new ReorderWaypointsOnRouteUseCase().execute({
        from: dragResult.source.index,
        to: dragResult.destination.index
      })

      if (result.isSuccess)
        ToastUtil.send({
          label: t('TOAST_WAYPOINT_RELOCATE_SUCCESS'),
          actionLabel: t('TOAST_WAYPOINT_RELOCATE_UNDO'),
          onAction: () => {
            undoRouteUseCase.execute()
            return true
          }
        })
      else
        ToastUtil.send({
          label: t('TOAST_WAYPOINT_RELOCATE_ERROR'),
          variant: 'error'
        })
    },
    [route]
  )

  const handleHighlightWaypoint = useCallback((waypointId: UniqueEntityID) => {
    routeStateMutator.setHighlightedWaypoint(waypointId)
  }, [])

  const handleFadeWaypoint = useCallback(() => {
    routeStateMutator.setHighlightedWaypoint(null)
  }, [])

  return (
    <RoutesPanelScreen
      route={route}
      isRouteSaved={isRouteSaved}
      onEditRouteName={handleEditRouteName}
      onClearRoute={handleClearRoute}
      onSaveRoute={handleSaveRoute}
      onInvertRoute={handleInvertRoute}
      onSplitRoute={handleSplitRoute}
      onDeleteWaypointFromRoute={handleDeleteWaypointFromRoute}
      onEditWaypointName={handleEditWaypointName}
      onSaveAsPdf={handleSaveAsPdf}
      onPrintRoute={handlePrintRoute}
      onExportToGps={handleExportToGps}
      OnExportToSimulator={handleExportToSimulator}
      onCopyToFPLBR={handleCopyToFPLBR}
      onDragEnd={handleReorderWaypoints}
      onHighlightWaypoint={handleHighlightWaypoint}
      onFadeWaypoint={handleFadeWaypoint}
    />
  )
}

export { RoutesPanelScreenPresenter }
