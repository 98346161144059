import type { ILogger, TypeLogLevel } from '@infra/logger/ILogger'
import { LogLevel } from '@infra/logger/LogLevels'
import * as Sentry from '@sentry/react'
import { injectable } from 'inversify'

@injectable()
export class LocalLogger implements ILogger {
  private _logLevel: TypeLogLevel = LogLevel.Info

  setLogLevel(logLevel: TypeLogLevel): void {
    this._logLevel = logLevel
  }

  debug(local: string, message: string): void {
    if (this._logLevel > LogLevel.Debug) return
    console.debug(`[${local}] ${message}`)
  }

  info(local: string, message: string): void {
    if (this._logLevel > LogLevel.Info) return
    console.info(`[${local}] ${message}`)
  }

  log(local: string, message: string): void {
    if (this._logLevel > LogLevel.Log) return
    console.log(`[${local}] ${message}`)
  }

  warn(local: string, message: string): void {
    if (this._logLevel > LogLevel.Warn) return
    console.warn(`[${local}] ${message}`)
    Sentry.captureMessage(`[${local}] ${message}`)
  }

  error(local: string, message: string): void {
    console.error(`[${local}] ${message}`)
    Sentry.captureMessage(`[${local}] ${message}`)
  }
}
