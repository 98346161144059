import { MenuItem } from '@nexds/web'

import { MeteorologyLayer } from '@/domain/protocols/Meteorology'

import { useBehaviorSubject } from '@/presentation/hooks/useBehaviorSubject'
import { useI18n } from '@/presentation/hooks/useI18n'
import {
  MeteorologyState,
  MeteorologyStateMutator,
  MeteorologyStateProps
} from '@/presentation/modules/MapScreen/states/MeteorologyState'
import { sendMetrics } from '@/presentation/utils/sendMetrics'

import { ToolContainer } from '../ToolbarTools.styles'

type MeteorologyLayerOptions = {
  key: MeteorologyLayer
  label: string
  isActive: boolean
}

function MeteorologyTool() {
  const { t } = useI18n()

  const meteorologyState = useBehaviorSubject<MeteorologyStateProps>(MeteorologyState)
  const meteorologyLayerOptions: MeteorologyLayerOptions[] = [
    {
      key: 'satellite-highlight',
      label: t('TOOLBAR_METEOROLOGY_ITEM_SATELLITE-HIGHLIGHT'),
      isActive: meteorologyState.layer === 'satellite-highlight'
    },
    {
      key: 'satellite-infrared',
      label: t('TOOLBAR_METEOROLOGY_ITEM_SATELLITE-INFRARED'),
      isActive: meteorologyState.layer === 'satellite-infrared'
    },
    {
      key: 'satellite-visible',
      label: t('TOOLBAR_METEOROLOGY_ITEM_SATELLITE-VISIBLE'),
      isActive: meteorologyState.layer === 'satellite-visible'
    },
    {
      key: 'radar-maxcappi',
      label: t('TOOLBAR_METEOROLOGY_ITEM_RADAR'),
      isActive: meteorologyState.layer === 'radar-maxcappi'
    },
    {
      key: 'sigmet',
      label: t('TOOLBAR_METEOROLOGY_ITEM_SIGMET'),
      isActive: meteorologyState.layer === 'sigmet'
    },
    {
      key: 'stsc',
      label: t('TOOLBAR_METEOROLOGY_ITEM_STSC'),
      isActive: meteorologyState.layer === 'stsc'
    },
    {
      key: 'sigwx',
      label: t('TOOLBAR_METEOROLOGY_ITEM_SIGWX'),
      isActive: meteorologyState.layer === 'sigwx'
    }
  ]

  const handleMeteorologyLayerChange = (layer: MeteorologyLayer) => {
    MeteorologyStateMutator.setLayer(meteorologyState.layer === layer ? null : layer)
  }

  return (
    <ToolContainer gap={12}>
      {meteorologyLayerOptions.map((element) => (
        <MenuItem
          key={element.key}
          title={element.label}
          size="sm"
          hasSwitch
          isSwitchChecked={element.isActive}
          onClick={() => {
            sendMetrics('PLANNINGAPP_TOOLBAR_METEOROLOGY-TYPE_SELECTED', {
              extra: element.key,
              active: !element.isActive
            })
            handleMeteorologyLayerChange(element.key)
          }}
        />
      ))}
    </ToolContainer>
  )
}

export { MeteorologyTool }
