import { TypeLogLevel } from '@infra/logger/ILogger'

type LogLevelString = 'Debug' | 'Info' | 'Log' | 'Warn' | 'Error'

export const LogLevel: Record<LogLevelString, TypeLogLevel> = {
  Debug: 0,
  Info: 1,
  Log: 2,
  Warn: 3,
  Error: 4
}
