import * as Turf from '@turf/turf'

import { InversionContainer } from '@/controller/container'
import { InjectionTokens } from '@/controller/tokens'

import { RouteBuilder } from '@/domain/builders/RouteBuilder'
import { UseCase } from '@/domain/core/UseCase'
import { CoordWaypoint, Route, Waypoint } from '@/domain/models'
import { Coordinates } from '@/domain/protocols/Coordinates'
import { Result } from '@/domain/protocols/Result'
import { RouteState } from '@/domain/states/RouteState'

import { ILogger } from '@/infra/logger/ILogger'
import { convertDecimalDegreesToDMS } from '@/utils/coordinates'

class SplitActiveRoute implements UseCase<Waypoint, Result<Route>> {
  private routeState: RouteState
  private logger: ILogger

  constructor() {
    const injectionContainer = InversionContainer.getInstance().getContainer()
    this.routeState = injectionContainer.get<RouteState>(InjectionTokens.RouteState)
    this.logger = injectionContainer.get<ILogger>(InjectionTokens.Logger)
  }

  execute(): Result<Route> {
    try {
      const routeStateSnapshot = this.routeState.getStateSnapshot()
      if (!routeStateSnapshot.activeRoute) return Result.fail('There is no active route')

      const routeWaypoints = routeStateSnapshot.activeRoute.waypoints
      if (!routeWaypoints) return Result.fail('There are no waypoints on the active route')

      const groundSpeed = routeStateSnapshot.activeRoute.groundSpeed

      const routeBuilder = new RouteBuilder().copyFromRoute(routeStateSnapshot.activeRoute)

      let splitsDone = 0

      for (let index = 1; index < routeWaypoints.length; index += 1) {
        const distance = this._distanceNMFloat(routeWaypoints[index - 1].coordinates, routeWaypoints[index].coordinates)

        let splitsNeeded = 0

        if (!distance) continue

        const splitsByDistance = distance / 200
        if (splitsByDistance > 1) {
          splitsNeeded = Math.floor(splitsByDistance)
        }

        if (groundSpeed) {
          const time = distance / groundSpeed
          const splitsByTime = time / 0.5

          if (splitsByTime > 1 && splitsByTime > splitsByDistance) {
            splitsNeeded = Math.floor(splitsByTime)
          }
        }

        if (splitsNeeded > 0) {
          for (let i = 1; i <= splitsNeeded; i++) {
            const [longitude, latitude] = this._splitLine(
              routeWaypoints[index - 1].coordinates,
              routeWaypoints[index].coordinates,
              splitsNeeded + 1,
              i
            )

            const newWaypointProps = {
              coordinates: Coordinates.create({ latitude, longitude }),
              name: `${convertDecimalDegreesToDMS(latitude, longitude)}`
            }

            const newWaypoint = CoordWaypoint.create(newWaypointProps, {})
            routeBuilder.addWaypointOnPosition(newWaypoint, index - 1 + i + splitsDone)
          }

          splitsDone += splitsNeeded
        }
      }
      const newRoute = routeBuilder.build()
      this.routeState.setActiveRoute(newRoute)

      return Result.ok(newRoute)
    } catch (error) {
      this.logger.error('AddWaypointToRouteUseCase', String(error))
      return Result.fail(error)
    }
  }

  private _splitLine = (start: Coordinates, end: Coordinates, length: number, index: number) => {
    const startPoint = Turf.point([start.longitude, start.latitude])
    const endPoint = Turf.point([end.longitude, end.latitude])

    const dist = Turf.distance(startPoint, endPoint)
    const heading = Turf.bearing(startPoint, endPoint)

    const splitPoint = Turf.destination(startPoint, (dist / length) * index, heading)

    return splitPoint.geometry.coordinates
  }

  private _distanceNMFloat = (start: Coordinates, end: Coordinates) => {
    const startPoint = Turf.point([start.longitude, start.latitude])
    const endPoint = Turf.point([end.longitude, end.latitude])
    return this._kmToNm(Turf.distance(startPoint, endPoint))
  }

  private _kmToNm = (meters: number) => {
    return meters / 1.852
  }
}

export { SplitActiveRoute }
