import { ChangeEvent } from 'react'

import { useScreenDimensions } from '@/presentation/hooks/useScreenDimensions'

import { ChartImage, ChartLabel, ChartSelectorButton, Container, Input } from './ChartSelector.styles'

interface ChartSelectorProps {
  id: string
  label: string
  imageSrc: string
  isActive: boolean
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

function ChartSelector(props: ChartSelectorProps) {
  const { label, imageSrc, id, isActive, onChange } = props

  return (
    <Container>
      <Input type="checkbox" id={id} checked={isActive} onChange={(e) => onChange?.(e)} />
      <ChartSelectorButton htmlFor={id} isActive={isActive}>
        <ChartImage src={imageSrc} />
        <ChartLabel>{label}</ChartLabel>
      </ChartSelectorButton>
    </Container>
  )
}

export { ChartSelector }
