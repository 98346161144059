import { useI18n } from '@/presentation/hooks/useI18n'

import { NewsResponse as NewsProps } from '../types'
import {
  NewsMessage,
  NewsDate,
  NewsScreenContainer,
  NewsTitle,
  NewsWrapper,
  Title,
  NewsScreenBody
} from './NewsScreen.styles'

function NewsScreen(props: NewsProps) {
  const { news } = props

  const { t } = useI18n()

  return (
    <NewsScreenContainer>
      <Title>{t('DRAWER_NEWS_MAIN_TITLE')}</Title>
      <NewsScreenBody>
        {news.map((newsItem) => (
          <NewsWrapper key={newsItem.id}>
            {/* // TODO: Mudar a transformação da data para o presenter */}
            <NewsDate>
              {/* // TODO: [i18n] formatar data e hora  */}
              {new Date(newsItem.creation_date).toLocaleDateString('pt-BR', {
                day: 'numeric',
                month: 'long',
                year: 'numeric'
              })}
            </NewsDate>
            <NewsTitle>{newsItem.title}</NewsTitle>
            <NewsMessage>{newsItem.message}</NewsMessage>
          </NewsWrapper>
        ))}
      </NewsScreenBody>
    </NewsScreenContainer>
  )
}

export { NewsScreen }
