import { IconButton } from '@nexds/web'
import styled, { css } from 'styled-components'

import { appZIndex } from '@/presentation/utils/zIndexMapper'

interface DrawerProps {
  isOpen?: boolean
  isActive?: boolean
}

export const Container = styled.div<DrawerProps>`
  position: absolute;
  z-index: ${appZIndex.DRAWER};
  left: 16px;
  height: 95%;
  align-self: center;
  width: fit-content;

  @media (max-width: 430px) {
    height: 100%;
    left: 0;
    width: ${({ isOpen }) => (isOpen ? '100vw' : 'fit-content')};
  }
`
export const LogoContainer = styled.div`
  height: 48px;
  padding: 4px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  & > svg {
    height: 40px;
    display: inline-block;
    transform: translateX(-13.13px);
  }
`

export const MainDrawer = styled.div<DrawerProps>`
  box-shadow: 2px 4px 6px 0px ${({ theme }) => `${theme.colors.secondaryD1}80`};
  position: absolute;
  z-index: ${appZIndex.DRAWER};
  left: 48px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 360px;
  padding: 0 ${({ isOpen }) => (isOpen ? '12px' : '0')};
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.secondaryD1};

  ${({ isOpen }) =>
    isOpen
      ? css`
          opacity: 1;
          filter: blur(0px);
          transform: translate3d(0, 0, 0);
          visibility: visible;
          transition: 0.3s all;
        `
      : css`
          opacity: 0;
          filter: blur(4px);
          transform: translate3d(-50px, 0, 0);
          visibility: hidden;
          transition: 0.3s all;
        `}

  @media (max-width: 430px) {
    width: 100%;
    padding-right: 70px;
    border-radius: 0;
  }
`

interface MainDrawerContentProps {
  $content_position: 'top' | 'bottom'
}

export const MainDrawerContent = styled.div<MainDrawerContentProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: ${({ $content_position }) => ($content_position === 'top' ? 'start' : 'end')};
  align-items: center;
`

export const MainDrawerItem = styled.button<DrawerProps>`
  border: none;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  padding: 12px 16px;
  margin: 4px;
  border-radius: 50px;
  background-color: ${({ theme, isActive }) => (isActive ? `${theme.colors.primary}59` : theme.colors.secondaryD1)};
  color: ${({ theme, isActive }) => (isActive ? theme.colors.primaryL2 : theme.colors.neutralL5)};
  ${({ theme }) => theme.fonts.infoLG};

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`

export const OptionContainer = styled.div<DrawerProps>`
  box-shadow: 2px 4px 6px 0px ${({ theme }) => `${theme.colors.secondaryD1}80`};
  position: absolute;
  z-index: ${appZIndex.DRAWER};

  border-top-right-radius: 20px;
  border-bottom-right-radius: 8px;

  height: 100%;
  width: 360px;
  left: 48px;
  background-color: ${({ theme }) => theme.colors.secondaryD1};
  padding: 0px 12px 8px 12px;

  ${({ isOpen }) =>
    isOpen
      ? css`
          opacity: 1;
          filter: blur(0px);
          transform: translate3d(0, 0, 0);
          visibility: visible;
          transition: 0.3s all;
        `
      : css`
          opacity: 0;
          filter: blur(4px);
          transform: translate3d(-50px, 0, 0);
          visibility: hidden;
          transition: 0.3s all;
        `}

  @media (max-width: 430px) {
    width: 100%;
    padding-right: 70px;
    border-radius: 0;
  }
`

interface OptionButtonProps {
  label?: string
  isActive?: boolean
  $should_show_tooltip?: boolean
}

export const OptionButton = styled(IconButton)<OptionButtonProps>`
  background-color: ${({ theme, isActive }) => (isActive ? `${theme.colors.primary}59` : theme.colors.secondaryD1)};
  width: 40px;
  height: 40px;
  position: relative;

  @media (max-height: 520px) {
    min-width: 30px;
    min-height: 30px;

    & svg {
      width: 20px;
      height: 20px;
    }
  }

  @media (max-height: 400px) {
    width: 28px;
    height: 28px;

    & svg {
      width: 18px;
      height: 18px;
    }
  }

  @media (hover: none) {
    &:focus {
      background-color: ${({ theme, isActive }) =>
        isActive ? `${theme.colors.primary}79` : theme.colors.secondary} !important;
    }
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme, isActive }) =>
        isActive ? `${theme.colors.primary}79` : theme.colors.secondary} !important;
    }

    &::after {
      content: attr(label);
      white-space: nowrap;
      position: absolute;
      left: calc(4px + 4px + 40px + 8px);
      width: fit-content;
      height: 40px;
      padding: 0 16px;
      border-radius: 50px;
      z-index: ${appZIndex.TOOLTIP};
      background-color: ${({ theme, isActive }) => (isActive ? '#0E5846' : theme.colors.secondaryL1)};

      box-shadow: 2px 4px 6px 0px ${({ theme }) => `${theme.colors.secondaryD1}80`};

      display: flex;
      justify-content: center;
      align-items: center;

      color: ${({ theme, isActive }) => (isActive ? theme.colors.primaryL2 : theme.colors.neutralL5)};

      ${({ theme }) => theme.fonts.infoMD}
      opacity: 0;
      visibility: hidden;
    }

    &:hover:after {
      opacity: 1;
      visibility: visible;
      transition-delay: 0.5s;
    }
  }
`

interface SidebarProps {
  hasBorder: boolean
}

export const SideBar = styled.div<SidebarProps>`
  box-shadow: 2px 4px 6px 0px ${({ theme }) => `${theme.colors.secondaryD1}80`};
  z-index: ${appZIndex.DRAWER + 1};
  background-color: ${({ theme }) => theme.colors.secondaryD1};
  border-top-right-radius: ${({ hasBorder }) => (hasBorder ? 0 : '20px')};
  border-bottom-right-radius: ${({ hasBorder }) => (hasBorder ? 0 : '8px')};
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-right: 1px solid ${({ theme, hasBorder }) => (hasBorder ? theme.colors.secondaryL1 : 'transparent')};
  position: absolute;
  width: 48px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  transition:
    border-top-right-radius 0.4s ease,
    border-bottom-right-radius 0.4s ease;

  & > *:first-child {
    width: 40px;
    height: 40px;
    margin: 4px;
  }

  @media (max-height: 430px) {
    padding-bottom: 8px;
  }

  @media (max-width: 430px) {
    border-radius: 0;
  }
`

export const SideBarContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.secondaryL1};

  @media (max-height: 460px) {
    overflow: hidden;
    gap: 8px;
  }
`

export const SideBarGroup = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px;
  gap: 8px;

  @media (max-height: 520px) {
    gap: 5px;
  }

  @media (max-height: 430px) {
    overflow: scroll;
  }
`
