import { InversionContainer } from '@/controller/container'
import { InjectionTokens } from '@/controller/tokens'

import { RouteBuilder } from '@/domain/builders/RouteBuilder'
import { UseCase } from '@/domain/core/UseCase'
import { Route } from '@/domain/models/Route/Route'
import { Waypoint } from '@/domain/models/Waypoint'
import { Result } from '@/domain/protocols/Result'
import { RouteState } from '@/domain/states/RouteState'

import { ILogger } from '@/infra/logger/ILogger'

interface OverrideWaypointOnRouteParams {
  waypoint: Waypoint
  index: number
}

class OverrideWaypointOnRoute implements UseCase<OverrideWaypointOnRouteParams, Result<Route>> {
  private routeState: RouteState
  private logger: ILogger

  constructor() {
    const injectionContainer = InversionContainer.getInstance().getContainer()
    this.routeState = injectionContainer.get<RouteState>(InjectionTokens.RouteState)
    this.logger = injectionContainer.get<ILogger>(InjectionTokens.Logger)
  }

  execute(request: OverrideWaypointOnRouteParams): Result<Route> {
    try {
      const routeStateSnapshot = this.routeState.getStateSnapshot()
      let route: Route = null

      if (routeStateSnapshot.activeRoute) {
        const routeBuilder = new RouteBuilder().copyFromRoute(routeStateSnapshot.activeRoute)
        routeBuilder.overrideWaypoint(request.waypoint, request.index)
        route = routeBuilder.build()
      } else {
        route = new RouteBuilder().addWaypoint(request.waypoint).build()
      }

      this.routeState.setActiveRoute(route)

      return Result.ok(route)
    } catch (error) {
      this.logger.error('OverrideWaypointOnRoute', String(error))
      return Result.fail(error)
    }
  }
}

export { OverrideWaypointOnRoute }
