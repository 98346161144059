import { InversionContainer } from '@/controller/container'
import { InjectionTokens } from '@/controller/tokens'

import { UseCase } from '@/domain/core/UseCase'
import { CoordWaypoint } from '@/domain/models/Waypoint'
import { Result } from '@/domain/protocols/Result'

import { IUserWaypointsRepository } from '@/data/UserWaypointsRepository/IUserWaypointsRepository'

export class LoadUserWaypointsUseCase implements UseCase<void, Promise<Result<CoordWaypoint[]>>> {
  userWaypointsRepository: IUserWaypointsRepository

  constructor() {
    const injectionContainer = InversionContainer.getInstance().getContainer()
    this.userWaypointsRepository = injectionContainer.get<IUserWaypointsRepository>(
      InjectionTokens.UserWaypointsRepository
    )
  }

  async execute(): Promise<Result<CoordWaypoint[]>> {
    const waypoints = await this.userWaypointsRepository.getWaypoints()
    return waypoints
  }
}
