import { injectable } from 'inversify'
import { Subscription } from 'rxjs'

import { InversionContainer } from '@/controller/container'
import { InjectionTokens } from '@/controller/tokens'

import { InitializableUseCase } from '@/domain/core/InitializableUseCase'
import { Result } from '@/domain/protocols/Result'
import { RouteState } from '@/domain/states/RouteState'

import { Buffer } from '@/utils/buffer'
@injectable()
export class UndoRouteUseCase implements InitializableUseCase<void, void> {
  private buffer: Buffer<string>
  private maxBufferLength = 5
  private subscription?: Subscription
  private routeState: RouteState

  constructor() {
    const injectionContainer = InversionContainer.getInstance().getContainer()
    this.routeState = injectionContainer.get<RouteState>(InjectionTokens.RouteState)
    this.buffer = new Buffer<string>(this.maxBufferLength)
  }

  init(): Result<void> | Promise<Result<void>> {
    this.subscription = this.routeState.getState().subscribe(() => {
      this.buffer.store(this.routeState.toString())
    })

    return Result.ok()
  }

  stop(): Result<void> | Promise<Result<void>> {
    this.subscription.unsubscribe()

    return Result.ok()
  }

  execute(): void | Promise<void> {
    const bufferValues = this.buffer.getValues()
    if (bufferValues.length < 2) return

    const lastValue = bufferValues[bufferValues.length - 2]
    this.routeState.fromString(lastValue)

    this.buffer.pop()
    this.buffer.pop()
  }
}
