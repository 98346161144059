import { Download, PlotOverMap, Select, SelectItem } from '@nexds/web'
import { useTheme } from 'styled-components'

import { ADHP } from '@/domain/models'
import { Procedure, ProcedureType } from '@/domain/protocols/Procedures'
import { ProceduresState } from '@/domain/states/ProceduresState'

import { useI18n } from '@/presentation/hooks/useI18n'
import { sendMetrics } from '@/presentation/utils/sendMetrics'

import { AdhpSearch } from '../AdhpSearch/AdhpSearch'
import { ProcedureTypeSelect } from '../ProcedureTypeSelect/ProcedureTypeSelect'
import {
  ChartButtons,
  ChartImg,
  ChartPDF,
  ChartsContainer,
  Container,
  Content,
  DownloadButton,
  NoADHPSelected,
  NoADHPSelectedContainer,
  NoChartsForSelectedADHP,
  NoChartsForSelectedADHPContainer,
  PlaceOverMapButton,
  SelectedADHPCode,
  SelectedADHPContainer,
  SelectedADHPName,
  Title
} from './ProceduresScreen.styles'

interface ProceduresScreenProps {
  adhp: ADHP
  adhpProcedures: Procedure[]
  procedureTypeSelected: ProcedureType
  procedureSelected: Procedure
  proceduresState: ProceduresState
  procedureImgSrc: string
  procedurePdfSrc: string
  availableProcedures: ProcedureType[]
  onAdhpSelected: (adhp: ADHP) => void
  onSelectProcedureType: (procedure: ProcedureType) => void
  handlePlaceOverMap: () => void
}

function ProceduresScreen(props: ProceduresScreenProps) {
  const { t } = useI18n()
  const theme = useTheme()

  const {
    adhp,
    adhpProcedures,
    procedureSelected,
    procedureTypeSelected,
    proceduresState,
    availableProcedures,
    handlePlaceOverMap,
    onAdhpSelected,
    onSelectProcedureType,
    procedureImgSrc,
    procedurePdfSrc
  } = props

  return (
    <Container>
      <Title>{t('DRAWER_PROCEDURE-CHARTS_MAIN_TITLE')}</Title>
      <Content>
        <AdhpSearch onAdhpSelected={onAdhpSelected} />
        <ChartsContainer>
          {adhp && (
            <SelectedADHPContainer>
              <SelectedADHPCode>{adhp?.code}</SelectedADHPCode>
              <SelectedADHPName>{adhp?.name}</SelectedADHPName>
            </SelectedADHPContainer>
          )}

          {availableProcedures.length > 0 && (
            <ProcedureTypeSelect
              availableProcedures={availableProcedures}
              onSelectProcedure={onSelectProcedureType}
              defaultProcedure={procedureTypeSelected ?? availableProcedures[0]}
            />
          )}

          {!adhp && (
            <NoADHPSelectedContainer>
              <NoADHPSelected>{t('DRAWER_PROCEDURE-CHARTS_SELECT-CHART-NO-CHARTS')}</NoADHPSelected>
            </NoADHPSelectedContainer>
          )}

          {adhp && availableProcedures.length === 0 && (
            <NoChartsForSelectedADHPContainer>
              <NoChartsForSelectedADHP>
                {t('DRAWER_PROCEDURE-CHARTS_SELECT-CHART-NO-CHARTS-FOR-ADHP')}
              </NoChartsForSelectedADHP>
            </NoChartsForSelectedADHPContainer>
          )}

          {procedureTypeSelected && (
            <Select
              key={procedureTypeSelected}
              label={t('DRAWER_PROCEDURE-CHARTS_SELECT-CHART-LABEL')}
              onChange={(value: string) => {
                proceduresState.setProcedureSelected(adhpProcedures.find((item) => item.file === value))
              }}
              maxDropdownRows={7}
              placeholder={t('DRAWER_PROCEDURE-CHARTS_SELECT-CHART-PLACEHOLDER')}
              helpGutter={false}
              value={procedureSelected?.file}
              startOpen={!procedureSelected}
            >
              {adhpProcedures
                ?.filter((procedure) => procedure.type === procedureTypeSelected)
                ?.map((item) => <SelectItem key={item.file} value={item.file} label={item.name} />)}
            </Select>
          )}

          {procedureImgSrc && procedureSelected?.fileType === 'georeferenced' && <ChartImg src={procedureImgSrc} />}

          {procedurePdfSrc && procedureSelected?.fileType === 'static' && (
            <ChartPDF src={`${procedurePdfSrc}#toolbar=0&navpanes=0&view=FitH&scrollbar=0`} />
          )}

          {procedureSelected && (
            <ChartButtons>
              <DownloadButton
                href={procedurePdfSrc}
                title={t('DRAWER_PROCEDURE-CHARTS_SELECT-CHART-DOWNLOAD')}
                target="_blank"
                onClick={() => {
                  sendMetrics('PLANNINGAPP_DRAWER_CHARTS-ACTION_CLICKED', { extra: 'download' })
                }}
              >
                <Download size={'sm'} color={theme.colors.neutralL5} />
              </DownloadButton>
              {procedureSelected?.fileType === 'georeferenced' && (
                <PlaceOverMapButton
                  title={t('DRAWER_PROCEDURE-CHARTS_SELECT-CHART-PLACE-OVER-MAP')}
                  onClick={() => {
                    sendMetrics('PLANNINGAPP_DRAWER_CHARTS-ACTION_CLICKED', { extra: 'plot-on-map' })
                    handlePlaceOverMap()
                  }}
                >
                  <PlotOverMap size={'sm'} color={theme.colors.neutralL5} />
                </PlaceOverMapButton>
              )}
            </ChartButtons>
          )}
        </ChartsContainer>
      </Content>
    </Container>
  )
}

export { ProceduresScreen }
