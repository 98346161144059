import { CachedHttpOptions, CachedHttpResponse, ICachedHttpClient } from '@infra/cachedHttp/ICachedHttpClient'
import type { IHttpClient } from '@infra/http/IHttpClient'
import type { IKeyValuePersister } from '@infra/keyValuePersister/IKeyValuePersister'
import type { ILogger } from '@infra/logger/ILogger'
import { inject, injectable } from 'inversify'
import { Observable } from 'rxjs'

import { InjectionTokens } from '@/controller/tokens'

interface CacheData<T> {
  value: T
  expiresAt?: string
}

@injectable()
export class LocalCachedHttpClient implements ICachedHttpClient {
  constructor(
    @inject(InjectionTokens.HttpClient) private readonly _httpClient: IHttpClient,
    @inject(InjectionTokens.KeyValuePersister) private readonly _persister: IKeyValuePersister,
    @inject(InjectionTokens.Logger) private readonly _logger: ILogger
  ) {}

  get<T>(url: string, options: CachedHttpOptions): Observable<CachedHttpResponse<T>> {
    return new Observable<CachedHttpResponse<T>>((observer) => {
      let isCacheSent = false

      const cachedResponse = this._persister.get(options.key)
      cachedResponse.then((response) => {
        if (response.isFailure) {
          this._logger.debug('LocalCachedHttpClient', `Cache miss for ${options.key}`)
          return
        }

        this._logger.debug('LocalCachedHttpClient', `Cache hit for ${options.key}`)
        const cacheData = response.getValue()
        const cacheDataParsed = JSON.parse(cacheData) as CacheData<T>

        if (cacheDataParsed.expiresAt && new Date(cacheDataParsed.expiresAt) < new Date()) {
          this._logger.debug('LocalCachedHttpClient', `Cache expired for ${options.key}`)
          return
        }

        this._logger.debug('LocalCachedHttpClient', `Returning cached response for ${options.key}`)

        observer.next({
          status: 200,
          headers: {
            'x-cached-response': 'true'
          },
          response: cacheDataParsed.value
        })

        isCacheSent = true
      })

      this._httpClient.get<T>(url, options).then((response) => {
        if (response.success === false) {
          if (isCacheSent) {
            observer.complete()
          } else {
            observer.error(response.error)
          }

          return
        }

        if (response.status >= 200 && response.status < 300) {
          this._persister.set(
            options.key,
            JSON.stringify({
              value: response.response,
              expiresAt: options.expiresInSeconds
                ? new Date(Date.now() + options.expiresInSeconds * 1000).toISOString()
                : undefined
            })
          )
        }

        observer.next(response)
        observer.complete()
      })
    })
  }
}
