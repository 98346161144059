import { useEffect, useLayoutEffect } from 'react'

import mapboxgl, { MapLayerMouseEvent, SymbolLayout, SymbolPaint } from 'mapbox-gl'

import { useMapContext } from './MapContext'

interface MapSymbolLayerProps {
  id: string
  sourceId: string
  imageSrc?: string
  icon?: string | mapboxgl.Expression
  layout?: SymbolLayout
  paint?: SymbolPaint
  filter?: any[]
  iconColor?: string
  textColor?: string
  beforeLayerId?: string
  minZoomLevel?: number
  onSymbolClick?: (event: MapLayerMouseEvent) => void
}

function MapSymbolLayer(props: MapSymbolLayerProps): null {
  const {
    id,
    sourceId,
    imageSrc,
    icon,
    layout,
    paint,
    filter,
    iconColor,
    textColor,
    minZoomLevel,
    beforeLayerId,
    onSymbolClick
  } = props

  const { map } = useMapContext()

  const imageName = imageSrc ? imageSrc.split('/').pop() : undefined

  useLayoutEffect(() => {
    if (!map) return

    if (imageSrc && imageName) {
      map.loadImage(imageSrc, (error, image) => {
        if (error) throw error
        if (!image) throw 'An error occurred while loading the image'

        if (!map.hasImage(imageName)) {
          map.addImage(imageName, image)
        }
      })
    }

    map.addLayer(
      {
        id,
        type: 'symbol',
        source: sourceId,
        filter: filter ?? ['all'],
        layout: {
          ...layout,
          'icon-image': imageName ?? icon
        },
        paint: {
          'text-color': textColor ?? paint?.['text-color'] ?? 'transparent',
          'icon-color': iconColor ?? paint?.['icon-color'] ?? 'transparent',
          ...paint
        },
        minzoom: minZoomLevel ?? 0
      },
      beforeLayerId
    )

    const symbolClickListener = (event: MapLayerMouseEvent) => {
      onSymbolClick?.(event)
    }

    if (onSymbolClick) {
      map.on('click', id, symbolClickListener)
    }

    const mouseEnterListener = () => {
      map.getCanvas().style.cursor = 'pointer'
    }

    const mouseLeaveListener = () => {
      map.getCanvas().style.cursor = ''
    }

    if (onSymbolClick) {
      map.on('mouseenter', id, mouseEnterListener)
      map.on('mouseleave', id, mouseLeaveListener)
    }

    return () => {
      if (onSymbolClick) {
        map.off('click', id, symbolClickListener)
        map.off('mouseenter', id, mouseEnterListener)
        map.off('mouseleave', id, mouseLeaveListener)
      }

      map.getCanvas().style.cursor = ''

      if (map.getLayer(id)) {
        map.removeLayer(id)
      }
    }
  }, [map, filter, layout, paint, icon, imageSrc])

  return null
}

export { MapSymbolLayer }
