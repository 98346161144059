import { Assets } from '@nexds/web'
import { useTheme } from 'styled-components'

import { Body, CloseButton, Content, Header, Title } from './InfoPanel.styles'

interface MeteorologyTooltipProps {
  title: string
  content: string
  onClose?: () => void
}

const MeteorologyTooltip = (props: MeteorologyTooltipProps) => {
  const { title, content, onClose } = props

  const theme = useTheme()

  const contents = content.split('\n')

  return (
    <>
      <Header>
        <Title>{title}</Title>
        <CloseButton onClick={onClose && onClose}>
          <Assets.Close size={'md'} color={theme.colors.secondaryL3} />
        </CloseButton>
      </Header>
      <Body>
        {contents.map((line, index) => (
          <Content key={index}>{line}</Content>
        ))}
      </Body>
    </>
  )
}

export { MeteorologyTooltip }
