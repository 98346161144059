import styled from 'styled-components'

import { getStateColor } from '@/presentation/utils'

export const SearchListContainer = styled.div`
  overflow: hidden;
  margin-top: 12px;
  border-radius: 10px;
  box-shadow: 2px 4px 6px 0px ${({ theme }) => `${theme.colors.secondary}59`};

  & > * {
    & > * {
      & > *:first-child {
        border-color: ${({ theme }) => theme.colors.neutralL3};

        &:hover {
          background-color: ${({ theme }) => theme.colors.neutralL4};
        }
      }
    }
  }
`

interface ListContainerProps {
  maxHeight: number
}

export const ListContainer = styled.div<ListContainerProps>`
  max-height: ${({ maxHeight }) => maxHeight}px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    border-radius: 20px;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.neutralL5};
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.neutralL1};
    border-radius: 20px;
  }
`

export const ActionButtonsWrapper = styled.div`
  display: none;
  position: absolute;
  max-height: 55px;
  top: 0;
  right: 0;
  padding: 0 16px;
  background: linear-gradient(to left, ${({ theme }) => theme.colors.neutralL3} 80%, transparent 100%);
`

export const ActionButton = styled.button`
  border: none;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
  line-height: 0;

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutralL2};
  }
`

export const ResultWrapper = styled.div<{ isSelected: boolean }>`
  position: relative;

  & > div:first-child {
    background-color: ${({ isSelected, theme }) =>
      isSelected ? getStateColor(theme.colors.neutralL3, 'focused') : theme.colors.neutralL5};
  }

  &:hover > div:first-child {
    background-color: ${({ theme }) => theme.colors.neutralL3};
  }

  & > div {
    // MenuItem
    height: 56px;
    & > div:first-child {
      // TextContainer
      gap: 2px;
      max-width: 100%;
      & > p:nth-child(1) {
        // Title
        /* ${({ theme }) => theme.fonts.infoLG} */
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      & > p:nth-child(2) {
        // Description
        ${({ theme }) => theme.fonts.infoSM}
        color: ${({ theme }) => theme.colors.secondaryL2};
      }
    }
  }

  &:hover ${ActionButtonsWrapper} {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  &:last-child *:first-child {
    border-bottom: 0;
  }
`
