import { useEffect, useState } from 'react'

import { MenuGroup, MenuItem } from '@nexds/web'

import { useBehaviorSubject } from '@/presentation/hooks/useBehaviorSubject'
import { useI18n } from '@/presentation/hooks/useI18n'
import icons from '@/presentation/modules/MapScreen/components/MapADHP/icons'
import {
  MapADHPState,
  MapADHPStateMutator,
  MapADHPStateProps
} from '@/presentation/modules/MapScreen/states/MapADHPState'
import { sendMetrics } from '@/presentation/utils/sendMetrics'

import { ToolContainer } from '../ToolbarTools.styles'
import { LegendsContainer } from './ADHPTool.styles'
import { Drawer } from './Drawer'
import { Legend } from './Legend'
import { Slider } from './Slider'

function ADHPTool() {
  const { t } = useI18n()

  const mapADHPState = useBehaviorSubject<MapADHPStateProps>(MapADHPState)

  const [filtersOpen, setFiltersOpen] = useState(false)
  const [legendsOpen, setLegendsOpen] = useState(false)

  useEffect(() => {
    if (filtersOpen) {
      setLegendsOpen(false)
    }
  }, [filtersOpen])

  useEffect(() => {
    if (legendsOpen) {
      setFiltersOpen(false)
      sendMetrics('PLANNINGAPP_TOOLBAR_ADHP-LEGEND_CLICKED')
    }
  }, [legendsOpen])

  const legends = [
    { icon: icons['legend-aerodrome-paved'], meaning: t('TOOLBAR_MAP-POINTS_LEGENDS_ITEM_PAVED-AERODROME') },
    {
      icon: icons['legend-military-aerodrome-paved'],
      meaning: t('TOOLBAR_MAP-POINTS_LEGENDS_ITEM_PAVED-MILITARY-AERODROME')
    },
    { icon: icons['legend-aerodrome-unpaved'], meaning: t('TOOLBAR_MAP-POINTS_LEGENDS_ITEM_UNPAVED-AERODROME') },
    {
      icon: icons['legend-military-aerodrome-unpaved'],
      meaning: t('TOOLBAR_MAP-POINTS_LEGENDS_ITEM_UNPAVED-MILITARY-AERODROME')
    },
    { icon: icons['legend-helipad'], meaning: t('TOOLBAR_MAP-POINTS_LEGENDS_ITEM_HELIPAD') },
    { icon: icons['legend-military-helipad'], meaning: t('TOOLBAR_MAP-POINTS_LEGENDS_ITEM_MILITARY-HELIPAD') },
    { icon: icons['legend-user-waypoint'], meaning: t('TOOLBAR_MAP-POINTS_LEGENDS_ITEM_USER-WAYPOINT') }
  ]

  return (
    <ToolContainer gap={16}>
      <MenuGroup>
        <MenuItem
          title={t('TOOLBAR_MAP-POINTS_ITEM_AERODROMES_LABEL')}
          size="sm"
          hasSwitch
          isSwitchChecked={mapADHPState.aerodromes}
          onClick={() => {
            MapADHPStateMutator.setAerodromes(!mapADHPState.aerodromes)

            sendMetrics('PLANNINGAPP_TOOLBAR_ADHP_SELECTED', {
              extra: 'aerodrome',
              active: !mapADHPState.aerodromes
            })
          }}
        />
        <Drawer isOpen={filtersOpen} setIsOpen={setFiltersOpen} handlePosition="bottom" title="Filtros">
          <Slider
            label={t('TOOLBAR_MAP-POINTS_ITEM_RUNWAY-LENGTH_LABEL')}
            id="min-runway-length"
            max={2500}
            min={0}
            step={100}
            value={mapADHPState.minRunwayLength}
            formatedValue={`${mapADHPState.minRunwayLength.toString().padStart(4, '0')}m`}
            onChange={(e) => {
              MapADHPStateMutator.setMinRunwayLength(parseInt(e.target.value))
            }}
            onDragExit={() => {
              sendMetrics('PLANNINGAPP_TOOLBAR_ADHP-MIN-TRACK-LENGTH_CLICKED')
            }}
          />

          <MenuGroup>
            <MenuItem
              title={t('TOOLBAR_MAP-POINTS_ITEM_PAVED-RUNWAY_LABEL')}
              size="sm"
              hasSwitch
              isSwitchChecked={mapADHPState.pavedRunway}
              onClick={() => {
                MapADHPStateMutator.setPavedRunway(!mapADHPState.pavedRunway)

                sendMetrics('PLANNINGAPP_TOOLBAR_ADHP-FILTER_SELECTED', {
                  extra: 'paved runway',
                  active: !mapADHPState.pavedRunway
                })
              }}
            />

            <MenuItem
              title={t('TOOLBAR_MAP-POINTS_ITEM_NIGHT-OPERATION_LABEL')}
              size="sm"
              hasSwitch
              isSwitchChecked={mapADHPState.nightOperation}
              onClick={() => {
                MapADHPStateMutator.setNightOperation(!mapADHPState.nightOperation)

                sendMetrics('PLANNINGAPP_TOOLBAR_ADHP-FILTER_SELECTED', {
                  extra: 'night operation',
                  active: !mapADHPState.nightOperation
                })
              }}
            />

            <MenuItem
              title={t('TOOLBAR_MAP-POINTS_ITEM_ATS_LABEL')}
              size="sm"
              hasSwitch
              isSwitchChecked={mapADHPState.ats}
              onClick={() => {
                MapADHPStateMutator.setAts(!mapADHPState.ats)

                sendMetrics('PLANNINGAPP_TOOLBAR_ADHP-FILTER_SELECTED', {
                  extra: 'ats',
                  active: !mapADHPState.ats
                })
              }}
            />

            <MenuItem
              title={t('TOOLBAR_MAP-POINTS_ITEM_IFR_LABEL')}
              size="sm"
              hasSwitch
              isSwitchChecked={mapADHPState.ifr}
              onClick={() => {
                MapADHPStateMutator.setIfr(!mapADHPState.ifr)

                sendMetrics('PLANNINGAPP_TOOLBAR_ADHP-FILTER_SELECTED', {
                  extra: 'ifr',
                  active: !mapADHPState.ifr
                })
              }}
            />
          </MenuGroup>
        </Drawer>
      </MenuGroup>

      <MenuItem
        title={t('TOOLBAR_MAP-POINTS_ITEM_HELIPADS_LABEL')}
        size="sm"
        hasSwitch
        isSwitchChecked={mapADHPState.helipads}
        onClick={() => {
          MapADHPStateMutator.setHelipads(!mapADHPState.helipads)

          sendMetrics('PLANNINGAPP_TOOLBAR_ADHP_SELECTED', {
            extra: 'helipad',
            active: !mapADHPState.helipads
          })
        }}
      />

      <MenuItem
        title={t('TOOLBAR_MAP-POINTS_ITEM_USER-WAYPOINTS_LABEL')}
        size="sm"
        hasSwitch
        isSwitchChecked={mapADHPState.userWaypoints}
        onClick={() => {
          MapADHPStateMutator.setUserWaypoints(!mapADHPState.userWaypoints)

          sendMetrics('PLANNINGAPP_TOOLBAR_ADHP-USER-WAYPOINTS_SELECTED', {
            active: !mapADHPState.userWaypoints
          })
        }}
      />

      <Drawer
        isOpen={legendsOpen}
        setIsOpen={setLegendsOpen}
        title={t('TOOLBAR_MAP-POINTS_ITEM_LEGENDS_LABEL')}
        lastChild
      >
        <LegendsContainer>
          {legends.map((legend, place) => (
            <Legend
              key={legend.meaning}
              icon={legend.icon}
              meaning={legend.meaning}
              legendsLength={legends.length}
              place={place}
            />
          ))}
        </LegendsContainer>
      </Drawer>
    </ToolContainer>
  )
}

export { ADHPTool }
