import { UniqueEntityID } from '@domain/core'

import { Coordinates } from '@/domain/protocols/Coordinates'

import { WaypointType } from '../WaypointType'
import { Waypoint, WaypointPojo, WaypointProps } from './Waypoint'

export interface VisualPositionExtra {
  rea: string
  visualDesc: string
}

export class VisualPosition extends Waypoint {
  public declare extra: VisualPositionExtra

  private constructor(props: WaypointProps, extra: VisualPositionExtra, id?: UniqueEntityID) {
    super(props, id)
    this.extra = extra
  }

  public static create(props: WaypointProps, extra: VisualPositionExtra, id?: UniqueEntityID): VisualPosition {
    props.waypointType = WaypointType.create(
      {
        name: 'Posição Visual', // t('WAYPOINT_TYPE_VISUAL-POSITION'), // TODO: fix this
        codeName: 'VisualPosition'
      },
      new UniqueEntityID('vp')
    )

    return new VisualPosition(props, extra, id)
  }

  public clone(): VisualPosition {
    return VisualPosition.create(this.props, this.extra)
  }

  public cloneWithID(): VisualPosition {
    return VisualPosition.create(this.props, this.extra, this.id)
  }

  public get rea(): string {
    return this.extra.rea
  }

  public set rea(rea: string) {
    this.extra.rea = rea
  }

  public get visualDesc(): string {
    return this.extra.visualDesc
  }

  public set visualDesc(visualDesc: string) {
    this.extra.visualDesc = visualDesc
  }

  public static fromPojo(pojo: WaypointPojo): VisualPosition {
    return VisualPosition.create(
      {
        waypointType: pojo.waypointType && WaypointType.fromPojo(pojo.waypointType),
        coordinates: Coordinates.fromPojo(pojo.coordinates),
        code: pojo.code,
        name: pojo.name,
        customName: pojo.customName,
        createdAt: pojo.createdAt,
        updatedAt: pojo.updatedAt
      },
      {
        ...(pojo.extra as any)
      },
      new UniqueEntityID(pojo.id)
    )
  }
}
