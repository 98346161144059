import { ChangeEvent } from 'react'

import { Input } from '@mui/material'

import { Label, RangeContainer, RangeText, SliderContainer } from './ADHPTool.styles'

interface SliderProps {
  label: string
  min: number
  max: number
  value: number
  step: number
  id: string
  formatedValue: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onDragExit: () => void
}

export function Slider(props: SliderProps) {
  return (
    <SliderContainer>
      <Label htmlFor={props.id}>{`${props.label}`}</Label>
      <RangeContainer>
        <RangeText>{props.formatedValue}</RangeText>
        <input
          id={props.id}
          type="range"
          min={props.min}
          max={props.max}
          step={props.step}
          onChange={props.onChange}
          onMouseUp={props.onDragExit}
          value={props.value}
        />
      </RangeContainer>
    </SliderContainer>
  )
}
