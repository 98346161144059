import { UniqueEntityID } from '@domain/core'

import { Coordinates } from '@/domain/protocols/Coordinates'

import { WaypointType } from '../WaypointType'
import { FlightPlanName, Waypoint, WaypointPojo, WaypointProps } from './Waypoint'

export type NavaidType = 'NDB' | 'VOR' | 'VOR/DME'

export interface NavaidExtra {
  type: NavaidType
  frequency: string
}

export class Navaid extends Waypoint {
  public declare extra: NavaidExtra

  private constructor(props: WaypointProps, extra: NavaidExtra, id?: UniqueEntityID) {
    super(props, id)
    this.extra = extra
  }

  public static create(props: WaypointProps, extra: NavaidExtra, id?: UniqueEntityID): Navaid {
    props.waypointType = WaypointType.create(
      {
        name: 'Auxílio à Navegação', // t('WAYPOINT_TYPE_NAVAID'), // TODO: fix this
        codeName: 'Navaid'
      },
      new UniqueEntityID('navaid')
    )

    return new Navaid(props, extra, id)
  }

  public clone(): Navaid {
    return Navaid.create(this.props, this.extra)
  }

  public cloneWithID(): Navaid {
    return Navaid.create(this.props, this.extra, this.id)
  }

  public getDisplayName(): string {
    return this.props.customName
      ? this.props.customName
      : `${this.props.code} ${this.extra.type} ${this.extra.frequency}`
  }

  public getFlightPlanName(): FlightPlanName {
    return {
      name: this.props.code || '',
      isCoordinate: false
    }
  }

  public getTypeName(): string {
    return this.name ? this.name : this.extra.type
  }

  public get type(): NavaidType {
    return this.extra.type
  }

  public set type(type: NavaidType) {
    this.extra.type = type
  }

  public get frequency(): string {
    return this.extra.frequency
  }

  public set frequency(frequency: string) {
    this.extra.frequency = frequency
  }

  public static fromPojo(pojo: WaypointPojo): Navaid {
    return Navaid.create(
      {
        waypointType: pojo.waypointType && WaypointType.fromPojo(pojo.waypointType),
        coordinates: Coordinates.fromPojo(pojo.coordinates),
        code: pojo.code,
        name: pojo.name,
        customName: pojo.customName,
        createdAt: pojo.createdAt,
        updatedAt: pojo.updatedAt
      },
      {
        ...(pojo.extra as any)
      },
      new UniqueEntityID(pojo.id)
    )
  }
}
