import GeoJSON from 'geojson'

import { AnyAirspace } from '@/domain/models/Airspace'
import { AirspaceType } from '@/domain/protocols/Airspaces'

import { stackIds } from '../../containers/MapStackPresenter'
import { MapGeoJsonLayer } from '../MapView/MapGeoJsonLayer'
import { MapGeoJsonSource } from '../MapView/MapGeoJsonSource'
import { airspaceColor } from './MapAirspace.styles'

interface MapActiveAirspaceProps {
  fillSourceId: string
  fillLayerId: string
  type: AirspaceType
  feature: GeoJSON.Feature<GeoJSON.Polygon, AnyAirspace>
}

function MapActiveAirspace(props: MapActiveAirspaceProps) {
  const { fillSourceId, fillLayerId, type, feature } = props

  return (
    <>
      <MapGeoJsonSource id={fillSourceId} data={feature}>
        <MapGeoJsonLayer
          types={['fill']}
          id={fillLayerId}
          sourceId={fillSourceId}
          beforeLayerId={stackIds.airspaces[type]}
          fillColor={airspaceColor[type]}
          fillOpacity={0.3}
        />
      </MapGeoJsonSource>
    </>
  )
}

export { MapActiveAirspace }
