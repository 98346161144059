import GeoJSON from 'geojson'

import { MeteorologyLayer } from '@/domain/protocols/Meteorology'

import { stackIds } from '../../containers/MapStackPresenter'
import { MapRasterLayer } from '../MapView/MapRasterLayer'
import { MapRasterSource } from '../MapView/MapRasterSource'

export interface MapMeteorologyRasterLayerProps {
  id: MeteorologyLayer
  url: string
  coordinates: [GeoJSON.Position, GeoJSON.Position, GeoJSON.Position, GeoJSON.Position]
}

const DEFAULT_LAYER_OPACITY: Record<MeteorologyLayer, number> = {
  'satellite-visible': 0.7,
  'satellite-infrared': 0.7,
  'satellite-highlight': 0.75,
  'radar-maxcappi': 0.75,
  sigmet: 1,
  stsc: 0.9,
  sigwx: 0.9
}

function MapMeteorologyRasterLayer(props: MapMeteorologyRasterLayerProps) {
  const { id, url, coordinates } = props

  return (
    <MapRasterSource id={`meteorology-${id}-source`} url={url} coordinates={coordinates}>
      <MapRasterLayer
        id={`meteorology-${id}-layer`}
        sourceId={`meteorology-${id}-source`}
        beforeLayerId={stackIds.meteorology}
        opacity={DEFAULT_LAYER_OPACITY[id]}
        fadeDuration={0}
      />
    </MapRasterSource>
  )
}

export { MapMeteorologyRasterLayer }
