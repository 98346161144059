import { RefObject, useEffect, useState } from 'react'

import { MenuItem } from '@nexds/web'

import { Container, MainMenu, OptionWrapper } from './ContextMenu.styles'

export interface ContextMenuItem {
  id: string
  label: string
  subItems?: ContextMenuItem[]
  itemClickParam?: any
  onItemClick?: (option?: any) => void
}

interface ContextMenuProps {
  contextMenuRef?: RefObject<HTMLDivElement>
  positionX: number
  positionY: number
  options: ContextMenuItem[]
}

function ContextMenu(props: ContextMenuProps) {
  const { contextMenuRef, positionX, positionY, options, ...rest } = props

  const [subOptions, setSubOptions] = useState<{ index: number; items?: ContextMenuItem[] }>(null)
  const menuWidth = 239

  // fechando as subOptions ao selecionar outra de algum nível acima:
  useEffect(() => {
    return () => {
      setSubOptions(null)
    }
  }, [options])

  return (
    <Container ref={contextMenuRef} positionX={positionX} positionY={positionY} {...rest}>
      <MainMenu menuWidth={menuWidth}>
        {options.map((option, index) => (
          <OptionWrapper key={option.id} onMouseEnter={() => setSubOptions({ index, items: option.subItems })}>
            <MenuItem
              title={option.label}
              chevron={!!option.subItems}
              size="sm"
              onClick={() =>
                option.itemClickParam ? option.onItemClick?.(option.itemClickParam) : option.onItemClick?.()
              }
            />
          </OptionWrapper>
        ))}
      </MainMenu>
      {subOptions?.items && subOptions.items.length > 0 && (
        <ContextMenu options={subOptions.items} positionX={menuWidth + 10} positionY={subOptions.index * 48} />
      )}
    </Container>
  )
}

export { ContextMenu }
