import { IDomainState } from '@domain/states/IDomainState'
import { injectable, unmanaged } from 'inversify'
import { BehaviorSubject } from 'rxjs'

@injectable()
export abstract class BaseState<T> implements IDomainState<T> {
  private state: BehaviorSubject<T>
  private initialState: T

  constructor(@unmanaged() initialState: T) {
    this.initialState = initialState
    this.state = new BehaviorSubject<T>(initialState)
  }

  getState(): BehaviorSubject<T> {
    return this.state
  }

  getStateSnapshot(): T {
    return this.state.getValue()
  }

  resetState() {
    this.state.next(this.initialState)
  }

  public updateState(newState: Partial<T>) {
    const resolvedState: T = {
      ...this.state.getValue(),
      ...newState
    }

    this._updateState(resolvedState)
  }

  protected _updateState(newState: T) {
    this.state.next(newState)
  }

  protected _updateStateProp<K extends keyof T>(propName: K, value: T[K]) {
    this.state.next({ ...this.getStateSnapshot(), [propName]: value })
  }

  protected _getStateProp<K extends keyof T>(propName: K): T[K] {
    return this.getStateSnapshot()[propName]
  }
}
