import { InversionContainer } from '@/controller/container'
import { InjectionTokens } from '@/controller/tokens'

import { RouteBuilder } from '@/domain/builders/RouteBuilder'
import { UseCase } from '@/domain/core/UseCase'
import { Waypoint } from '@/domain/models/Waypoint'
import { Result } from '@/domain/protocols/Result'
import { RouteState } from '@/domain/states/RouteState'

import { ILogger } from '@/infra/logger/ILogger'

interface RenameWaypointFromRouteUseCaseProps {
  waypoint: Waypoint
  name: string
}

class RenameWaypointFromRouteUseCase implements UseCase<RenameWaypointFromRouteUseCaseProps, Result<void>> {
  private routeState: RouteState
  private logger: ILogger

  constructor() {
    const injectionContainer = InversionContainer.getInstance().getContainer()
    this.routeState = injectionContainer.get<RouteState>(InjectionTokens.RouteState)
    this.logger = injectionContainer.get<ILogger>(InjectionTokens.Logger)
  }

  execute(request: RenameWaypointFromRouteUseCaseProps): Result<void> {
    try {
      const newWaypoint = request.waypoint.cloneWithID()
      if (!newWaypoint) return Result.fail('Failed to clone waypoint')
      newWaypoint.customName = request.name

      const routeStateSnapshot = this.routeState.getStateSnapshot()
      if (!routeStateSnapshot.activeRoute) return Result.fail('There is no active route')
      const position = routeStateSnapshot.activeRoute.waypoints.findIndex((item) => item.id.equals(request.waypoint.id))

      if (position < 0) return Result.fail('An error occurred while trying to find the waypoint position on the route')

      const routeBuilder = new RouteBuilder().copyFromRoute(routeStateSnapshot.activeRoute)
      routeBuilder.removeWaypoint(request.waypoint)
      routeBuilder.addWaypointOnPosition(newWaypoint, position, true)

      this.routeState.setActiveRoute(routeBuilder.build())

      return Result.ok()
    } catch (error) {
      this.logger.error('RenameWaypointFromRouteUseCase', String(error))
      return Result.fail(error)
    }
  }
}

export { RenameWaypointFromRouteUseCase }
