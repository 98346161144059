import { InversionContainer } from '@/controller/container'
import { InjectionTokens } from '@/controller/tokens'

import { UseCase } from '@/domain/core/UseCase'
import { Result } from '@/domain/protocols/Result'
import { RouteState } from '@/domain/states/RouteState'

import { IUserRoutesRepository } from '@/data/UserRoutesRepository/IUserRoutesRepository'

class IsActiveRouteInRoutesSavedUseCase implements UseCase<void, Result<boolean>> {
  private routeState: RouteState
  private userRoutesRepository: IUserRoutesRepository

  constructor() {
    const injectionContainer = InversionContainer.getInstance().getContainer()
    this.routeState = injectionContainer.get<RouteState>(InjectionTokens.RouteState)
    this.userRoutesRepository = injectionContainer.get<IUserRoutesRepository>(InjectionTokens.UserRoutesRepository)
  }

  execute(): Result<boolean> {
    try {
      const route = this.routeState.getStateSnapshot().activeRoute
      if (!route) return Result.fail('There is no active route')

      const result = this.userRoutesRepository.getRoute(route.id)

      if (result.isFailure) return Result.ok(false)
      return Result.ok(true)
    } catch (error) {
      return Result.fail(error)
    }
  }
}

export { IsActiveRouteInRoutesSavedUseCase }
