import styled from 'styled-components'

import { appZIndex } from '@/presentation/utils/zIndexMapper'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 14px 16px 14px;
  background: ${({ theme }) => theme.colors.neutralL5};
  border-radius: 10px;

  gap: 14px;
`

export const WaypointName = styled.h1`
  ${({ theme }) => theme.fonts.titleMD}
  color: ${({ theme }) => theme.colors.secondary};
`

export const City = styled.h2`
  ${({ theme }) => theme.fonts.infoMD}
  color: ${({ theme }) => theme.colors.secondary};
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Options = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.neutralL4};
  border-radius: 10px;

  flex-direction: column;

  overflow: hidden;
`

export const Link = styled.a`
  ${({ theme }) => theme.fonts.infoMD}
  color: ${({ theme }) => theme.colors.secondary};
  text-decoration: none;
  height: 48px;

  display: flex;

  align-items: center;
  padding: 0 24px;

  gap: 8px;

  width: 100%;

  transition: background 0.2s;

  &:hover {
    background: ${({ theme }) => theme.colors.neutralL3};
  }
`

export const AddToRoute = styled.button`
  ${({ theme }) => theme.fonts.infoMD}
  color: ${({ theme }) => theme.colors.secondary};
  text-decoration: none;
  height: 48px;

  display: flex;

  align-items: center;
  padding: 0 24px;

  border: none;
  outline: none;
  cursor: pointer;

  border-bottom: 1px solid ${({ theme }) => theme.colors.neutralL3};

  gap: 8px;

  width: 100%;

  transition: background 0.2s;

  &:hover {
    background: ${({ theme }) => theme.colors.neutralL3};
  }
`

interface WaypointInfoContainerProps {
  screenPosition: {
    top: number
    left: number
    translateX: string
    translateY: string
  }
}

export const WaypointInfoContainer = styled.div<WaypointInfoContainerProps>`
  position: relative;
  z-index: ${appZIndex.WAYPOINT_INFO};
  height: fit-content;
  width: 336px;

  top: ${({ screenPosition }) => screenPosition.top}px;
  left: ${({ screenPosition }) => screenPosition.left}px;
  transform: ${({ screenPosition }) => `translate(${screenPosition.translateX},${screenPosition.translateY})`};
`
