import { Info, Plus } from '@nexds/web'
import { useTheme } from 'styled-components'

import { Waypoint, ADHP } from '@/domain/models'
import { AddWaypointToRouteUseCase } from '@/domain/useCases/Route'

import { useI18n } from '@/presentation/hooks/useI18n'
import { sendMetrics } from '@/presentation/utils/sendMetrics'

import { AddToRoute, City, Container, Header, Link, Options, WaypointName } from './WaypointInfo.styles'

interface WaypointInfoProps {
  name: string
  waypoint: Waypoint | ADHP
  icao?: string
  city?: string
  uf?: string
}

export function WaypointInfo(props: WaypointInfoProps) {
  const { name, city, uf, icao, waypoint } = props

  const theme = useTheme()
  const { t } = useI18n()

  function handleAddToRoute() {
    const addWaypointToRouteUseCase = new AddWaypointToRouteUseCase()
    addWaypointToRouteUseCase.execute(waypoint)

    sendMetrics('PLANNINGAPP_MAP_ADHP_SELECTED', { extra: 'add-to-route' })
  }

  return (
    <Container>
      <Header>
        <WaypointName>{name}</WaypointName>
        {city && uf && <City>{`${city}, ${uf}`}</City>}
      </Header>
      <Options>
        <AddToRoute onClick={handleAddToRoute}>
          <Plus color={theme.colors.secondary} size={'md'} />
          {t('MAP-POINTS_MODAL_ADD-TO-ROUTE')}
        </AddToRoute>
        {icao && waypoint instanceof ADHP && (
          <Link
            href={`https://aisweb.decea.mil.br/?i=aerodromos&codigo=${icao}`}
            target="_blank"
            rel="noreferrer"
            onClick={() => sendMetrics('PLANNINGAPP_MAP_ADHP_SELECTED', { extra: 'info' })}
          >
            <Info color={theme.colors.secondary} size={'md'} />
            {waypoint.type === 'AD' ? t('MAP-POINTS_MODAL_INFO-AERODROME') : t('MAP-POINTS_MODAL_INFO-HELIPAD')}
          </Link>
        )}
      </Options>
    </Container>
  )
}
