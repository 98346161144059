import { Checkbox, ChevronRight } from '@nexds/web'
import { useTheme } from 'styled-components'

import { CoordWaypoint } from '@/domain/models'

import { ClickableArea, UserWaypoint as Container } from './UserWaypoint.styles'

interface UserWaypointProps {
  waypoint: CoordWaypoint
  checked: boolean
  waypointsLength: number
  place: number
  onChange: () => void
  onClick: () => void
}

function UserWaypoint(props: UserWaypointProps) {
  const { waypoint, checked, waypointsLength, place, onChange, onClick } = props

  const theme = useTheme()

  return (
    <Container waypointsLength={waypointsLength} place={place}>
      <Checkbox checked={checked} onChange={onChange} label={waypoint.customName} />
      <ClickableArea onClick={onClick}>
        <ChevronRight size="sm" color={theme.colors.neutralL5} />
      </ClickableArea>
    </Container>
  )
}

export { UserWaypoint }
