import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
`

export const Body = styled.div`
  height: 90%;
  width: calc(100% + 9px);
  padding: 20px 13px;

  overflow-y: auto;

  & * {
    user-select: text;
  }

  &::-webkit-scrollbar {
    border-radius: 20px;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.secondaryL2};
    border-radius: 20px;
  }
`

export const Title = styled.h1`
  ${({ theme }) => theme.fonts.infoLG}
  text-transform: uppercase;
  padding: 20px 13px;
  width: 100%;
`

export const Rating = styled.div`
  display: flex;
  gap: 8px;
`

export const RatingContent = styled.div``

export const RatingTitleWrapper = styled.div`
  display: flex;
  gap: 6px;
`

export const RatingTitle = styled.span`
  ${({ theme }) => theme.fonts.titleXS}
`

export const RatingDescription = styled.p`
  ${({ theme }) => theme.fonts.infoSM}
`

export const AppDescription = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const AppDescriptionText = styled.p`
  ${({ theme }) => theme.fonts.textMD}
`

export const StoresButtonsWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  gap: 11px;
  align-items: center;

  @media (max-width: 400px) {
    flex-direction: column;
  }
`

export const StoreLinkButton = styled.a``

export const QRCodeContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;

  img {
    margin: 24px 0;
    width: 160px;
    height: 160px;
    align-self: center;
  }
`

export const QRCodeDescription = styled.li`
  ${({ theme }) => theme.fonts.infoMD}
`

export const QRCodeStepsList = styled.ol`
  list-style-position: inside;
  & > li {
    ${({ theme }) => theme.fonts.textSM}
  }

  li:not(:first-of-type) {
    margin-top: 16px;
  }

  li:not(:last-of-type) {
    margin-bottom: 16px;
  }
`
