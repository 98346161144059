import { useEffect, useState } from 'react'

import { InjectionTokens } from '@/controller/tokens'

import { Procedure } from '@/domain/protocols/Procedures'
import { ProceduresState } from '@/domain/states/ProceduresState'

import { useInjection } from '@/presentation/contexts/InjectionContext'

import { MapProcedure } from '../components/MapProcedureChart/MapProcedureChart'

function MapProcedurePresenter() {
  const proceduresState = useInjection<ProceduresState>(InjectionTokens.ProceduresState)
  const [procedure, setProcedure] = useState<Procedure>(null)

  useEffect(() => {
    const procedureChartStateSubscription = proceduresState.getState().subscribe((state) => {
      setProcedure(state.presentedProcedure)
    })

    return () => {
      procedureChartStateSubscription.unsubscribe()
    }
  }, [proceduresState])

  return procedure && <MapProcedure url={procedure.imageFile} boundaries={procedure.boundaries} />
}

export { MapProcedurePresenter }
