import Cookies from 'js-cookie'

import { InversionContainer } from '@/controller/container'
import { InjectionTokens } from '@/controller/tokens'

import { UseCase } from '@/domain/core/UseCase'
import { Result } from '@/domain/protocols/Result'
import { AuthState } from '@/domain/states/AuthState'
import { MapLayerState } from '@/domain/states/MapLayerState'
import { RouteState } from '@/domain/states/RouteState'

import { IService } from '@/services/IService'

import { IAccountWebClient } from '@/infra/Account/IAccountWebClient'

import { UndoRouteUseCase } from '../Route/undoRoute'

export class LogoutUseCase implements UseCase<void, Promise<Result<void>>> {
  private accountWebClient: IAccountWebClient
  private authState: AuthState
  private routeState: RouteState
  private mapLayerState: MapLayerState
  private authenticator: IService
  private statePersister: IService
  private undoRouteUseCase: UndoRouteUseCase

  constructor() {
    const injectionContainer = InversionContainer.getInstance().getContainer()
    this.authState = injectionContainer.get<AuthState>(InjectionTokens.AuthState)
    this.routeState = injectionContainer.get<RouteState>(InjectionTokens.RouteState)
    this.mapLayerState = injectionContainer.get<MapLayerState>(InjectionTokens.MapLayerState)
    this.accountWebClient = injectionContainer.get<IAccountWebClient>(InjectionTokens.AccountWebClient)
    this.authenticator = injectionContainer.get<IService>(InjectionTokens.Authenticator)
    this.statePersister = injectionContainer.get<IService>(InjectionTokens.StatePersister)
    this.undoRouteUseCase = injectionContainer.get<UndoRouteUseCase>(InjectionTokens.UndoRouteUseCase)
  }

  async execute(): Promise<Result<void>> {
    await this.accountWebClient.logout()

    this.authenticator.stop()
    this.statePersister.stop()
    this.undoRouteUseCase.stop()

    this.authState.resetState()
    this.routeState.resetState()
    this.mapLayerState.resetState()

    const cookieName = '_nat'
    Cookies.remove(cookieName, { domain: '.nexatlas.com' })

    const profileCookieName = '_nap'
    Cookies.remove(profileCookieName, { domain: '.nexatlas.com' })

    window.location.href = import.meta.env.VITE_LOGOUT_URL

    return Result.ok()
  }
}
