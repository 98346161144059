import { useLayoutEffect } from 'react'

import GeoJSON from 'geojson'
import {
  MapLayerMouseEvent,
  CircleLayout,
  CirclePaint,
  MapMouseEvent,
  EventData,
  MapboxGeoJSONFeature
} from 'mapbox-gl'

import { Coordinates } from '@/domain/protocols/Coordinates'
import { MapPosition } from '@/domain/protocols/MapPosition'

import { useMapContext } from './MapContext'

interface MapCircleLayerProps {
  id: string
  sourceId: string
  color?: CirclePaint['circle-color']
  radius?: CirclePaint['circle-radius']
  opacity?: CirclePaint['circle-opacity']
  strokeWidth?: CirclePaint['circle-stroke-width']
  strokeColor?: CirclePaint['circle-stroke-color']
  visibility?: CircleLayout['visibility']
  paint?: CirclePaint
  layout?: CircleLayout
  beforeLayerId?: string
  minZoomLevel?: number
  onCircleClick?: (features: GeoJSON.Feature[], mapPosition: MapPosition) => void
  onCircleMoveStart?: (feature?: GeoJSON.Feature) => void
  onCircleDragging?: (mapPosition: MapPosition) => void
  onCircleMoveEnd?: (mapPosition: MapPosition) => void
}

function MapCircleLayer(props: MapCircleLayerProps): null {
  const {
    id,
    paint,
    color,
    radius,
    layout,
    opacity,
    sourceId,
    visibility,
    strokeColor,
    strokeWidth,
    minZoomLevel,
    beforeLayerId,
    onCircleClick,
    onCircleMoveStart,
    onCircleDragging,
    onCircleMoveEnd
  } = props

  const { map } = useMapContext()

  useLayoutEffect(() => {
    if (!map) return

    map.addLayer(
      {
        id,
        type: 'circle',
        source: sourceId,
        layout: {
          visibility: visibility ?? 'visible',
          ...layout
        },
        paint: {
          'circle-color': color,
          'circle-radius': radius,
          'circle-opacity': opacity ?? 1,
          'circle-stroke-color': strokeColor ?? '#000',
          'circle-stroke-width': strokeWidth ?? 0,
          ...paint
        },
        minzoom: minZoomLevel ?? 0
      },
      beforeLayerId
    )

    let moved = false

    const symbolClickListener = (event: MapLayerMouseEvent) => {
      if (moved) return
      onCircleClick?.(event.features ?? [], {
        coordinates: Coordinates.create({ longitude: event.lngLat.lng, latitude: event.lngLat.lat }),
        position: { x: event.point.x, y: event.point.y }
      })
    }
    if (onCircleClick) {
      map.on('click', id, symbolClickListener)
    }
    const mouseEnterListener = () => {
      map.getCanvas().style.cursor = 'pointer'
    }
    const mouseLeaveListener = () => {
      map.getCanvas().style.cursor = ''
    }
    const onMoveListener = (e: MapMouseEvent & EventData) => {
      map.getCanvas().style.cursor = 'grabbing'
      moved = true

      onCircleDragging({
        coordinates: Coordinates.create({ longitude: e.lngLat.lng, latitude: e.lngLat.lat }),
        position: { x: e.point.x, y: e.point.y }
      })
    }
    const onUpListener = (e: MapMouseEvent & EventData) => {
      map.off('mousemove', onMoveListener)
      map.getCanvas().style.cursor = ''

      if (!moved) return
      const mapPosition: MapPosition = {
        coordinates: Coordinates.create({ longitude: e.lngLat.lng, latitude: e.lngLat.lat }),
        position: { x: e.point.x, y: e.point.y }
      }

      onCircleMoveEnd(mapPosition)
      moved = false
    }
    const mouseDownListener = (
      e: MapMouseEvent & {
        features?: MapboxGeoJSONFeature[] | undefined
      } & EventData
    ) => {
      e.preventDefault()
      onCircleMoveStart(e.features[0])

      map.getCanvas().style.cursor = 'grab'
      map.on('mousemove', onMoveListener)
      map.once('mouseup', onUpListener)
    }

    if (onCircleMoveStart) map.on('mousedown', id, mouseDownListener)
    map.on('mouseenter', id, mouseEnterListener)
    map.on('mouseleave', id, mouseLeaveListener)

    return () => {
      if (onCircleClick) {
        map.off('click', id, symbolClickListener)
      }
      if (onCircleMoveStart) {
        map.off('mouseup', id, onUpListener)
        map.off('mousedown', id, mouseDownListener)
      }
      map.off('mouseenter', id, mouseEnterListener)
      map.off('mouseleave', id, mouseLeaveListener)
      map.getCanvas().style.cursor = ''

      if (map.getLayer(id)) {
        map.removeLayer(id)
      }
    }
  }, [map, onCircleClick])

  return null
}

export { MapCircleLayer }
