import { useState, useRef, KeyboardEventHandler, useCallback, ChangeEventHandler, useEffect } from 'react'

import { TextField } from '@nexds/web'

import { InjectionTokens } from '@/controller/tokens'

import { ADHP } from '@/domain/models'

import { AeroInfoRepository } from '@/data/AeroInfoRepository/AeroInfoRepository'

import { useInjection } from '@/presentation/contexts/InjectionContext'
import { useI18n } from '@/presentation/hooks/useI18n'

import { ADHPCode, ADHPName, ADHPResult, ResultsContainer } from '../ProceduresScreen/ProceduresScreen.styles'

interface AdhpSearchProps {
  onAdhpSelected: (adhp: ADHP) => void
}

function AdhpSearch(props: AdhpSearchProps) {
  const { onAdhpSelected } = props

  const { t } = useI18n()

  const [currentAdhpResult, setCurrentAdhpResult] = useState(0)
  const [adhpSearchValue, setAdhpSearchValue] = useState('')
  const [adhpSearchResults, setAdhpSearchResults] = useState<ADHP[]>([])

  const [selectedAdhp, setSelectedAdhp] = useState<ADHP>(null)
  const selectedAdhpResult = adhpSearchResults[currentAdhpResult]
  const resultsRef = useRef<HTMLDivElement>(null)

  const aeroinfoRespository = useInjection<AeroInfoRepository>(InjectionTokens.AeroInfoRepository)

  const handleSearchKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    const resultItemHeight = 55

    if (e.key === 'Escape') {
      e.preventDefault()
      e.currentTarget.blur()
      setCurrentAdhpResult(0)
      handleAdhpResultClick(selectedAdhpResult)
    }

    if (e.key === 'ArrowDown') {
      e.preventDefault()

      setCurrentAdhpResult((prev) => (prev < adhpSearchResults.length - 1 ? prev + 1 : adhpSearchResults.length - 1))

      if (currentAdhpResult > 2) {
        resultsRef.current.scrollBy({ top: resultItemHeight, behavior: 'smooth' })
      }
    }

    if (e.key === 'ArrowUp') {
      e.preventDefault()

      setCurrentAdhpResult((prev) => (prev >= 0 ? prev - 1 : 0))

      resultsRef.current.scrollBy({ top: -resultItemHeight, behavior: 'smooth' })
    }

    if (e.key === 'Enter' && selectedAdhpResult) {
      e.preventDefault()
      e.currentTarget.blur()
      handleAdhpResultClick(selectedAdhpResult)
    }
  }

  const handleAdhpSearchChange = useCallback<ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>>((e) => {
    setAdhpSearchValue(e.target.value)
    setSelectedAdhp(null)
  }, [])

  const handleAdhpResultClick = useCallback((adhp: ADHP) => {
    setSelectedAdhp(adhp)
    setAdhpSearchValue(adhp.code)
    onAdhpSelected(adhp)
  }, [])

  const fetchADHPResults = useCallback(async () => {
    const results = await aeroinfoRespository.searchByInfo(adhpSearchValue, 15, true)
    setAdhpSearchResults(results as ADHP[])
  }, [aeroinfoRespository, adhpSearchValue])

  useEffect(() => {
    fetchADHPResults()
  }, [adhpSearchValue])

  return (
    <>
      <TextField
        placeholder={t('DRAWER_PROCEDURE-CHARTS_SEARCH-AERODROME-PLACEHOLDER')}
        rightIcon="Search"
        labelGutter={false}
        value={selectedAdhp ? '' : adhpSearchValue}
        onChange={(e) => {
          handleAdhpSearchChange(e)
          setCurrentAdhpResult(0)
        }}
        onKeyDown={handleSearchKeyDown}
      />
      {!selectedAdhp && (
        <ResultsContainer ref={resultsRef}>
          {adhpSearchResults.map((adhp) => {
            return (
              <ADHPResult
                key={adhp.id.toString()}
                onClick={() => handleAdhpResultClick(adhp)}
                isSelected={selectedAdhpResult === adhp}
              >
                <ADHPCode>{adhp.code}</ADHPCode>
                <ADHPName>{adhp.name}</ADHPName>
              </ADHPResult>
            )
          })}
        </ResultsContainer>
      )}
    </>
  )
}

export { AdhpSearch }
