import { ADHP } from './ADHP'
import { AwWaypoint } from './AwWaypoint'
import { City } from './City'
import { CoordWaypoint } from './CoordWaypoint'
import { Navaid } from './Navaid'
import { VisualPosition } from './VisualPosition'
import { Waypoint, WaypointPojo } from './Waypoint'

export const waypointFromPojo = (pojo: WaypointPojo): Waypoint => {
  if (pojo.waypointType?.codeName === 'ADHP') return ADHP.fromPojo(pojo)
  else if (pojo.waypointType?.codeName === 'AwWaypoint') return AwWaypoint.fromPojo(pojo)
  else if (pojo.waypointType?.codeName === 'City') return City.fromPojo(pojo)
  else if (pojo.waypointType?.codeName === 'CoordWaypoint') return CoordWaypoint.fromPojo(pojo)
  else if (pojo.waypointType?.codeName === 'Navaid') return Navaid.fromPojo(pojo)
  else if (pojo.waypointType?.codeName === 'VisualPosition') return VisualPosition.fromPojo(pojo)
  else return null
}
