import styled from 'styled-components'

interface ChartSelectorProps {
  isActive?: boolean
}

export const Container = styled.div`
  @media (max-width: 430px) {
    max-width: 60px;
  }
`

export const Input = styled.input<ChartSelectorProps>`
  display: none;

  &:focus ~ label {
    border: ${({ theme, isActive }) => (isActive ? theme.colors.secondaryL3 : 'transparent')};
  }
`

export const ChartSelectorButton = styled.label<ChartSelectorProps>`
  cursor: pointer;
  width: 80px;
  height: 120px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  border-radius: 10px;
  background-color: ${({ theme, isActive }) => (isActive ? `${theme.colors.secondaryL1}bf` : 'transparent')};
  border: 1px solid ${({ theme, isActive }) => (isActive ? theme.colors.secondaryL3 : 'transparent')};

  &:hover {
    background-color: ${({ theme, isActive }) =>
      isActive ? `${theme.colors.secondaryL1}b3` : `${theme.colors.secondaryL1}26`};
  }

  &:active {
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.colors.secondaryL1 : `${theme.colors.secondaryL1}52`};
  }

  @media (max-width: 430px) {
    width: 70px;
    height: 110px;
  }
`

interface ChartImageProps {
  src: string
}

export const ChartImage = styled.div<ChartImageProps>`
  height: 64px;
  width: 64px;
  border-radius: 10px;

  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 430px) {
    max-width: 58px;
    max-height: 58px;
  }
`

export const ChartLabel = styled.p`
  ${({ theme }) => theme.fonts.textSM}
  text-align: center;
  width: 100%;
  color: ${({ theme }) => theme.colors.neutralL5};
  margin-top: 8px;
  flex: 1;

  @media (max-width: 430px) {
    ${({ theme }) => theme.fonts.infoXS}
  }
`
