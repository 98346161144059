import styled from 'styled-components'

import { appZIndex } from '@/presentation/utils/zIndexMapper'

interface ContainerProps {
  show: boolean
}

export const Container = styled.div<ContainerProps>`
  position: absolute;
  bottom: ${({ show }) => (show ? 20 : -60)}px;
  left: 50%;
  transform: translateX(-50%);
  z-index: ${appZIndex.METEOROLOGY_CONTROL};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  transition: bottom 0.4s;
`

interface InfoContainerProps {
  infoWarning: boolean
}

export const InfoContainer = styled.div<InfoContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 36px;
  padding: 0 12px;
  border-radius: 13px;
  margin-bottom: 16px;
  gap: 8px;

  background-color: ${({ theme, infoWarning }) =>
    infoWarning ? `${theme.colors.error}cc` : `${theme.colors.secondaryD1}cc`};
`

export const InfoText = styled.p`
  ${({ theme }) => theme.fonts.infoMD}
  color: ${({ theme }) => theme.colors.neutralL5};
`

export const InfoButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
`

export const PlayerContainer = styled.div`
  width: 350px;
  height: 58px;
  background-color: ${({ theme }) => `${theme.colors.secondaryD1}cc`};
  border-radius: 13px;

  padding: 0 10px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

export const SliderContainer = styled.div`
  flex: 1;
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 14px;
`
