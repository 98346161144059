import styled from 'styled-components'

import { useI18n } from '@/presentation/hooks/useI18n'

function BetaScreenPresenter() {
  const { t } = useI18n()
  return (
    <BetaScreenContainer>
      <Title>{t('DRAWER_BETA_MAIN_TITLE')}</Title>
      <Body>
        <Subtitle>Esta é uma versão de testes!</Subtitle>
        <Content>Algumas funcionalidades ainda estão sendo aprimoradas, então você pode encontrar erros.</Content>
        <Content>
          📢 Sua opinião é fundamental para fazermos melhorias! Deixe seu feedback e ajude-nos a tornar essa experiência
          ainda melhor. ✍️
        </Content>
        <Subtitle>🆕 Principais novidades</Subtitle>
        <Content>
          <p>A nova atualização trará algumas funcionalidades muito pedidas, como:</p>
          <BulletList>
            <li>Cartas de procedimento;</li>
            <li>Espaços aéreos;</li>
            <li>Nova meteorologia;</li>
            <li>Aeródromos no mapa;</li>
          </BulletList>
          <p>e muito mais!</p>
        </Content>
      </Body>
    </BetaScreenContainer>
  )
}

export { BetaScreenPresenter }

const BetaScreenContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Title = styled.h1`
  ${({ theme }) => theme.fonts.infoLG}
  text-transform: uppercase;
  padding: 20px 13px;
  width: 100%;
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`

const Subtitle = styled.h2`
  margin-top: 24px;
  ${({ theme }) => theme.fonts.infoMD}
`

const Content = styled.div`
  ${({ theme }) => theme.fonts.textMD}
`

const BulletList = styled.ul`
  ${({ theme }) => theme.fonts.textMD}
  padding-left: 34px;
`
