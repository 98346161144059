import axios from 'axios'
import GeoJSON from 'geojson'
import { injectable } from 'inversify'

import { Coordinates } from '@/domain/protocols/Coordinates'
import { Result } from '@/domain/protocols/Result'

import { GeocodeResponse, IGeocodeApi } from './IGeocodeApi'

interface MapboxGeocodeResponse {
  type: 'FeatureCollection'
  query: string[]
  features: Array<{
    id: string
    type: 'Feature'
    place_type: string[]
    relevance: number
    properties: any
    text: string
    place_name: string
    center: [number, number]
    geometry: GeoJSON.Point
    address: string
    context: Array<{
      id: string
      text: string
      wikidata: string
    }>
  }>
  attribution: string
}

@injectable()
export class MapboxApi implements IGeocodeApi {
  private axiosClient = axios.create({
    baseURL: 'https://api.mapbox.com',
    timeout: 5000,
    params: {
      access_token: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN
    }
  })

  async getGeocode(search: string, proximity?: Coordinates): Promise<Result<GeocodeResponse[]>> {
    try {
      const response = await this.axiosClient.get<MapboxGeocodeResponse>(`/geocoding/v5/mapbox.places/${search}.json`, {
        params: {
          proximity: proximity ? `${proximity.longitude},${proximity.latitude}` : undefined,
          language: 'pt-br',
          country: 'br',
          types: 'address,poi'
        }
      })

      const geocodeResults: GeocodeResponse[] = response.data.features.map((feature) => ({
        coordinates: Coordinates.createFromArray(feature.center),
        nameShort: feature.text,
        name: feature.place_name
      }))

      return Result.ok(geocodeResults)
    } catch (error) {
      return Result.fail(error)
    }
  }
}
