import styled from 'styled-components'

export const VelocimeterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  & > svg {
    cursor: pointer;
  }
`

export const VelocimeterInput = styled.input`
  width: 48px;
  height: 22px;
  padding: 3px 0;
  border-radius: 4px;
  border: none;
  ${({ theme }) => theme.fonts.infoMD}
  background-color: ${({ theme }) => theme.colors.secondary};
  text-align: center;

  &::placeholder {
    text-align: center;
    color: ${({ theme }) => theme.colors.secondaryL2};
    ${({ theme }) => theme.fonts.infoMD}
  }

  &[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
`
