import { InversionContainer } from '@/controller/container'
import { InjectionTokens } from '@/controller/tokens'

import { RouteBuilder } from '@/domain/builders/RouteBuilder'
import { UseCase } from '@/domain/core/UseCase'
import { Result } from '@/domain/protocols/Result'
import { RouteState } from '@/domain/states/RouteState'

import { ILogger } from '@/infra/logger/ILogger'

class SetRouteSpeedUseCase implements UseCase<number, Result<void>> {
  private routeState: RouteState
  private logger: ILogger

  constructor() {
    const injectionContainer = InversionContainer.getInstance().getContainer()
    this.routeState = injectionContainer.get<RouteState>(InjectionTokens.RouteState)
    this.logger = injectionContainer.get<ILogger>(InjectionTokens.Logger)
  }

  execute(speed: number): Result<void> {
    try {
      const routeStateSnapshot = this.routeState.getStateSnapshot()
      const activeRoute = routeStateSnapshot.activeRoute

      if (!activeRoute) return Result.fail('There is no active route')

      const route = new RouteBuilder().copyFromRoute(activeRoute).setGroundSpeed(speed).build()

      this.routeState.setActiveRoute(route)
      return Result.ok()
    } catch (error) {
      this.logger.error('SetRouteSpeedUseCase', String(error))
      return Result.fail(error)
    }
  }
}

export { SetRouteSpeedUseCase }
