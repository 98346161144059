import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { InjectionTokens } from '@/controller/tokens'

import { MapLayerState, MapLayerStateProps } from '@/domain/states/MapLayerState'
import { RouteState, RouteStateProps } from '@/domain/states/RouteState'

import { useBehaviorSubject } from '@/presentation/hooks/useBehaviorSubject'
import { useGlobalState } from '@/presentation/hooks/useGlobalState'
import { useI18n } from '@/presentation/hooks/useI18n'
import { sendMetrics } from '@/presentation/utils/sendMetrics'

import { MapImperativeInterface } from '../../MapScreen/interfaces/MapImperativeInterface'
import { FeatureInfoState } from '../../MapScreen/states/FeatureInfoState'
import { MapADHPStateChecker, MapADHPStateMutator } from '../../MapScreen/states/MapADHPState'
import { MeasuringSegmentState, MeasuringSegmentStateMutator } from '../../MapScreen/states/MapMeasuringSegmentState'
import { MeteorologyState, MeteorologyStateProps } from '../../MapScreen/states/MeteorologyState'
import { ScreenElementsState, ScreenElementsStateProps } from '../../MapScreen/states/ScreenElementsState'
import { getFitBoundaries } from '../../MapScreen/utils/map'
import { ToolProps, Toolbar } from '../components/Toolbar'
import { AirspacesTool, ChartsTool, MeteorologyTool, ADHPTool } from '../components/ToolbarTools'

function ToolbarPresenter() {
  const { t } = useI18n()

  const featureInfoState = useBehaviorSubject(FeatureInfoState)
  const measuringSegmentState = useBehaviorSubject(MeasuringSegmentState)
  const screenElementsState = useBehaviorSubject<ScreenElementsStateProps>(ScreenElementsState)
  const [routeState] = useGlobalState<RouteState, RouteStateProps>(InjectionTokens.RouteState)
  const mapLayersStateMethods = useGlobalState<MapLayerState, MapLayerStateProps>(InjectionTokens.MapLayerState)[1]
  const meteorologyState = useBehaviorSubject<MeteorologyStateProps>(MeteorologyState)

  const [activeTool, setActiveTool] = useState<string>(null)

  useEffect(() => {
    if (measuringSegmentState.active) {
      sendMetrics('PLANNINGAPP_TOOLBAR_RULER_CLICKED', { active: measuringSegmentState.active })
    }
  }, [measuringSegmentState.active])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        MeasuringSegmentStateMutator.clear()
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  const handleRulerToolbarItemPress = useCallback(() => {
    if (measuringSegmentState.active) {
      MeasuringSegmentStateMutator.clear()
      setActiveTool(null)
    } else {
      MeasuringSegmentStateMutator.setActive(true)
      setActiveTool('ruler')
    }
  }, [measuringSegmentState.active])

  const clearRulerStateOnOtherItemPress = useCallback(() => {
    MeasuringSegmentStateMutator.clear()
  }, [])

  const handleFit = useCallback(() => {
    const boundaries = getFitBoundaries(routeState.activeRoute)
    const padding: [number, number, number, number] = [
      40 + screenElementsState.boundsDefaultPadding.top,
      75 + screenElementsState.boundsDefaultPadding.right,
      40 + screenElementsState.boundsDefaultPadding.bottom,
      75 + screenElementsState.boundsDefaultPadding.left
    ]
    MapImperativeInterface.flyToBounds(boundaries, padding, 500)
    sendMetrics('PLANNINGAPP_TOOLBAR_ZOOM_CLICKED', { extra: 'fit' })
  }, [routeState.activeRoute, screenElementsState])

  const handleZoomIn = () => {
    MapImperativeInterface.zoomIn()
    sendMetrics('PLANNINGAPP_TOOLBAR_ZOOM_CLICKED', { extra: 'zoom-in' })
  }

  const handleZoomOut = () => {
    MapImperativeInterface.zoomOut()
    sendMetrics('PLANNINGAPP_TOOLBAR_ZOOM_CLICKED', { extra: 'zoom-out' })
  }

  const toolbarItems: ToolProps[] = [
    {
      key: 'charts',
      title: t('TOOLBAR_CHARTS-AND-MAPS_MAIN_TITLE'),
      icon: 'Layers',
      hasItemActive: mapLayersStateMethods.isChartActive(),
      content: <ChartsTool />
    },
    {
      key: 'meteorology',
      title: t('TOOLBAR_METEOROLOGY_MAIN_TITLE'),
      icon: 'Rain',
      hasItemActive: !!meteorologyState.layer,
      content: <MeteorologyTool />
    },
    {
      key: 'airspaces',
      icon: 'Airspaces3',
      title: t('TOOLBAR_AIRSPACES_MAIN-TITLE'),
      hasItemActive: mapLayersStateMethods.isAirspaceActive(),
      content: <AirspacesTool />
    },
    {
      key: 'adhps',
      icon: 'PointOnMap',
      title: t('TOOLBAR_MAP-POINTS_MAIN_TITLE'),
      hasItemActive: MapADHPStateChecker.isADHPActive(),
      content: <ADHPTool />
    },
    {
      key: 'ruler',
      title: t('TOOLBAR_ITEM_RULER'),
      icon: 'Ruler',
      hasItemActive: measuringSegmentState.active,
      onPress: handleRulerToolbarItemPress
    },
    {
      key: 'fit',
      title: t('TOOLBAR_ITEM_FIT'),
      icon: 'Fit',
      onPress: handleFit
    },
    {
      key: 'zoom-in',
      title: t('TOOLBAR_ITEM_ZOOM-IN'),
      icon: 'Plus',
      onPress: handleZoomIn
    },
    {
      key: 'zoom-out',
      title: t('TOOLBAR_ITEM_ZOOM-OUT'),
      icon: 'Minus',
      onPress: handleZoomOut
    }
  ]

  const onOtherItemPressCallbacks = [clearRulerStateOnOtherItemPress]

  return (
    <Toolbar
      tools={toolbarItems}
      activeToolKey={activeTool}
      setActiveToolKey={setActiveTool}
      onOtherItemPressCallbacks={onOtherItemPressCallbacks}
      shouldHide={!!featureInfoState.active}
    />
  )
}

export { ToolbarPresenter }
