import { useEffect, useState } from 'react'

import { InjectionTokens } from '@/controller/tokens'

import { IHttpClient } from '@/infra/http/IHttpClient'
import { useInjection } from '@/presentation/contexts/InjectionContext'

import { ChartsAndDataScreen } from '../components/ChartsAndDataScreen'
import { ChartsAndData, ChartsAndDataResponse } from '../types'

function ChartsAndDataScreenPresenter() {
  const [chartsAndData, setChartsAndData] = useState<ChartsAndData[]>()

  const httpClient = useInjection<IHttpClient>(InjectionTokens.HttpClient)

  useEffect(() => {
    const fetchNews = async () => {
      const data = await httpClient.get<ChartsAndDataResponse>('https://api.nexatlas.com/dataUpdate/getItems')

      if (data.success) {
        setChartsAndData(data.response.dataUpdateItems)
      }
    }

    fetchNews()
  }, [])

  return chartsAndData && <ChartsAndDataScreen dataUpdateItems={chartsAndData} />
}

export { ChartsAndDataScreenPresenter }
