type InjectionTokensKeys =
  | 'I18n'
  | 'Logger'
  | 'FeatureFlagsProvider'
  | 'HttpClient'
  | 'CachedHttpClient'
  | 'GeocodeApi'
  | 'AccountWebClient'
  | 'AmplitudeMetricsProvider'
  | 'KeyValuePersister'
  | 'Authenticator'
  | 'StatePersister'
  | 'StatePersisterStates'
  | 'MetricsService'
  | 'TrackingService'
  | 'MapLayerState'
  | 'ProceduresState'
  | 'RouteState'
  | 'MapState'
  | 'AuthState'
  | 'FeatureFlagsRepository'
  | 'AirspaceRepository'
  | 'MeteorologyRepository'
  | 'AeroInfoRepository'
  | 'UserRoutesRepository'
  | 'UserWaypointsRepository'
  | 'ProceduresRepository'
  | 'UndoRouteUseCase'

const InjectionTokens: Record<InjectionTokensKeys, symbol> = {
  I18n: Symbol.for('I18n'),
  Logger: Symbol.for('Logger'),
  FeatureFlagsProvider: Symbol.for('FeatureFlagsProvider'),

  // WebClients
  HttpClient: Symbol.for('HttpClient'),
  CachedHttpClient: Symbol.for('CachedHttpClient'),
  GeocodeApi: Symbol.for('GeocodeApi'),
  AccountWebClient: Symbol.for('AccountWebClient'),
  AmplitudeMetricsProvider: Symbol.for('AmplitudeMetricsProvider'),

  // Persistence
  KeyValuePersister: Symbol.for('KeyValuePersister'),

  // Services
  Authenticator: Symbol.for('Authenticator'),
  StatePersister: Symbol.for('StatePersister'),
  StatePersisterStates: Symbol.for('StatePersisterStates'),
  MetricsService: Symbol.for('MetricsService'),
  TrackingService: Symbol.for('TrackingService'),

  // Global States
  MapLayerState: Symbol.for('MapLayerState'),
  RouteState: Symbol.for('RouteState'),
  MapState: Symbol.for('MapState'),
  ProceduresState: Symbol.for('ProceduresState'),
  AuthState: Symbol.for('AuthState'),

  // Repositories
  FeatureFlagsRepository: Symbol.for('FeatureFlagsRepository'),
  AirspaceRepository: Symbol.for('AirspaceRepository'),
  MeteorologyRepository: Symbol.for('MeteorologyRepository'),
  AeroInfoRepository: Symbol.for('AeroInfoRepository'),
  UserRoutesRepository: Symbol.for('UserRoutesRepository'),
  UserWaypointsRepository: Symbol.for('UserWaypointsRepository'),
  ProceduresRepository: Symbol.for('ProceduresRepository'),

  UndoRouteUseCase: Symbol.for('UndoRouteUseCase')
}

export { InjectionTokens }
