import { IService } from '@services/IService'
import axios from 'axios'
import { Container } from 'inversify'

import { Result } from '@/domain/protocols/Result'
import { UndoRouteUseCase } from '@/domain/useCases/Route/undoRoute'

import { ITrackingService } from '@/services/Tracking/ITrackingService'

import { IAeroInfoRepository } from '@/data/AeroInfoRepository/IAeroInfoRepository'
import { IAirspacesRepository } from '@/data/AirspacesRepository/IAirspacesRepository'
import { FeatureFlagsRepository } from '@/data/FeatureFlagsRepository/FeatureFlagsRepository'
import { IUserRoutesRepository } from '@/data/UserRoutesRepository/IUserRoutesRepository'
import { IUserWaypointsRepository } from '@/data/UserWaypointsRepository/IUserWaypointsRepository'

import { I18n } from '@/i18n/I18n'
import { IAccountWebClient } from '@/infra/Account/IAccountWebClient'

import { SplashScreenInfoStateMutator } from '../presentation/modules/Base/components/SplashScreen/SplashScreenInfoState'
import { InjectionTokens } from './tokens'

export class LifeCycle {
  private _container: Container

  constructor(container: Container) {
    this._container = container
  }

  async initializeComponents(): Promise<Result<void>> {
    const authenticator = this._container.get<IService>(InjectionTokens.Authenticator)
    const accountWebClient = this._container.get<IAccountWebClient>(InjectionTokens.AccountWebClient)
    const featureFlagsRepository = this._container.get<FeatureFlagsRepository>(InjectionTokens.FeatureFlagsRepository)
    const airspaceRepository = this._container.get<IAirspacesRepository>(InjectionTokens.AirspaceRepository)
    const aeroInfoRepository = this._container.get<IAeroInfoRepository>(InjectionTokens.AeroInfoRepository)
    const userWaypointsRepository = this._container.get<IUserWaypointsRepository>(
      InjectionTokens.UserWaypointsRepository
    )
    const userRoutesRepository = this._container.get<IUserRoutesRepository>(InjectionTokens.UserRoutesRepository)
    const statePersister = this._container.get<IService>(InjectionTokens.StatePersister)
    const i18n = this._container.get<I18n>(InjectionTokens.I18n)

    const undoRouteUseCase = this._container.get<UndoRouteUseCase>(InjectionTokens.UndoRouteUseCase)

    const trackingService = this._container.get<ITrackingService>(InjectionTokens.TrackingService)

    SplashScreenInfoStateMutator.initTask('testingInternetConnection')
    try {
      await axios.head('https://aerodata.nexatlas.com')
    } catch (error) {
      return Result.fail('No internet connection')
    }
    SplashScreenInfoStateMutator.finishTask('testingInternetConnection')

    SplashScreenInfoStateMutator.initTask('checkingLoginStatus')
    const authenticatorResult = (await authenticator.init()) as Result<void>

    if (authenticatorResult?.isFailure) {
      return Result.fail('NOT_AUTHENTICATED')
    }

    await accountWebClient.init()
    await authenticator.start()
    SplashScreenInfoStateMutator.finishTask('checkingLoginStatus')

    await featureFlagsRepository.init()

    await trackingService.init()

    SplashScreenInfoStateMutator.initTask('getWaypointData')
    await Promise.all([
      i18n.init(),
      statePersister.init(),
      aeroInfoRepository.init(),
      airspaceRepository.init(),
      userWaypointsRepository.init(),
      userRoutesRepository.init()
    ])

    SplashScreenInfoStateMutator.finishTask('getWaypointData')

    undoRouteUseCase.init()
    statePersister.start()
    trackingService.start()

    return Result.ok()
  }
}
