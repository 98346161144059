import { InversionContainer } from '@/controller/container'
import { InjectionTokens } from '@/controller/tokens'

import { UseCase } from '@/domain/core/UseCase'
import { Route } from '@/domain/models/Route/Route'
import { Result } from '@/domain/protocols/Result'
import { RouteState } from '@/domain/states/RouteState'

import { IUserRoutesRepository } from '@/data/UserRoutesRepository/IUserRoutesRepository'

class SaveActiveRouteUseCase implements UseCase<Route, Result<void>> {
  private routeState: RouteState
  private userRoutesRepository: IUserRoutesRepository

  constructor() {
    const injectionContainer = InversionContainer.getInstance().getContainer()
    this.routeState = injectionContainer.get<RouteState>(InjectionTokens.RouteState)
    this.userRoutesRepository = injectionContainer.get<IUserRoutesRepository>(InjectionTokens.UserRoutesRepository)
  }

  async execute(): Promise<Result<void>> {
    try {
      const activeRoute = this.routeState.getStateSnapshot().activeRoute

      if (!activeRoute) return Result.fail('There is no active route')
      if (activeRoute.waypoints.length < 2)
        return Result.fail('The route cannot be saved because it has no departure and destination.')

      const saveResult = await this.userRoutesRepository.upsertRoute(activeRoute)

      if (saveResult.isFailure) {
        return Result.fail(saveResult.error)
      }

      this.routeState.setIsSaved(true)

      return Result.ok()
    } catch (error) {
      return Result.fail(error)
    }
  }
}

export { SaveActiveRouteUseCase }
