import { PropsWithChildren, useEffect, useRef, useState } from 'react'

import 'mapbox-gl/dist/mapbox-gl.css'
import mapboxgl from 'mapbox-gl'
import { Subject, throttleTime } from 'rxjs'
import { AsyncScheduler } from 'rxjs/internal/scheduler/AsyncScheduler'

import { Coordinates } from '@/domain/protocols/Coordinates'
import { MapPosition } from '@/domain/protocols/MapPosition'

import { MapClickEvent, MapClickEventFromMeasuringSegment, MapMouseMoveEvent } from '../../interfaces/MapEvents'
import { MapImperativeSubject } from '../../interfaces/MapImperativeInterface'
import { ignoreMapClickLayers } from './constants'
import { MapContext } from './MapContext'

mapboxgl.accessToken = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN

interface MapViewProps {
  defaultPosition: {
    center: Coordinates
    zoom: number
  }

  style?: string
}

const MouseMoveThrottler = new Subject<MapPosition>()

function MapView(props: PropsWithChildren<MapViewProps>) {
  const { children, defaultPosition, style } = props

  const mapContainerRef = useRef<HTMLDivElement>(null)
  const mapRef = useRef<mapboxgl.Map>(null)

  const [isMapLoaded, setIsMapLoaded] = useState(false)
  const [isMapStyleLoaded, setIsMapStyleLoaded] = useState(false)

  const handleMapClick = (event: mapboxgl.MapMouseEvent) => {
    const position: MapPosition = {
      position: { x: event.point.x, y: event.point.y },
      coordinates: Coordinates.create({ latitude: event.lngLat.lat, longitude: event.lngLat.lng })
    }

    MapClickEventFromMeasuringSegment.next(position)

    const ignoredLayers: string[] = []

    mapRef.current.getStyle().layers.forEach((layer) => {
      if (ignoreMapClickLayers.includes(layer.id)) {
        ignoredLayers.push(layer.id)
      }
    })

    const features = mapRef.current.queryRenderedFeatures(event.point, { layers: ignoredLayers })
    if (features.length > 0) return

    MapClickEvent.next(position)
  }

  const handleMapMouseMove = (event: mapboxgl.MapMouseEvent) => {
    const position = {
      position: { x: event.point.x, y: event.point.y },
      coordinates: Coordinates.create({ latitude: event.lngLat.lat, longitude: event.lngLat.lng })
    }

    MouseMoveThrottler.next(position)
  }

  const handleMapLoaded = () => {
    setIsMapLoaded(true)
  }

  const handleMapStyleLoaded = () => {
    setIsMapStyleLoaded(true)
  }

  useEffect(() => {
    if (mapRef.current) return

    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current as HTMLDivElement,
      center: [defaultPosition.center.longitude, defaultPosition.center.latitude],
      zoom: defaultPosition.zoom,
      style: style ?? 'mapbox://styles/mapbox/streets-v11',
      attributionControl: false,
      dragRotate: false,
      touchPitch: false,
      pitchWithRotate: false,
      touchZoomRotate: true,
      dragPan: true,
      maxPitch: 0,
      logoPosition: 'top-right',
      doubleClickZoom: false,
      projection: { name: 'globe' }
    })

    mapRef.current.touchZoomRotate.disableRotation()
    mapRef.current.on('click', handleMapClick)
    mapRef.current.on('mousemove', handleMapMouseMove)
    mapRef.current.on('load', handleMapLoaded)
    mapRef.current.on('style.load', handleMapStyleLoaded)

    // Start listening to map events
    const eventsSubscription = MapImperativeSubject.subscribe((event) => {
      switch (event.type) {
        case 'flyToBounds':
          mapRef.current?.fitBounds(event.bounds, {
            padding: {
              top: event.padding?.[0] ?? 0,
              right: event.padding?.[1] ?? 0,
              bottom: event.padding?.[2] ?? 0,
              left: event.padding?.[3] ?? 0
            },
            duration: event.duration
          })
          break
        case 'flyToCoordinates':
          mapRef.current?.easeTo({
            center: [event.coordinates.longitude, event.coordinates.latitude],
            zoom: event.zoom ?? mapRef.current?.getZoom(),
            duration: event.duration,
            padding: {
              top: event.padding?.[0] ?? 0,
              right: event.padding?.[1] ?? 0,
              bottom: event.padding?.[2] ?? 0,
              left: event.padding?.[3] ?? 0
            }
          })
          break
        case 'zoomIn':
          mapRef.current?.zoomIn()
          break
        case 'zoomOut':
          mapRef.current?.zoomOut()
          break
      }
    })

    const mouseMoveThrottlerSubscribe = MouseMoveThrottler.pipe(
      throttleTime(41.67, AsyncScheduler as any, { leading: true, trailing: true })
    ).subscribe((position) => {
      MapMouseMoveEvent.next(position)
    })

    return () => {
      eventsSubscription.unsubscribe()
      mapRef.current?.off('click', handleMapClick)
      mapRef.current?.off('load', handleMapLoaded)
      mapRef.current?.off('style.load', handleMapStyleLoaded)
      mouseMoveThrottlerSubscribe.unsubscribe()
      mapRef.current?.remove()
    }
  }, [])

  useEffect(() => {
    if (!mapRef.current) return
    setIsMapStyleLoaded(false)
    mapRef.current.setStyle(style ?? 'mapbox://styles/mapbox/streets-v11')
  }, [style])

  useEffect(() => {
    if (!mapRef.current) return
    const center = Coordinates.create({ latitude: -14.235, longitude: -51.9253 })
    if (isMapLoaded && isMapStyleLoaded)
      setTimeout(() => {
        mapRef.current?.easeTo({
          center: { lat: center.latitude, lng: center.longitude },
          zoom: 4,
          duration: 2000
        })
      }, 1000)
  }, [isMapLoaded])

  return (
    <>
      <div
        ref={mapContainerRef}
        id="map-container"
        style={{
          height: '100%',
          width: '100%',
          position: 'absolute'
        }}
      />
      {isMapLoaded && isMapStyleLoaded && (
        <MapContext.Provider value={{ map: mapRef.current }}>{children}</MapContext.Provider>
      )}
    </>
  )
}

export { MapView }
