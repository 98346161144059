import { useInjection } from '@contexts/InjectionContext'
import { BaseState } from '@domain/states/BaseState'
import { useBehaviorSubject } from '@hooks/useBehaviorSubject'

const useGlobalState = <T extends BaseState<P>, P>(identifier: string | symbol) => {
  const stateWrapper = useInjection<T>(identifier)
  const stateValue = useBehaviorSubject(stateWrapper.getState())

  return [stateValue, stateWrapper] as const
}

export { useGlobalState }
