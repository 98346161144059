import { useEffect, useState } from 'react'

import { InjectionTokens } from '@/controller/tokens'

import { IHttpClient } from '@/infra/http/IHttpClient'
import { useInjection } from '@/presentation/contexts/InjectionContext'

import { NewsScreen } from '../components/NewsScreen'
import { News, NewsResponse } from '../types'

function NewsScreenPresenter() {
  const [news, setNews] = useState<News[]>()

  const httpClient = useInjection<IHttpClient>(InjectionTokens.HttpClient)

  useEffect(() => {
    const fetchNews = async () => {
      const data = await httpClient.get<NewsResponse>('https://api.nexatlas.com/news/get?page=0')

      if (data.success) {
        setNews(data.response.news)
      }
    }

    fetchNews()
  }, [])

  return news && <NewsScreen news={news} />
}

export { NewsScreenPresenter }
