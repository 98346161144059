import { injectable } from 'inversify'

import { InversionContainer } from '@/controller/container'
import { InjectionTokens } from '@/controller/tokens'

import { RoutePolicy } from '../policies/RoutePolicy'
import { Result } from '../protocols/Result'
import { BaseState } from './BaseState'
import { RouteState } from './RouteState'

export interface MapStateProps {
  nextWaypointSequence: number
}

const INITIAL_STATE: MapStateProps = {
  nextWaypointSequence: null
}

@injectable()
export class MapState extends BaseState<MapStateProps> {
  constructor() {
    super(INITIAL_STATE)
  }

  setNextWaypointSequence(sequence: number): Result<void> {
    const injectionContainer = InversionContainer.getInstance().getContainer()
    const routeState = injectionContainer.get<RouteState>(InjectionTokens.RouteState)
    const routeStateValue = routeState.getStateSnapshot()

    const currentRoute = routeStateValue.activeRoute
    if (!currentRoute) return Result.fail('Route is null')

    if (RoutePolicy.hasWaypointSequence(currentRoute, sequence).isFailure) {
      return Result.fail("Route doesn't have next waypoint")
    }

    this._updateStateProp('nextWaypointSequence', sequence)
    return Result.ok()
  }

  incrementWaypointSequence(): Result<void> {
    const injectionContainer = InversionContainer.getInstance().getContainer()
    const routeState = injectionContainer.get<RouteState>(InjectionTokens.RouteState)
    const routeStateValue = routeState.getStateSnapshot()

    const currentRoute = routeStateValue.activeRoute
    if (!currentRoute) return Result.fail('Route is null')
    const currentSequence = this._getStateProp('nextWaypointSequence')
    if (!currentSequence) return Result.fail('next waypoint sequence is null')

    if (RoutePolicy.hasWaypointSequence(currentRoute, currentSequence + 1).isFailure) {
      return Result.fail("Route doesn't have next waypoint")
    }

    this._updateStateProp('nextWaypointSequence', currentSequence + 1)
    return Result.ok()
  }

  decrementWaypointSequence(): Result<void> {
    const injectionContainer = InversionContainer.getInstance().getContainer()
    const routeState = injectionContainer.get<RouteState>(InjectionTokens.RouteState)
    const routeStateValue = routeState.getStateSnapshot()

    const currentRoute = routeStateValue.activeRoute
    if (!currentRoute) return Result.fail('Route is null')
    const currentSequence = this._getStateProp('nextWaypointSequence')
    if (!currentSequence) return Result.fail('next waypoint sequence is null')

    if (RoutePolicy.hasWaypointSequence(currentRoute, currentSequence - 1).isFailure) {
      return Result.fail("Route doesn't have next waypoint")
    }

    this._updateStateProp('nextWaypointSequence', currentSequence - 1)
    return Result.ok()
  }
}
