import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100%;
  height: 100%;
  max-width: 100vw;
  margin: 0 auto;

  text-align: center;
  background-color: ${({ theme }) => theme.colors.secondary};
  ${({ theme }) => theme.fonts.h5}
`
