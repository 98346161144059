import styled from 'styled-components'

import { appZIndex } from '@/presentation/utils/zIndexMapper'

interface WaypointInfoContainerProps {
  screenPosition: {
    top: number
    left: number
    translateX: string
    translateY: string
  }
}

export const WaypointInfoContainer = styled.div<WaypointInfoContainerProps>`
  position: relative;
  z-index: ${appZIndex.WAYPOINT_INFO};
  height: fit-content;
  min-width: 250px;
  width: max-content;

  top: ${({ screenPosition }) => screenPosition.top}px;
  left: ${({ screenPosition }) => screenPosition.left}px;
  transform: ${({ screenPosition }) => `translate(${screenPosition.translateX},${screenPosition.translateY})`};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 14px 16px 14px;
  background: ${({ theme }) => theme.colors.neutralL5};
  border-radius: 10px;

  gap: 14px;
`

export const WaypointName = styled.h1`
  ${({ theme }) => theme.fonts.titleMD}
  color: ${({ theme }) => theme.colors.secondary};
`

export const Coords = styled.h2`
  ${({ theme }) => theme.fonts.infoMD}
  color: ${({ theme }) => theme.colors.secondary};
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Options = styled.div`
  display: flex;
  background: ${({ theme }) => theme.colors.neutralL4};
  border-radius: 10px;

  flex-direction: column;

  overflow: hidden;

  & > button:first-child {
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutralL3};
  }
`

interface ActionButtonProps {
  variant: 'default' | 'warning'
}

export const ActionButton = styled.button<ActionButtonProps>`
  ${({ theme }) => theme.fonts.infoMD}
  color: ${({ theme, variant }) => (variant === 'warning' ? theme.colors.error : theme.colors.secondary)};
  text-decoration: none;

  height: 48px;
  width: 100%;
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 0 24px;

  border: none;
  outline: none;
  cursor: pointer;

  transition: background 0.2s;

  &:hover {
    background: ${({ theme }) => theme.colors.neutralL3};
  }
`
