import { useEffect, useLayoutEffect } from 'react'

import { useMapContext } from './MapContext'

interface MapRasterLayerProps {
  id: string
  sourceId: string
  minZoom?: number
  maxZoom?: number
  opacity?: number
  fadeDuration?: number
  beforeLayerId?: string
}

function MapRasterLayer(props: MapRasterLayerProps): null {
  const { id, sourceId, minZoom = 0, maxZoom = 22, opacity, beforeLayerId } = props

  const { map } = useMapContext()

  useLayoutEffect(() => {
    if (!map) return

    map.addLayer(
      {
        id,
        type: 'raster',
        source: sourceId,
        minzoom: minZoom,
        maxzoom: maxZoom,
        paint: {
          'raster-opacity': opacity ?? 1,
          'raster-fade-duration': props.fadeDuration ?? 0
        }
      },
      beforeLayerId
    )

    return () => {
      map.removeLayer(id)
    }
  }, [map])

  useEffect(() => {
    if (!map) return

    if (opacity !== undefined) {
      map.setPaintProperty(id, 'raster-opacity', opacity)
    }
  }, [map, opacity])

  return null
}

export { MapRasterLayer }
