import { useCallback, useEffect, useRef, useState } from 'react'

import { Assets } from '@nexds/web'
import { useTheme } from 'styled-components'

import { SetRouteSpeedUseCase } from '@/domain/useCases/Route'

import { VelocimeterContainer, VelocimeterInput } from './Velocimeter.styles'

interface VelocimeterProps {
  groundSpeed: number
  // onEditGroundSpeed: (speed: number) => void
}

function Velocimeter(props: VelocimeterProps) {
  const { groundSpeed } = props

  const theme = useTheme()
  const inputRef = useRef<HTMLInputElement>(null)

  const [inputValue, setInputValue] = useState(groundSpeed)

  return (
    <VelocimeterContainer
      onClick={() => {
        inputRef?.current?.focus()
      }}
    >
      <Assets.Velocimeter color={theme.colors.neutralL5} size="sm" />
      <VelocimeterInput
        ref={inputRef}
        type="number"
        placeholder="kt"
        value={inputValue}
        onKeyDown={(e) => {
          if (e.key === 'Enter') inputRef.current?.blur()
        }}
        min={0}
        onChange={(e) => {
          if (!e.target.value || e.target.value === '') setInputValue(0)
          else {
            const value = parseInt(e.target.value, 10)
            e.target.value = value.toString()
            setInputValue(value)
            new SetRouteSpeedUseCase().execute(value)
          }
        }}
      />
    </VelocimeterContainer>
  )
}

export { Velocimeter }
