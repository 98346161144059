import styled from 'styled-components'

export const MarketContainer = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  & > * {
    position: absolute;
  }
`
