import { Info, Trash } from '@nexds/web'
import { useTheme } from 'styled-components'

import { ADHPType } from '@/domain/models'
import { Coordinates } from '@/domain/protocols/Coordinates'

import ModalBalloonPointer from '@/presentation/assets/adhp/modal/balloon-pointer.svg?react'
import { useI18n } from '@/presentation/hooks/useI18n'
import { sendMetrics } from '@/presentation/utils/sendMetrics'
import { convertDecimalDegreesToDMS } from '@/utils/coordinates'

import { Overlay } from '../../../containers/Map.styles'
import {
  ActionButton,
  Content,
  Coords,
  Header,
  Options,
  WaypointInfoContainer,
  WaypointName
} from './WaypointInfoBox.styles'

export type WaypointPopupInfo = {
  id: string
  name: string
  coordinates: Coordinates
  code?: string
  adhpType: ADHPType
}

interface WaypointInfoBoxProps {
  menuScreenPos: {
    top: number
    left: number
    translateX: string
    translateY: string
  }
  focusedWaypoint: WaypointPopupInfo

  pointerPosition:
    | 'top-left'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-right'
    | 'center-left'
    | 'center-right'
    | 'center-top'
    | 'center-bottom'
    | 'default'
  onOverlayClick: () => void
  onRemoveWaypointClick: (waypointId: string) => void
  onShowInfoClick: (infoCode: string) => void
}

export function WaypointInfoBox(props: WaypointInfoBoxProps) {
  const { menuScreenPos, focusedWaypoint, pointerPosition, onOverlayClick, onRemoveWaypointClick, onShowInfoClick } =
    props

  const theme = useTheme()
  const { t } = useI18n()

  const pointerPos = {
    'top-left': { transform: 'translate(-30%, -30%) rotate(-45deg)', top: '0', left: '0', opacity: 0 },
    'top-right': { transform: 'translate(-70%, -30%) rotate(45deg)', top: '0', left: '100%', opacity: 0 },

    'bottom-left': { transform: 'translate(-30%, -90%) rotate(-10deg)', top: '100%', left: '0', opacity: 0 },
    'bottom-right': { transform: 'translate(-70%, -90%) rotate(10deg)', top: '100%', left: '100%', opacity: 0 },

    'center-left': { transform: 'translate(-60%, -60%) rotate(30deg)', top: '50%', left: '0', opacity: 1 },
    'center-right': { transform: 'translate(-40%, -60%) rotate(-30deg)', top: '50%', left: '100%', opacity: 1 },

    'center-top': { transform: 'translate(-50%, -70%)', top: '0', left: '50%', opacity: 1 },
    'center-bottom': { transform: 'translate(-50%, -30%) rotate(180deg)', top: '100%', left: '50%', opacity: 1 },

    default: { transform: 'translate(-60%, -80%) rotate(30deg)', top: '50%', left: '0', opacity: 1 }
  }

  return (
    <Overlay onClick={onOverlayClick}>
      <WaypointInfoContainer screenPosition={menuScreenPos}>
        <ModalBalloonPointer
          height={24}
          width={24}
          style={{
            position: 'absolute',
            top: pointerPos[pointerPosition].top,
            left: pointerPos[pointerPosition].left,
            transform: pointerPos[pointerPosition].transform,
            opacity: pointerPos[pointerPosition].opacity,
            zIndex: 1000
          }}
        />
        <Content>
          <Header>
            <WaypointName>{focusedWaypoint.name}</WaypointName>
            <Coords>
              {convertDecimalDegreesToDMS(focusedWaypoint.coordinates.latitude, focusedWaypoint.coordinates.longitude)}
            </Coords>
          </Header>
          <Options>
            {focusedWaypoint.code && (
              <ActionButton
                variant="default"
                onClick={() => {
                  onShowInfoClick(focusedWaypoint.code)
                  // sendMetrics('PLANNINGAPP_MAP_ADHP_SELECTED', { extra: 'info' })
                }}
              >
                <Info color={theme.colors.secondary} size={'md'} />
                {focusedWaypoint.adhpType === 'AD'
                  ? t('MAP_ROUTE-WAYPOINTS_MODAL_INFO-AERODROME')
                  : t('MAP_ROUTE-WAYPOINTS_MODAL_INFO-HELIPAD')}
              </ActionButton>
            )}
            <ActionButton variant="warning" onClick={() => onRemoveWaypointClick(focusedWaypoint.id)}>
              <Trash color={theme.colors.error} size={'md'} />
              {t('MAP_ROUTE-WAYPOINTS_MODAL_REMOVE-FROM-ROUTE')}
            </ActionButton>
          </Options>
        </Content>
      </WaypointInfoContainer>
    </Overlay>
  )
}
