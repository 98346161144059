import { useMemo, useEffect } from 'react'

import { InjectionTokens } from '@/controller/tokens'

import { ADHP } from '@/domain/models'
import { ProcedureType } from '@/domain/protocols/Procedures'
import { ProceduresState, ProceduresStateProps } from '@/domain/states/ProceduresState'

import { IProceduresRepository } from '@/data/ProcedureChartsRepository/IProceduresRepository'

import { useInjection } from '@/presentation/contexts/InjectionContext'
import { useGlobalState } from '@/presentation/hooks/useGlobalState'

import { DrawerImperativeInterface } from '../Base/containers/DrawerPresenter/DrawerImperativeInterface'
import { MapImperativeInterface } from '../MapScreen/interfaces/MapImperativeInterface'
import { ProceduresScreen } from './components/ProceduresScreen/ProceduresScreen'

function ProceduresScreenPresenter() {
  const proceduresRepository = useInjection<IProceduresRepository>(InjectionTokens.ProceduresRepository)
  const [{ adhp, adhpProcedures, procedureTypeSelected, procedureSelected }, proceduresState] = useGlobalState<
    ProceduresState,
    ProceduresStateProps
  >(InjectionTokens.ProceduresState)

  const onAdhpSelected = async (adhp: ADHP) => {
    proceduresState.resetState()

    proceduresState.setAdhp(adhp)

    const response = await proceduresRepository.getProcedureCharts(adhp.code)

    if (response.isSuccess) {
      proceduresState.setAdhpProcedures(response.getValue())
    }
  }

  const availableProcedures = useMemo(() => {
    if (adhpProcedures) {
      return adhpProcedures.map((item) => item.type)
    }

    return []
  }, [adhpProcedures])

  useEffect(() => {
    if (availableProcedures.length > 0 && !procedureTypeSelected) {
      proceduresState.setProcedureTypeSelected(availableProcedures[0])
    }
  }, [availableProcedures])

  const onSelectProcedureType = (procedure: ProcedureType) => {
    proceduresState.setProcedureSelected(null)
    proceduresState.setProcedureTypeSelected(procedure)
  }

  const handlePlaceOverMap = () => {
    proceduresState.setPresentedProcedure({
      ...procedureSelected,
      imageFile: procedureImgSrc
    })

    const bounds = {
      southwestLng: procedureSelected?.boundaries[3][1],
      southwestLat: procedureSelected?.boundaries[3][0],
      northeastLng: procedureSelected?.boundaries[1][1],
      northeastLat: procedureSelected?.boundaries[1][0]
    }

    MapImperativeInterface.flyToBounds(
      [bounds.southwestLng, bounds.southwestLat, bounds.northeastLng, bounds.northeastLat],
      [100, 100, 100, 100],
      1000
    )

    DrawerImperativeInterface.close()
  }

  const procedurePdfSrc = useMemo(() => {
    if (!adhp || !procedureTypeSelected || !procedureSelected) return null

    return `${proceduresRepository.getBaseUrl()}/${adhp.code}/${procedureTypeSelected}/${procedureSelected?.file}`
  }, [adhp, procedureTypeSelected, procedureSelected])

  const procedureImgSrc = useMemo(() => {
    if (!adhp || !procedureTypeSelected || !procedureSelected || !procedureSelected.imageFile) return null

    return `${proceduresRepository.getBaseUrl()}/${adhp.code}/${procedureTypeSelected}/${procedureSelected?.imageFile}`
  }, [adhp, procedureTypeSelected, procedureSelected])

  return (
    <ProceduresScreen
      adhp={adhp}
      adhpProcedures={adhpProcedures}
      procedureTypeSelected={procedureTypeSelected}
      procedureSelected={procedureSelected}
      proceduresState={proceduresState}
      handlePlaceOverMap={handlePlaceOverMap}
      onAdhpSelected={onAdhpSelected}
      onSelectProcedureType={onSelectProcedureType}
      availableProcedures={availableProcedures}
      procedureImgSrc={procedureImgSrc}
      procedurePdfSrc={procedurePdfSrc}
    />
  )
}

export { ProceduresScreenPresenter }
