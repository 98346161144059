import { InversionContainer } from '@/controller/container'
import { InjectionTokens } from '@/controller/tokens'

import { RouteBuilder } from '@/domain/builders/RouteBuilder'
import { UseCase } from '@/domain/core/UseCase'
import { Route } from '@/domain/models/Route/Route'
import { Waypoint } from '@/domain/models/Waypoint'
import { Result } from '@/domain/protocols/Result'
import { MapState } from '@/domain/states/MapState'
import { RouteState } from '@/domain/states/RouteState'

import type { ILogger } from '@/infra/logger/ILogger'

import { ClearRouteUseCase } from './clearRoute'

class DeleteWaypointFromRouteUseCase implements UseCase<Waypoint, Result<void>> {
  routeState: RouteState
  logger: ILogger

  constructor() {
    const injectionContainer = InversionContainer.getInstance().getContainer()
    this.routeState = injectionContainer.get<RouteState>(InjectionTokens.RouteState)
    this.logger = injectionContainer.get<ILogger>(InjectionTokens.Logger)
  }

  execute(request: Waypoint): Result<void> {
    try {
      const routeStateSnapshot = this.routeState.getStateSnapshot()

      let route: Route = null

      if (!routeStateSnapshot.activeRoute) {
        return Result.fail('There is no active route')
      }

      if (routeStateSnapshot.activeRoute?.waypoints?.length === 1) {
        return new ClearRouteUseCase().execute()
      }

      const routeBuilder = new RouteBuilder().copyFromRoute(routeStateSnapshot.activeRoute)
      routeBuilder.removeWaypoint(request)

      this.routeState.setActiveRoute(routeBuilder.build())

      return Result.ok()
    } catch (error) {
      this.logger.error('DeleteWaypointFromRouteUseCase', String(error))
      return Result.fail(error)
    }
  }
}

export { DeleteWaypointFromRouteUseCase }
