import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  gap: 16px;
`

export const Label = styled.label`
  ${({ theme }) => theme.fonts.labelMD};
`

export const ChartSelector = styled.div`
  display: grid;
  gap: 16px;

  grid-template-columns: repeat(5, 1fr);
`

interface ChartSelectorItemProps {
  disabled: boolean
  isSelected: boolean
}

export const ChartSelectorItem = styled.button<ChartSelectorItemProps>`
  ${({ theme }) => theme.fonts.titleXS};

  outline: none;
  background: transparent;

  cursor: pointer;

  border-radius: 40px;

  display: ${({ disabled }) => (disabled ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;

  padding: 8px 0px;

  background: ${({ isSelected, theme }) => (isSelected ? theme.colors.primary : 'transparent')};
  border: ${({ isSelected, theme }) => (isSelected ? 'none' : `1px solid ${theme.colors.secondaryL1}`)};

  &:hover {
    background: ${({ theme, disabled, isSelected }) =>
      disabled ? null : isSelected ? theme.colors.primary : theme.colors.secondaryL1};
  }
`
