export interface PureEntityProps {
  [key: string]: any
}

export class PureEntity<T extends PureEntityProps, P extends PureEntityProps | T = T> {
  public readonly props: T

  public constructor(props: T) {
    this.props = props
  }

  public toPojo(): P {
    return this.props as P
  }
}
