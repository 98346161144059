import { RefObject, useEffect } from 'react'

import { Assets, MenuGroup, MenuItem } from '@nexds/web'
import { useTheme } from 'styled-components'

import { Waypoint } from '@/domain/models'
import { Coordinates } from '@/domain/protocols/Coordinates'

import { useBehaviorSubject } from '@/presentation/hooks/useBehaviorSubject'
import { MapTargetStateActions } from '@/presentation/modules/MapScreen/states/MapTargetState'
import {
  ScreenElementsState,
  ScreenElementsStateProps
} from '@/presentation/modules/MapScreen/states/ScreenElementsState'

import { MapImperativeInterface } from '../../../MapScreen/interfaces/MapImperativeInterface'
import {
  ActionButton,
  ActionButtonsWrapper,
  ListContainer,
  ResultWrapper,
  SearchListContainer
} from './SearchList.styles'

interface SearchListProps {
  waypoints: Waypoint[]
  maxHeight: number
  onWaypointClick: (waypoint: Waypoint) => void
  onLocateWaypointClick: (coordinates: Coordinates) => void
  currentSearchResult?: number
  searchResults?: Waypoint[]
  resultsRef: RefObject<HTMLDivElement>
}

function SearchList(props: SearchListProps) {
  const { waypoints, maxHeight, onWaypointClick, onLocateWaypointClick } = props
  const theme = useTheme()

  const screenElementsState = useBehaviorSubject<ScreenElementsStateProps>(ScreenElementsState)

  const handleShowInfo = (infoCode: string) => {
    window.open(`https://aisweb.decea.gov.br/?i=aerodromos&codigo=${infoCode}`)
  }

  return (
    <SearchListContainer>
      <MenuGroup isLightStyle>
        <ListContainer maxHeight={maxHeight} ref={props.resultsRef}>
          {waypoints &&
            waypoints.map((waypoint) => (
              <ResultWrapper
                key={waypoint.id.toString()}
                isSelected={props.searchResults && waypoint.id === props.searchResults[props.currentSearchResult]?.id}
              >
                <MenuItem
                  size="sm"
                  isLightStyle
                  title={waypoint.getDisplayName()}
                  description={waypoint.extra?.isAddress ? undefined : waypoint.getTypeName()}
                  onClick={() => onWaypointClick(waypoint)}
                />
                <ActionButtonsWrapper>
                  {waypoint.waypointType?.codeName === 'ADHP' && (
                    <ActionButton onClick={() => (waypoint.code ? handleShowInfo(waypoint.code) : undefined)}>
                      <Assets.Info color={theme.colors.secondaryL2} size={'sm'} />
                    </ActionButton>
                  )}
                  <ActionButton onClick={() => onLocateWaypointClick?.(waypoint.coordinates)}>
                    <Assets.CenterPoint color={theme.colors.secondaryL2} size={'sm'} />
                  </ActionButton>
                </ActionButtonsWrapper>
              </ResultWrapper>
            ))}
        </ListContainer>
      </MenuGroup>
    </SearchListContainer>
  )
}

export { SearchList }
