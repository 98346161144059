import GeoJSON from 'geojson'

import { ADHP, Waypoint } from '@/domain/models'

import { limitString } from '@/utils/string'

import { MAP_SYMBOL_PRIORITIES } from '../MapRoute/GeojsonUtils'

export const makeGeoJsonADHPFeatureCollection =
  (waypoints: Array<ADHP | Waypoint>) => (): GeoJSON.FeatureCollection => {
    const adhpToFeature = (waypoint: ADHP | Waypoint): GeoJSON.Feature => {
      try {
        if (waypoint instanceof ADHP) {
          const isMilitary: boolean = waypoint.rotaer?.typeUtil === 'MIL'
          const hasATS: boolean = waypoint.rotaer?.communications.some(
            (com) => com.type === 'Torre' || com.type === 'Rádio' || com.type === 'Tráfego'
          )
          const longestRunwayLength: number = waypoint.rotaer?.runways.reduce(
            (acc, runway) => (runway.length > acc ? runway.length : acc),
            0
          )
          const hasPavedRunway: boolean = waypoint.rotaer?.runways.some(
            (runway) => runway.surface === 'CONC' || runway.surface === 'ASPH'
          )
          const hasNightOperation: boolean = waypoint.rotaer?.typeOpr.includes('DIURNA') === false
          const hasIFR: boolean = waypoint.rotaer?.typeOpr.includes('IFR')

          return {
            type: 'Feature',
            id: waypoint.id.toValue().toString(),
            properties: {
              id: waypoint.id.toValue().toString(),
              code: limitString(waypoint.getDisplayName(), 16),
              priority: MAP_SYMBOL_PRIORITIES.MAP_ADHP + (waypoint.type === 'AD' ? 1 / longestRunwayLength : 0),
              nameLength: waypoint.getDisplayName().length > 16 ? 16 : waypoint.getDisplayName().length,
              type: waypoint.type,
              military: isMilitary,
              ifr: hasIFR,
              ats: hasATS,
              runwayLength: waypoint.type === 'AD' ? longestRunwayLength : 0,
              pavedRunway: hasPavedRunway,
              nightOperation: hasNightOperation
            },
            geometry: {
              type: 'Point',
              coordinates: waypoint.coordinates.toArray()
            }
          }
        } else {
          const coordinatesRegex = new RegExp(/(\d{6}[NS])\s(\d{7}[WE])/)

          return {
            type: 'Feature',
            id: waypoint.id.toValue().toString(),
            properties: {
              id: waypoint.id.toValue().toString(),
              code: coordinatesRegex.test(waypoint.getDisplayName())
                ? waypoint.getDisplayName()
                : limitString(waypoint.getDisplayName(), 12),
              priority: MAP_SYMBOL_PRIORITIES.MAP_ADHP,
              nameLength: waypoint.getDisplayName().length > 16 ? 16 : waypoint.getDisplayName().length,
              type: 'user-waypoint',
              icon: 'user-waypoint',
              focusedIcon: 'focused-user-waypoint'
            },
            geometry: {
              type: 'Point',
              coordinates: waypoint.coordinates.toArray()
            }
          }
        }
      } catch (error) {
        console.error('MapADHP GeojsonUtils', error)
        // TODO: Log exception
        return null
      }
    }

    const pointFeatureArray: GeoJSON.Feature[] = waypoints.map(adhpToFeature)

    pointFeatureArray.sort((a, b) => {
      if (a.properties.type === 'AD' && b.properties.type === 'AD') {
        return a.properties.runwayLength - b.properties.runwayLength
      }

      return 0
    })

    return {
      type: 'FeatureCollection',
      features: pointFeatureArray
    }
  }
