import { distanceCoordToSegment, nmDistance } from '@/utils/coordinates'

import { UniqueEntityID } from '../core'
import { Route } from '../models/Route/Route'
import { Waypoint } from '../models/Waypoint'

class RouteBuilder {
  id: UniqueEntityID
  name: string
  updatedAt: Date
  groundSpeed?: number
  waypoints: Waypoint[] = []

  setId(id: UniqueEntityID) {
    this.id = id
    return this
  }

  setName(name: string) {
    this.name = name
    return this
  }

  setUpdatedAt(updatedAt: Date) {
    this.updatedAt = updatedAt
    return this
  }

  setGroundSpeed(groundSpeed: number) {
    this.groundSpeed = groundSpeed
    return this
  }

  setWaypoints(waypoints: Waypoint[]) {
    this.waypoints = waypoints
    return this
  }

  addWaypoint(waypoint: Waypoint, doNotClone: boolean = false) {
    this.waypoints.push(doNotClone ? waypoint : waypoint.clone())
    return this
  }

  addWaypointOnPosition(waypoint: Waypoint, position: number, doNotClone: boolean = false) {
    this.waypoints.splice(position, 0, doNotClone ? waypoint : waypoint.clone())
    return this
  }

  addSmartWaypoint(waypoint: Waypoint, doNotClone: boolean = false) {
    if (this.waypoints.length < 2) {
      this.waypoints.push(doNotClone ? waypoint : waypoint.clone())
    }

    let distanceMap: Array<[number, number]> = []
    for (let i = 0; i < this.waypoints.length - 1; i++) {
      distanceMap.push([
        i,
        distanceCoordToSegment(waypoint.coordinates, this.waypoints[i].coordinates, this.waypoints[i + 1].coordinates)
      ])
    }

    distanceMap = distanceMap.sort((a, b) => a[1] - b[1])
    const insertIndex = distanceMap[0][0] + 1

    this.addWaypointOnPosition(waypoint, insertIndex)

    return insertIndex
  }

  overrideWaypoint(waypoint: Waypoint, index: number) {
    this.waypoints[index] = waypoint.clone()
    return this
  }

  removeWaypoint(waypoint: Waypoint) {
    const newArray: Waypoint[] = []
    let removeIndex: number = -1

    for (let i = 0; i < this.waypoints.length; i++) {
      const item = this.waypoints[i]

      // Race condition. Sometimes the waypoint is deleted while the route is being built
      // @see https://sentry.nexatlas.com/organizations/nexatlas/issues/2462/
      if (!item) continue

      if (!item.id.equals(waypoint.id)) {
        newArray.push(item)
      } else {
        removeIndex = i
      }
    }

    this.waypoints = newArray

    return removeIndex
  }

  copyFromRoute(route: Route) {
    this.id = new UniqueEntityID(route.id.toString())
    this.name = route.name
    this.updatedAt = route.updatedAt
    this.groundSpeed = route.groundSpeed
    this.waypoints = route.waypoints ? [...route.waypoints] : []
    return this
  }

  private createAutomaticRouteName() {
    if (this.waypoints.length === 1) return this.waypoints[0].getDisplayName()

    return `${this.waypoints[0].getDisplayName()} - ${this.waypoints[this.waypoints.length - 1].getDisplayName()}`
  }

  private calculateRouteDistance() {
    let totalDistance = 0

    for (let i = 1; i < this.waypoints.length; i++) {
      totalDistance += nmDistance(this.waypoints[i - 1].coordinates, this.waypoints[i].coordinates)
    }

    return totalDistance
  }

  build() {
    let name = null
    let totalDistance = null

    if (!this.name) name = this.createAutomaticRouteName()
    else if (this.waypoints.length === 2 && this.name === this.waypoints[0].getDisplayName())
      name = this.createAutomaticRouteName()
    else name = this.name

    if (this.waypoints.length >= 2) {
      totalDistance = this.calculateRouteDistance()
    }

    return Route.create(
      {
        name,
        totalDistance,
        updatedAt: this.updatedAt || new Date(),
        groundSpeed: this.groundSpeed,
        waypoints: this.waypoints
      },
      this.id
    )
  }
}

export { RouteBuilder }
