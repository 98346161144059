import { inject, injectable } from 'inversify'
import { Subscription } from 'rxjs'

import { InjectionTokens } from '@/controller/tokens'

import { Result } from '@/domain/protocols/Result'
import { AuthState } from '@/domain/states/AuthState'

import type { ILogger } from '@/infra/logger/ILogger'

import type { IMetricsService } from '../Metrics/IMetricsService'
import { ITrackingService, TrackingStatus } from './ITrackingService'
@injectable()
export class TrackingService implements ITrackingService {
  public trackingStatus: TrackingStatus = 'authorized'

  private _metricsAuthSubscription: Subscription = null

  constructor(
    @inject(InjectionTokens.AuthState) private authState: AuthState,
    @inject(InjectionTokens.MetricsService) private metricsService: IMetricsService,
    @inject(InjectionTokens.Logger) private logger: ILogger
  ) {}

  async init(): Promise<Result<void>> {
    console.log('[TrackingService] Initializing tracking service')
    // this.trackingStatus = await requestTrackingPermission()
    return Result.ok()
  }

  async getTrackingStatus(): Promise<TrackingStatus> {
    // const trackingStatus = await getTrackingStatus()

    return this.trackingStatus
  }

  start(): Result<void> {
    // Start Tracking services
    this._startTrackingServices()

    return Result.ok()
  }

  private _startTrackingServices = () => {
    if (this.trackingStatus === 'not-determined') {
      this.logger.error('TrackingService', 'Tracking is not determined, trying again in 10 seconds')
      this.getTrackingStatus()
      setTimeout(() => this._startTrackingServices(), 10000)
      return Result.fail('Tracking is not determined')
    }

    if (this.trackingStatus === 'denied' || this.trackingStatus === 'restricted') {
      this.logger.debug('TrackingService', `Tracking status is ${this.trackingStatus}, ending`)
      return Result.fail('Tracking is not allowed')
    }

    this.logger.debug('TrackingService', `Tracking status is ${this.trackingStatus}, starting service`)

    this.metricsService.setMetricsRejected(false)

    this._startMetricsIdentify()
    this.metricsService.sendMetric('APP_ACCESS')
  }

  stop(): Result<void> {
    this.logger.debug('TrackingService', 'Stopping service')

    this._stopMetricsIdentify()

    return Result.ok()
  }

  protected _startMetricsIdentify = () => {
    this._metricsAuthSubscription = this.authState.getState().subscribe((auth) => {
      if (!auth.user) {
        this.metricsService.setUserId(null)
        this.metricsService.setUserProperties(null)
      } else {
        const userProperties = {
          aircraft_type: auth.user?.profile?.aircraftType,
          aviation_segment: auth.user?.profile?.aviationSegment,
          complete: auth.user?.profile?.complete,
          complete_on: auth.user?.profile?.completeOn,
          created_at: auth.user?.createdAt,
          creation_date: auth.user?.profile?.creationDate,
          email: auth.user?.email,
          name: String(auth.user?.profile?.name),
          phone_ddd: auth.user?.profile?.phoneDDD,
          phone_number: auth.user?.profile?.phoneNumber,
          pilot: auth.user?.profile?.pilot,
          pilot_professional: auth.user?.profile?.pilotProfessional,
          plan_id: auth.user?.planId,
          segment: auth.user?.profile?.aviationSegment || auth.user?.profile?.selfClassification,
          subscription_created: auth.user?.subscriptionCreated,
          subscription_method: auth.user?.subscriptionMethod,
          subscription_status: auth.user?.subscriptionStatus,
          subscription_updated: auth.user?.subscriptionUpdated,
          surname: String(auth.user?.profile?.surname),
          trial_checked: auth.user?.trialChecked,
          trial_to: auth.user?.trialTo,
          type: auth.user?.type
        }

        this.metricsService.setUserId(auth.user.id)
        this.metricsService.setUserProperties(userProperties)
      }
    })
  }

  protected _stopMetricsIdentify = () => {
    if (this._metricsAuthSubscription) {
      this._metricsAuthSubscription.unsubscribe()
    }
  }
}
