import { InversionContainer } from '@/controller/container'
import { InjectionTokens } from '@/controller/tokens'

import { UseCase } from '@/domain/core/UseCase'
import { Waypoint } from '@/domain/models/Waypoint'
import { Result } from '@/domain/protocols/Result'
import { RouteState } from '@/domain/states/RouteState'

import type { ILogger } from '@/infra/logger/ILogger'

class CopyRouteToFPLBR implements UseCase<Waypoint, Result<string>> {
  private routeState: RouteState
  private logger: ILogger

  constructor() {
    const injectionContainer = InversionContainer.getInstance().getContainer()
    this.routeState = injectionContainer.get<RouteState>(InjectionTokens.RouteState)
    this.logger = injectionContainer.get<ILogger>(InjectionTokens.Logger)
  }

  async execute(): Promise<Result<string>> {
    try {
      const routeStateSnapshot = this.routeState.getStateSnapshot()
      const routeWaypoints = routeStateSnapshot.activeRoute?.waypoints
      const routeWaypointsArray: string[] = []
      routeWaypoints?.forEach((waypoint, index) => {
        if (index === 0) {
          routeWaypointsArray.push('DCT')
        } else if (index === routeWaypoints.length - 1) {
          if (routeWaypointsArray[routeWaypointsArray.length - 1] !== 'DCT') {
            routeWaypointsArray.push('DCT')
          }
        } else {
          routeWaypointsArray.push(waypoint.getFlightPlanName().name)
          if (
            waypoint.getFlightPlanName().isCoordinate === false ||
            routeWaypoints[index + 1].getFlightPlanName().isCoordinate === false
          ) {
            routeWaypointsArray.push('DCT')
          }
        }
      })

      const routeString = routeWaypointsArray.join(' ')
      await navigator.clipboard.writeText(routeString)

      return Result.ok(routeString)
    } catch (error) {
      this.logger.error('CopyRouteToFPLBR', String(error))
      return Result.fail(error)
    }
  }
}

export { CopyRouteToFPLBR }
