import { BehaviorSubject } from 'rxjs'

import { Coordinates } from '@/domain/protocols/Coordinates'

export interface MeasuringSegmentStateProps {
  active: boolean
  measuring: boolean
  start: Coordinates
  end: Coordinates
}

const INITIAL_STATE: MeasuringSegmentStateProps = {
  active: false,
  measuring: false,
  start: null,
  end: null
}

export const MeasuringSegmentState = new BehaviorSubject<MeasuringSegmentStateProps>(INITIAL_STATE)

export const MeasuringSegmentStateMutator = {
  mutate: (newState: MeasuringSegmentStateProps) => {
    MeasuringSegmentState.next(newState)
  },

  clear: () => {
    MeasuringSegmentState.next(INITIAL_STATE)
  },

  setMeasuring: (measuring: boolean) => {
    MeasuringSegmentState.next({
      ...MeasuringSegmentState.getValue(),
      measuring
    })
  },

  setActive: (active: boolean) => {
    MeasuringSegmentState.next({
      ...MeasuringSegmentState.getValue(),
      active
    })
  },

  setStart: (start: Coordinates) => {
    MeasuringSegmentState.next({
      ...MeasuringSegmentState.getValue(),
      start
    })
  },

  setEnd: (end: Coordinates) => {
    MeasuringSegmentState.next({
      ...MeasuringSegmentState.getValue(),
      end
    })
  }
}
