import { InversionContainer } from '@/controller/container'
import { InjectionTokens } from '@/controller/tokens'

import { UseCase } from '@/domain/core/UseCase'
import { Route } from '@/domain/models/Route/Route'
import { Result } from '@/domain/protocols/Result'
import { RouteState } from '@/domain/states/RouteState'

interface SetActiveRouteParams {
  activeRoute: Route
  isSaved: boolean
}

class SetActiveRouteUseCase implements UseCase<SetActiveRouteParams, Result<void>> {
  private routeState: RouteState

  constructor() {
    const injectionContainer = InversionContainer.getInstance().getContainer()
    this.routeState = injectionContainer.get<RouteState>(InjectionTokens.RouteState)
  }

  execute(request: SetActiveRouteParams): Result<void> {
    try {
      this.routeState.updateState({
        activeRoute: request.activeRoute,
        isSaved: request.isSaved
      })

      return Result.ok()
    } catch (error) {
      return Result.fail(error)
    }
  }
}

export { SetActiveRouteUseCase }
