import { PureEntity } from '../core/PureEntity'

export interface CoordinatesProps {
  latitude: number
  longitude: number
}

export class Coordinates extends PureEntity<CoordinatesProps> {
  public static create(props: CoordinatesProps): Coordinates {
    if (props.latitude < -90 || props.latitude > 90) {
      return new Coordinates({ latitude: 0, longitude: 0 })
    }

    if (props.longitude < -180 || props.longitude > 180) {
      return new Coordinates({ latitude: 0, longitude: 0 })
    }

    return new Coordinates(props)
  }

  public static createFromArray(array: number[]) {
    return Coordinates.create({ longitude: array[0], latitude: array[1] })
  }

  public toArray(): number[] {
    return [this.longitude, this.latitude]
  }

  public static fromPojo(pojo: CoordinatesProps): Coordinates {
    return new Coordinates(pojo)
  }

  public static fromArrayPojo(pojos: CoordinatesProps[]): Coordinates[] {
    return pojos.map((pojo) => new Coordinates(pojo))
  }

  public get latitude(): number {
    return this.props.latitude
  }

  public set latitude(latitude: number) {
    if (latitude < -90 || latitude > 90) {
      return
    }

    this.props.latitude = latitude
  }

  public get longitude(): number {
    return this.props.longitude
  }

  public set longitude(longitude: number) {
    if (longitude < -180 || longitude > 180) {
      return
    }

    this.props.longitude = longitude
  }
}
