import { Entity, UniqueEntityID } from '@domain/core'

export type WaypointTypeCode = 'ADHP' | 'AwWaypoint' | 'City' | 'CoordWaypoint' | 'Navaid' | 'VisualPosition'

export interface WaypointTypeProps {
  name: string
  codeName: WaypointTypeCode
}

export interface WaypointTypePojo {
  id: string
  name: string
  codeName: WaypointTypeCode
}

export class WaypointType extends Entity<WaypointTypeProps, WaypointTypePojo> {
  public static create(props: WaypointTypeProps, id?: UniqueEntityID): WaypointType {
    return new WaypointType(props, id)
  }

  public get name(): string {
    return this.props.name
  }

  public set name(name: string) {
    this.props.name = name
  }

  public get codeName(): WaypointTypeCode {
    return this.props.codeName
  }

  public set codeName(codeName: WaypointTypeCode) {
    this.props.codeName = codeName
  }

  public static fromPojo(pojo: WaypointTypePojo): WaypointType {
    return new WaypointType(
      {
        name: pojo.name,
        codeName: pojo.codeName
      },
      new UniqueEntityID(pojo.id)
    )
  }
}
