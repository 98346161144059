import { InversionContainer } from '@/controller/container'
import { InjectionTokens } from '@/controller/tokens'

import { UseCase } from '@/domain/core/UseCase'
import { Result } from '@/domain/protocols/Result'
import { RouteState } from '@/domain/states/RouteState'

import type { ILogger } from '@/infra/logger/ILogger'

class ClearRouteUseCase implements UseCase<void, Result<void>> {
  private routeState: RouteState
  private logger: ILogger

  constructor() {
    const injectionContainer = InversionContainer.getInstance().getContainer()
    this.routeState = injectionContainer.get<RouteState>(InjectionTokens.RouteState)
    this.logger = injectionContainer.get<ILogger>(InjectionTokens.Logger)
  }

  execute(): Result<void> {
    try {
      const routeStateSnapshot = this.routeState.getStateSnapshot()

      if (!routeStateSnapshot.activeRoute) {
        return Result.fail('There is no active route')
      }

      this.routeState.resetState()

      return Result.ok()
    } catch (error) {
      this.logger.error('ClearRouteUseCase', String(error))
      return Result.fail(error)
    }
  }
}

export { ClearRouteUseCase }
