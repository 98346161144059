import { BehaviorSubject } from 'rxjs'

import { Coordinates } from '@/domain/protocols/Coordinates'

export interface MapTargetStateProps {
  coordinates: Coordinates | null
}

const INITIAL_STATE: MapTargetStateProps = {
  coordinates: null
}

export const MapTargetState = new BehaviorSubject<MapTargetStateProps>(INITIAL_STATE)

export const MapTargetStateActions = {
  setTarget: (coordinates: Coordinates, timeout?: number) => {
    MapTargetState.next({
      coordinates
    })

    setTimeout(() => {
      MapTargetState.next(INITIAL_STATE)
    }, timeout || 5000)
  }
}
