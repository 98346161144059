import { I18n } from '@/i18n/I18n'
import { TranslationTokens, VarsByToken } from '@/i18n/ITranslations'

import { removeDiacritics } from './normalize'

/**
 * Format bytes into human readable string.
 *
 * @param bytes total of Bytes
 * @returns format Bytes to a string with properly size
 */
export const formatBytes = (bytes: number): string => {
  if (!bytes && bytes !== 0) return null
  if (bytes < 1024) return `${bytes}B`
  else if (bytes < 1048576) return (bytes / 1024).toFixed(1) + 'KB'
  else if (bytes < 1073741824) return (bytes / 1048576).toFixed(1) + 'MB'
  return (bytes / 1073741824).toFixed(1) + 'GB'
}

export const formatTime = (
  seconds: number,
  t: <T extends TranslationTokens>(...args: VarsByToken[T] extends never ? [T] : [T, VarsByToken[T]]) => string
) => {
  if (seconds >= 60) {
    return `${Math.floor(seconds / 60)} ${
      Math.floor(seconds / 60) > 1 ? t('TIME-UNITS_MINUTES') : t('TIME-UNITS_MINUTE')
    }`
  }
  return `${Math.floor(seconds)} ${Math.floor(seconds) > 1 ? t('TIME-UNITS_SECONDS') : t('TIME-UNITS_SECOND')}`
}

export function removeAccents(str: string) {
  return str?.normalize?.('NFD')?.replace?.(/[\u0300-\u036f]/g, '') ?? ''
}

export function formatPhoneNumber(numero: string) {
  if (!numero) return ''
  const length = numero.length

  if (length === 10) {
    return `(${numero.substring(0, 2)}) ${numero.substring(2, 6)}-${numero.substring(6, 10)}`
  } else {
    return `(${numero.substring(0, 2)}) ${numero.substring(2, 7)}-${numero.substring(7, 11)}`
  }
}

export const phoneMask = (phone: string): string => {
  if (phone.length > 15) phone = phone.substring(0, 15)
  phone = phone.replace(/\D/g, '') // Remove tudo o que não é dígito
  phone = phone.replace(/^(\d{2})(\d)/g, '($1) $2') // Coloca parênteses em volta dos dois primeiros dígitos
  phone = phone.replace(/(\d)(\d{4})$/, '$1-$2') // Coloca hífen entre o quarto e o quinto dígitos
  return phone
}

// Limit a string to a certain length and add an ellipsis if it's longer
export const limitString = (str: string, limit: number): string => {
  if (str.length > limit) {
    return str.substring(0, limit) + '...'
  }

  return str
}

export function normalizeStringSearch(string: string) {
  return removeDiacritics(string)
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
}

export const normalizeForSearch = (str: string) => {
  return removeDiacritics(str)
    .replace(/[\u0300-\u036f]/g, '')
    .toUpperCase()
}

export function numberToFixedLength(number: string | number, len: number, character: string = ' ') {
  let text = number.toString().substring(0, len)

  while (text.length < len) {
    text = `${character}${text}`
  }

  return text
}
