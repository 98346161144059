import { useEffect, useMemo, useRef } from 'react'

import CompleteLogoNexatlas from '@assets/complete-logo-nexatlas-dark-text.svg'
import { ProgressBar } from '@nexds/web'

import { useBehaviorSubject } from '@/presentation/hooks/useBehaviorSubject'
import {
  SplashScreenInfoState,
  SplashScreenInfoStateProps,
  taskMessages,
  taskSize
} from '@/presentation/modules/Base/components/SplashScreen/SplashScreenInfoState'

import { SplashContainer, Logo, Messages, ProgressContainer } from './SplashScreen.styles'

function SplashScreen() {
  const splashScreenInfoState = useBehaviorSubject<SplashScreenInfoStateProps>(SplashScreenInfoState)
  const messageBoxRef = useRef<HTMLDivElement>(null)

  const orderedTasks = useMemo(() => {
    const reversedTasks = splashScreenInfoState.tasks.slice().reverse()

    return reversedTasks.sort((a, b) => {
      if (!a.done && b.done) {
        return -1
      } else if (a.done && !b.done) {
        return 1
      }

      return 0
    })
  }, [splashScreenInfoState.tasks])

  useEffect(() => {
    if (messageBoxRef.current) {
      messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight
    }
  }, [splashScreenInfoState.tasks])

  const completedTasks = useMemo(() => {
    return splashScreenInfoState.tasks.filter((task) => task.done).length
  }, [splashScreenInfoState.tasks])

  const currentTask = useMemo(() => {
    const task = orderedTasks.find((task) => !task.done)
    if (!task) {
      return orderedTasks[0]
    }
    return task
  }, [orderedTasks])

  return (
    <SplashContainer>
      <figure>
        <Logo src={CompleteLogoNexatlas} />
      </figure>
      <ProgressContainer>
        <ProgressBar value={completedTasks} max={taskSize} size="md" />
        <Messages ref={messageBoxRef}>{currentTask ? taskMessages[currentTask.taskId] : ''}</Messages>
      </ProgressContainer>
    </SplashContainer>
  )
}

export { SplashScreen }
