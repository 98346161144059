import { Authentication, Flag, PaymentMethodType, Plan, Profile, StatusProps, User } from '@/domain/protocols/User'

type BooleanMapperType = 0 | 1

export const EmptyResponseMapper = (data: any) => {
  if (data === '') return null
  return data
}

export const BooleanResponseMapper = (number: BooleanMapperType): boolean => {
  return number === 1
}

export const DateResponseMapper = (date: string): Date => {
  if (!date) return null
  if (date === '0000-00-00 00:00:00') return null
  return new Date(date)
}

export const StatusPropsMapper = (status: string): StatusProps => {
  const statusProps: StatusProps[] = ['', 'canceled', 'ended', 'paid', 'pending_payment', 'trial', 'unpaid']
  if (statusProps.includes(status as StatusProps)) return status as StatusProps
  return null
}

export const PaymentMethodTypeMapper = (status: string): PaymentMethodType => {
  const paymentMethodType: PaymentMethodType[] = ['boleto', 'credit_card']
  if (paymentMethodType.includes(status as PaymentMethodType)) return status as PaymentMethodType
  return null
}

export const UserResponseMapper = (user: UserRemote): User => {
  if (!user) return null
  return {
    id: user.id,
    createdAt: DateResponseMapper(user.created_at),
    updateOn: DateResponseMapper(user.updated_on),
    email: user.email,
    password: user.password,
    type: user.type,
    salt: user.salt,
    readTerms: BooleanResponseMapper(user.read_terms),
    active: BooleanResponseMapper(user.active),
    activeOn: DateResponseMapper(user.active_on),
    emailConfirmationToken: user.email_confirmation_token,
    confirmedEmail: BooleanResponseMapper(user.confirmed_email),
    confirmedEmailOn: DateResponseMapper(user.confirmed_email_on),
    facebookConnected: BooleanResponseMapper(user.facebook_connected),
    googleConnected: BooleanResponseMapper(user.google_connected),
    duplicate: BooleanResponseMapper(user.duplicate),
    creationDate: DateResponseMapper(user.creation_date),
    trialTo: DateResponseMapper(user.trial_to),
    trialChecked: BooleanResponseMapper(user.trial_checked),
    managerId: EmptyResponseMapper(user.manager_id),
    planId: EmptyResponseMapper(user.plan_id),
    subscriptionId: EmptyResponseMapper(user.subscription_id),
    subscriptionChecked: BooleanResponseMapper(user.subscription_checked),
    subscriptionCreated: DateResponseMapper(user.subscription_created),
    subscriptionUpdated: DateResponseMapper(user.subscription_updated),
    subscriptionStatus: StatusPropsMapper(user.subscription_status),
    subscriptionMethod: PaymentMethodTypeMapper(user.subscription_method),
    subscriptionCardId: EmptyResponseMapper(user.subscription_card_id),
    subscriptionCardLast: EmptyResponseMapper(user.subscription_card_last),
    subscriptionBoletoUrl: EmptyResponseMapper(user.subscription_boleto_url),
    subscriptionPeriodStart: DateResponseMapper(user.subscription_period_start),
    subscriptionPeriodEnd: DateResponseMapper(user.subscription_period_end),
    subscriptionCanceledReason: EmptyResponseMapper(user.subscription_canceled_reason),
    subscriptionCanceledText: EmptyResponseMapper(user.subscription_canceled_text),
    subscriptionSpecialPlan: EmptyResponseMapper(user.subscription_special_plan),
    subscriptionSpecialDependants: EmptyResponseMapper(user.subscription_special_dependants),
    subscriptionSpecialScreens: EmptyResponseMapper(user.subscription_special_screens),
    authentications: user.authentications?.map(AuthenticationResponseMapper),
    flags: user.flags?.map(FlagResponseMapper),
    plan: PlanResponseMapper(user.plan),
    manager: UserResponseMapper(user.manager),
    dependants: user.dependants?.map(UserResponseMapper),
    profile: ProfileResponseMapper(user.profile)
  }
}

const ProfileResponseMapper = (profile: ProfileRemote): Profile => {
  if (!profile) return null
  return {
    id: profile.id,
    userId: profile.user_id,
    name: String(profile.name),
    surname: String(profile.surname),
    complete: BooleanResponseMapper(profile.complete),
    completeOn: DateResponseMapper(profile.complete_on),
    pilot: BooleanResponseMapper(profile.pilot),
    pilotProfessional: BooleanResponseMapper(profile.pilot_professional),
    aircraftType: EmptyResponseMapper(profile.aircraft_type),
    aviationSegment: profile.aviation_segment,
    selfClassification: profile.self_classification,
    picture: EmptyResponseMapper(profile.picture),
    creationDate: DateResponseMapper(profile.creation_date),
    cpf: EmptyResponseMapper(profile.cpf) !== null ? String(profile.cpf) : null,
    neighborhood: EmptyResponseMapper(profile.neighborhood),
    address: EmptyResponseMapper(profile.address),
    streetNumber: profile.street_number,
    complementary: profile.complementary,
    zipcode: profile.zipcode,
    phoneDDD: String(profile.phone_ddd),
    phoneNumber: String(profile.phone_number),
    bornAt: DateResponseMapper(profile.born_at)
  }
}

const PlanResponseMapper = (plan: PlanRemote): Plan => {
  if (!plan) return null
  return {
    id: plan.id,
    text: plan.text,
    price: plan.price,
    months: plan.months,
    numCobrancas: EmptyResponseMapper(plan.num_cobrancas),
    sequence: plan.sequence,
    dependants: plan.dependants,
    screens: plan.screens,
    active: BooleanResponseMapper(plan.active)
  }
}

const FlagResponseMapper = (flag: FlagRemote): Flag => {
  if (!flag) return null
  return {
    id: flag.id,
    userId: flag.user_id,
    flag: flag.flag,
    value: flag.value
  }
}

const AuthenticationResponseMapper = (authentication: AuthenticationRemote): Authentication => {
  if (!authentication) return null
  return {
    id: authentication.id,
    userId: authentication.user_id,
    creationDate: DateResponseMapper(authentication.creation_date),
    validTo: DateResponseMapper(authentication.valid_to),
    mode: EmptyResponseMapper(authentication.mode),
    dead: BooleanResponseMapper(authentication.dead),
    deadOn: DateResponseMapper(authentication.dead_on),
    token: authentication.token,
    source: authentication.source,
    device: EmptyResponseMapper(authentication.device)
  }
}

export interface UserRemote {
  id: number
  email: string
  password: string
  salt: string
  active: BooleanMapperType
  email_confirmation_token: string
  confirmed_email: BooleanMapperType
  trial_to: string
  active_on?: string
  confirmed_email_on?: string
  manager_id?: number
  plan_id?: number
  subscription_id?: number
  subscription_created?: string
  subscription_updated?: string
  subscription_status?: string
  subscription_method?: string
  subscription_card_id?: string
  subscription_card_last?: string
  subscription_boleto_url?: string
  subscription_period_start?: string
  subscription_period_end?: string
  subscription_canceled_reason?: string
  subscription_canceled_text?: string
  subscription_special_plan?: number
  subscription_special_dependants?: number
  subscription_special_screens?: number
  created_at: string
  updated_on: string // TODO: verificar o nome
  type: 'PF' | 'PJ'
  read_terms: BooleanMapperType
  facebook_connected: BooleanMapperType
  google_connected: BooleanMapperType
  duplicate: BooleanMapperType
  creation_date: string
  trial_checked: BooleanMapperType
  subscription_checked: BooleanMapperType

  authentications?: AuthenticationRemote[]
  flags?: FlagRemote[]
  dependants?: UserRemote[]
  plan?: PlanRemote
  manager?: UserRemote
  profile?: ProfileRemote
}

interface AuthenticationRemote {
  id: number
  user_id: number
  creation_date: string
  valid_to: string
  mode?: string
  dead?: BooleanMapperType
  dead_on?: string
  token: string
  source: string
  device?: string

  user?: User
}

interface FlagRemote {
  id: number
  user_id: number
  flag: string
  value: string

  user?: User
}

interface ProfileRemote {
  id: number
  user_id: number
  name: string
  surname: string
  complete: BooleanMapperType
  complete_on: string
  pilot: BooleanMapperType
  pilot_professional: BooleanMapperType
  aircraft_type?: string
  aviation_segment: string
  self_classification: string
  picture?: string
  creation_date: string
  cpf?: string
  neighborhood?: string
  address?: string
  street_number: string
  complementary: string
  zipcode: string
  phone_ddd: string
  phone_number: string
  born_at: string
}

interface PlanRemote {
  id: number
  text: string
  price: number
  months: number
  num_cobrancas?: number
  sequence: number
  dependants: number
  screens: number
  active: BooleanMapperType
}
