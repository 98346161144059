import { PropsWithChildren, useEffect, useState } from 'react'

import GeoJSON from 'geojson'
import { GeoJSONSource } from 'mapbox-gl'

import { AnyAirspace } from '@/domain/models/Airspace'

import { useMapContext } from './MapContext'

interface MapGeoJsonSourceProps {
  id: string
  data?: GeoJSON.FeatureCollection | GeoJSON.Feature<GeoJSON.Polygon, AnyAirspace>
}

function MapGeoJsonSource(props: PropsWithChildren<MapGeoJsonSourceProps>) {
  const { id, data } = props

  const [sourceLoaded, setSourceLoaded] = useState(false)
  const { map } = useMapContext()

  // Add source logic
  useEffect(() => {
    if (!map) return
    if (!data) return

    map.addSource(id, {
      type: 'geojson',
      data
    })

    setSourceLoaded(true)

    return () => {
      map.removeSource(id)
    }
  }, [map])

  // Update data logic
  useEffect(() => {
    if (!map) return

    if (!sourceLoaded) return

    if (sourceLoaded) {
      const source = map.getSource(id) as GeoJSONSource

      if (source) {
        if (data) {
          source.setData(data)
        } else {
          source.setData({ type: 'FeatureCollection', features: [] })
        }
      }
    }
  }, [map, sourceLoaded, data])

  if (!sourceLoaded) return null

  return <>{props.children}</>
}

export { MapGeoJsonSource }
