import { Subject } from 'rxjs'

import { RouterPaths } from '@/presentation/router/RouterPathsMapper'

export type DrawerImperativeEvent = {
  type: 'open' | 'close'
  screen?: RouterPaths
}

const DrawerImperativeSubject = new Subject<DrawerImperativeEvent>()

const DrawerImperativeInterface = {
  close: () => {
    DrawerImperativeSubject.next({ type: 'close' })
  },

  open: (screen: RouterPaths) => {
    DrawerImperativeSubject.next({ type: 'open', screen })
  }
}

export { DrawerImperativeInterface, DrawerImperativeSubject }
