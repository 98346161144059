import styled from 'styled-components'

import { getStateColor } from '@/presentation/utils'

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  height: calc(100% - 64px);
`

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;

  gap: 16px;

  height: 40px;

  & > div:nth-child(1) {
    height: 40px;
  }

  & > div label {
    display: none;
  }

  & > div > div:nth-child(3) {
    display: none;
  }

  & > div {
    flex: 1;
  }

  & > button {
    width: 40px;
    height: 40px;
  }

  & > div:nth-child(1) svg path {
    stroke: ${({ theme }) => theme.colors.secondaryL2};
  }
`

export const ControlsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-left: 8px;
`

export const MassImportOrDeleteContainer = styled.div`
  display: flex;
  gap: 4px;
`

export const ActionButton = styled.button`
  border: none;
  box-shadow: 0 0 0 0;
  background-color: transparent;
  border-radius: 6px;
  width: 32px;
  height: 32px;
  cursor: pointer;

  &:disabled {
    opacity: 0.3;
    cursor: default;
    & > svg path {
      stroke: ${({ theme }) => theme.colors.neutralL5};
    }
  }

  &:not(:disabled):hover {
    background-color: ${({ theme }) => getStateColor(theme.colors.secondaryD1, 'hover')};
  }
`

export const ScrollableContainer = styled.div`
  width: calc(100% + 9px);
  padding-right: 9px;
  flex: 1;

  overflow: auto;

  &::-webkit-scrollbar {
    border-radius: 20px;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.secondaryL2};
    border-radius: 20px;
  }
`

export const WaypointsContainer = styled.div`
  overflow: hidden;

  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.secondary};
`

export const MassImportButton = styled.button`
  border: none;
  outline: none;

  background: none;
  cursor: pointer;

  padding: 4px 0;
  margin: 0;

  text-align: start;

  color: ${({ theme }) => theme.colors.primaryL1};
  ${({ theme }) => theme.fonts.infoLG}

  transition: color 200ms;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`

export const MessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  flex-grow: 1;
`

export const Message = styled.p`
  margin: 0;
  padding: 0;

  ${({ theme }) => theme.fonts.infoMD}
`
