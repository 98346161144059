import { UniqueEntityID } from '@domain/core'

import { Coordinates } from '@/domain/protocols/Coordinates'

import { WaypointType } from '../WaypointType'
import { AIPSup, Chart, DayTime, Infotemp, Meteorology, NOTAM, Rotaer, RotaerHTML } from './@types/ADHP'
import { Waypoint, WaypointPojo, WaypointProps } from './Waypoint'

export type ADHPType = 'AD' | 'HP'

export interface ADHPExtra {
  type: ADHPType
  city: string
  uf: string

  rotaer?: Rotaer
  rotaerHTML?: RotaerHTML
  infotemp?: Infotemp[]
  aipsup?: AIPSup[]
  notam?: NOTAM[]
  meteorology?: Meteorology
  daytime?: DayTime
  charts?: Chart[]
}

export class ADHP extends Waypoint {
  public declare extra: ADHPExtra // TODO: fix this

  private constructor(props: WaypointProps, extra: ADHPExtra, id?: UniqueEntityID) {
    super(props, id)
    this.extra = extra
  }

  public static create(props: WaypointProps, extra: ADHPExtra, id?: UniqueEntityID): ADHP {
    props.waypointType = WaypointType.create(
      {
        name: 'Aeródromo ou Heliponto', // t('WAYPOINT_TYPE_ADHP'), // TODO: fix this
        codeName: 'ADHP'
      },
      new UniqueEntityID('adhp')
    )

    return new ADHP(props, extra, id)
  }

  public clone(): ADHP {
    return ADHP.create(this.props, this.extra)
  }

  public cloneWithID(): ADHP {
    return ADHP.create(this.props, this.extra, this.id)
  }

  public getDisplayName(): string {
    return this.props.customName ? this.props.customName : this.props.code ? this.props.code : ''
  }

  public getTypeName(): string {
    return this.extra.type === 'HP' ? `HELPN ${this.props.name}` : this.props.name ? this.props.name : ''
  }

  public get type(): ADHPType {
    return this.extra.type
  }

  public set type(type: ADHPType) {
    this.extra.type = type
  }

  public get city(): string {
    return this.extra.city
  }

  public set city(city: string) {
    this.extra.city = city
  }

  public get uf(): string {
    return this.extra.uf
  }

  public set uf(uf: string) {
    this.extra.uf = uf
  }

  public get rotaer(): Rotaer {
    return this.extra.rotaer
  }

  public set rotaer(rotaer: Rotaer) {
    this.extra.rotaer = rotaer
  }

  public get rotaerHTML(): RotaerHTML {
    return this.extra.rotaerHTML
  }

  public set rotaerHTML(rotaerHTML: RotaerHTML) {
    this.extra.rotaerHTML = rotaerHTML
  }

  public get infotemp(): Infotemp[] {
    return this.extra.infotemp
  }

  public set infotemp(infotemp: Infotemp[]) {
    this.extra.infotemp = infotemp
  }

  public get aipsup(): AIPSup[] {
    return this.extra.aipsup
  }

  public set aipsup(aipsup: AIPSup[]) {
    this.extra.aipsup = aipsup
  }

  public get notam(): NOTAM[] {
    return this.extra.notam
  }

  public set notam(notam: NOTAM[]) {
    this.extra.notam = notam
  }

  public get meteorology(): Meteorology {
    return this.extra.meteorology
  }

  public set meteorology(meteorology: Meteorology) {
    this.extra.meteorology = meteorology
  }

  public get daytime(): DayTime {
    return this.extra.daytime
  }

  public set daytime(daytime: DayTime) {
    this.extra.daytime = daytime
  }

  public get charts(): Chart[] {
    return this.extra.charts
  }

  public set charts(charts: Chart[]) {
    this.extra.charts = charts
  }

  public static fromPojo(pojo: WaypointPojo): ADHP {
    return ADHP.create(
      {
        waypointType: WaypointType.fromPojo(pojo.waypointType),
        coordinates: Coordinates.fromPojo(pojo.coordinates),
        code: pojo.code,
        name: pojo.name,
        customName: pojo.customName,
        createdAt: pojo.createdAt,
        updatedAt: pojo.updatedAt
      },
      {
        ...(pojo.extra as any)
      },
      new UniqueEntityID(pojo.id)
    )
  }
}
