import * as amplitude from '@amplitude/analytics-browser'
import { injectable } from 'inversify'

import { LogLevel } from '../logger/LogLevels'
import { IGenericMetricsProvider, PropertyObject } from './IGenericMetricsProvider'

@injectable()
export class AmplitudeMetricsProvider implements IGenericMetricsProvider {
  identify: amplitude.Identify = new amplitude.Identify()

  constructor() {
    amplitude.init(import.meta.env.VITE_AMPLITUDE_KEY, undefined, {
      minIdLength: 1,
      logLevel: LogLevel.Debug
    })
  }

  sendMetric(key: string, extra: PropertyObject) {
    amplitude.track(key, { ...extra, source: 'planning', platform: 'Web' })
  }

  setUserId(userId: string | number) {
    if (!userId) {
      amplitude.reset()
    } else {
      amplitude.setUserId(String(userId))
    }
  }

  setUserProperties(properties: { [key: string]: any }) {
    if (!properties) return

    for (const key in properties) {
      this.identify.set(key, properties[key])
    }

    amplitude.identify(this.identify)
  }
}
