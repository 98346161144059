import { inject } from 'inversify'

import { InjectionTokens } from '@/controller/tokens'

import { Result } from '@/domain/protocols/Result'

import type { HttpOptions, IHttpClient } from '@/infra/http/IHttpClient'

import { IGenericVolatileRemoteRepository } from './IGenericVolatileRemoteRepository'

export type RemoteUrlMap = Record<string, string>

abstract class GenericVolatileRemoteRepository<T> implements IGenericVolatileRemoteRepository<T> {
  private data: Record<string, T> = {}

  public constructor(@inject(InjectionTokens.HttpClient) private remoteClient: IHttpClient) {}

  protected getRemoteUrl(): RemoteUrlMap {
    return {}
  }

  public async init(): Promise<Result<void>> {
    const remoteUrls = this.getRemoteUrl()

    for (const key in remoteUrls) {
      const response = await this.remoteClient.get<T>(remoteUrls[key])

      if (response.success === true) {
        this.data[key] = response.response
      } else {
        return Result.fail(response.error)
      }
    }

    return Result.ok()
  }

  public getData(key: string): Result<T> {
    if (!this.data || !this.data[key]) {
      return Result.fail('Data not initialized')
    }

    return Result.ok(this.data[key])
  }

  public getAllData(): Result<Record<string, T>> {
    if (!this.data) {
      return Result.fail('Data not initialized')
    }

    return Result.ok(this.data)
  }

  public async getRemoteData<T>(url: string, options?: HttpOptions): Promise<Result<T>> {
    const response = await this.remoteClient.get<T>(url, options)

    if (response.success === true) {
      return Result.ok(response.response)
    }

    return Result.fail(response.error)
  }
}

export { GenericVolatileRemoteRepository }
