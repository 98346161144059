import React, { createContext, useContext } from 'react'

import { Container, interfaces } from 'inversify'

const InjectionContext = createContext<Container>(null)

interface InjectionProviderProps {
  container: Container
  children: React.ReactNode
}

export const InjectionProvider: React.FC<InjectionProviderProps> = (props: InjectionProviderProps) => {
  const { container, children } = props

  return <InjectionContext.Provider value={container}>{children}</InjectionContext.Provider>
}

export function useInjection<T>(identifier: interfaces.ServiceIdentifier<T>) {
  const container = useContext(InjectionContext)

  if (!container) {
    throw new Error('useInjection must be used within an InjectionProvider')
  }

  return container.get<T>(identifier)
}
