/**
 * A simple contained buffer with max length for general use
 */
export class Buffer<T> {
  protected length: number
  protected values: T[]

  constructor(length: number, initialValues?: T[]) {
    this.length = length

    if (!initialValues) {
      this.values = []
    } else if (initialValues.length > length) {
      this.values = initialValues.slice(initialValues.length - length)
    } else {
      this.values = initialValues
    }
  }

  store(value: T) {
    if (this.values.length >= this.length) {
      this.shift()
    }

    this.values.push(value)
  }

  getValues() {
    return this.values
  }

  shift() {
    return this.values.shift()
  }

  pop() {
    return this.values.pop()
  }

  clear() {
    this.values = []
  }
}
