import { useEffect, useMemo, useState } from 'react'

import * as turf from '@turf/turf'

import { Coordinates } from '@/domain/protocols/Coordinates'

import { useBehaviorSubject } from '@/presentation/hooks/useBehaviorSubject'
import { fixHeadingByMagneticDeclination } from '@/utils/coordinates'

import { MapMeasuringSegment } from '../components/MapMeasuringSegment'
import { MapClickEventFromMeasuringSegment, MapMouseMoveEvent } from '../interfaces/MapEvents'
import { MeasuringSegmentState, MeasuringSegmentStateMutator } from '../states/MapMeasuringSegmentState'

function MapMeasuringSegmentPresenter() {
  const { measuring, active, start, end } = useBehaviorSubject(MeasuringSegmentState)

  const [showingSegment, setShowingSegment] = useState(false)

  useEffect(() => {
    if (active) {
      const MapClickEventFromMeasuringSegmentSubscription = MapClickEventFromMeasuringSegment.subscribe((s) => {
        if (!measuring) {
          if (showingSegment) {
            MeasuringSegmentStateMutator.setEnd(null)
            MeasuringSegmentStateMutator.setStart(null)
          }

          MeasuringSegmentStateMutator.setMeasuring(true)
          MeasuringSegmentStateMutator.setStart(s.coordinates)
        }

        if (measuring && start) {
          setShowingSegment(true)

          MeasuringSegmentStateMutator.setMeasuring(false)
          MeasuringSegmentStateMutator.setEnd(s.coordinates)
        }
      })

      const mapMouseMoveEventSubscription = MapMouseMoveEvent.subscribe((s) => {
        if (measuring) {
          MeasuringSegmentStateMutator.setEnd(s.coordinates)
        }
      })

      return () => {
        MapClickEventFromMeasuringSegmentSubscription.unsubscribe()
        mapMouseMoveEventSubscription.unsubscribe()
      }
    }
  }, [measuring, start, active])

  const segmentCoordinates: [Coordinates, Coordinates] = useMemo(() => {
    if (!start || !end) return null

    return [start, end]
  }, [start, end])

  const segmentRadials: [number, number] = useMemo(() => {
    if (!segmentCoordinates) return null

    const firstPoint = turf.point([segmentCoordinates[0].longitude, segmentCoordinates[0].latitude])
    const secondPoint = turf.point([segmentCoordinates[1].longitude, segmentCoordinates[1].latitude])

    const firstRadialAzimuth = fixHeadingByMagneticDeclination(
      turf.bearingToAzimuth(turf.bearing(firstPoint, secondPoint)),
      segmentCoordinates[0]
    )
    const secondRadialAzimuth = firstRadialAzimuth

    return [firstRadialAzimuth, secondRadialAzimuth]
  }, [segmentCoordinates])

  return (
    (start || end) &&
    segmentCoordinates &&
    segmentRadials && (
      <MapMeasuringSegment radials={segmentRadials} segmentCoordinates={segmentCoordinates} finish={!measuring} />
    )
  )
}

export { MapMeasuringSegmentPresenter }
