import { Entity, UniqueEntityID } from '@domain/core'

import { Waypoint } from '../Waypoint'
import { waypointFromPojo } from '../Waypoint/utils'
import { WaypointPojo } from '../Waypoint/Waypoint'

export interface RouteProps {
  name: string
  updatedAt: Date
  totalDistance: number
  groundSpeed?: number

  waypoints?: Waypoint[]
}

export interface RoutePojo {
  id: string

  name: string
  updatedAt: Date
  totalDistance: number
  groundSpeed?: number

  waypoints?: WaypointPojo[]
}

export class Route extends Entity<RouteProps, RoutePojo> {
  public static create(props: RouteProps, id?: UniqueEntityID): Route {
    if (props.totalDistance && props.totalDistance < 0) {
      throw new Error('Distance must be greater than 0')
    }

    if (props.groundSpeed && props.groundSpeed < 0) {
      throw new Error('Ground speed must be greater than 0')
    }

    return new Route(props, id)
  }

  public get name(): string {
    return this.props.name
  }

  public set name(name: string) {
    this.props.name = name
  }

  public get updatedAt(): Date {
    return this.props.updatedAt
  }

  public set updatedAt(updatedAt: Date) {
    this.props.updatedAt = updatedAt
  }

  public get totalDistance(): number {
    return this.props.totalDistance
  }

  public set totalDistance(totalDistance: number) {
    if (totalDistance < 0) {
      throw new Error('Distance must be greater than 0')
    }

    this.props.totalDistance = totalDistance
  }

  public get groundSpeed(): number {
    return this.props.groundSpeed
  }

  public set groundSpeed(groundSpeed: number) {
    if (groundSpeed < 0) {
      throw new Error('Ground speed must be greater than 0')
    }

    this.props.groundSpeed = groundSpeed
  }

  public get waypoints(): Waypoint[] {
    return this.props.waypoints
  }

  public set waypoints(waypoints: Waypoint[]) {
    this.props.waypoints = waypoints
  }

  public toPojo(): RoutePojo {
    const waypoints = this.waypoints?.map((waypoint) => waypoint.toPojo())

    const routePojo: RoutePojo = {
      id: this.id.toString(),
      name: this.name,
      updatedAt: this.updatedAt,
      totalDistance: this.totalDistance,
      groundSpeed: this.groundSpeed,
      waypoints
    }

    return routePojo
  }

  public static fromPojo(pojo: RoutePojo): Route {
    if (!pojo) return null

    const waypoints = pojo.waypoints?.map((waypoint) => waypointFromPojo(waypoint))

    const routeProps: RouteProps = {
      name: pojo.name,
      updatedAt: pojo.updatedAt,
      totalDistance: pojo.totalDistance,
      groundSpeed: pojo.groundSpeed,
      waypoints
    }

    return new Route(routeProps, new UniqueEntityID(pojo.id))
  }
}
