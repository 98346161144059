import { inject, injectable } from 'inversify'

import { InjectionTokens } from '@/controller/tokens'

import FeatureFlags from '@/domain/protocols/FeatureFlags'
import { Result } from '@/domain/protocols/Result'

import type { IFeatureFlagsProvider } from '@/infra/featureFlags/IFeatureFlagsProvider'

import { IFeatureFlagsRepository } from './IFeatureFlagsRepository'

@injectable()
class FeatureFlagsRepository implements IFeatureFlagsRepository {
  private featureFlags: FeatureFlags = {} as FeatureFlags

  constructor(@inject(InjectionTokens.FeatureFlagsProvider) private featureFlagsProvider: IFeatureFlagsProvider) {}

  async init(): Promise<Result<void>> {
    return new Promise((resolve, reject) => {
      const result = this.featureFlagsProvider.getFeatureFlags()

      result.subscribe({
        next: (_featureFlags) => {
          this.featureFlags = _featureFlags
        },
        error: (error) => {
          reject(error)
        },
        complete: () => {
          resolve(Result.ok())
        }
      })
    })
  }

  getFeatureFlags(): FeatureFlags {
    return this.featureFlags
  }
}

export { FeatureFlagsRepository }
