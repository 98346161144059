import styled from 'styled-components'

export const NewsScreenContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const Title = styled.h1`
  ${({ theme }) => theme.fonts.infoLG}
  text-transform: uppercase;
  padding: 20px 13px;
  width: 100%;
`

export const NewsScreenBody = styled.div`
  width: calc(100% + 9px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    border-radius: 20px;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.secondaryL2};
    border-radius: 20px;
  }
`

export const NewsWrapper = styled.div`
  width: 100%;
  padding: 20px 13px;
`

export const NewsDate = styled.span`
  display: block;
  ${({ theme }) => theme.fonts.textMD}
  margin-bottom: 5px;
`

export const NewsTitle = styled.h2`
  ${({ theme }) => theme.fonts.infoMD}
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 20px;
  user-select: text;
`

export const NewsMessage = styled.p`
  user-select: text;
  white-space: pre-wrap;
  ${({ theme }) => theme.fonts.textMD}
`
