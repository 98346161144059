import { useEffect, useState } from 'react'

import {
  BackdropImperativeEvent,
  BackdropImperativeSubject
} from '@/presentation/interfaces/BackdropImperativeInterface'

import { Backdrop } from './BackdropGlobal.styles'

function BackdropGlobal() {
  const [props, setProps] = useState({ show: false, level: 0, opacity: 0, onClose: () => {} })

  useEffect(() => {
    const eventsSubscription = BackdropImperativeSubject.subscribe((event) => {
      switch (event.type) {
        case 'show':
          setProps((prev) => ({ ...prev, ...event }))
          break
        case 'hide':
          setProps({ show: false, level: 0, opacity: 0, onClose: () => {} })
      }
    })

    return () => {
      eventsSubscription.unsubscribe()
    }
  }, [])

  if (props.show) return <Backdrop zIndex={props.level} opacity={props.opacity} onClick={props.onClose} />
  else return null
}

export { BackdropGlobal }
