import { UniqueEntityID } from '@domain/core'

import { Coordinates } from '@/domain/protocols/Coordinates'

import { WaypointType } from '../WaypointType'
import { FlightPlanName, Waypoint, WaypointPojo, WaypointProps } from './Waypoint'

export type AwWaypointExtra = {}
export class AwWaypoint extends Waypoint {
  public declare extra: AwWaypointExtra

  private constructor(props: WaypointProps, extra: AwWaypointExtra, id?: UniqueEntityID) {
    super(props, id)
    this.extra = extra
  }

  public static create(props: WaypointProps, extra: AwWaypointExtra, id?: UniqueEntityID): AwWaypoint {
    props.waypointType = WaypointType.create(
      {
        name: 'Fixo', // t('WAYPOINT_TYPE_AW-WAYPOINT'), // TODO: fix this
        codeName: 'AwWaypoint'
      },
      new UniqueEntityID('wpt')
    )

    return new AwWaypoint(props, extra, id)
  }

  public clone(): AwWaypoint {
    return AwWaypoint.create(this.props, this.extra)
  }

  public cloneWithID(): AwWaypoint {
    return AwWaypoint.create(this.props, this.extra, this.id)
  }

  public getFlightPlanName(): FlightPlanName {
    const numRegex = /\d+/g
    if (this.props.name && !this.props.name.match(numRegex)) {
      return {
        name: this.props.name,
        isCoordinate: false
      }
    } else {
      return super.getFlightPlanName()
    }
  }

  public static fromPojo(pojo: WaypointPojo): AwWaypoint {
    return AwWaypoint.create(
      {
        waypointType: pojo.waypointType && WaypointType.fromPojo(pojo.waypointType),
        coordinates: Coordinates.fromPojo(pojo.coordinates),
        code: pojo.code,
        name: pojo.name,
        customName: pojo.customName,
        createdAt: pojo.createdAt,
        updatedAt: pojo.updatedAt
      },
      {
        ...(pojo.extra as any)
      },
      new UniqueEntityID(pojo.id)
    )
  }
}
