import { injectable } from 'inversify'
import { jsonDateParser } from 'json-date-parser'

import { Waypoint } from '../models'
import { Route } from '../models/Route/Route'
import { SerializableState } from './SerializableState'

export interface RouteStateProps {
  activeRoute: Route
  isSaved: boolean
  highlightedWaypoint: Waypoint['id']
}

const INITIAL_STATE: RouteStateProps = {
  isSaved: false,
  activeRoute: null,
  highlightedWaypoint: null
}

@injectable()
export class RouteState extends SerializableState<RouteStateProps> {
  constructor() {
    super(INITIAL_STATE)
  }

  setActiveRoute(route: Route, isSaved = false) {
    this._updateState({
      ...this.getStateSnapshot(),
      activeRoute: route,
      isSaved
    })
  }

  setIsSaved(isSaved: boolean) {
    this._updateStateProp('isSaved', isSaved)
  }

  setHighlightedWaypoint(waypointId: Waypoint['id']) {
    this._updateStateProp('highlightedWaypoint', waypointId)
  }

  getKey(): string {
    return 'route'
  }

  toString(): string {
    const snapshot = this.getStateSnapshot()

    return JSON.stringify({
      activeRoute: snapshot.activeRoute?.toPojo() ?? null,
      isSaved: snapshot.isSaved
    })
  }

  fromString(state: string): void {
    const parsedState = JSON.parse(state, jsonDateParser)

    this._updateState({
      ...this.getStateSnapshot(),
      activeRoute: Route.fromPojo(parsedState.activeRoute),
      isSaved: parsedState.isSaved
    })
  }
}
