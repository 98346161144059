import { BehaviorSubject } from 'rxjs'

import { MapPosition } from '@/domain/protocols/MapPosition'

export interface MapSearchStateProps {
  searchPosition: MapPosition
  searchOverride: number
}

const INITIAL_STATE: MapSearchStateProps = {
  searchPosition: null,
  searchOverride: null
}

export const MapSearchState = new BehaviorSubject<MapSearchStateProps>(INITIAL_STATE)

export const MapSearchStateMutator = {
  mutate: (newState: MapSearchStateProps) => {
    MapSearchState.next(newState)
  },

  setSearchPosition: (searchPosition: MapPosition) => {
    MapSearchState.next({
      ...MapSearchState.getValue(),
      searchPosition
    })
  },

  setSearchOverride: (searchOverride: number) => {
    MapSearchState.next({
      ...MapSearchState.getValue(),
      searchOverride
    })
  },

  setSearchPositionAndOverride: (searchPosition: MapPosition, searchOverride: number) => {
    MapSearchState.next({
      ...MapSearchState.getValue(),
      searchPosition,
      searchOverride
    })
  },

  clear: () => {
    MapSearchState.next({
      searchOverride: null,
      searchPosition: null
    })
  }
}
