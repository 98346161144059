import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { IconButtonProps } from '@nexds/web'

import { useI18n } from '@/presentation/hooks/useI18n'
import { closeDrawer, openDrawer } from '@/presentation/router/DrawerRouter'
import { RouterPaths } from '@/presentation/router/RouterPathsMapper'
import { isTesting } from '@/utils/testing'

import { ScreenElementsStateMutator } from '../../../MapScreen/states/ScreenElementsState'
import { Drawer } from '../../components/Drawer'
import { WrittenSearchPresenter } from '../WrittenSearchPresenter'
import { DrawerImperativeSubject } from './DrawerImperativeInterface'

export interface DrawerItemProps {
  routePath: RouterPaths
  title: string
  icon: IconButtonProps['icon']
  haveContent: boolean
}

function DrawerPresenter() {
  const routerLocation = useLocation()
  const navigate = useNavigate()
  const { t } = useI18n()

  const topItems: DrawerItemProps[] = [
    {
      routePath: RouterPaths.MAP,
      icon: 'Map',
      title: t('DRAWER_ITEM_MAP_LABEL'),
      haveContent: false
    },
    {
      routePath: RouterPaths.ROUTES_PANEL,
      icon: 'Route',
      title: t('DRAWER_ITEM_ROUTE-PANEL_LABEL'),
      haveContent: true
    },
    {
      routePath: RouterPaths.SAVED_ROUTES,
      icon: 'Star',
      title: 'Rotas salvas',
      haveContent: true
    },
    {
      routePath: RouterPaths.PROCEDURE_CHARTS,
      icon: 'Charts',
      title: t('DRAWER_ITEM_PROCEDURE_CHARTS'),
      haveContent: true
    },
    {
      routePath: RouterPaths.MARKET,
      icon: 'Market',
      title: t('DRAWER_ITEM_MARKET_LABEL'),
      haveContent: true
    }
  ]

  const bottomItems: DrawerItemProps[] = [
    {
      routePath: RouterPaths.MOBILE,
      icon: 'Mobile',
      title: t('DRAWER_ITEM_MOBILE_LABEL'),
      haveContent: true
    },
    {
      routePath: RouterPaths.NEWS,
      icon: 'News',
      title: t('DRAWER_ITEM_NEWS_LABEL'),
      haveContent: true
    },
    {
      routePath: RouterPaths.SETTINGS,
      icon: 'Settings',
      title: t('DRAWER_ITEM_SETTINGS_LABEL'),
      haveContent: true
    }
  ]

  if (isTesting()) {
    bottomItems.unshift({
      routePath: RouterPaths.BETA,
      icon: 'Test',
      title: t('DRAWER_ITEM_BETA_LABEL'),
      haveContent: true
    })
  }

  const defaultOption = RouterPaths.MAP

  const [isMainDrawerOpen, setIsMainDrawerOpen] = useState(false)
  const [activeOption, setActiveOption] = useState<RouterPaths>(defaultOption)

  useEffect(() => {
    const eventSubscription = DrawerImperativeSubject.subscribe((event) => {
      switch (event.type) {
        case 'close':
          closeDrawer()
          break
        case 'open':
          openDrawer(event.screen)
          break
        default:
          break
      }
    })

    return () => {
      eventSubscription.unsubscribe()
    }
  }, [])

  useEffect(() => {
    if (routerLocation.pathname === '/') {
      navigate(defaultOption)
    }
    if (routerLocation.pathname === RouterPaths.MAP || routerLocation.pathname === '/') {
      ScreenElementsStateMutator.setDrawer(false)
    } else {
      ScreenElementsStateMutator.setDrawer(true)
    }
    setActiveOption(routerLocation.pathname as RouterPaths)
  }, [defaultOption, navigate, routerLocation])

  return (
    <>
      <Drawer
        topItems={topItems}
        bottomItems={bottomItems}
        defaultOption={defaultOption}
        activeOption={activeOption}
        setActiveOption={setActiveOption}
        isMainDrawerOpen={isMainDrawerOpen}
        setIsMainDrawerOpen={setIsMainDrawerOpen}
      />
      <WrittenSearchPresenter shouldOverlap={activeOption === RouterPaths.ROUTES_PANEL && !isMainDrawerOpen} />
    </>
  )
}

export { DrawerPresenter }
