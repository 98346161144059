import { useI18n } from '@/presentation/hooks/useI18n'

import { ConfigurationScreen } from '../components/ConfigurationScreen'

function ConfigurationScreenPresenter() {
  const { t } = useI18n()
  const screensTitleMapper = {
    '/settings/user-waypoints': t('DRAWER_SETTINGS_USER-WAYPOINTS_MAIN_TITLE'),
    '/settings/data-and-charts': t('DRAWER_SETTINGS_MAIN_DATA-AND-CHARTS_MAIN_TITLE'),
    '/settings/user-waypoints/create': t('DRAWER_SETTINGS_USER-WAYPOINTS_WAYPOINT_ADD-NEW')
  }

  return <ConfigurationScreen screensTitleMapper={screensTitleMapper} />
}

export { ConfigurationScreenPresenter }
