import { InversionContainer } from '@/controller/container'
import { InjectionTokens } from '@/controller/tokens'

import { IMetricsService } from '@/services/Metrics/IMetricsService'

import { PropertyObject } from '@/infra/MetricsProvider/IGenericMetricsProvider'

export const sendMetrics = (key: string, extra?: PropertyObject) => {
  const metricsService = InversionContainer.getInstance()
    .getContainer()
    .get<IMetricsService>(InjectionTokens.MetricsService)

  metricsService.sendMetric(key, extra)
}
