import { InversionContainer } from '@/controller/container'
import { InjectionTokens } from '@/controller/tokens'

import { RouteBuilder } from '@/domain/builders/RouteBuilder'
import { UniqueEntityID } from '@/domain/core'
import { UseCase } from '@/domain/core/UseCase'
import { Route } from '@/domain/models/Route/Route'
import { Result } from '@/domain/protocols/Result'
import { RouteState } from '@/domain/states/RouteState'

class CloneRouteUseCase implements UseCase<Route, Result<Route>> {
  private routeState: RouteState

  constructor() {
    const injectionContainer = InversionContainer.getInstance().getContainer()
    this.routeState = injectionContainer.get<RouteState>(InjectionTokens.RouteState)
  }

  execute(route: Route): Result<Route> {
    if (!route) return Result.fail('Route is not defined')
    const routeWaypoints = route.waypoints
    if (!routeWaypoints) return Result.fail('Route is empty')

    const waypointsCloned = []

    for (let i = 0; i < routeWaypoints.length; i++) waypointsCloned.push(routeWaypoints[i].clone())

    const clonedRoute = new RouteBuilder()
      .copyFromRoute(route)
      .setId(new UniqueEntityID())
      .setWaypoints(waypointsCloned)
      .setUpdatedAt(new Date())
      .build()

    if (!clonedRoute) return Result.fail('Failed to clone route')

    this.routeState.setActiveRoute(clonedRoute)

    return Result.ok(clonedRoute)
  }
}

export { CloneRouteUseCase }
