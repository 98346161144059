import { InversionContainer } from '@/controller/container'
import { InjectionTokens } from '@/controller/tokens'

import { RouteBuilder } from '@/domain/builders/RouteBuilder'
import { UseCase } from '@/domain/core/UseCase'
import { Route } from '@/domain/models/Route/Route'
import { Waypoint } from '@/domain/models/Waypoint'
import { Result } from '@/domain/protocols/Result'
import { MapState } from '@/domain/states/MapState'
import { RouteState } from '@/domain/states/RouteState'

import type { ILogger } from '@/infra/logger/ILogger'

class AddWaypointToRouteUseCase implements UseCase<Waypoint, Result<Route>> {
  private routeState: RouteState
  private mapState: MapState
  private logger: ILogger

  constructor() {
    const injectionContainer = InversionContainer.getInstance().getContainer()
    this.routeState = injectionContainer.get<RouteState>(InjectionTokens.RouteState)
    this.mapState = injectionContainer.get<MapState>(InjectionTokens.MapState)
    this.logger = injectionContainer.get<ILogger>(InjectionTokens.Logger)
  }

  execute(request: Waypoint): Result<Route> {
    try {
      const routeStateSnapshot = this.routeState.getStateSnapshot()
      const mapStateSnapshot = this.mapState.getStateSnapshot()

      let route: Route = null
      let insertIndex: number = null

      if (routeStateSnapshot.activeRoute) {
        if (routeStateSnapshot.activeRoute.waypoints && routeStateSnapshot.activeRoute.waypoints.length > 1) {
          const routeBuilder = new RouteBuilder().copyFromRoute(routeStateSnapshot.activeRoute)
          insertIndex = routeBuilder.addSmartWaypoint(request)

          route = routeBuilder.build()
        } else {
          route = new RouteBuilder().copyFromRoute(routeStateSnapshot.activeRoute).addWaypoint(request).build()
        }
      } else {
        route = new RouteBuilder().addWaypoint(request).build()
      }

      this.routeState.setActiveRoute(route)

      if (mapStateSnapshot.nextWaypointSequence && insertIndex! + 1 < mapStateSnapshot.nextWaypointSequence) {
        this.mapState.incrementWaypointSequence()
      }

      return Result.ok(route)
    } catch (error) {
      this.logger.error('AddWaypointToRouteUseCase', String(error))
      return Result.fail(error)
    }
  }
}

export { AddWaypointToRouteUseCase }
