import { inject, injectable } from 'inversify'

import { InjectionTokens } from '@/controller/tokens'

import { Result } from '@/domain/protocols/Result'

import type { IHttpClient } from '@/infra/http/IHttpClient'

import { IProceduresRepository, RequestGetProceduresResponse } from './IProceduresRepository'
@injectable()
export class ProceduresRepository implements IProceduresRepository {
  constructor(@inject(InjectionTokens.HttpClient) private httpCLient: IHttpClient) {}

  getBaseUrl(): string {
    return 'https://nexatlas-static-files.nyc3.digitaloceanspaces.com/procedures'
  }

  async getProcedureCharts(icao: string): Promise<Result<RequestGetProceduresResponse>> {
    try {
      const response = await this.httpCLient.get<RequestGetProceduresResponse>(
        `${this.getBaseUrl()}/${icao}/manifest.json`,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )

      if (response.success === false) return Result.fail(response.error)
      if (response.status !== 200) return Result.fail(response.response)

      return Result.ok(response.response)
    } catch (error) {
      return Result.fail(error)
    }
  }
}
