import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  height: calc(100% - 64px);
`

export const TextFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const Example = styled.pre`
  ${({ theme }) => theme.fonts.textMD};
  color: ${({ theme }) => theme.colors.secondaryL2};
`

export const Controls = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
