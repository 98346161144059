import { MapStackLayer } from '../components/MapView/MapStackLayer'

export const stackIds = {
  satellitePlaces: 'satellite-places-stack',
  satellite: 'satellite-stack',
  roadMap: 'road-map-stack',
  ENRCL: 'ENRCL-stack',
  ENRCH: 'ENRCH-stack',
  WAC: 'WAC-stack',
  ARC: 'ARC-stack',
  REA: 'REA-stack',
  REH: 'REH-stack',

  // Airspaces
  airspaces: {
    adiz: 'airspace-adiz-stack',
    afis: 'airspace-afis-stack',
    atz: 'airspace-atz-stack',
    ctr: 'airspace-ctr-stack',
    fir: 'airspace-fir-stack',
    firSector: 'airspace-firSector-stack',
    fiz: 'airspace-fiz-stack',
    fca: 'airspace-fca-stack',
    suaDangerous: 'airspace-suaDangerous-stack',
    suaProhibited: 'airspace-suaProhibited-stack',
    suaRestricted: 'airspace-suaRestricted-stack',
    tma: 'airspace-tma-stack',
    tmaSector: 'airspace-tmaSector-stack'
  },

  // Airspaces Labels
  airspacesLabel: {
    adiz: 'airspace-adiz-label-stack',
    afis: 'airspace-afis-label-stack',
    atz: 'airspace-atz-label-stack',
    ctr: 'airspace-ctr-label-stack',
    fir: 'airspace-fir-label-stack',
    firSector: 'airspace-firSector-label-stack',
    fiz: 'airspace-fiz-label-stack',
    fca: 'airspace-fca-label-stack',
    suaDangerous: 'airspace-suaDangerous-label-stack',
    suaProhibited: 'airspace-suaProhibited-label-stack',
    suaRestricted: 'airspace-suaRestricted-label-stack',
    tma: 'airspace-tma-label-stack',
    tmaSector: 'airspace-tmaSector-label-stack'
  },
  // Measuring Segment
  measuringSegment: 'measuring-segment-stack',
  // Meteorology
  meteorology: 'meteorology-stack',
  // Route
  route: {
    pointLabel: 'route-point-label-stack',
    point: 'route-point-stack',
    lineLabel: 'route-line-label-stack',
    line: 'route-line-stack'
  },
  // MapADHP
  adhp: 'adhp-stack',
  trackHeadboard: 'track-headboard-stack',
  // Procedure Chart
  procedureChart: 'procedure-chart-stack',
  procedureChartButtons: 'procedure-chart-buttons-stack',
  mapTarget: 'map-target-stack'
}

function MapStackPresenter() {
  return (
    <>
      <MapStackLayer id={stackIds.satellitePlaces} />
      <MapStackLayer id={stackIds.satellite} />
      <MapStackLayer id={stackIds.roadMap} />
      <MapStackLayer id={stackIds.ENRCL} />
      <MapStackLayer id={stackIds.ENRCH} />
      <MapStackLayer id={stackIds.WAC} />
      <MapStackLayer id={stackIds.ARC} />
      <MapStackLayer id={stackIds.REA} />
      <MapStackLayer id={stackIds.REH} />

      {/* Procedure Chart */}
      <MapStackLayer id={stackIds.procedureChart} />

      {/* Airspaces */}
      <MapStackLayer id={stackIds.airspaces.adiz} />
      <MapStackLayer id={stackIds.airspaces.afis} />
      <MapStackLayer id={stackIds.airspaces.atz} />
      <MapStackLayer id={stackIds.airspaces.ctr} />
      <MapStackLayer id={stackIds.airspaces.fir} />
      <MapStackLayer id={stackIds.airspaces.firSector} />
      <MapStackLayer id={stackIds.airspaces.fiz} />
      <MapStackLayer id={stackIds.airspaces.fca} />
      <MapStackLayer id={stackIds.airspaces.suaDangerous} />
      <MapStackLayer id={stackIds.airspaces.suaProhibited} />
      <MapStackLayer id={stackIds.airspaces.suaRestricted} />
      <MapStackLayer id={stackIds.airspaces.tma} />
      <MapStackLayer id={stackIds.airspaces.tmaSector} />

      {/* Airspaces Labels */}
      <MapStackLayer id={stackIds.airspacesLabel.adiz} />
      <MapStackLayer id={stackIds.airspacesLabel.afis} />
      <MapStackLayer id={stackIds.airspacesLabel.atz} />
      <MapStackLayer id={stackIds.airspacesLabel.ctr} />
      <MapStackLayer id={stackIds.airspacesLabel.fir} />
      <MapStackLayer id={stackIds.airspacesLabel.firSector} />
      <MapStackLayer id={stackIds.airspacesLabel.fiz} />
      <MapStackLayer id={stackIds.airspacesLabel.fca} />
      <MapStackLayer id={stackIds.airspacesLabel.suaDangerous} />
      <MapStackLayer id={stackIds.airspacesLabel.suaProhibited} />
      <MapStackLayer id={stackIds.airspacesLabel.suaRestricted} />
      <MapStackLayer id={stackIds.airspacesLabel.tma} />
      <MapStackLayer id={stackIds.airspacesLabel.tmaSector} />

      {/* Meteorology */}
      <MapStackLayer id={stackIds.meteorology} />
      {/* MapADHP */}
      <MapStackLayer id={stackIds.adhp} />
      {/* Route */}
      <MapStackLayer id={stackIds.route.line} />
      <MapStackLayer id={stackIds.route.lineLabel} />
      <MapStackLayer id={stackIds.route.point} />
      <MapStackLayer id={stackIds.route.pointLabel} />
      {/* TrackHeadboard */}
      <MapStackLayer id={stackIds.trackHeadboard} />
      {/* Procedure Chart */}
      <MapStackLayer id={stackIds.procedureChartButtons} />
      {/* Measuring Segment */}
      <MapStackLayer id={stackIds.measuringSegment} />
      {/* Map Target */}
      <MapStackLayer id={stackIds.mapTarget} />
    </>
  )
}

export { MapStackPresenter }
