import { InversionContainer } from '@/controller/container'
import { InjectionTokens } from '@/controller/tokens'

import { UseCase } from '@/domain/core/UseCase'
import { CoordWaypoint, Waypoint } from '@/domain/models'
import { Coordinates, CoordinatesProps } from '@/domain/protocols/Coordinates'
import { Result } from '@/domain/protocols/Result'

import { IAeroInfoRepository } from '@/data/AeroInfoRepository/IAeroInfoRepository'

import { ILogger } from '@/infra/logger/ILogger'

class GetProximityWaypointsUseCase implements UseCase<Coordinates, Result<Waypoint[]>> {
  aeroInfoRepository: IAeroInfoRepository
  logger: ILogger

  constructor() {
    const injectionContainer = InversionContainer.getInstance().getContainer()
    this.aeroInfoRepository = injectionContainer.get<IAeroInfoRepository>(InjectionTokens.AeroInfoRepository)
    this.logger = injectionContainer.get<ILogger>(InjectionTokens.Logger)
  }

  execute(center: Coordinates): Result<Waypoint[]> {
    try {
      const searchResultSet = this.aeroInfoRepository.searchByProximityWithLowPrecision(center, 20, 15)
      const resultSet = [CoordWaypoint.create({ coordinates: center }, {}), ...searchResultSet]

      return Result.ok(resultSet)
    } catch (error) {
      this.logger.error('GetProximityWaypointsUseCase', String(error))
      return Result.fail(error)
    }
  }
}

export { GetProximityWaypointsUseCase }
