import { InversionContainer } from '@/controller/container'
import { InjectionTokens } from '@/controller/tokens'

import { UniqueEntityID } from '@/domain/core'
import { UseCase } from '@/domain/core/UseCase'
import { Route } from '@/domain/models/Route/Route'
import { Result } from '@/domain/protocols/Result'

import { IUserRoutesRepository } from '@/data/UserRoutesRepository/IUserRoutesRepository'

class GetSingleRouteUseCase implements UseCase<UniqueEntityID, Result<Route>> {
  private userRoutesRepository: IUserRoutesRepository

  constructor() {
    const injectionContainer = InversionContainer.getInstance().getContainer()
    this.userRoutesRepository = injectionContainer.get<IUserRoutesRepository>(InjectionTokens.UserRoutesRepository)
  }

  async execute(id: UniqueEntityID): Promise<Result<Route>> {
    try {
      return this.userRoutesRepository.getRoute(id)
    } catch (error) {
      return Result.fail(error)
    }
  }
}

export { GetSingleRouteUseCase }
