import { Entity } from '@domain/core'

import { WaypointType } from '@/domain/models/WaypointType'
import { Coordinates, CoordinatesProps } from '@/domain/protocols/Coordinates'

import { convertDecimalDegreesToDMSWithoutSeconds } from '@/utils/coordinates'

import { WaypointTypePojo } from '../WaypointType/WaypointType'

export interface WaypointProps {
  waypointType?: WaypointType
  coordinates: Coordinates
  code?: string
  name?: string
  customName?: string

  createdAt?: Date
  updatedAt?: Date
}

interface GenericExtra {
  [key: string]: any
}
export interface WaypointPojo {
  id: string

  waypointType?: WaypointTypePojo
  coordinates: CoordinatesProps
  code?: string
  name?: string
  customName?: string

  createdAt?: Date
  updatedAt?: Date

  extra?: GenericExtra
}

export type FlightPlanName = {
  name: string
  isCoordinate: boolean
}

export abstract class Waypoint extends Entity<WaypointProps, WaypointPojo> {
  public extra?: GenericExtra

  public getDisplayName(): string {
    return this.props.customName ? this.props.customName : this.props.code ? this.props.code : ''
  }

  public getFlightPlanName(): FlightPlanName {
    return {
      name: convertDecimalDegreesToDMSWithoutSeconds(this.props.coordinates.latitude, this.props.coordinates.longitude),
      isCoordinate: true
    }
  }

  public getTypeName(): string {
    if (this.props.waypointType) {
      return this.props.waypointType.name
    }

    return ''
  }

  public clone(): Waypoint {
    return {} as Waypoint
  }

  public cloneWithID(): Waypoint {
    return null
  }

  public get waypointType(): WaypointType {
    return this.props.waypointType
  }

  public set waypointType(waypointType: WaypointType) {
    this.props.waypointType = waypointType
  }

  public get code(): string {
    return this.props.code
  }

  public set code(code: string) {
    this.props.code = code
  }

  public get name(): string {
    return this.props.name
  }

  public set name(name: string) {
    this.props.name = name
  }

  public get customName(): string {
    return this.props.customName
  }

  public set customName(customName: string) {
    this.props.customName = customName
  }

  public get coordinates(): Coordinates {
    return this.props.coordinates
  }

  public set coordinates(coordinates: Coordinates) {
    this.props.coordinates = coordinates
  }

  public get createdAt(): Date {
    return this.props.createdAt
  }

  public set createdAt(createdAt: Date) {
    this.props.createdAt = createdAt
  }

  public get updatedAt(): Date {
    return this.props.updatedAt
  }

  public set updatedAt(updatedAt: Date) {
    this.props.updatedAt = updatedAt
  }

  public toPojo(): WaypointPojo {
    const waypointPojo: WaypointPojo = {
      id: this.id.toString(),
      waypointType: this.waypointType?.toPojo(),
      coordinates: this.coordinates.toPojo(),
      code: this.code,
      name: this.name,
      customName: this.customName,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      extra: this.extra
    }

    return waypointPojo
  }
}
