import { useState } from 'react'

import { CircularProgress } from '@mui/material'

import { sendMetrics } from '@/presentation/utils/sendMetrics'

import { MarketContainer } from './MarketScreen.styles'

function MarketScreen() {
  const [isLoading, setIsLoading] = useState(true)

  const handleLoad = () => {
    setIsLoading(false)
    sendMetrics('PLANNINGAPP_DRAWER_SERVICES_SELECTED')
  }
  return (
    <MarketContainer>
      {isLoading && <CircularProgress />}
      <iframe
        src="https://market.nexatlas.com/list?embedded=true"
        title="NexMarket"
        width={'100%'}
        height={'100%'}
        frameBorder="0"
        onLoad={handleLoad}
      />
    </MarketContainer>
  )
}

export { MarketScreen }
