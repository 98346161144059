import { MenuGroup, MenuItem } from '@nexds/web'

import { InjectionTokens } from '@/controller/tokens'

import { AirspaceType } from '@/domain/protocols/Airspaces'
import { MapLayerState, MapLayerStateProps } from '@/domain/states/MapLayerState'

import { useGlobalState } from '@/presentation/hooks/useGlobalState'
import { useI18n } from '@/presentation/hooks/useI18n'
import { sendMetrics } from '@/presentation/utils/sendMetrics'

import { ToolContainer } from '../ToolbarTools.styles'

interface AirspaceItem {
  mapLayerName: AirspaceType
  label: string
  isActive: boolean
  concurrent?: AirspaceType
  groupId?: string
}

function AirspacesTool() {
  const { t } = useI18n()
  const [mapLayerState, mapLayerStateMutator] = useGlobalState<MapLayerState, MapLayerStateProps>(
    InjectionTokens.MapLayerState
  )

  const airspaces: AirspaceItem[] = [
    {
      mapLayerName: 'fir',
      label: 'FIR',
      isActive: mapLayerState.fir,
      concurrent: 'firSector',
      groupId: 'fir'
    },
    {
      mapLayerName: 'firSector',
      label: t('TOOLBAR_AIRSPACES_ITEM_FIR-SECTOR_LABEL'),
      isActive: mapLayerState.firSector,
      concurrent: 'fir',
      groupId: 'fir'
    },
    {
      mapLayerName: 'suaDangerous',
      label: t('TOOLBAR_AIRSPACES_ITEM_SUA-DANGEROUS_LABEL'),
      isActive: mapLayerState.suaDangerous
    },
    {
      mapLayerName: 'suaProhibited',
      label: t('TOOLBAR_AIRSPACES_ITEM_SUA-PROHIBITED_LABEL'),
      isActive: mapLayerState.suaProhibited
    },
    {
      mapLayerName: 'suaRestricted',
      label: t('TOOLBAR_AIRSPACES_ITEM_SUA-RESTRICT_LABEL'),
      isActive: mapLayerState.suaRestricted
    },
    {
      mapLayerName: 'tma',
      label: 'TMA',
      isActive: mapLayerState.tma
    },
    {
      mapLayerName: 'tmaSector',
      label: t('TOOLBAR_AIRSPACES_ITEM_TMA-SECTOR_LABEL'),
      isActive: mapLayerState.tmaSector
    },
    {
      mapLayerName: 'ctr',
      label: t('TOOLBAR_AIRSPACES_ITEM_CTR'),
      isActive: mapLayerState.ctr
    },
    {
      mapLayerName: 'atz',
      label: t('TOOLBAR_AIRSPACES_ITEM_ATZ'),
      isActive: mapLayerState.atz
    },
    {
      mapLayerName: 'fiz',
      label: t('TOOLBAR_AIRSPACES_ITEM_FIZ'),
      isActive: mapLayerState.fiz
    },
    {
      mapLayerName: 'afis',
      label: t('TOOLBAR_AIRSPACES_ITEM_AFIS'),
      isActive: mapLayerState.afis
    },
    {
      mapLayerName: 'fca',
      label: t('TOOLBAR_AIRSPACES_ITEM_FCA'),
      isActive: mapLayerState.fca
    },
    {
      mapLayerName: 'adiz',
      label: t('TOOLBAR_AIRSPACES_ITEM_ADIZ'),
      isActive: mapLayerState.adiz
    }
  ]

  const groupedAirspaces = airspaces.reduce<Record<string, AirspaceItem[]>>((groups, item) => {
    if (item.groupId) {
      groups[item.groupId] = groups[item.groupId] || []
      groups[item.groupId].push(item)
    }
    return groups
  }, {})
  const ungroupedAirspaces = airspaces.filter((item) => !item.groupId)

  const handleAirspaces = (mapLayerName: AirspaceType, concurrent?: AirspaceType) => {
    mapLayerStateMutator.setLayer(mapLayerName, !mapLayerState[mapLayerName])
    if (concurrent) mapLayerStateMutator.setLayer(concurrent, false)
  }

  return (
    <ToolContainer gap={12}>
      {groupedAirspaces &&
        Object.entries(groupedAirspaces).map(([groupId, items]) => (
          <MenuGroup key={groupId}>
            {items.map((airspace) => (
              <MenuItem
                key={airspace.label}
                title={airspace.label}
                size="sm"
                hasSwitch
                isSwitchChecked={airspace.isActive}
                onClick={() => {
                  sendMetrics('PLANNINGAPP_TOOLBAR_AIRSPACES-TYPE_SELECTED', {
                    extra: airspace.mapLayerName,
                    active: !airspace.isActive // active is the new state
                  })
                  handleAirspaces(airspace.mapLayerName, airspace.concurrent)
                }}
              />
            ))}
          </MenuGroup>
        ))}
      {ungroupedAirspaces?.map((airspace) => (
        <MenuItem
          key={airspace.label}
          title={airspace.label}
          size="sm"
          hasSwitch
          isSwitchChecked={airspace.isActive}
          onClick={() => {
            sendMetrics('PLANNINGAPP_TOOLBAR_AIRSPACES-TYPE_SELECTED', {
              extra: airspace.mapLayerName,
              active: !airspace.isActive // active is the new state
            })
            handleAirspaces(airspace.mapLayerName)
          }}
        />
      ))}
    </ToolContainer>
  )
}

export { AirspacesTool }
