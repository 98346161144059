import { IconButton } from '@nexds/web'
import styled, { css, keyframes } from 'styled-components'

import { appZIndex } from '@/presentation/utils/zIndexMapper'

interface ContainerProps {
  shouldHide: boolean
  showToolPanel: boolean
}

export const Container = styled.div<ContainerProps>`
  height: fit-content;
  width: 40px;
  background-color: ${({ theme }) => theme.colors.secondaryD1};
  position: absolute;
  right: 10px;
  z-index: ${({ showToolPanel }) => (showToolPanel ? appZIndex.TOOLBAR_SMALL_SCREEN : appZIndex.TOOLBAR)};
  align-self: center;
  display: ${({ shouldHide }) => (shouldHide ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 5px 0;
  border-radius: 10px;
  box-shadow: -2px 4px 6px 0px ${({ theme }) => `${theme.colors.secondary}80`};

  @media (max-height: 460px) {
    max-height: 260px;
  }

  @media (max-width: 430px) {
    right: 0;
  }
`

interface ToolButtonProps {
  label: string
  isActive: boolean
  hasItemActive: boolean
}

export const ToolButton = styled(IconButton)<ToolButtonProps>`
  width: 32px;
  height: 32px;

  @media (max-height: 460px) {
    max-width: 28px;
    max-height: 28px;

    & svg {
      width: 18px;
      height: 18px;
    }
  }

  ${({ theme, isActive }) =>
    isActive &&
    css`
      background-color: ${theme.colors.primary}59 !important;
    `};

  @media (hover: none) {
    &:focus {
      background-color: ${({ theme, isActive }) =>
        isActive ? `${theme.colors.primary}79` : theme.colors.secondary} !important;
    }
  }

  @media (hover: hover) {
    &:hover {
      ${({ theme, isActive, hasItemActive }) =>
        isActive
          ? css`
              background-color: ${theme.colors.primary}79 !important;
            `
          : hasItemActive
            ? css`
                background-color: ${theme.colors.primaryD1}33 !important;
              `
            : ''};
    }

    &::after {
      content: attr(label);
      white-space: nowrap;
      position: absolute;
      right: calc(4px + 4px + 40px + 8px);
      width: fit-content;
      height: 40px;
      padding: 0 16px;
      border-radius: 50px;
      z-index: ${appZIndex.TOOLTIP};
      background-color: ${({ theme, isActive }) => (isActive ? '#0E5846' : theme.colors.secondaryL1)};

      box-shadow: -2px 4px 6px 0px ${({ theme }) => `${theme.colors.secondary}80`};

      display: flex;
      justify-content: center;
      align-items: center;

      color: ${({ theme, isActive }) => (isActive ? theme.colors.primaryL2 : theme.colors.neutralL5)};
      ${({ theme }) => theme.fonts.infoMD}
      opacity: 0;
      visibility: hidden;
    }

    &:hover:after {
      opacity: 1;
      visibility: visible;
      transition-delay: 0.5s;
    }
  }
`

const fadeIn = keyframes`
  from {
    opacity: 0;
    filter: blur(2px);
    transform: translate3d(0, 0, 0);
    visibility: hidden;
  }
  to {
    opacity: 1;
    filter: blur(0px);
    transform: translate3d(-65px, 0, 0);
    visibility: visible;
  }
`

const fadeOut = keyframes`
  from {
    opacity: 1;
    filter: blur(0px);
    transform: translate3d(-65px, 0, 0);
    visibility: visible;
  }
  to {
    opacity: 0;
    filter: blur(2px);
    transform: translate3d(0, 0, 0);
    visibility: hidden;
  }
`

interface ToolPanelProps {
  show: boolean
}

export const ToolPanel = styled.div<ToolPanelProps>`
  max-height: 78vh;
  height: fit-content;
  z-index: 10;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 10px;
  background-color: ${({ theme }) => theme.colors.secondaryD1};
  border-radius: 10px;
  width: 290px;
  opacity: 0;
  box-shadow: -2px 4px 6px 0px ${({ theme }) => `${theme.colors.secondary}80`};

  ${({ show }) =>
    show
      ? css`
          animation: ${fadeIn} 0.3s forwards;
        `
      : css`
          animation: ${fadeOut} 0.1s forwards;
        `};

  @media (max-width: 800px) {
    max-height: 80vh;
  }

  @media (max-width: 430px) {
    right: -21px;
    width: 80vw;
  }

  @media (max-height: 630px) {
    max-height: 60vh;
  }
`

export const ToolPanelHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 64px;
  width: 100%;
  padding: 18px 14px;

  border-bottom: 1px solid ${({ theme }) => theme.colors.secondaryL1};
`

export const ToolPanelTitle = styled.h1`
  ${({ theme }) => theme.fonts.titleXS}
  color: ${({ theme }) => theme.colors.neutralL5};
  text-transform: uppercase;
`

export const ToolPanelBody = styled.div`
  width: calc(100% - 2px);
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 12px;
  margin: 8px 0;

  overflow-y: auto;

  &::-webkit-scrollbar {
    border-radius: 20px;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.secondaryL2};
    border-radius: 20px;
  }
`
