import { InversionContainer } from '@/controller/container'
import { InjectionTokens } from '@/controller/tokens'

import { RouteBuilder } from '@/domain/builders/RouteBuilder'
import { UseCase } from '@/domain/core/UseCase'
import { Route } from '@/domain/models/Route/Route'
import { Result } from '@/domain/protocols/Result'
import { RouteState, RouteStateProps } from '@/domain/states/RouteState'

import { ILogger } from '@/infra/logger/ILogger'

class ReverseActiveRouteUseCase implements UseCase<void, Result<Route>> {
  private routeState: RouteState
  private logger: ILogger

  constructor() {
    const injectionContainer = InversionContainer.getInstance().getContainer()
    this.routeState = injectionContainer.get<RouteState>(InjectionTokens.RouteState)
    this.logger = injectionContainer.get<ILogger>(InjectionTokens.Logger)
  }

  execute(): Result<Route> {
    try {
      const activeRoute = this.routeState.getStateSnapshot().activeRoute
      if (!activeRoute) return Result.fail('There is no active route')

      const routeWaypoints = activeRoute.waypoints
      if (!routeWaypoints) return Result.fail('There are no waypoints on the active route')

      const routeBuilder = new RouteBuilder()
      routeBuilder.setId(activeRoute.id)

      for (let i = routeWaypoints.length - 1; i >= 0; i--) {
        routeBuilder.addWaypoint(routeWaypoints[i])
      }

      routeBuilder.setGroundSpeed(activeRoute.groundSpeed)

      const newRoute = routeBuilder.build()

      this.routeState.setActiveRoute(newRoute)

      return Result.ok()
    } catch (error) {
      this.logger.error('ReverseActiveRouteUseCase', String(error))
      return Result.fail(error)
    }
  }
}

export { ReverseActiveRouteUseCase }
