import styled from 'styled-components'

import { Container } from './NoInternet.styles'

function NoInternetScreen() {
  return (
    <Container>
      Você precisa estar conectado à internet para que possamos carregar as informações aeronáuticas.
    </Container>
  )
}

export { NoInternetScreen }
