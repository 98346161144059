import * as Turf from '@turf/turf'

import { Coordinates } from '@/domain/protocols/Coordinates'

export const kmToNm = (meters: number) => {
  return meters / 1.852
}

export const distanceNMFloat = (start: Coordinates, end: Coordinates) => {
  const startPoint = Turf.point([start.longitude, start.latitude])
  const endPoint = Turf.point([end.longitude, end.latitude])
  return kmToNm(Turf.distance(startPoint, endPoint))
}

export const flightTime = (distance: number, speed: number) => {
  if (speed > 0) {
    return distance / speed
  }
  return 0
}

export const millisecondsToHourMinute = (mili: number) => {
  const minutes = String(Math.floor((mili / (1000 * 60)) % 60))
  const hours = String(Math.floor(mili / (1000 * 60 * 60)))

  return `${hours.length < 2 ? `0${hours}` : hours}:${`0${minutes}`.slice(-2)}`
}
