/* global __APP_VERSION__ */

import 'reflect-metadata'
import ReactDOM from 'react-dom/client'

import { InjectionProvider } from '@contexts/InjectionContext.tsx'
import { InversionContainer } from '@controller/container.ts'
import { LifeCycle } from '@controller/lifeCycle.ts'
import isPropValid from '@emotion/is-prop-valid'
import { ILogger } from '@infra/logger/ILogger'
import { LogLevel } from '@infra/logger/LogLevels.ts'
import { ThemeProvider } from '@nexds/web'
import { App } from '@presentation/App.tsx'
import { GlobalStyle } from '@presentation/global.styles.tsx'
import * as Sentry from '@sentry/react'
import { StyleSheetManager } from 'styled-components'
import { registerSW } from 'virtual:pwa-register'

import { InjectionTokens } from './controller/tokens'
import { NoInternetScreen } from './presentation/modules/Base/components/NoInternetScreen/NoInternetScreen'
import { SplashScreen } from './presentation/modules/Base/components/SplashScreen/SplashScreen'

function shouldForwardProp(propName: string, target: any) {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName)
  }
  // For other elements, forward all props
  return true
}

function reloadApp() {
  window.location.reload()
}

async function registerServiceWorker() {
  const updatePage = registerSW({
    immediate: true,
    onNeedRefresh: () => {
      reloadApp()
    }
  })
  await updatePage(true)
}

const bootstrap = async () => {
  console.log(`[Bootstrap] Bootstrapping app version ${__APP_VERSION__}, mode: ${import.meta.env.MODE}`)

  window.addEventListener('online', reloadApp)
  Sentry.init({
    dsn: 'https://f53c62663e7c414999ad4bd73b39e7af@sentry.nexatlas.com/19',
    integrations: [Sentry.browserTracingIntegration()],
    release: __APP_VERSION__,
    tracesSampleRate: 0.1
  })

  await registerServiceWorker()

  const loadingRoot = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

  loadingRoot.render(
    <ThemeProvider theme="dark">
      <GlobalStyle />
      <SplashScreen />
    </ThemeProvider>
  )

  const container = InversionContainer.getInstance().getContainer()
  const logger = container.get<ILogger>(InjectionTokens.Logger)
  logger.setLogLevel(import.meta.env.MODE === 'production' ? LogLevel.Warn : LogLevel.Debug)

  const lifeCycle = new LifeCycle(container)
  const initializationResult = await lifeCycle.initializeComponents()

  if (initializationResult.isFailure) {
    logger.error('Bootstrap', initializationResult.error)
    loadingRoot.unmount()
    ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
      <ThemeProvider theme="dark">
        <GlobalStyle />
        <NoInternetScreen />
      </ThemeProvider>
    )
    return
  }

  await new Promise((resolve) => setTimeout(resolve, 500))

  window.removeEventListener('online', reloadApp)
  loadingRoot.unmount()
  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <InjectionProvider container={container}>
      <StyleSheetManager shouldForwardProp={shouldForwardProp}>
        <ThemeProvider theme="dark">
          <GlobalStyle />
          <App />
        </ThemeProvider>
      </StyleSheetManager>
    </InjectionProvider>
  )
}

bootstrap()
