import { inject, injectable } from 'inversify'

import { InjectionTokens } from '@/controller/tokens'

import type { ILogger } from '@/infra/logger/ILogger'
import { AmplitudeMetricsProvider } from '@/infra/MetricsProvider/AmplitudeMetricsProvider'
import { IGenericMetricsProvider, PropertyObject } from '@/infra/MetricsProvider/IGenericMetricsProvider'

import { IMetricsService } from './IMetricsService'

@injectable()
export class MetricsService implements IMetricsService {
  private metricProviders: IGenericMetricsProvider[]
  private rejectMetricsSending: boolean = true

  constructor(
    @inject(InjectionTokens.AmplitudeMetricsProvider) private amplitudeMetricsProvider: AmplitudeMetricsProvider,
    @inject(InjectionTokens.Logger) private logger: ILogger
  ) {
    this.metricProviders = []
    this.addMetricsProvider(this.amplitudeMetricsProvider)
  }

  async init() {
    return
  }

  async start() {
    return
  }

  async stop() {
    return
  }

  setMetricsRejected(rejected: boolean) {
    this.rejectMetricsSending = rejected
  }

  addMetricsProvider(metricProvider: IGenericMetricsProvider) {
    this.metricProviders.push(metricProvider)
  }

  sendMetric(key: string, extra?: PropertyObject) {
    if (this.rejectMetricsSending) return

    this.logger.debug('MetricsService', `Sending metric ${key}, extra: ${JSON.stringify(extra)}`)

    this.metricProviders.forEach((metricProvider) => {
      metricProvider.sendMetric(key, extra)
    })
  }

  setUserId(userId: string | number) {
    if (this.rejectMetricsSending) return

    this.metricProviders.forEach((metricProvider) => {
      metricProvider.setUserId(userId)
    })
  }

  setUserProperties(properties: Object) {
    if (this.rejectMetricsSending) return

    this.metricProviders.forEach((metricProvider) => {
      metricProvider.setUserProperties(properties)
    })
  }
}
