import * as turf from '@turf/turf'
import GeoJSON from 'geojson'

export function twoToFourCoordinatesConverter(
  twoCoordinates?: [number, number, number, number]
): [[number, number], [number, number], [number, number], [number, number]] {
  if (!twoCoordinates) return null
  if (twoCoordinates.length !== 4) return null
  return [
    [twoCoordinates[0], twoCoordinates[3]],
    [twoCoordinates[2], twoCoordinates[3]],
    [twoCoordinates[2], twoCoordinates[1]],
    [twoCoordinates[0], twoCoordinates[1]]
  ]
}

export const createCircleFeature = (
  feature: GeoJSON.Feature<GeoJSON.MultiPoint>
): GeoJSON.Feature<GeoJSON.MultiPolygon> => {
  // Transform multi point to multi polygon using turf to create circles for each point
  const pointCoordinates = feature.geometry.coordinates
  const radius = feature.properties?.['circle-radius'] || 10000

  const circleFeatures = pointCoordinates.map((pointCoordinate) => {
    const circle = turf.circle(pointCoordinate, radius, {
      units: 'meters',
      steps: 10
    })

    return circle
  })

  const multiPolygon = turf.multiPolygon(circleFeatures.map((circleFeature) => circleFeature.geometry.coordinates))

  return {
    ...feature,
    geometry: multiPolygon.geometry
  }
}
