import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import CompleteLogoNexAtlas from '@assets/complete-logo-nexatlas-light-text.svg?react'
import { CircularProgress } from '@mui/material'
import { IconButton } from '@nexds/web'
import { useTheme } from 'styled-components'

import { useBehaviorSubject } from '@/presentation/hooks/useBehaviorSubject'
import { useScreenDimensions } from '@/presentation/hooks/useScreenDimensions'
import { DrawerRouter } from '@/presentation/router/DrawerRouter'
import { RouterPaths } from '@/presentation/router/RouterPathsMapper'

import { DrawerItemProps } from '../../containers/DrawerPresenter/DrawerPresenter'
import { ModalPresenter } from '../../containers/ModalPresenter'
import { ToastPresenter } from '../../containers/ToastPresenter'
import { LoadingState } from '../../states/LoadingState'
import {
  Container,
  LogoContainer,
  MainDrawer,
  MainDrawerContent,
  MainDrawerItem,
  OptionButton,
  OptionContainer,
  SideBar,
  SideBarContent,
  SideBarGroup
} from './Drawer.styles'

export interface DrawerProps {
  defaultOption: RouterPaths
  topItems: DrawerItemProps[]
  bottomItems: DrawerItemProps[]
  isMainDrawerOpen: boolean
  setIsMainDrawerOpen: (isMainDrawerOpen: boolean) => void
  activeOption: RouterPaths
  setActiveOption: (activeOption: RouterPaths) => void
}

function Drawer(props: DrawerProps) {
  const { defaultOption, topItems, bottomItems, activeOption, setActiveOption, isMainDrawerOpen, setIsMainDrawerOpen } =
    props

  const theme = useTheme()
  const navigate = useNavigate()

  const loadingState = useBehaviorSubject(LoadingState)

  const isOptionContainerOpen = useMemo(() => {
    return activeOption !== defaultOption
  }, [activeOption, defaultOption])

  useEffect(() => {
    if (activeOption) setIsMainDrawerOpen(false)
  }, [activeOption, setIsMainDrawerOpen])

  function handleOptionCLick(itemRoutePath: RouterPaths) {
    if (activeOption) {
      if (activeOption === itemRoutePath && !isMainDrawerOpen) {
        navigate(defaultOption)
        setActiveOption(defaultOption)
      } else if (activeOption === itemRoutePath && isMainDrawerOpen) {
        setIsMainDrawerOpen(false)
      } else {
        navigate(itemRoutePath)
        setActiveOption(itemRoutePath)
      }
    } else {
      navigate(itemRoutePath)
      setActiveOption(itemRoutePath)
    }
  }

  return (
    <Container data-testid="Drawer/Container" isOpen={isOptionContainerOpen || isMainDrawerOpen}>
      <SideBar hasBorder={isMainDrawerOpen || isOptionContainerOpen}>
        <IconButton
          data-testid="Drawer/ToggleButton"
          size="sm"
          color="ghost"
          icon="HamburgerMenu"
          onClick={() => setIsMainDrawerOpen(!isMainDrawerOpen)}
        />
        <SideBarContent>
          <SideBarGroup>
            {topItems.map((item) => (
              <OptionButton
                key={item.routePath}
                data-testid="Drawer/ToggleButton"
                label={item.title}
                isActive={activeOption.startsWith(item.routePath)}
                $should_show_tooltip={!isMainDrawerOpen}
                size="sm"
                color="ghost"
                iconColor={activeOption.startsWith(item.routePath) ? theme.colors.primaryL2 : theme.colors.neutralL5}
                icon={item.icon}
                onClick={() => handleOptionCLick(item.routePath)}
              />
            ))}
          </SideBarGroup>
          <SideBarGroup>
            {loadingState.isLoading && (
              <CircularProgress
                size={20}
                style={{
                  alignSelf: 'center'
                }}
              />
            )}
            {bottomItems.map((item) => (
              <OptionButton
                key={item.routePath}
                data-testid="Drawer/ToggleButton"
                label={item.title}
                $should_show_tooltip={!isMainDrawerOpen}
                isActive={activeOption.startsWith(item.routePath)}
                size="sm"
                color="ghost"
                iconColor={activeOption.startsWith(item.routePath) ? theme.colors.primaryL2 : theme.colors.neutralL5}
                icon={item.icon}
                onClick={() => handleOptionCLick(item.routePath)}
              />
            ))}
          </SideBarGroup>
        </SideBarContent>
      </SideBar>
      <OptionContainer isOpen={isOptionContainerOpen}>
        <DrawerRouter />
        <ModalPresenter locale="drawer" />
        <ToastPresenter />
      </OptionContainer>
      <MainDrawer data-testid="Drawer/MainDrawer" isOpen={isMainDrawerOpen}>
        <LogoContainer>
          <CompleteLogoNexAtlas width={132} />
        </LogoContainer>
        <MainDrawerContent $content_position="top">
          {topItems.map((item) => (
            <MainDrawerItem
              key={item.routePath}
              isActive={activeOption.startsWith(item.routePath)}
              onClick={() => handleOptionCLick(item.routePath)}
            >
              {item.title}
            </MainDrawerItem>
          ))}
        </MainDrawerContent>
        <MainDrawerContent $content_position="bottom">
          {bottomItems.map((item) => (
            <MainDrawerItem
              key={item.routePath}
              isActive={activeOption.startsWith(item.routePath)}
              onClick={() => handleOptionCLick(item.routePath)}
            >
              {item.title}
            </MainDrawerItem>
          ))}
        </MainDrawerContent>
      </MainDrawer>
    </Container>
  )
}

export { Drawer }
