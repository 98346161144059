import styled from 'styled-components'

import { appZIndex } from '@/presentation/utils/zIndexMapper'

interface SearchBoxProps {
  shouldOverlap: boolean
}
export const SearchContainer = styled.div``

export const SearchBox = styled.div<SearchBoxProps>`
  z-index: ${({ shouldOverlap }) => (shouldOverlap ? appZIndex.SEARCH_BOX : appZIndex.DRAWER - 1)};
  position: absolute;
  top: 2.5%;
  left: calc(
    16px + 50px + 12px
  ); // 16: borda esquerda da tela até o Drawer; 50: largura do Drawer; 12: gap entre o Drawer e o SearchBox, referente ao padding interno do Painel de Rotas aberto;
  height: fit-content;
  width: calc(
    360px - 12px - 12px
  ); // largura do Drawer, descontando os paddings. Passa a impressão de que está dentro do Painel de Rotas
  padding: 8px 0 0 0;

  & input {
    ${({ theme }) => theme.fonts.infoMD};
  }

  & input::placeholder {
    overflow: visible;
    transition: transform 0.7s cubic-bezier(0.36, 0, 0.91, 0.73);
  }

  & input:focus::placeholder {
    opacity: 0.3;
  }

  & input:hover::placeholder {
    transform: translateX(-120px);
    transition: transform 2s cubic-bezier(0.36, 0, 0.91, 0.73);
  }

  & > *:nth-child(1) {
    & > *:nth-child(1) {
      box-shadow: 2px 4px 6px 0px ${({ theme }) => `${theme.colors.secondary}59`};
    }
  }

  @media (max-width: 430px) {
    left: calc(50px + 12px);
    width: 100%;
    padding-right: 70px;
  }
`
