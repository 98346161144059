import { InversionContainer } from '@/controller/container'
import { InjectionTokens } from '@/controller/tokens'

import { UseCase } from '@/domain/core/UseCase'
import { Route } from '@/domain/models/Route/Route'
import { Result } from '@/domain/protocols/Result'
import { RouteState, RouteStateProps } from '@/domain/states/RouteState'

import { IUserRoutesRepository } from '@/data/UserRoutesRepository/IUserRoutesRepository'

import { ILogger } from '@/infra/logger/ILogger'

interface RenameRouteUseCaseProps {
  route: Route
  name: string
}

class RenameRouteUseCase implements UseCase<RenameRouteUseCaseProps, Result<void>> {
  private routeState: RouteState
  private userRoutesRepository: IUserRoutesRepository
  private logger: ILogger

  constructor() {
    const injectionContainer = InversionContainer.getInstance().getContainer()
    this.routeState = injectionContainer.get<RouteState>(InjectionTokens.RouteState)
    this.userRoutesRepository = injectionContainer.get<IUserRoutesRepository>(InjectionTokens.UserRoutesRepository)
    this.logger = injectionContainer.get<ILogger>(InjectionTokens.Logger)
  }

  execute(request: RenameRouteUseCaseProps): Result<void> {
    try {
      if (!request.route.waypoints) return Result.fail('There are no waypoints on the active rote')
      const newRoute = new Route({ ...request.route.props, name: request.name }, request.route.id)

      this.routeState.setActiveRoute(newRoute)

      return Result.ok()
    } catch (error) {
      this.logger.error('RenameRouteUseCase', String(error))
      return Result.fail(error)
    }
  }
}

export { RenameRouteUseCase }
