import styled, { css } from 'styled-components'

export const Container = styled.div`
  height: 76px;
  width: 100%;

  position: relative;

  display: flex;
  flex-direction: row;
  gap: 10px;

  padding: 18px 16px;

  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 10px;

  overflow: hidden;

  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondaryL1};
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  width: 100%;

  align-items: center;
  justify-content: space-between;
`

export const MainColumnWrapper = styled.div`
  width: 50%;
`

export const ColumnRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;

  width: 100%;

  align-items: center;
  justify-content: flex-end;
`

interface HoverProps {
  isDeleting?: boolean
}

export const HideOnHover = styled.div<HoverProps>`
  width: 50%;
  opacity: ${({ isDeleting }) => (isDeleting ? 0 : 1)};
  pointer-events: ${({ isDeleting }) => (isDeleting ? 'none' : 'all')};
  transition: 300ms;

  ${Container}:hover & {
    opacity: 0;
    pointer-events: none;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`

export const Route = styled.p`
  ${({ theme }) => theme.fonts.infoLG};
  color: ${({ theme }) => theme.colors.neutralL5};
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 330px) {
    ${({ theme }) => theme.fonts.infoSM};
  }
`

export const SavedAt = styled.p`
  ${({ theme }) => theme.fonts.infoSM};
  color: ${({ theme }) => theme.colors.neutralL2};
  white-space: nowrap;

  @media (max-width: 330px) {
    ${({ theme }) => theme.fonts.infoXS};
  }
`

export const Label = styled.label`
  ${({ theme }) => theme.fonts.textSM};
  line-height: 100%;
  color: ${({ theme }) => theme.colors.neutralL2};
  white-space: nowrap;

  @media (max-width: 330px) {
    ${({ theme }) => theme.fonts.textSM};
  }
`

export const Value = styled.p`
  ${({ theme }) => theme.fonts.infoLG};
  line-height: 100%;
  color: ${({ theme }) => theme.colors.neutralL5};
  white-space: nowrap;

  @media (max-width: 330px) {
    ${({ theme }) => theme.fonts.infoSM};
  }
`

export const DeleteButton = styled.button<HoverProps>`
  width: 64px;
  height: 56px;
  border-radius: 8px;

  border: none;

  background-color: ${({ theme }) => theme.colors.secondaryL1};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;

  position: absolute;
  top: 50%;
  right: 16px;

  transition: 300ms;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondaryL2};
    cursor: pointer;
  }

  opacity: ${({ isDeleting }) => (isDeleting ? 1 : 0)};
  ${({ isDeleting }) =>
    isDeleting
      ? css`
          transform: translateY(-50%) translateX(0px);
        `
      : css`
          transform: translateY(-50%) translateX(10px);
        `}
  pointer-events: ${({ isDeleting }) => (isDeleting ? 'all' : 'none')};

  ${Container}:hover & {
    opacity: 1;
    transform: translateY(-50%) translateX(0px);
    pointer-events: all;
  }
`

export const Delete = styled.p`
  ${({ theme }) => theme.fonts.infoSM}
  color: ${({ theme }) => theme.colors.neutralL5}
`
