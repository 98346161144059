import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import GeoJSON from 'geojson'
import { useTheme } from 'styled-components'

import { Waypoint } from '@/domain/models'
import { Coordinates } from '@/domain/protocols/Coordinates'
import { MapPosition } from '@/domain/protocols/MapPosition'

import { useBehaviorSubject } from '@/presentation/hooks/useBehaviorSubject'

import { stackIds } from '../../containers/MapStackPresenter'
import { MapSearchState, MapSearchStateMutator, MapSearchStateProps } from '../../states/MapSearchState'
import { MapCircleLayer } from '../MapView/MapCircleLayer'
import { MapGeoJsonLayer } from '../MapView/MapGeoJsonLayer'
import { MapGeoJsonSource } from '../MapView/MapGeoJsonSource'
import { MapSymbolLayer } from '../MapView/MapSymbolLayer'
import {
  makeGeoJsonHighlightCircleFeatureCollection,
  makeGeoJsonLineFeatureCollection,
  makeGeoJsonLineLabelFeatureCollection,
  makeGeoJsonPointFeatureCollection
} from './GeojsonUtils'
import {
  routeLineLabelLayout,
  routeLineLabelDefaultPaint,
  routeLineLeftBorderPaint,
  routeLinePaint,
  routeLineRightBorderPaint,
  routeWaypointCirclePaint,
  routeWaypointLabelLayout,
  routeWaypointLabelPaint,
  routeWaypointHighlightedPointPaint,
  routeWaypointHighlightedCirclePaint
} from './MapRoute.styles'

interface MapRouteProps {
  waypoints: Waypoint[]
  highlightedWaypoint: Waypoint
  nextWaypointSequence: number
  onWaypointClick: (waypointId: string, mapPosition: MapPosition) => void
}

function MapRoute(props: MapRouteProps) {
  const { waypoints, highlightedWaypoint, nextWaypointSequence, onWaypointClick } = props
  const theme = useTheme()

  const mapSearchState = useBehaviorSubject<MapSearchStateProps>(MapSearchState)

  const rubberbandMovingIndex = useRef<number | null>(null)
  const [rubberbandMovingCoordinates, setRubberbandMovingCoordinates] = useState<Coordinates | null>(null)
  const [rubberbandSearching, setRubberbandSearching] = useState(false)

  useEffect(() => {
    if (mapSearchState.searchOverride === null && rubberbandSearching) {
      setRubberbandSearching(false)
      setRubberbandMovingCoordinates(null)
      rubberbandMovingIndex.current = null
    }
  }, [mapSearchState.searchOverride, rubberbandSearching])

  const geoJsonHighlightCircleFeatureCollection: GeoJSON.FeatureCollection = useMemo(
    makeGeoJsonHighlightCircleFeatureCollection(highlightedWaypoint),
    [highlightedWaypoint, makeGeoJsonHighlightCircleFeatureCollection]
  )

  const geoJsonPointFeatureCollection: GeoJSON.FeatureCollection = useMemo(
    makeGeoJsonPointFeatureCollection(
      waypoints,
      nextWaypointSequence,
      highlightedWaypoint,
      rubberbandMovingIndex && rubberbandMovingCoordinates
        ? {
            index: rubberbandMovingIndex.current,
            coordinates: rubberbandMovingCoordinates
          }
        : null
    ),
    [
      highlightedWaypoint,
      waypoints,
      nextWaypointSequence,
      makeGeoJsonPointFeatureCollection,
      rubberbandMovingIndex,
      rubberbandMovingCoordinates
    ]
  )

  const geoJsonLineFeatureCollection: GeoJSON.FeatureCollection = useMemo(
    makeGeoJsonLineFeatureCollection(
      waypoints,
      rubberbandMovingIndex && rubberbandMovingCoordinates
        ? {
            index: rubberbandMovingIndex.current,
            coordinates: rubberbandMovingCoordinates
          }
        : null
    ),
    [
      waypoints,
      nextWaypointSequence,
      makeGeoJsonLineFeatureCollection,
      rubberbandMovingIndex,
      rubberbandMovingCoordinates
    ]
  )

  const geoJsonLineLabelFeatureCollection: GeoJSON.FeatureCollection = useMemo(
    makeGeoJsonLineLabelFeatureCollection(
      waypoints,
      nextWaypointSequence,
      rubberbandMovingIndex && rubberbandMovingCoordinates
        ? {
            index: rubberbandMovingIndex.current,
            coordinates: rubberbandMovingCoordinates
          }
        : null
    ),
    [
      waypoints,
      nextWaypointSequence,
      makeGeoJsonLineLabelFeatureCollection,
      rubberbandMovingIndex,
      rubberbandMovingCoordinates
    ]
  )

  const handleWaypointClick = useCallback(
    (features: GeoJSON.Feature[], _mapPosition: MapPosition) => {
      onWaypointClick(features[0].properties.waypointId, _mapPosition)
    },
    [waypoints]
  )

  const handleMoveStart = (feature?: GeoJSON.Feature) => {
    rubberbandMovingIndex.current = waypoints.findIndex((wpt) => wpt.id.toString() === feature.properties.waypointId)
  }

  const handleDragging = (_mapPosition: MapPosition) => {
    if (rubberbandMovingIndex.current === null) return
    setRubberbandMovingCoordinates(_mapPosition.coordinates)
  }

  const handleMoveEnd = (mapPosition: MapPosition) => {
    MapSearchStateMutator.setSearchPositionAndOverride(mapPosition, rubberbandMovingIndex.current)
    setRubberbandSearching(true)
  }

  const ids = {
    source: {
      highlightedWaypoint: 'route-highlighted-waypoint-source',
      waypoint: 'route-waypoints-source',
      line: 'route-line-source',
      lineLabel: 'route-line-label-source'
    },
    layer: {
      highlightedWaypoint: {
        circle: 'route-highlighted-waypoint-circle',
        point: 'route-highlighted-waypoint-point'
      },
      waypoint: { label: 'route-waypoint-label', point: 'route-waypoints-point' },
      line: {
        fill: 'route-line-fill',
        right: 'route-line-right-border',
        left: 'route-line-left-border',
        lineLabel: 'route-line-label'
      }
    }
  }

  return (
    <>
      {highlightedWaypoint && (
        <>
          <MapGeoJsonSource id={ids.source.highlightedWaypoint} data={geoJsonHighlightCircleFeatureCollection}>
            {/* Waypoint Highlight Circle */}
            <MapCircleLayer
              id={ids.layer.highlightedWaypoint.circle}
              sourceId={ids.source.highlightedWaypoint}
              beforeLayerId={stackIds.route.point}
              radius={10}
              paint={routeWaypointHighlightedCirclePaint(theme)}
            />

            {/* Waypoint Highlight point */}
            <MapCircleLayer
              id={ids.layer.highlightedWaypoint.point}
              sourceId={ids.source.highlightedWaypoint}
              beforeLayerId={stackIds.route.point}
              paint={routeWaypointHighlightedPointPaint(theme)}
            />
          </MapGeoJsonSource>
        </>
      )}
      <MapGeoJsonSource id={ids.source.waypoint} data={geoJsonPointFeatureCollection}>
        {/* Waypoint Label */}
        <MapSymbolLayer
          id={ids.layer.waypoint.label}
          sourceId={ids.source.waypoint}
          imageSrc={routeWaypointLabelLayout()['icon-image'] as string}
          layout={routeWaypointLabelLayout()}
          paint={routeWaypointLabelPaint(theme)}
          filter={['all', ['!=', 'code', '']]}
          beforeLayerId={stackIds.route.pointLabel}
        />

        {/* Waypoint Circle */}
        <MapCircleLayer
          id={ids.layer.waypoint.point}
          sourceId={ids.source.waypoint}
          paint={routeWaypointCirclePaint(theme)}
          beforeLayerId={stackIds.route.point}
          onCircleClick={handleWaypointClick}
          onCircleMoveStart={handleMoveStart}
          onCircleDragging={handleDragging}
          onCircleMoveEnd={handleMoveEnd}
        />
      </MapGeoJsonSource>

      {/* Route Line */}
      <MapGeoJsonSource id={ids.source.line} data={geoJsonLineFeatureCollection}>
        <MapGeoJsonLayer
          id={ids.layer.line.fill}
          sourceId={ids.source.line}
          types={['line']}
          paint={routeLinePaint(theme)}
          beforeLayerId={stackIds.route.line}
        />
        <MapGeoJsonLayer
          id={ids.layer.line.right}
          sourceId={ids.source.line}
          types={['line']}
          paint={routeLineRightBorderPaint(theme)}
          beforeLayerId={stackIds.route.line}
        />
        <MapGeoJsonLayer
          id={ids.layer.line.left}
          sourceId={ids.source.line}
          types={['line']}
          paint={routeLineLeftBorderPaint(theme)}
          beforeLayerId={stackIds.route.line}
        />
      </MapGeoJsonSource>

      {/* Route Line Label */}
      <MapGeoJsonSource id={ids.source.lineLabel} data={geoJsonLineLabelFeatureCollection}>
        <MapSymbolLayer
          id={ids.layer.line.lineLabel}
          sourceId={ids.source.lineLabel}
          imageSrc={routeLineLabelLayout()['icon-image'] as string}
          layout={routeLineLabelLayout()}
          paint={routeLineLabelDefaultPaint(theme)}
          filter={['==', 'state', 'default']}
          beforeLayerId={stackIds.route.lineLabel}
        />
      </MapGeoJsonSource>
    </>
  )
}

export { MapRoute }
