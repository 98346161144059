import { UniqueEntityID } from '@domain/core'

import { Coordinates } from '@/domain/protocols/Coordinates'

import { convertDecimalDegreesToDMS } from '@/utils/coordinates'

import { WaypointType } from '../WaypointType'
import { Waypoint, WaypointPojo, WaypointProps } from './Waypoint'

export type CoordWaypointExtra = {
  isUserWaypoint?: boolean
  isAddress?: boolean
}

export class CoordWaypoint extends Waypoint {
  public declare extra: CoordWaypointExtra

  private constructor(props: WaypointProps, extra: CoordWaypointExtra, id?: UniqueEntityID) {
    super(props, id)
    this.extra = extra
  }

  public static create(props: WaypointProps, extra: CoordWaypointExtra, id?: UniqueEntityID): CoordWaypoint {
    props.waypointType = WaypointType.create(
      {
        name: 'Coordenadas', // t('WAYPOINT_TYPE_COORD-WAYPOINT'), // TODO: fix this
        codeName: 'CoordWaypoint'
      },
      new UniqueEntityID('coord')
    )

    return new CoordWaypoint(props, extra, id)
  }

  public clone(): CoordWaypoint {
    return CoordWaypoint.create(this.props, this.extra)
  }

  public cloneWithID(): CoordWaypoint {
    return CoordWaypoint.create(this.props, this.extra, this.id)
  }

  public getDisplayName(): string {
    return this.props.customName
      ? this.props.customName
      : convertDecimalDegreesToDMS(this.props.coordinates.latitude, this.props.coordinates.longitude)
  }

  public getTypeName(): string {
    return this.extra.isUserWaypoint
      ? 'Ponto do usuário' // t('WAYPOINT_TYPE_USER-WAYPOINT') // TODO: fix this
      : this.extra.isAddress
        ? 'Endereço' // t('WAYPOINT_TYPE_ADDRESS') // TODO: fix this
        : 'Coordenadas' // t('WAYPOINT_TYPE_COORD-WAYPOINT') // TODO: fix this
  }

  public static fromPojo(pojo: WaypointPojo): CoordWaypoint {
    return CoordWaypoint.create(
      {
        waypointType: pojo.waypointType && WaypointType.fromPojo(pojo.waypointType),
        coordinates: Coordinates.fromPojo(pojo.coordinates),
        code: pojo.code,
        name: pojo.name,
        customName: pojo.customName,
        createdAt: pojo.createdAt,
        updatedAt: pojo.updatedAt
      },
      {
        ...(pojo.extra as any)
      },
      new UniqueEntityID(pojo.id)
    )
  }
}
