import { Fragment } from 'react'

import { Assets } from '@nexds/web'
import { useTheme } from 'styled-components'

import { MeteorologyManifest } from '@/data/MeteorologyRepository/IMeteorologyRepository'

import { MeteorologyAssets } from '@/presentation/assets/meteorology/Assets'

import {
  Body,
  CloseButton,
  Divider,
  Header,
  LegendColor,
  LegendContainer,
  LegendImage,
  LegendItem,
  LegendsWrapper,
  LegendText,
  LegendTextIcon,
  LegendTitle,
  Title
} from './InfoPanel.styles'

interface MeteorologyLegendsProps {
  title: string
  legends: MeteorologyManifest['legends']
  onClose?: () => void
}

const MeteorologyLegends = (props: MeteorologyLegendsProps) => {
  const { title, legends, onClose } = props

  const theme = useTheme()

  return (
    <>
      <Header>
        <Title>{title}</Title>
        <CloseButton onClick={onClose && onClose}>
          <Assets.Close size={'md'} color={theme.colors.secondaryL3} />
        </CloseButton>
      </Header>
      <Body>
        <LegendsWrapper>
          {legends.map((legend, index) => (
            <LegendContainer key={`${legend.title}-${index}`}>
              <LegendTitle>{legend.title}</LegendTitle>
              {legend.items.map((item, itemIndex) => (
                <Fragment key={item.color ?? item.image ?? item.textIcon ?? itemIndex}>
                  <Divider />
                  <LegendItem>
                    {item.image && <LegendImage src={MeteorologyAssets[item.image]} />}
                    {item.color && <LegendColor color={item.color} />}
                    {item.textIcon && <LegendTextIcon>{item.textIcon}</LegendTextIcon>}
                    <LegendText>{item.label}</LegendText>
                  </LegendItem>
                </Fragment>
              ))}
            </LegendContainer>
          ))}
        </LegendsWrapper>
      </Body>
    </>
  )
}

export { MeteorologyLegends }
