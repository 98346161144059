import { Route } from '@/domain/models'

export const getFitBoundaries = (activeRoute: Route): [number, number, number, number] => {
  if (activeRoute && activeRoute.waypoints && activeRoute.waypoints.length > 0) {
    let minLat = activeRoute.waypoints[0].coordinates.latitude
    let minLng = activeRoute.waypoints[0].coordinates.longitude
    let maxLat = activeRoute.waypoints[0].coordinates.latitude
    let maxLng = activeRoute.waypoints[0].coordinates.longitude

    for (let i = 1; i < activeRoute.waypoints.length; i++) {
      if (activeRoute.waypoints[i].coordinates.latitude < minLat) {
        minLat = activeRoute.waypoints[i].coordinates.latitude
      }
      if (activeRoute.waypoints[i].coordinates.latitude > maxLat) {
        maxLat = activeRoute.waypoints[i].coordinates.latitude
      }

      if (activeRoute.waypoints[i].coordinates.longitude < minLng) {
        minLng = activeRoute.waypoints[i].coordinates.longitude
      }
      if (activeRoute.waypoints[i].coordinates.longitude > maxLng) {
        maxLng = activeRoute.waypoints[i].coordinates.longitude
      }
    }

    if (activeRoute.waypoints.length === 1) {
      minLat -= 1
      minLng -= 1
      maxLat += 1
      maxLng += 1
    }

    if (
      activeRoute.waypoints.length === 2 &&
      activeRoute.waypoints[0].coordinates.latitude === activeRoute.waypoints[1].coordinates.latitude &&
      activeRoute.waypoints[0].coordinates.longitude === activeRoute.waypoints[1].coordinates.longitude
    ) {
      minLat -= 0.5
      minLng -= 0.5
      maxLat += 0.5
      maxLng += 0.5
    }
    return [minLng, minLat, maxLng, maxLat]
  } else {
    return [-74.37, 5.92, -34.73, -33.63]
  }
}
