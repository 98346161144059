import { InversionContainer } from '@/controller/container'
import { InjectionTokens } from '@/controller/tokens'

import { RouteBuilder } from '@/domain/builders/RouteBuilder'
import { UseCase } from '@/domain/core/UseCase'
import { Result } from '@/domain/protocols/Result'
import { RouteState } from '@/domain/states/RouteState'

import { ILogger } from '@/infra/logger/ILogger'

interface ReorderWaypointsOnRouteUseCaseProps {
  from: number
  to: number
}
class ReorderWaypointsOnRouteUseCase implements UseCase<ReorderWaypointsOnRouteUseCaseProps, Result<void>> {
  private routeState: RouteState
  private logger: ILogger

  constructor() {
    const injectionContainer = InversionContainer.getInstance().getContainer()
    this.routeState = injectionContainer.get<RouteState>(InjectionTokens.RouteState)
    this.logger = injectionContainer.get<ILogger>(InjectionTokens.Logger)
  }

  execute(request: ReorderWaypointsOnRouteUseCaseProps): Result<void> {
    try {
      if (request.from === request.to) return Result.fail('There is nothing to reorder')

      const activeRoute = this.routeState.getStateSnapshot().activeRoute
      if (!activeRoute) return Result.fail('There is no active route')

      const routeWaypoints = activeRoute.waypoints
      if (!routeWaypoints) return Result.fail('There are no waypoints on the active route')

      const moveWaypoint = routeWaypoints?.[request.from]

      const routeBuilder = new RouteBuilder().copyFromRoute(activeRoute)
      routeBuilder.removeWaypoint(moveWaypoint)
      const newRoute = routeBuilder.addWaypointOnPosition(moveWaypoint.cloneWithID()!, request.to, true).build()

      this.routeState.setActiveRoute(newRoute)

      return Result.ok()
    } catch (error) {
      this.logger.error('ReorderWaypointsOnRouteUseCase', String(error))
      return Result.fail(error)
    }
  }
}

export { ReorderWaypointsOnRouteUseCase }
