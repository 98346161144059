import { useMemo } from 'react'

import GeoJSON from 'geojson'

import { MeteorologyLayer } from '@/domain/protocols/Meteorology'

import { stackIds } from '../../containers/MapStackPresenter'
import { LayerType, MapGeoJsonLayer } from '../MapView/MapGeoJsonLayer'
import { MapGeoJsonSource } from '../MapView/MapGeoJsonSource'

export interface MapMeteorologyGeoJsonLayerProps {
  id: MeteorologyLayer
  featureStyle: 'fill' | 'stroke' | 'fill-stroke' | 'point'
  geoJson: GeoJSON.FeatureCollection
  onClick: (features: GeoJSON.Feature[]) => void
}

const DEFAULT_LAYER_OPACITY: Record<MeteorologyLayer, number> = {
  'satellite-visible': 0.7,
  'satellite-infrared': 0.7,
  'satellite-highlight': 0.75,
  'radar-maxcappi': 0.75,
  sigmet: 1,
  stsc: 0.9,
  sigwx: 0.9
}

function MapMeteorologyGeoJsonLayer(props: MapMeteorologyGeoJsonLayerProps) {
  const { id, featureStyle, geoJson, onClick } = props

  const layerTypes: LayerType[] = useMemo(() => {
    switch (featureStyle) {
      case 'fill':
        return ['fill']
      case 'stroke':
        return ['line']
      case 'fill-stroke':
        return ['fill', 'line']
      case 'point':
        return ['fill']
      default:
        return []
    }
  }, [featureStyle])

  return (
    <MapGeoJsonSource id={`meteorology-${id}-source`} data={geoJson}>
      <MapGeoJsonLayer
        id={`meteorology-${id}-layer`}
        sourceId={`meteorology-${id}-source`}
        types={layerTypes}
        beforeLayerId={stackIds.meteorology}
        fillColor={['case', ['has', 'fill'], ['get', 'fill'], '#000000']}
        fillOpacity={[
          'case',
          ['has', 'fill-opacity'],
          ['*', ['get', 'fill-opacity'], DEFAULT_LAYER_OPACITY[id]],
          DEFAULT_LAYER_OPACITY[id]
        ]}
        borderColor={['case', ['has', 'stroke'], ['get', 'stroke'], '#000000']}
        borderOpacity={['case', ['has', 'stroke-opacity'], ['get', 'stroke-opacity'], 1]}
        borderWidth={['case', ['has', 'stroke-width'], ['get', 'stroke-width'], 1]}
        onBorderClick={onClick}
        onFillClick={onClick}
      />
    </MapGeoJsonSource>
  )
}

export { MapMeteorologyGeoJsonLayer }
