import { UniqueEntityID } from '@/domain/core'
import { ADHP, AwWaypoint, City, Navaid, VisualPosition, WaypointProps } from '@/domain/models/Waypoint'
import { Coordinates } from '@/domain/protocols/Coordinates'

import { WaypointsFullResponseItem } from './IAeroInfoRepository'

export interface AeroInfoMapperResponse {
  ADHPs: ADHP[]
  ADHPsFull: ADHP[]
  AwWaypoints: AwWaypoint[]
  Cities: City[]
  Navaids: Navaid[]
  VisualPositions: VisualPosition[]
}

export const AeroInfoMapper = (waypoints: WaypointsFullResponseItem[]): AeroInfoMapperResponse => {
  const ADHPs: ADHP[] = []
  const ADHPsFull: ADHP[] = []
  const AwWaypoints: AwWaypoint[] = []
  const Cities: City[] = []
  const Navaids: Navaid[] = []
  const VisualPositions: VisualPosition[] = []

  for (const info of waypoints) {
    try {
      const waypointProps: WaypointProps = {
        code: info.code,
        name: info.name,
        coordinates: Coordinates.create({
          latitude: info.coordinates.latitude,
          longitude: info.coordinates.longitude
        }),
        createdAt: new Date(info.createdAt),
        updatedAt: new Date(info.updatedAt)
      }

      switch (info.__typename) {
        case 'ADHP':
          ADHPs.push(
            ADHP.create(
              waypointProps,
              {
                type: info.extra.type,
                city: info.extra.city,
                uf: info.extra.uf
              },
              new UniqueEntityID(info.id)
            )
          )

          ADHPsFull.push(
            ADHP.create(
              waypointProps,
              {
                type: info.extra.type,
                city: info.extra.city,
                uf: info.extra.uf,
                rotaer: info.extra.rotaer,
                rotaerHTML: info.extra.rotaerHTML,
                infotemp: info.extra.infotemp,
                aipsup: info.extra.aipsup,
                notam: info.extra.notam,
                meteorology: info.extra.meteorology,
                daytime: info.extra.daytime,
                charts: info.extra.charts
              },
              new UniqueEntityID(info.id)
            )
          )
          break
        case 'AwWaypoint':
          AwWaypoints.push(AwWaypoint.create(waypointProps, {}, new UniqueEntityID(info.id)))
          break
        case 'City':
          Cities.push(City.create(waypointProps, {}, new UniqueEntityID(info.id)))
          break
        case 'Navaid':
          Navaids.push(
            Navaid.create(
              waypointProps,
              { frequency: info.extra.frequency, type: info.extra.type },
              new UniqueEntityID(info.id)
            )
          )
          break
        case 'VisualPosition':
          VisualPositions.push(
            VisualPosition.create(
              waypointProps,
              { rea: info.extra.rea, visualDesc: info.extra.visualDesc },
              new UniqueEntityID(info.id)
            )
          )
          break
      }
    } catch (error) {
      console.error(`[AeroInfoMapper] ${info}\n${error}`)
      continue
    }
  }
  return { ADHPs, ADHPsFull, AwWaypoints, Cities, Navaids, VisualPositions }
}
