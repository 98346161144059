import { useMemo } from 'react'

import GeoJSON from 'geojson'
import { useTheme } from 'styled-components'

import { CoordWaypoint } from '@/domain/models'
import { Coordinates } from '@/domain/protocols/Coordinates'
import { AddWaypointToRouteUseCase } from '@/domain/useCases/Route'

import SymbolMeasuringSegmentTooltipFinish from '@/presentation/assets/measuringSegment/measuring-segment-tooltip-finish.png'
import SymbolMeasuringSegmentTooltipMeasuring from '@/presentation/assets/measuringSegment/measuring-segment-tooltip-measuring.png'

import { stackIds } from '../../containers/MapStackPresenter'
import { MeasuringSegmentStateMutator } from '../../states/MapMeasuringSegmentState'
import { MapCircleLayer } from '../MapView/MapCircleLayer'
import { MapGeoJsonLayer } from '../MapView/MapGeoJsonLayer'
import { MapGeoJsonSource } from '../MapView/MapGeoJsonSource'
import { MapSymbolLayer } from '../MapView/MapSymbolLayer'
import { MapSymbolSource } from '../MapView/MapSymbolSource'
import {
  makeGeoJsonMeasuringSegmentPointFeatureCollection,
  makeGeoJsonMeasuringSegmentLineFeatureCollection,
  makeGeoJsonMeasuringSegmentLabelFeatureCollection
} from './GeojsonUtils'

export interface MapMeasuringSegmentProps {
  segmentCoordinates: [Coordinates, Coordinates]
  radials: [number, number]
  finish?: boolean
}

function MapMeasuringSegment(props: MapMeasuringSegmentProps) {
  const { segmentCoordinates, radials } = props

  const theme = useTheme()

  const geoJsonMeasuringSegmentPointFeatureCollection: GeoJSON.FeatureCollection = useMemo(
    makeGeoJsonMeasuringSegmentPointFeatureCollection(segmentCoordinates),
    [segmentCoordinates, radials]
  )

  const geoJsonMeasuringSegmentLineFeatureCollection: GeoJSON.FeatureCollection = useMemo(
    makeGeoJsonMeasuringSegmentLineFeatureCollection(segmentCoordinates),
    [segmentCoordinates]
  )

  const geoJsonMeasuringSegmentLabelFeatureCollection: GeoJSON.Feature = useMemo(() => {
    const index = radials[0] <= 180 ? 0 : 1

    return makeGeoJsonMeasuringSegmentLabelFeatureCollection(
      segmentCoordinates[index],
      segmentCoordinates[1 - index],
      segmentCoordinates[1],
      radials[index]
    )()
  }, [segmentCoordinates, radials])

  const handleTooltipClick = () => {
    MeasuringSegmentStateMutator.setMeasuring(false)

    const addWaypointToRouteUseCase = new AddWaypointToRouteUseCase()

    const waypoint = CoordWaypoint.create(
      {
        coordinates: segmentCoordinates[1]
      },
      null
    )

    addWaypointToRouteUseCase.execute(waypoint)
  }

  const ids = {
    source: {
      line: 'measuring-segment-source-line',
      point: 'measuring-segment-source-point',
      label: 'measuring-segment-source-label'
    },
    layer: {
      line: 'measuring-segment-layer-line',
      point: 'measuring-segment-circle-point',
      internalPoint: 'measuring-segment-circle-point-internal',
      label: 'measuring-segment-layer-label',
      labelButton: 'measuring-segment-layer-label-button'
    }
  }

  return (
    <>
      <MapGeoJsonSource id={ids.source.line} data={geoJsonMeasuringSegmentLineFeatureCollection}>
        <MapGeoJsonLayer
          types={['line']}
          id={ids.layer.line}
          sourceId={ids.source.line}
          beforeLayerId={stackIds.measuringSegment}
          borderColor={theme.colors.secondaryD3}
          borderWidth={4}
          borderOpacity={1}
        />
      </MapGeoJsonSource>
      <MapGeoJsonSource id={ids.source.point} data={geoJsonMeasuringSegmentPointFeatureCollection}>
        <MapCircleLayer
          id={ids.layer.point}
          sourceId={ids.source.point}
          beforeLayerId={stackIds.measuringSegment}
          color="#FCFCFC"
          radius={8}
          opacity={0.6}
          strokeWidth={4}
          strokeColor={theme.colors.secondaryD3}
        />
        <MapCircleLayer
          id={ids.layer.internalPoint}
          sourceId={ids.source.point}
          beforeLayerId={stackIds.measuringSegment}
          color={theme.colors.secondaryD3}
          radius={4}
        />
      </MapGeoJsonSource>
      <MapSymbolSource id={ids.source.label} data={geoJsonMeasuringSegmentLabelFeatureCollection}>
        {!props.finish && (
          <MapSymbolLayer
            id={ids.layer.label}
            imageSrc={SymbolMeasuringSegmentTooltipMeasuring}
            sourceId={ids.source.label}
            iconColor="#000"
            textColor="#fff"
            layout={{
              'text-field': ['get', 'labelText'],
              'text-size': 15,
              'text-font': ['Open Sans SemiBold'],
              'icon-size': 0.3,
              'icon-offset': [0, -120],
              'text-offset': [0, -2.7],
              'icon-allow-overlap': true,
              'text-ignore-placement': true,
              'text-allow-overlap': true,
              'text-anchor': 'center'
            }}
            minZoomLevel={0}
            beforeLayerId={stackIds.measuringSegment}
          />
        )}
        {props.finish && (
          <MapSymbolLayer
            id={ids.layer.label}
            imageSrc={SymbolMeasuringSegmentTooltipFinish}
            sourceId={ids.source.label}
            iconColor="#000"
            textColor="#fff"
            layout={{
              'text-field': ['get', 'labelText'],
              'text-size': 15,
              'text-font': ['Open Sans SemiBold'],
              'icon-size': 0.3,
              'icon-offset': [0, -120],
              'text-offset': [3.8, -2.7],
              'icon-allow-overlap': true,
              'text-ignore-placement': true,
              'text-allow-overlap': true,
              'text-anchor': 'right'
            }}
            minZoomLevel={0}
            onSymbolClick={handleTooltipClick}
            beforeLayerId={stackIds.measuringSegment}
          />
        )}
      </MapSymbolSource>
    </>
  )
}

export { MapMeasuringSegment }
