import { Subject } from 'rxjs'

import { Coordinates } from '@/domain/protocols/Coordinates'

export type MapImperativeEvent =
  | {
      type: 'flyToBounds'
      bounds: [number, number, number, number]
      padding?: [number, number, number, number]
      duration?: number
    }
  | {
      type: 'flyToCoordinates'
      coordinates: Coordinates
      zoom?: number
      duration?: number
      padding?: [number, number, number, number]
    }
  | {
      type: 'zoomIn'
    }
  | {
      type: 'zoomOut'
    }

const MapImperativeSubject = new Subject<MapImperativeEvent>()

const MapImperativeInterface = {
  flyToBounds: (
    bounds: [number, number, number, number],
    padding?: [number, number, number, number],
    duration: number = 500
  ) => {
    MapImperativeSubject.next({ type: 'flyToBounds', bounds, padding, duration })
  },

  flyToCoordinates: (
    coordinates: Coordinates,
    zoom?: number,
    duration: number = 500,
    padding?: [number, number, number, number]
  ) => {
    MapImperativeSubject.next({ type: 'flyToCoordinates', coordinates, zoom, duration, padding })
  },
  zoomIn: () => {
    MapImperativeSubject.next({ type: 'zoomIn' })
  },
  zoomOut: () => {
    MapImperativeSubject.next({ type: 'zoomOut' })
  }
}

export { MapImperativeInterface, MapImperativeSubject }
