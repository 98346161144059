import styled from 'styled-components'

interface ContextMenuProps {
  positionX?: number
  positionY?: number
  menuWidth?: number
}

export const Container = styled.div<ContextMenuProps>`
  position: absolute;
  z-index: 10;

  top: ${({ positionY }) => `${positionY ?? 0}px`};
  left: ${({ positionX }) => `${positionX ?? 0}px`};

  & * {
    ${({ theme }) => theme.fonts.infoMD}
  }
`

export const MainMenu = styled.div<ContextMenuProps>`
  width: ${({ menuWidth }) => menuWidth}px;
  white-space: nowrap;
  height: fit-content;
  background-color: ${({ theme }) => theme.colors.secondary};
  border-radius: 10px;
`

export const OptionWrapper = styled.div`
  & > div {
    // MenuItem
    & > div:first-child {
      // TextContainer
      max-width: calc(100% - 20px - 16px);
      & > p:nth-child(1) {
        // Title
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
`
