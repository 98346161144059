import { useLayoutEffect } from 'react'

import { useMapContext } from './MapContext'

interface MapStackLayerProps {
  id: string
}

function MapStackLayer(props: MapStackLayerProps): null {
  const { id } = props

  const { map } = useMapContext()

  useLayoutEffect(() => {
    if (!map) return

    map.addSource(`${id}-source`, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    })

    map.addLayer({
      id,
      type: 'raster',
      source: `${id}-source`
    })

    return () => {
      map.removeLayer(id)
    }
  }, [map])

  return null
}

export { MapStackLayer }
