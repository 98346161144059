import { Subject } from 'rxjs'

export type BackdropImperativeEvent =
  | {
      type: 'show'
      show: true
      level: number
      opacity?: number
      onClose?: () => void
    }
  | {
      type: 'hide'
      show: false
    }

const BackdropImperativeSubject = new Subject<BackdropImperativeEvent>()

const BackdropImperativeInterface = {
  show: (level: number, opacity?: number, onClose?: () => void) => {
    BackdropImperativeSubject.next({ type: 'show', show: true, level, opacity, onClose })
  },

  hide: () => {
    BackdropImperativeSubject.next({ type: 'hide', show: false })
  }
}

export { BackdropImperativeInterface, BackdropImperativeSubject }
