import styled from 'styled-components'

import { appZIndex } from '@/presentation/utils/zIndexMapper'

export const BannerMobileContainer = styled.div`
  width: 100%;
  height: 210px;
  padding: 20px 22px 25px 22px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  position: absolute;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  background-color: ${({ theme }) => theme.colors.neutralL5};
  z-index: ${appZIndex.BANNER_MOBILE};

  & > button * {
    ${({ theme }) => theme.fonts.titleXS}
  }
`

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;

  & > svg {
    min-height: 32px;
    min-width: 32px;
  }
`

export const Title = styled.h3`
  ${({ theme }) => theme.fonts.infoMD}
  color: black;

  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

export const Description = styled.p`
  ${({ theme }) => theme.fonts.textSM}
  color: ${({ theme }) => theme.colors.secondaryL2};
  min-height: 28px;

  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`
export const LinkButton = styled.a`
  color: ${({ theme }) => theme.colors.secondaryL3};
  ${({ theme }) => theme.fonts.infoSM}
  width: 100%;
  margin-top: 10px;
  text-align: center;
  cursor: pointer;
`
