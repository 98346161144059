import { BehaviorSubject } from 'rxjs'

interface LoadingStateProps {
  isLoading: boolean
}

const INITIAL_STATE: LoadingStateProps = {
  isLoading: false
}

export const LoadingState = new BehaviorSubject<LoadingStateProps>(INITIAL_STATE)

export const LoadingStateMutator = {
  mutate: (newState: LoadingStateProps) => {
    LoadingState.next(newState)
  },

  setIsLoading: (isLoading: boolean) => {
    LoadingState.next({
      isLoading
    })
  }
}
