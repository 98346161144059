import { BehaviorSubject } from 'rxjs'

import { MeteorologyLayer } from '@/domain/protocols/Meteorology'

export interface MeteorologyStateProps {
  layer: MeteorologyLayer
  time: string
}

const INITIAL_STATE: MeteorologyStateProps = {
  layer: null,
  time: null
}

export const MeteorologyState = new BehaviorSubject<MeteorologyStateProps>(INITIAL_STATE)

export const MeteorologyStateMutator = {
  mutate: (newState: MeteorologyStateProps) => {
    MeteorologyState.next(newState)
  },

  setLayer: (layer: MeteorologyLayer) => {
    MeteorologyState.next({
      ...MeteorologyState.getValue(),
      layer,
      time: null
    })
  },

  setTime: (time: string) => {
    MeteorologyState.next({
      ...MeteorologyState.getValue(),
      time
    })
  }
}
