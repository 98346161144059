import { inject, injectable } from 'inversify'

import { InjectionTokens } from '@/controller/tokens'

import { UniqueEntityID } from '@/domain/core'
import { Waypoint, WaypointType } from '@/domain/models'
import { Route } from '@/domain/models/Route/Route'
import { waypointFromPojo } from '@/domain/models/Waypoint/utils'
import { Result } from '@/domain/protocols/Result'
import { AuthState } from '@/domain/states/AuthState'

import type { IHttpClient } from '@/infra/http/IHttpClient'
import { convertDecimalDegreesToDMS } from '@/utils/coordinates'

import { IUserRoutesRepository, RequestGetRoutesResponse, RouteWaypointDTO } from './IUserRoutesRepository'

@injectable()
export class UserRoutesRepository implements IUserRoutesRepository {
  private userRoutes: Route[]
  constructor(
    @inject(InjectionTokens.HttpClient) private httpCLient: IHttpClient,
    @inject(InjectionTokens.AuthState) private authState: AuthState
  ) {}

  protected getRemoteUrl(): string {
    return `${import.meta.env.VITE_NEXATLAS_API_URL}/navigationRoute`
  }

  public async init(): Promise<Result<void>> {
    const remoteUserRoutes = await this._getRemoteRoutes()
    if (!remoteUserRoutes.isSuccess) return Result.fail(remoteUserRoutes.error)
    this.userRoutes = remoteUserRoutes.getValue()
    return Result.ok()
  }

  private async _getRemoteRoutes(): Promise<Result<Route[]>> {
    const resolveWaypointTypeNameFromId = (id: number) => {
      if (id === 1 || id === 5) return 'ADHP'
      else if (id === 2) return 'City'
      else if (id === 3) return 'CoordWaypoint'
      else if (id === 4) return 'AwWaypoint'
      else if (id === 6 || id === 7 || id === 8) return 'Navaid'
      else if (id === 9) return 'VisualPosition'
    }

    const resolveCustomName = (name: string, waypointType: number) => {
      try {
        const coordRE = /^[0-9]{6}[NS]\s[0-9]{7}[WE]$/gi
        if (waypointType === 3 && name.match(coordRE)) return null
        if (waypointType === 3) return name
      } catch (err) {
        return null
      }

      return null
    }

    const resolveMoreParamsFromWaypoint = (id: number) => {
      if (id === 1) return { type: 'AD' }
      if (id === 5) return { type: 'HP' }
      if (id === 6) return { type: 'NDB' }
      if (id === 7) return { type: 'VOR' }
      if (id === 8) return { type: 'VOR/DME' }
      return {}
    }

    try {
      const response = await this.httpCLient.get<RequestGetRoutesResponse>(`${this.getRemoteUrl()}/userRoutes`, {
        headers: {
          Authorization: `Token ${this.authState.getStateSnapshot().token}`
        }
      })

      if (response.success === false) return Result.fail(response.error)

      const routes = response.response.routes.map((remoteRoute) => {
        return Route.create(
          {
            name: remoteRoute.name,
            updatedAt: new Date(remoteRoute.updated_on * 1000),
            totalDistance: remoteRoute.total_distance,
            groundSpeed: remoteRoute.ground_speed ?? null,
            waypoints: remoteRoute.allwaypoints
              .sort((a: any, b: any) => a.sequence - b.sequence)
              .map(
                (waypoint: RouteWaypointDTO): Waypoint =>
                  waypointFromPojo({
                    id: waypoint.id,
                    waypointType: {
                      name: resolveWaypointTypeNameFromId(waypoint.waypoint_type_id),
                      codeName: resolveWaypointTypeNameFromId(waypoint.waypoint_type_id),
                      id: resolveWaypointTypeNameFromId(waypoint.waypoint_type_id)
                    },
                    coordinates: {
                      latitude: waypoint.latitude,
                      longitude: waypoint.longitude
                    },
                    code: waypoint.code,
                    name: waypoint.name,
                    customName: resolveCustomName(waypoint.name, waypoint.waypoint_type_id),
                    createdAt: new Date(waypoint.creation_date),
                    updatedAt: new Date(waypoint.creation_date),
                    extra: {
                      ...JSON.parse(waypoint.meta),
                      ...resolveMoreParamsFromWaypoint(waypoint.waypoint_type_id)
                    }
                  })
              )
          },
          new UniqueEntityID(remoteRoute.id)
        )
      })

      return Result.ok(routes)
    } catch (error) {
      return Result.fail(error)
    }
  }

  async getRoutes(needWaypoints: boolean = true): Promise<Result<Route[]>> {
    const remoteUserRoutes = await this._getRemoteRoutes()
    if (!remoteUserRoutes.isSuccess) return Result.fail(remoteUserRoutes.error)
    this.userRoutes = remoteUserRoutes.getValue()
    if (needWaypoints) return Result.ok(remoteUserRoutes.getValue())
    else {
      return Result.ok(
        remoteUserRoutes.getValue().map((route) => {
          const routeWithoutWaypoints = Route.create({ ...route.props, waypoints: [] }, route.id)
          return routeWithoutWaypoints
        })
      )
    }
  }
  getRoute(routeId: UniqueEntityID): Result<Route> {
    const route = this.userRoutes.find((route) => route.id.toString() === routeId.toString())
    if (!route) return Result.fail('route not found')
    return Result.ok(route)
  }

  async upsertRoute(route: Route): Promise<Result<Route>> {
    const resolveIdFromWaypointTypeAndWaypoint = (waypointType: WaypointType, waypoint: Waypoint) => {
      if (waypointType.codeName === 'ADHP') {
        if (waypoint.extra.type === 'AD') return 1
        if (waypoint.extra.type === 'HP') return 5
      }

      if (waypointType.codeName === 'City') return 2
      if (waypointType.codeName === 'CoordWaypoint') return 3
      if (waypointType.codeName === 'AwWaypoint') return 4

      if (waypointType.codeName === 'Navaid') {
        if (waypoint.extra.type === 'NDB') return 6
        if (waypoint.extra.type === 'VOR') return 7
        if (waypoint.extra.type === 'VOR/DME') return 8
      }

      if (waypointType.codeName === 'VisualPosition') return 9
    }

    try {
      const routeData = {
        id: route.id.toString(),
        name: route.name,
        ground_speed: route.groundSpeed > 0 ? route.groundSpeed : null,
        total_distance: route.totalDistance,
        updated_on: route.updatedAt.getTime() / 1000,
        waypoints: route.waypoints.map((waypoint: Waypoint, index: number) => ({
          id: waypoint.id.toString(),
          code: waypoint.customName || waypoint.code,
          name:
            waypoint.customName ||
            waypoint.name ||
            convertDecimalDegreesToDMS(waypoint.coordinates.latitude, waypoint.coordinates.longitude),
          route_id: route.id.toString(),
          sequence: index + 1,
          waypoint_id: waypoint.id.toString(),
          latitude: waypoint.coordinates.latitude,
          longitude: waypoint.coordinates.longitude,
          waypoint_type_id: resolveIdFromWaypointTypeAndWaypoint(waypoint.waypointType, waypoint),
          meta: waypoint.extra
        }))
      }

      await this.httpCLient.post(`${this.getRemoteUrl()}/upsertRoute`, routeData, {
        headers: {
          Authorization: `Token ${this.authState.getStateSnapshot().token}`
        }
      })

      const updatedUserRoutes = await this._getRemoteRoutes()
      if (updatedUserRoutes.isSuccess) this.userRoutes = updatedUserRoutes.getValue()

      return Result.ok(null)
    } catch (err) {
      return Result.fail(err)
    }
  }

  async deleteRoute(routeId: string): Promise<Result<void>> {
    try {
      const response = await this.httpCLient.post(
        `${this.getRemoteUrl()}/removeRoute`,
        {
          route_id: routeId.toString()
        },
        {
          headers: {
            Authorization: `Token ${this.authState.getStateSnapshot().token}`
          }
        }
      )

      if (response.success === false) return Result.fail(response.error)

      if (response.status >= 200 && response.status <= 300) {
        const result = await this._getRemoteRoutes()
        if (result.isSuccess) this.userRoutes = result.getValue()
        return Result.ok()
      } else {
        return Result.fail(`Server Error: ${response.status}`)
      }
    } catch (err) {
      return Result.fail(err)
    }
  }
}
