import styled from 'styled-components'

interface BackdropProps {
  zIndex: number
  opacity?: number
}

export const Backdrop = styled.div<BackdropProps>`
  position: absolute;
  inset: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => `${theme.colors.secondaryD1}90`};
  z-index: ${({ zIndex }) => zIndex - 1};
  opacity: ${({ opacity }) => opacity ?? 0};
`
