import SigWXLegendsClouds1 from '@/presentation/assets/meteorology/sigwx-legends-clouds-1.png'
import SigWXLegendsForecast1 from '@/presentation/assets/meteorology/sigwx-legends-forecast-1.png'
import SigWXLegendsForecast2 from '@/presentation/assets/meteorology/sigwx-legends-forecast-2.png'
import SigWXLegendsForecast3 from '@/presentation/assets/meteorology/sigwx-legends-forecast-3.png'
import SigWXLegendsForecast4 from '@/presentation/assets/meteorology/sigwx-legends-forecast-4.png'
import SigWXLegendsForecast5 from '@/presentation/assets/meteorology/sigwx-legends-forecast-5.png'
import SigWXLegendsForecast6 from '@/presentation/assets/meteorology/sigwx-legends-forecast-6.png'
import SigWXLegendsForecast7 from '@/presentation/assets/meteorology/sigwx-legends-forecast-7.png'
import SigWXLegendsForecast8 from '@/presentation/assets/meteorology/sigwx-legends-forecast-8.png'
import SigWXLegendsForecast9 from '@/presentation/assets/meteorology/sigwx-legends-forecast-9.png'
import SigWXLegendsFront1 from '@/presentation/assets/meteorology/sigwx-legends-front-1.png'
import SigWXLegendsFront10 from '@/presentation/assets/meteorology/sigwx-legends-front-10.png'
import SigWXLegendsFront11 from '@/presentation/assets/meteorology/sigwx-legends-front-11.png'
import SigWXLegendsFront12 from '@/presentation/assets/meteorology/sigwx-legends-front-12.png'
import SigWXLegendsFront13 from '@/presentation/assets/meteorology/sigwx-legends-front-13.png'
import SigWXLegendsFront14 from '@/presentation/assets/meteorology/sigwx-legends-front-14.png'
import SigWXLegendsFront15 from '@/presentation/assets/meteorology/sigwx-legends-front-15.png'
import SigWXLegendsFront16 from '@/presentation/assets/meteorology/sigwx-legends-front-16.png'
import SigWXLegendsFront17 from '@/presentation/assets/meteorology/sigwx-legends-front-17.png'
import SigWXLegendsFront18 from '@/presentation/assets/meteorology/sigwx-legends-front-18.png'
import SigWXLegendsFront19 from '@/presentation/assets/meteorology/sigwx-legends-front-19.png'
import SigWXLegendsFront2 from '@/presentation/assets/meteorology/sigwx-legends-front-2.png'
import SigWXLegendsFront3 from '@/presentation/assets/meteorology/sigwx-legends-front-3.png'
import SigWXLegendsFront4 from '@/presentation/assets/meteorology/sigwx-legends-front-4.png'
import SigWXLegendsFront5 from '@/presentation/assets/meteorology/sigwx-legends-front-5.png'
import SigWXLegendsFront6 from '@/presentation/assets/meteorology/sigwx-legends-front-6.png'
import SigWXLegendsFront7 from '@/presentation/assets/meteorology/sigwx-legends-front-7.png'
import SigWXLegendsFront8 from '@/presentation/assets/meteorology/sigwx-legends-front-8.png'
import SigWXLegendsFront9 from '@/presentation/assets/meteorology/sigwx-legends-front-9.png'
import SigWXLegendsPhenomena1 from '@/presentation/assets/meteorology/sigwx-legends-phenomena-1.png'
import SigWXLegendsPhenomena10 from '@/presentation/assets/meteorology/sigwx-legends-phenomena-10.png'
import SigWXLegendsPhenomena11 from '@/presentation/assets/meteorology/sigwx-legends-phenomena-11.png'
import SigWXLegendsPhenomena12 from '@/presentation/assets/meteorology/sigwx-legends-phenomena-12.png'
import SigWXLegendsPhenomena13 from '@/presentation/assets/meteorology/sigwx-legends-phenomena-13.png'
import SigWXLegendsPhenomena14 from '@/presentation/assets/meteorology/sigwx-legends-phenomena-14.png'
import SigWXLegendsPhenomena15 from '@/presentation/assets/meteorology/sigwx-legends-phenomena-15.png'
import SigWXLegendsPhenomena16 from '@/presentation/assets/meteorology/sigwx-legends-phenomena-16.png'
import SigWXLegendsPhenomena17 from '@/presentation/assets/meteorology/sigwx-legends-phenomena-17.png'
import SigWXLegendsPhenomena18 from '@/presentation/assets/meteorology/sigwx-legends-phenomena-18.png'
import SigWXLegendsPhenomena19 from '@/presentation/assets/meteorology/sigwx-legends-phenomena-19.png'
import SigWXLegendsPhenomena2 from '@/presentation/assets/meteorology/sigwx-legends-phenomena-2.png'
import SigWXLegendsPhenomena20 from '@/presentation/assets/meteorology/sigwx-legends-phenomena-20.png'
import SigWXLegendsPhenomena21 from '@/presentation/assets/meteorology/sigwx-legends-phenomena-21.png'
import SigWXLegendsPhenomena22 from '@/presentation/assets/meteorology/sigwx-legends-phenomena-22.png'
import SigWXLegendsPhenomena23 from '@/presentation/assets/meteorology/sigwx-legends-phenomena-23.png'
import SigWXLegendsPhenomena3 from '@/presentation/assets/meteorology/sigwx-legends-phenomena-3.png'
import SigWXLegendsPhenomena4 from '@/presentation/assets/meteorology/sigwx-legends-phenomena-4.png'
import SigWXLegendsPhenomena5 from '@/presentation/assets/meteorology/sigwx-legends-phenomena-5.png'
import SigWXLegendsPhenomena6 from '@/presentation/assets/meteorology/sigwx-legends-phenomena-6.png'
import SigWXLegendsPhenomena7 from '@/presentation/assets/meteorology/sigwx-legends-phenomena-7.png'
import SigWXLegendsPhenomena8 from '@/presentation/assets/meteorology/sigwx-legends-phenomena-8.png'
import SigWXLegendsPhenomena9 from '@/presentation/assets/meteorology/sigwx-legends-phenomena-9.png'

export const MeteorologyAssets: Record<string, string> = {
  'sigwx-legends-clouds-1.png': SigWXLegendsClouds1,
  'sigwx-legends-forecast-1.png': SigWXLegendsForecast1,
  'sigwx-legends-forecast-2.png': SigWXLegendsForecast2,
  'sigwx-legends-forecast-3.png': SigWXLegendsForecast3,
  'sigwx-legends-forecast-4.png': SigWXLegendsForecast4,
  'sigwx-legends-forecast-5.png': SigWXLegendsForecast5,
  'sigwx-legends-forecast-6.png': SigWXLegendsForecast6,
  'sigwx-legends-forecast-7.png': SigWXLegendsForecast7,
  'sigwx-legends-forecast-8.png': SigWXLegendsForecast8,
  'sigwx-legends-forecast-9.png': SigWXLegendsForecast9,
  'sigwx-legends-front-1.png': SigWXLegendsFront1,
  'sigwx-legends-front-2.png': SigWXLegendsFront2,
  'sigwx-legends-front-3.png': SigWXLegendsFront3,
  'sigwx-legends-front-4.png': SigWXLegendsFront4,
  'sigwx-legends-front-5.png': SigWXLegendsFront5,
  'sigwx-legends-front-6.png': SigWXLegendsFront6,
  'sigwx-legends-front-7.png': SigWXLegendsFront7,
  'sigwx-legends-front-8.png': SigWXLegendsFront8,
  'sigwx-legends-front-9.png': SigWXLegendsFront9,
  'sigwx-legends-front-10.png': SigWXLegendsFront10,
  'sigwx-legends-front-11.png': SigWXLegendsFront11,
  'sigwx-legends-front-12.png': SigWXLegendsFront12,
  'sigwx-legends-front-13.png': SigWXLegendsFront13,
  'sigwx-legends-front-14.png': SigWXLegendsFront14,
  'sigwx-legends-front-15.png': SigWXLegendsFront15,
  'sigwx-legends-front-16.png': SigWXLegendsFront16,
  'sigwx-legends-front-17.png': SigWXLegendsFront17,
  'sigwx-legends-front-18.png': SigWXLegendsFront18,
  'sigwx-legends-front-19.png': SigWXLegendsFront19,
  'sigwx-legends-phenomena-1.png': SigWXLegendsPhenomena1,
  'sigwx-legends-phenomena-2.png': SigWXLegendsPhenomena2,
  'sigwx-legends-phenomena-3.png': SigWXLegendsPhenomena3,
  'sigwx-legends-phenomena-4.png': SigWXLegendsPhenomena4,
  'sigwx-legends-phenomena-5.png': SigWXLegendsPhenomena5,
  'sigwx-legends-phenomena-6.png': SigWXLegendsPhenomena6,
  'sigwx-legends-phenomena-7.png': SigWXLegendsPhenomena7,
  'sigwx-legends-phenomena-8.png': SigWXLegendsPhenomena8,
  'sigwx-legends-phenomena-9.png': SigWXLegendsPhenomena9,
  'sigwx-legends-phenomena-10.png': SigWXLegendsPhenomena10,
  'sigwx-legends-phenomena-11.png': SigWXLegendsPhenomena11,
  'sigwx-legends-phenomena-12.png': SigWXLegendsPhenomena12,
  'sigwx-legends-phenomena-13.png': SigWXLegendsPhenomena13,
  'sigwx-legends-phenomena-14.png': SigWXLegendsPhenomena14,
  'sigwx-legends-phenomena-15.png': SigWXLegendsPhenomena15,
  'sigwx-legends-phenomena-16.png': SigWXLegendsPhenomena16,
  'sigwx-legends-phenomena-17.png': SigWXLegendsPhenomena17,
  'sigwx-legends-phenomena-18.png': SigWXLegendsPhenomena18,
  'sigwx-legends-phenomena-19.png': SigWXLegendsPhenomena19,
  'sigwx-legends-phenomena-20.png': SigWXLegendsPhenomena20,
  'sigwx-legends-phenomena-21.png': SigWXLegendsPhenomena21,
  'sigwx-legends-phenomena-22.png': SigWXLegendsPhenomena22,
  'sigwx-legends-phenomena-23.png': SigWXLegendsPhenomena23
}
